import { atom } from 'recoil';
import setIsGraphOpenToSocketServerEffect from '../../store/recoil/effects/setIsGraphOpenToSocketServerEffect';
import { IWidgetGraph } from '../OnlinePanel/OnlinePanelState';
import { IGraphCode } from './GraphService';

export interface IGraphCurrentPointsData {
  timestamp: string;
  address: string;
  code: string;
}

export const isGraphOpenAtom = atom<number | boolean>({
  key: 'isGraphOpen',
  default: false,
  effects: [setIsGraphOpenToSocketServerEffect()],
});

export const ecoDriveWidgetGraphAtom = atom<IWidgetGraph | null>({
  key: 'ecoDriveWidgetGraph',
  default: null,
});

export const tooltipCodeAtom = atom<IGraphCode | null>({
  key: 'tooltipCode',
  default: null,
});

export const lastClickedTimestampAtom = atom<string>({
  key: 'lastClickedGraphTimestamp',
  default: '',
});

export const graphCurrentPointsDataAtom = atom<IGraphCurrentPointsData[] | null>({
  key: 'graphCurrentPointsData',
  default: null,
});

export const speedWidgetGraphAtom = atom<IWidgetGraph | null>({
  key: 'speedWidgetGraph',
  default: null,
});

export const widgetGraphSelectedValuesAtom = atom<string[] | null>({
  key: 'widgetGraphSelectedValues',
  default: null,
});

export const throttleWidgetGraphAtom = atom<IWidgetGraph | null>({
  key: 'throttleWidgetGraph',
  default: null,
});

export const fuelLevelPercWidgetGraphAtom = atom<IWidgetGraph | null>({
  key: 'fuelLevelPercWidgetGraph',
  default: null,
});

export const fuelLevelLitersWidgetGraphAtom = atom<IWidgetGraph | null>({
  key: 'fuelLevelLitersWidgetGraph',
  default: null,
});

export const ordinaryWidgetGraphAtom = atom<IWidgetGraph | null>({
  key: 'ordinaryWidgetGraph',
  default: null,
});

export const lastGraphReloadTimestampAtom = atom<Date | null>({
  key: 'lastGraphReloadTimestamp',
  default: null,
});

export const evBatteryKwWidgetGraphAtom = atom<IWidgetGraph | null>({
  key: 'evBatteryKwWidgetGraph',
  default: null,
});
export const evBatteryPercWidgetGraphAtom = atom<IWidgetGraph | null>({
  key: 'evBatteryPercWidgetGraph',
  default: null,
});
export const adBluePercWidgetGraphAtom = atom<IWidgetGraph | null>({
  key: 'adBluePercWidgetGraph',
  default: null,
});
