import dayjs from 'dayjs';
import FormikValidatorService from './FormikValidatorService';

export const transformErrors = (errors: Record<string, string>) => {
  return Object.entries(errors).reduce((acc, [key, value]) => {
    const message = value as string;
    acc[key] = { type: key, message };
    return acc;
  }, {});
};

export const validateFields = (values, validationForForm) => {
  let formErrors = {};

  const newValues = {
    ...values,
    validUntil: dayjs(values.validUntil).toISOString(),
  };
  if (!validationForForm) {
    return formErrors;
  }
  formErrors = FormikValidatorService.validateByValidationApiResponse(newValues, validationForForm);

  return formErrors;
};
