import * as React from 'react';
import { useRecoilState } from 'recoil';
import { onPageWithFormAtom } from '../store/recoil/AppState';

/**
 * Hook for settings if user is on page with form (used for app update)
 *
 * Usage: use 'usePageWithForm()' in component body where form is used.
 *
 * @returns {null}
 */
export default function usePageWithForm(): null {
  const [onPageWithForm, setOnPageWithForm] = useRecoilState<boolean>(onPageWithFormAtom);

  React.useEffect(() => {
    if (!onPageWithForm) {
      setOnPageWithForm(true);
    }
    return () => {
      if (onPageWithForm) {
        setOnPageWithForm(false);
      }
    };
  }, [onPageWithForm, setOnPageWithForm]);

  return null;
}
