import dayjs from 'dayjs';
import { AxiosResponse } from 'axios';
import { REPORT_05, REPORT_07, REPORT_Z01 } from '.';
import ApiService from '../../Services/ApiService';
import LoaderService from '../../Services/LoaderService';
import PermissionsService, {
  PERMISSION_REPORT,
  PERMISSION_AUTO_REPORT,
} from '../../Services/PermissionsService';
import { IValidationRule } from '../../Services/FormikValidatorService';
import { IReportItem } from './types';
import { getRequest } from '../../Services/HttpService';

export const handleDownloadRequest = async (url: string, values: any) => {
  const res = await ApiService.post(url.replace('/api', ''), values);
  return res;
};

export const getDownloads = async () => {
  const response = await ApiService.get(`/v2/report/available`);
  if (response && response.data && response.data.data && response.data.data.available === true) {
    return true;
  }
  return false;
};

export const getAllowedReports = (reports: IReportItem[], selectedCustomers: number[]) => {
  const filteredOptions: IReportItem[] = [];
  reports.forEach((report) => {
    if (
      PermissionsService.hasAccess(
        `9${report.value === REPORT_Z01 ? '11' : `0${report.value}`}`,
        ['read'],
        selectedCustomers
      )
    ) {
      filteredOptions.push(report);
    }
  });
  return filteredOptions;
};

export const getReports = async (selectedCustomers: number[]) => {
  LoaderService.showLoader();
  const response = await ApiService.get(`/v2/report/list`);
  LoaderService.showLoader(false);
  if (response.data && response.data.reports && response.status === 200) {
    const options = response.data.reports.map((i: any) => {
      return {
        item: `${i.code} - ${i.name}`,
        value: String(i.id),
        tooltip: i.description,
        actionUrl: i.actionUrl,
      };
    });
    return getAllowedReports(options, selectedCustomers);
  }
  return false;
};

export const getVehicles = async (reportId: string) => {
  LoaderService.showLoader();
  const response = await ApiService.get(`/v2/report/form/vehicleIds`, {
    reportId,
  });
  LoaderService.showLoader(false);
  if (response.data && response.status === 200) {
    const vehicles = [...response.data];
    vehicles.map((item) => {
      item.value = String(item.value);
      return item;
    });
    return vehicles;
  }
  return false;
};

export const handleReportSettings = (
  userSettingsFromResponse: any,
  reportSettingsOptions: string[],
  reportId?: string
) => {
  let userSettings = null;
  if (userSettingsFromResponse && Object.keys(userSettingsFromResponse).length > 0) {
    userSettings = userSettingsFromResponse;
  }
  if (userSettings) {
    const reportSettings: string[] = [];
    Object.keys(userSettings).forEach((key: string) => {
      if (userSettings && userSettings[key] === true && reportSettingsOptions.includes(key)) {
        reportSettings.push(key);
      }
    });
    return reportSettings;
  }
  return reportId === REPORT_05 ? [] : reportSettingsOptions;
};

export const handleAllowedRange = (dateValidation: IValidationRule[]) => {
  if (dateValidation) {
    const minDate = dateValidation.find((i) => i.param === 'minDate');
    const maxDate = dateValidation.find((i) => i.param === 'maxDate');
    if (minDate && maxDate) {
      return [minDate.value, maxDate.value];
    }
    return undefined;
  }
  return undefined;
};

export const getDrivers = async (reportId: string) => {
  LoaderService.showLoader();
  const response = await ApiService.get(`/v2/report/form/driverId`, {
    reportId,
  });
  LoaderService.showLoader(false);
  if (response.data && response.status === 200) {
    const drivers = [...response.data];
    drivers.map((item) => {
      item.value = String(item.value);
      return item;
    });
    return drivers;
  }
  return false;
};

export const getCustomers = async (reportId: string) => {
  LoaderService.showLoader();
  const response = await ApiService.get(`/v2/report/form/customerIds`, {
    reportId,
  });
  LoaderService.showLoader(false);
  if (response.data && response.status === 200) {
    const customers = [...response.data];
    customers.map((item) => {
      item.value = String(item.value);
      return item;
    });
    return customers;
  }
  return false;
};
export const getBluecoins = async (reportId: string) => {
  LoaderService.showLoader();
  const response: AxiosResponse | false = await getRequest(
    `/v2/report/form/bluecoinIds?reportId=${reportId}`,
    {
      returnAxiosResponse: true,
    }
  );
  LoaderService.showLoader(false);
  if ((response as AxiosResponse).data && (response as AxiosResponse).status === 200) {
    const bluecoins = [...(response as AxiosResponse).data];
    bluecoins.map((item) => {
      item.value = String(item.value);
      return item;
    });
    return bluecoins;
  }
  return false;
};

export const getValidation = async (reportId: string) => {
  LoaderService.showLoader();
  const response = await ApiService.get(`${`/v2/report/validation`}${`?reportId=${reportId}`}`);
  LoaderService.showLoader(false);
  if (
    response.data &&
    response.data.report &&
    response.data.report.form &&
    response.status === 200
  ) {
    return response.data.report;
  }
  return false;
};

export const getValidationWithEntity = async (reportId: string, entity: string) => {
  LoaderService.showLoader();
  const response = await ApiService.get(
    `${`/v2/report/validation`}${`?reportId=${reportId}&entity=${entity}`}`
  );
  LoaderService.showLoader(false);
  if (
    response.data &&
    response.data.report &&
    response.data.report.form &&
    response.status === 200
  ) {
    return response.data.report;
  }
  return false;
};

export const getDatepickerMessage = (range: string) => {
  let message = '';
  switch (range) {
    case 'month':
      message = 'errors.reports.cannotSelectDateLongerThanMonth';
      break;
    case '3months':
      message = 'errors.reports.cannotSelectDateLongerThan3Month';
      break;
    case 'year':
      message = 'errors.reports.cannotSelectDateLongerThanYear';
      break;
    default:
  }
  return message;
};

export const getReportRefDate = (reportId: string, date: Date, range: string, mergeBy?: string) => {
  if (reportId === REPORT_07 && (mergeBy === 'year' || mergeBy === 'month')) {
    range = 'year';
  } else if (reportId === REPORT_Z01) {
    range = 'month';
  }
  let refDate = new Date();
  const newDate = dayjs(new Date(date))
    .set('second', 0)
    .set('millisecond', 0)
    .set('minute', 0)
    .set('hour', 24)
    .toDate();
  switch (range) {
    case 'month':
      refDate = new Date(newDate.setMonth(newDate.getMonth() - 1));
      break;
    case '3months':
      refDate = new Date(newDate.setMonth(newDate.getMonth() - 3));
      break;
    case 'year':
      refDate = new Date(newDate.setFullYear(newDate.getFullYear() - 1));
      break;
    default:
  }
  return refDate;
};

export const getRangeValidity = (
  reportId: string,
  fromDate: Date,
  toDate: Date,
  range: string,
  mergeBy?: string
) => {
  if (getReportRefDate(reportId, toDate, range, mergeBy).getTime() > new Date(fromDate).getTime()) {
    return false;
  }
  return true;
};

export const handleDatepickerValidation = (dates: Date[], reportId: string, range: string) => {
  if (dates && dates.length === 2) {
    const dateFrom = dates[0];
    const dateTo = dates[1];
    if (!getRangeValidity(reportId, dateFrom, dateTo, range)) {
      return getDatepickerMessage(range);
    }
    return '';
  }
  return '';
};

export const getReportsAccess = (types: string[], selectedCustomers: number[]) => {
  return (
    PermissionsService.hasAccess(`${PERMISSION_REPORT}01`, types, selectedCustomers) ||
    PermissionsService.hasAccess(`${PERMISSION_REPORT}02`, types, selectedCustomers) ||
    PermissionsService.hasAccess(`${PERMISSION_REPORT}03`, types, selectedCustomers) ||
    PermissionsService.hasAccess(`${PERMISSION_REPORT}04`, types, selectedCustomers) ||
    PermissionsService.hasAccess(`${PERMISSION_REPORT}05`, types, selectedCustomers) ||
    PermissionsService.hasAccess(`${PERMISSION_REPORT}06`, types, selectedCustomers) ||
    PermissionsService.hasAccess(`${PERMISSION_REPORT}07`, types, selectedCustomers) ||
    PermissionsService.hasAccess(`${PERMISSION_REPORT}08`, types, selectedCustomers) ||
    PermissionsService.hasAccess(`${PERMISSION_REPORT}09`, types, selectedCustomers) ||
    PermissionsService.hasAccess(`${PERMISSION_REPORT}11`, types, selectedCustomers)
  );
};
