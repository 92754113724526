import { atom } from 'recoil';
import { IRidePlayerSpeedLimitsSettings } from './types';

export const speedlimitSettingsAtom = atom<IRidePlayerSpeedLimitsSettings>({
  key: 'speedlimitSettings',
  default: { minimumSpeed: 50, warningSpeed: 90, maximumSpeed: 130 },
});

export const actualPlayPositionAtom = atom<number>({
  key: 'actualPlayPosition',
  default: 0,
});

export const activeRideNumberAtom = atom<number>({
  key: 'activeRideNumber',
  default: 1,
  // effects: [
  //   ({ onSet }) => {
  //     onSet((newActiveRideNumber) => {
  //       console.debug('Active ride number changed: ', newActiveRideNumber);
  //     });
  //   },
  // ],
});

export const stopAtom = atom<boolean>({
  key: 'stop',
  default: false,
});

export const pauseAtom = atom<boolean>({
  key: 'pause',
  default: false,
});

export const playAtom = atom<boolean>({
  key: 'play',
  default: false,
});

export const nextAtom = atom<boolean>({
  key: 'next',
  default: false,
});

export const prevAtom = atom<boolean>({
  key: 'prev',
  default: false,
});

export const activeLastRideIdAtom = atom<string | null>({
  key: 'activeLastRideId',
  default: null,
});

export const reloadLastRideAtom = atom<boolean>({
  key: 'reloadLastRide',
  default: false,
});

export const showLastRideChangedWarningAtom = atom<boolean>({
  key: 'showLastRideChangedWarning',
  default: false,
});

export const ignoreRideChangeAtom = atom<boolean>({
  key: 'ignoreRideChange',
  default: false,
});

export const enableArrowsAtom = atom<boolean>({
  key: 'enableArrows',
  default: true,
});

export const enableSpeedlimitsAtom = atom<boolean>({
  key: 'enableSpeedlimits',
  default: true,
});

export const enableWaitingsAtom = atom<boolean>({
  key: 'enableWaitings',
  default: true,
});

export const rideAutofocusAtom = atom<boolean>({
  key: 'rideAutofocus',
  default: false,
});

export const enableFitBoundsAtom = atom<boolean>({
  key: 'enableFitBounds',
  default: true,
});

export const isRidePlayedAtom = atom<boolean>({
  key: 'isRidePlayed',
  default: false,
});

export const playerSpeedAtom = atom<number>({
  key: 'playerSpeed',
  default: 1,
});

export const showDataLayerAtom = atom<boolean>({
  key: 'showDataLayer',
  default: false,
});

export const showSelectRidesModalAtom = atom<boolean>({
  key: 'showSelectRidesModal',
  default: false,
});

export const selectedRidesAtom = atom<number[]>({
  key: 'selectedRides',
  default: [],
});

export const isRideActiveAtom = atom<number | false>({
  key: 'isRideActive',
  default: false,
});

export const autoplayAtom = atom<boolean>({
  key: 'autoplay',
  default: false,
});

export const showAllRidesAtom = atom<boolean>({
  key: 'showAllRides',
  default: true,
});

export const activeRideIndexAtom = atom<number>({
  key: 'activeRideIndex',
  default: 0,
});
