import { getRequest } from './HttpService';

export interface ICurrency {
  id: number;
  code: string;
}

export const getCurrencies = async (): Promise<ICurrency[] | null> => {
  const response = await getRequest<ICurrency[]>(`/v1/currency`);
  if (response) {
    return response as ICurrency[];
  }
  return null;
};
