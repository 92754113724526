import localforage from 'localforage';
import { DefaultValue } from 'recoil';

export const localForageEffect =
  (key: string) =>
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  ({ setSelf, onSet }): void => {
    setSelf(
      localforage.getItem(key).then(
        (savedValue: any) => (savedValue != null ? savedValue : new DefaultValue()) // Abort initialization if no value was stored
      )
    );

    onSet((newValue: any, _, isReset: boolean) => {
      if (isReset) {
        localforage.removeItem(key);
      } else {
        localforage.setItem(key, newValue);
      }
    });
  };

export default localForageEffect;
