import localforage from 'localforage';

// export const createLocalForageStore = localforage.createInstance({
//   name: 'ccc',
//   storeName: 'keyvaluepairs',
//   description: 'Some description',
//   driver: [localforage.INDEXEDDB, localforage.WEBSQL, localforage.LOCALSTORAGE],
// });

export const localForageStore = localforage.createInstance({
  driver: [localforage.INDEXEDDB, localforage.WEBSQL, localforage.LOCALSTORAGE],
});
