const settings = {
  GoogleMaps: {
    version: '3',
    client: 'gme-commandersystems',
    libraries: ['drawing', 'visualization', 'places', 'geometry'],
    apiKey: import.meta.env.VITE_GOOGLE_API_KEY, // 'AIzaSyDbarjL05zh3vPu2gaAt6H2KGGemFAreoI'
  },
  Vehicle: {
    statusClass: {
      DRIVING: 'green',
      STOP: 'red',
      NO_GPS: 'blue',
      NO_GSM: 'yellow',
    },
    typeClass: {
      1: 'icon-car', // Car
      2: 'icon-van', // Van
      3: 'icon-truck', // Truck
      4: 'icon-car', // Person (icon missing)
      5: 'icon-car', // Loader (icon missing)
      6: 'icon-tractor', // Tractor
      7: 'icon-motorcycle', // Motorcycle
      8: 'icon-semi_trailer', // Trailer (icon missing)
      9: 'icon-car', // Train (icon missing)
      10: 'icon-helicopter',
      11: 'icon-boat', // Boat
      12: 'icon-bike', // Bike
      13: 'icon-plane', // Plane
      15: 'icon-car', // Clearing device (icon missing)
      16: 'icon-bus', // Bus
      17: 'icon-car', // Excavator (icon missing)
      18: 'icon-car', // Dozer (icon missing)
      19: 'icon-car', // Roller (icon missing)
      21: 'icon-car', // Truck 3 (icon missing)
      22: 'icon-car', // Truck 4 (icon missing)
      23: 'icon-car', // Semitruck (icon missing)
      30: 'icon-semi_trailer', // Semitrailer
      32: 'icon-pickup', // Pickup
      33: 'icon-lorry', // Lorry
      36: 'icon-ambulance', // Ambulance
      38: 'icon-power_generator', // Power generator
      39: 'icon-tractor_advanced', // Tractor andvanced
    },
    typeName: {
      1: 'car',
      2: 'van',
      3: 'truck',
      4: 'person',
      5: 'loader',
      6: 'tractor',
      7: 'motorcycle',
      8: 'trailer',
      9: 'train',
      10: 'helicopter',
      11: 'boat',
      12: 'bicycle',
      13: 'plane',
      15: 'clearing_vehicle',
      16: 'bus',
      17: 'excavator',
      18: 'dozer',
      19: 'roller',
      21: 'truck3',
      22: 'truck4',
      23: 'semi_truck',
      30: 'semi_trailer',
      32: 'pickup',
      33: 'lorry',
      36: 'ambulance',
      38: 'power_generator',
      39: 'tractor_advanced',
      40: 'wood_export',
      41: 'harvester',
    },
  },
  Files: {
    binaryMimeTypes: [
      'image/x-icon',
      'image/png',
      'image/gif',
      'image/jpeg',
      'application/pdf',
      'application/vnd.ms-office',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/octet-stream',
    ],
    textMimeTypes: ['text/plain'],
    extensionMimeTypes: {
      html: 'text/html',
      jpg: 'image/jpg',
      jpeg: 'image/jpg',
      png: 'image/png',
      svg: 'image/svg+xml',
      gif: 'image/gif',
      ico: 'image/x-icon',
      css: 'text/css',
      less: 'text/css',
      sass: 'text/css',
      scss: 'text/css',
      ttf: 'application/x-font-ttf',
      woff: 'application/font-woff',
      woff2: 'application/font-woff2',
    },
  },
  Table: {
    service_book: {
      tableLink: 'service-book',
      tableName: 'table.serviceBook',
      tabs: ['table.vehicleRecords'],
      activeTab: 'table.vehicleRecords',
      navigator: true,
    },
    refuelings: {
      tableLink: 'refueling',
      tableName: 'table.refuelings',
      tabs: ['table.refuelings'],
      activeTab: 'table.refuelings',
      navigator: true,
    },
    corrections: {
      tableLink: 'tacho-correction',
      tableName: 'table.corrections',
      tabs: ['table.corrections'],
      activeTab: 'table.corrections',
      navigator: true,
    },
    overview_tours: {
      tableLink: 'ride-book',
      tableName: 'table.overviewTours',
      tabs: ['table.dayToDay'],
      activeTab: 'table.dayToDay',
      navigator: true,
    },
    'overview_tours/detail': {
      tableLink: 'overview_tours/detail',
      tableName: 'rides.rideDetail',
      tabs: ['rides.rideDetail'],
      activeTab: 'rides.rideDetail',
      navigator: false,
    },
    system_settings: {
      tableLink: 'ride-tag',
      tableName: 'table.systemSettings',
      tabs: ['table.purposes', 'table.parkingPurposes'],
      activeTab: 'table.purposes',
      navigator: false,
    },
    'system_settings/parking': {
      tableLink: 'parking-tag',
      tableName: 'table.systemSettings',
      tabs: ['table.purposes', 'table.parkingPurposes'],
      activeTab: 'table.parkingPurposes',
      navigator: false,
    },
    // cars_settings: {
    //   tableLink: 'driver',
    //   tableName: 'table.systemSettings',
    //   tabs: ['table.purposes'],
    //   navigator: false,
    // },
    drivers_settings: {
      tableLink: 'driver',
      tableName: 'table.driverSettings',
      tabs: ['table.driversList'],
      activeTab: 'table.driversList',
      navigator: false,
    },
  },
  widgetColors: {
    vehicleCard: {
      STOP: '#b50536',
      NO_GPS: '#3d72c8',
      NO_GSM: '#865E1D',
      DRIVING: '#05b590',
      UNKNOWN: '#737373',
    },
    waypoint: {
      active: '#05B590',
    },
  },
  statusColor: {
    DRIVING: { fill: '77E4CD', stroke: '146755', selected: '05B590' },
    STOP: { fill: 'DD6486', stroke: '541325', selected: 'B50536' },
    NO_GPS: { fill: '9ABAEE', stroke: '0D336F', selected: '3d72c8' },
    NO_GSM: { fill: 'F8C27E', stroke: '865E1D', selected: 'f8c27e' },
    UNKNOWN: { fill: 'ffffff', stroke: '494949', selected: '494949' },
    PRIVATE: { fill: 'C8C8C8', stroke: '737373', selected: '737373' },
  },
  navigatorStatusColor: {
    DRIVING: { fill: '77E4CD', stroke: '146755', selected: 'FFFFFF', selectedStroke: '05B590' },
    STOP: { fill: 'DD6486', stroke: '541325', selected: 'FFFFFF', selectedStroke: 'DD6486' },
    NO_GPS: { fill: '9ABAEE', stroke: '0D336F', selected: 'FFFFFF', selectedStroke: '3D72C8' },
    NO_GSM: { fill: 'FFD8A0', stroke: '865E1D', selected: 'FFFFFF', selectedStroke: 'F8C27E' },
    UNKNOWN: { fill: '191C1F', stroke: '494949', selected: '494949', selectedStroke: 'FFFFFF' },
    PRIVATE: { fill: '737373', stroke: '494949', selected: 'FFFFFF', selectedStroke: '737373' },
  },
  bluecoinStatusColor: {
    ACTIVE: { fill: 'DAA9CB', stroke: '7B235F', selected: 'FFFFFF' },
    INACTIVE: { fill: 'C8C8C8', stroke: '737373', selected: 'FFFFFF' },
  },
  onlineMap: {
    superCluster: {
      radius: 75,
      maxZoom: 20,
      minPoints: 5,
    },
    superClusterBluecoins: {
      radius: 75,
      maxZoom: 20,
      minPoints: 2,
    },
    toolbar: {
      waypointsControl: true,
      autoFocusControl: true,
      trafficLayerControl: true,
      vehicleSearchControl: true,
      vehicleFocusControl: true,
      vehicleChipsControl: true,
      bluecoinsControl: true,
    },
  },
  selectColor: {
    backgroundColor: '#D1E9FF',
  },
};

export default settings;
