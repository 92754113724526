import * as React from 'react';
import { useLocation } from 'wouter';

export default function usePrevLocation() {
  const [location] = useLocation();
  const [prevLocation, setPrevLocation] = React.useState<null | string>(null);
  const lastSavedLocation = React.useRef<null | string>(null); // temp buffer

  React.useEffect(() => {
    // this function will be called each time the location changes
    setPrevLocation(lastSavedLocation.current);

    lastSavedLocation.current = location;
  }, [location]);

  return prevLocation;
}
