import * as React from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useIntl } from 'react-intl';
import { useLocation } from 'wouter';
import dayjs from 'dayjs';
import { CmdToastButton } from '@commander-services/gui-components/build/components/CmdToast';
import PermissionsService, {
  PERMISSION_AUTO_REPORT,
  PERMISSION_GDPR_PROFILE_LIST,
  PERMISSION_GDPR_USER_CHANGE_PROFILE,
  PERMISSION_ONLINE_MAP_TEMPLATES_ASSIGN,
  PERMISSION_ONLINE_MAP_TEMPLATES_ASSIGNMENT,
  PERMISSION_ONLINE_MAP_TEMPLATES_ASSIGNMENT_DEFAULT,
  PERMISSION_ONLINE_MAP_TEMPLATES_CUSTOMER,
  PERMISSION_ONLINE_MAP_TEMPLATES_PRIVATE,
  PERMISSION_ONLINE_MAP_TEMPLATES_SYSTEM,
  PERMISSION_PERIOD_LOCK_ADMIN,
  PERMISSION_TACHO_CORRECTION,
  PERMISSION_TACHO_CORRECTION_ADMIN,
} from '../../../../Services/PermissionsService'; // eslint-disable-line import/no-unresolved
import TableService, {
  TABLE_REFUELINGS_IMPORT_RECORDS,
  TABLE_SUPPLIER,
  TABLE_OPERATING_COSTS,
  TABLE_OPERATING_COSTS_TYPES,
  TABLE_AUTO_REPORT,
  TABLE_TEMPLATES,
  TABLE_PERIOD_LOCK,
  TABLE_PERIOD_LOCK_DEFINITION,
  TABLE_GDPR_PROFILES,
  TABLE_CODEBOOKS_RIDE,
  TABLE_CODEBOOKS_PARKING,
  TABLE_REFUELINGS_IMPORT,
  TABLE_GDPR_PROFILES_HISTORY,
  TABLE_PERSON,
  TABLE_GDPR_USER,
  TABLE_ONLINE_MAP_ASSIGN,
  TABLE_CORRECTIONS,
  TABLE_ALARM_LOG,
  TABLE_GDPR_USER_HISTORY,
  TABLE_ALARM,
  TABLE_AUTO_REPORT_LOG,
  TABLE_FUEL_CARD,
  TABLE_REFUELING,
} from '../../../../Services/TableService';
import TableTd from './TableTd';
import ShowLogRefueling from '../../../Wizard/ShowLogRefueling';
import ConfirmDialog from '../../../ConfirmDialog';
import ReportService from '../../../../Services/ReportService';
import OnlinePanelPreview from '../../../OnlinePanel/OnlinePanelPreview/OnlinePanelPreview';
import PeriodLockService from '../../../../Services/PeriodLockService';
import showMessage from '../../../Toastr/ToastService';
import ActionsMenu from './ActionsMenu';
import {
  URL_ALARM,
  URL_AUTO_REPORTS,
  URL_CODEBOOKS,
  URL_EXPENSE_IMPORT,
  URL_GDPR_PROFILE,
  URL_GDPR_USER,
} from '../../../../router/constants';
import { ITableFilter, setTableActiveRow, setTableFilterAtom, tablesAtom } from '../../../../store/recoil/TableState';
import LoaderService from '../../../../Services/LoaderService';
import { IDynamicTableState } from '../../../../interfaces/tables';
import ConfirmToaster from '../../../Forms/ConfirmToaster/ConfirmToaster';
import { STATUS_ERROR } from '../../../../Services/ApiService';
import { isSubPageOpen } from '../../../../store/recoil/SubpageState';
import { activateAlarm, deactivateAlarm } from '../../../Alarms/AlarmService';
import { postRequest } from '../../../../Services/HttpService';

const REMOVE = 'remove';
const ACTIVATE_ALARM = 'action_activate';
const DEACTIVATE_ALARM = 'action_deactivate';
const DETAIL = 'detail';
const ADD_NOTE = 'action_edit_note';
const LOG = 'action_display_log';
const EDIT_REFUELING = 'edit'; // Why not just edit?
export const PENDING = 'action_status_pending';
export const IGNORE = 'action_status_ignore';
const CUSTOM_REMOVE = 'action_remove';
const CUSTOM_EDIT = 'action_edit';
const ALARM_LOG = 'action_showLogs';
const HISTORY = 'action_history';
const ASSIGN_DEFAULT = 'action_assignDefault';
const ASSIGN = 'action_assign';
const ACTION_PREVIEW = 'action_preview';
const PERIOD_LOCK_DEACTIVATE = 'action_period_lock_definition_disable';
const PERIOD_LOCK_ACTIVATE = 'action_period_lock_definition_enable';
const PERIOD_UNLOCK = 'action_status_unlock';
const PERIOD_LOCK = 'action_status_lock';
const PERIOD_LOCK_EDIT_LOCKAFTER = 'action_edit_lockAfter';
const ASSIGN_PROFILE = 'action_assign-profile';
const ASSIGNED_TO_RIDE = 'assignedToRide';
const ASSIGNED_USERS = 'assignedUsers';
const ASSIGNES_ROLES = 'assignedRoles';
const LOCK_AFTER = 'lockAfter';

interface IComponentProps {
  id: any;
  data: any;
  columns: any;
  tableName: string;
  rowHeight: boolean;
  actionSave: any;
  multipleActions: any;
  activeRows: any;
  moreThanTwoActions: boolean;
  firstItems: boolean;
  lastItems: boolean;
  lastItem: boolean;
  dateFormat: string;
  records?: any;
  countRow?: number;
  handleForm?: any;
  openForm?: any;
  multipleActionsAllowed: boolean;
}

interface IHandleParams {
  id: number;
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>;
  type?: string;
  customerId?: number;
  locked?: string;
}

export interface IAction {
  icon: string;
  id: number;
  link?: string | null;
  title: string;
  type: string;
  disabled?: boolean;
}

function TableRow(props: IComponentProps): JSX.Element {
  const [pathname, navigate] = useLocation();
  const { formatMessage: f } = useIntl();
  const setTableFilter = useSetRecoilState<ITableFilter | null>(setTableFilterAtom);
  const [open, setOpen] = React.useState<boolean>(false);
  const [clientBottom, setClientBottom] = React.useState<number>(0);
  const [isOpenLog, setIsOpenLog] = React.useState(false);
  const [logSummary, setLogSummary] = React.useState([]);
  const [isButtonRemoveClicked, setIsButtonRemoveClicked] = React.useState(false);
  const [removeId, setRemoveId] = React.useState(0);
  const [isOnlinePanelPreviewOpen, setIsOnlinePanelPreviewOpen] = React.useState(false);
  const [firstColumn, setFirstColumn] = React.useState<string>('');
  const [isAction, setIsAction] = React.useState<boolean>(false);
  const [clientY, setClientY] = React.useState(0);
  const reportTableName =
    props.tableName === TABLE_AUTO_REPORT && props.data && props.data.reportId
      ? `${PERMISSION_AUTO_REPORT}${Number(props.data.reportId) < 10 ? '0' : ''}${
          props.data.reportId
        }`
      : null;
  const gdprProfileName =
    props.tableName === TABLE_GDPR_PROFILES ? PERMISSION_GDPR_PROFILE_LIST : null;
  const [isButtonPeriodClicked, setIsButtonPeriodClicked] = React.useState<string>('');
  const setIsEditFormClicked = useRecoilState<boolean>(isSubPageOpen)[1];
  const setTables = useSetRecoilState<IDynamicTableState>(tablesAtom);

  const [isUpdatingAllowed, setIsUpdatingAllowed] = React.useState(
    props.tableName === TABLE_CORRECTIONS
      ? PermissionsService.hasAccess(
          PERMISSION_TACHO_CORRECTION,
          ['update'],
          props.data.customerId || null
        ) &&
          PermissionsService.hasAccess(
            PERMISSION_TACHO_CORRECTION,
            ['read'],
            props.data.customerId || null
          )
      : PermissionsService.hasAccess(
          reportTableName ||
            (props.tableName === TABLE_ONLINE_MAP_ASSIGN
              ? PERMISSION_ONLINE_MAP_TEMPLATES_ASSIGN
              : props.tableName),
          ['update'],
          props.data.customerId || null
        )
  );

  React.useEffect(() => {
    const columns = Object.keys(props.columns)
      .filter((j) => !props.columns[j].system)
      .filter((j) => props.columns[j].visible);
    setFirstColumn(columns[0]);
  }, [props.columns]);

  const getSystemData = (column: string) => {
    const { tableName, data } = props;
    let systemData: any = null;
    switch (tableName) {
      case TABLE_PERIOD_LOCK_DEFINITION:
        if (column === LOCK_AFTER && data.lockAfterPeriods && data.lockAfterPeriods.length > 0) {
          systemData = data.lockAfterPeriods;
        }
        break;
      case TABLE_GDPR_USER:
        if (
          column === ASSIGNES_ROLES &&
          data.assignedRolesList &&
          data.assignedRolesList.length > 0
        ) {
          systemData = data.assignedRolesList;
        }
        break;
      case TABLE_GDPR_PROFILES:
        if (
          column === ASSIGNED_USERS &&
          props.columns.customerId &&
          props.columns.customerId.filter &&
          props.columns.customerId.filter.selectedValue
        ) {
          systemData = { filteredCustomerId: props.columns.customerId.filter.selectedValue };
        }
        break;
      case TABLE_REFUELING:
        if (column === ASSIGNED_TO_RIDE) {
          systemData = {
            hasPositions: !!(data.positionCount && data.positionCount > 0),
          };
        }
        break;
      case TABLE_CORRECTIONS:
        if (column === ASSIGNED_TO_RIDE) {
          systemData = {
            hasPositions: !!(data.positionCount && data.positionCount > 0),
          };
        }
        break;
      default:
    }
    return systemData;
  };

  const getLockedRideBoolean = React.useCallback(
    (locked: string, type: string) => {
      if (
        props.tableName === TABLE_CORRECTIONS &&
        PermissionsService.hasAccess(
          PERMISSION_TACHO_CORRECTION_ADMIN,
          [type],
          props.data.customerId
        )
      ) {
        return false;
      }
      return (
        locked === 'locked' ||
        (locked === 'restricted' &&
          !PermissionsService.hasAccess(
            PERMISSION_PERIOD_LOCK_ADMIN,
            [type],
            props.data.customerId
          ))
      );
    },
    [props]
  );

  const handleClickOutside = React.useCallback(
    (event: any) => {
      const { data } = props;
      let id = null;
      const idArr = event.target.id.split('-');

      if (
        idArr &&
        idArr.length === 2 &&
        (idArr[0] === 'actionsMenu' || idArr[0] === 'actionsMenuIcon')
      ) {
        id = idArr[1];
      }
      if (String(id) !== String(data.id)) {
        setOpen(false);
      }
    },
    [props]
  );

  const handleKeyboard = (event: any) => {
    if (event.keyCode === 27) {
      setOpen(false);
    }
  };

  const openMenu = (event: any) => {
    event.preventDefault();
    const element = document.getElementById(`actionsMenu-${props.data.id}`);
    if (element) {
      const elementInfo = element.getBoundingClientRect();
      setClientY(Number(elementInfo.y));
    }
    setClientBottom(Number(window.innerHeight) - Number(event.clientY));
    setOpen(!open);
  };

  const handleSpecialSubmit = (button: string) => {
    switch (button) {
      case 'check':
        // function
        break;
      default:
        console.error('No action');
    }
  };

  // const handleRemove = async (e: any, id: any) => {
  const handleRemove = async (removeParams: IHandleParams) => {
    const id = removeParams.id;
    const e = removeParams.event;
    e.preventDefault();
    const { tableName } = props;
    const { customerId } = props.data;

    LoaderService.showLoader();

    const response = await TableService.remove(tableName, id, customerId);

    if (response) {
      props.actionSave(null);
    }
    LoaderService.showLoader(false);
  };

  const handleFormGeneration = async (id: any, actionTypes?: string) => {
    const { tableName, data } = props;
    const { customerId } = data;
    LoaderService.showLoader();

    const response = await TableService.getFormData(tableName, id, customerId, actionTypes);

    if (response && response.status === 'ok') {
      const oldInputs = [...response.form.inputs];
      if (tableName !== TABLE_REFUELINGS_IMPORT_RECORDS) {
        props.handleForm(oldInputs, id, customerId || null, data.locked || null);
      } else {
        const newInputs = [...oldInputs];
        newInputs.map((item: any) => {
          Object.keys(item).map((name: any) => {
            if (item[name] === 'accountingTs') {
              const date = dayjs(item.value).format('DD.MM.YYYY HH:mm');

              item.value = date;
            }
          });
        });
        props.handleForm(newInputs, id, customerId || null, actionTypes);
      }
    }
    LoaderService.showLoader(false);
  };

  const getIsEditableModalFromUrl = (type?: string) => {
    if (
      props.tableName === TABLE_FUEL_CARD ||
      props.tableName === TABLE_OPERATING_COSTS_TYPES ||
      props.tableName === TABLE_SUPPLIER ||
      props.tableName === TABLE_CORRECTIONS ||
      props.tableName === TABLE_CODEBOOKS_RIDE ||
      props.tableName === TABLE_CODEBOOKS_PARKING ||
      (props.tableName === TABLE_REFUELINGS_IMPORT_RECORDS && type !== PENDING && type !== IGNORE)
    ) {
      return true;
    }
    return false;
  };

  const handleEdit = React.useCallback(
    async (params: IHandleParams) => {
      const event = params.event;
      const id = params.id;
      const actionTypes = params.type;
      // async (id: any, event?: any, actionTypes?: string) => {
      if (event) {
        event.preventDefault();
      }
      const { tableName, data } = props;
      // const { customerId } = data;
      const customerId = params.customerId;
      const tempPath = pathname.split('/');

      if (tableName !== TABLE_TEMPLATES && tableName !== TABLE_GDPR_PROFILES) {
        switch (tableName) {
          case TABLE_CODEBOOKS_RIDE:
            navigate(
              `${URL_CODEBOOKS}/ride-tag/edit/${id}${customerId ? `?customerId=${customerId}` : ''}`
            );
            break;
          case TABLE_CODEBOOKS_PARKING:
            navigate(
              `${URL_CODEBOOKS}/parking-tag/edit/${id}${
                customerId ? `?customerId=${customerId}` : ''
              }`
            );
            break;
          case TABLE_PERIOD_LOCK_DEFINITION:
            navigate(
              `/${tempPath[1]}/definition/edit/${id}${
                customerId ? `?customerId=${customerId}` : ''
              }`
            );
            break;
          case TABLE_GDPR_PROFILES_HISTORY:
            navigate(
              `/${tempPath[1]}/history/edit/${id}${customerId ? `?customerId=${customerId}` : ''}`
            );
            break;
          case TABLE_REFUELINGS_IMPORT_RECORDS:
            if (actionTypes !== PENDING && actionTypes !== IGNORE) {
              navigate(
                `${URL_EXPENSE_IMPORT}/expense/import/record/edit/${id}${
                  customerId ? `?customerId=${customerId}` : ''
                }`
              );
            }
            break;
          case TABLE_REFUELINGS_IMPORT:
            navigate(`/${tempPath[1]}/edit/${id}${customerId ? `?customerId=${customerId}` : ''}`);
            break;
          default:
            if (params.locked) {
              navigate(
                `/${tempPath[1]}/edit/${id}${
                  customerId ? `?customerId=${customerId}&locked=${params.locked}` : ''
                }`
              );
            } else {
              navigate(
                `/${tempPath[1]}/edit/${id}${customerId ? `?customerId=${customerId}` : ''}`
              );
            }

            break;
        }
      }

      if (!getIsEditableModalFromUrl(actionTypes)) {
        if (
          tableName === TABLE_SUPPLIER ||
          tableName === TABLE_ALARM ||
          tableName === TABLE_OPERATING_COSTS
        ) {
          props.handleForm(id, true, customerId || null);
        } else if (tableName === TABLE_GDPR_PROFILES) {
          props.handleForm(id, true, customerId || null, data.assignedUsers);
        } else if (props.openForm) {
          if (tableName === TABLE_PERSON) {
            props.openForm(id, true, customerId || null);
          } else {
            props.openForm(id, customerId || null);
          }
        } else if (props.handleForm) {
          handleFormGeneration(id, actionTypes);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleLog = async (event: any, id: any) => {
    event.preventDefault();

    LoaderService.showLoader();

    const response = await TableService.getLog(props.tableName, id, props.data.customerId);
    if (response && response.status === 'ok') {
      setIsOpenLog(true);
      setLogSummary(response.log);
    }
    LoaderService.showLoader(false);
  };

  const handleRedirectToLogs = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    id: number
  ) => {
    event.preventDefault();
    const { tableName } = props;
    let tab = '';
    let key = '';
    let ids: string[] | number | null = null;
    if (tableName === TABLE_ALARM) {
      tab = TABLE_ALARM_LOG;
      key = 'name';
      ids = id;
      navigate(`${URL_ALARM}/log`);
    } else if (tableName === TABLE_AUTO_REPORT) {
      tab = TABLE_AUTO_REPORT_LOG;
      key = 'autoReportId';
      ids = [String(id)];
      navigate(`${URL_AUTO_REPORTS}/log`);
    } else if (tableName === TABLE_GDPR_USER) {
      tab = TABLE_GDPR_USER_HISTORY;
      key = 'name';
      ids = [String(id)];
      navigate(`${URL_GDPR_USER}/history`);
    } else if (tableName === TABLE_GDPR_PROFILES) {
      tab = TABLE_GDPR_PROFILES_HISTORY;
      key = 'name';
      ids = id;
      navigate(`${URL_GDPR_PROFILE}/history`);
    }
    if (key && tab) {
      const filter = {
        [key]: ids,
      };

      setTableFilter(filter);
    }
  };

  const handleCancelLog = () => {
    setIsOpenLog(false);
  };

  const assignByType = (type: string, id: number) => {
    if (props.openForm) {
      let vehicleName = '';
      if (
        props.columns &&
        props.columns.name &&
        props.columns.name.filter &&
        props.columns.name.filter.values
      ) {
        const vehicle: any = Object.values(props.columns.name.filter.values).find(
          (i: any) => String(i.value) === String(id)
        );
        if (vehicle && vehicle.item) {
          vehicleName = vehicle.item;
        }
      }
      const templateId =
        type === ASSIGN_DEFAULT ? props.data.defaultTemplateId : props.data.templateId;
      props.openForm(id, props.data.customerId || null, type, vehicleName, templateId);
    }
  };

  const handleAlarmActivation = async (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    id: string
  ) => {
    event.preventDefault();

    LoaderService.showLoader();
    const response = await activateAlarm(id);
    if (response.status && response.status !== STATUS_ERROR) {
      props.actionSave(null);
    }
    LoaderService.showLoader(false);
  };

  const handleReportActivation = async (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    id: string
  ) => {
    event.preventDefault();

    LoaderService.showLoader();
    const response = await ReportService.activateReport([id]);
    if (response) {
      props.actionSave(null);
    }
    LoaderService.showLoader(false);
  };

  const handleAlarmDeactivation = async (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    id: string
  ) => {
    event.preventDefault();

    LoaderService.showLoader();
    const response = await deactivateAlarm(id);
    if (response.status && response.status !== STATUS_ERROR) {
      props.actionSave(null);
    }
    LoaderService.showLoader(false);
  };

  const handleReportDeactivation = async (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    id: string
  ) => {
    event.preventDefault();

    LoaderService.showLoader();
    const response = await ReportService.deactivateReport([id]);
    if (response) {
      props.actionSave(null);
    }
    LoaderService.showLoader(false);
  };

  const handlePeriodLockDeactivation = async (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    id: string
  ) => {
    event.preventDefault();

    LoaderService.showLoader();
    const response = await PeriodLockService.deactivate(id);
    if (response) {
      props.actionSave(null);
    }
    LoaderService.showLoader(false);
  };

  const handlePeriodLockActivation = async (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    id: string
  ) => {
    event.preventDefault();

    LoaderService.showLoader();
    const response = await PeriodLockService.activate(id);
    if (response) {
      props.actionSave(null);
    }
    LoaderService.showLoader(false);
  };

  const handlePeriodLock = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    LoaderService.showLoader();
    const response = await PeriodLockService.lock(props.id);
    if (response) {
      props.actionSave(null);
    }
    LoaderService.showLoader(false);
    setIsButtonPeriodClicked('');
  };

  const handlePeriodUnlock = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    LoaderService.showLoader();

    const response = await PeriodLockService.unlock(props.id);
    if (response) {
      props.actionSave(null);
    }
    LoaderService.showLoader(false);
    setIsButtonPeriodClicked('');
  };

  const handleAction = async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const type = event.currentTarget.getAttribute('data-type');
    const { tableName, data } = props;
    const id = event.currentTarget.getAttribute('data-id');
    const lockDate = event.currentTarget.getAttribute('data-lock-date');

    setIsEditFormClicked(true);

    switch (type) {
      case REMOVE:
        if (
          tableName === TABLE_SUPPLIER ||
          tableName === TABLE_OPERATING_COSTS ||
          tableName === TABLE_OPERATING_COSTS_TYPES ||
          tableName === TABLE_ALARM ||
          tableName === TABLE_AUTO_REPORT ||
          tableName === TABLE_TEMPLATES ||
          tableName === TABLE_PERIOD_LOCK_DEFINITION ||
          tableName === TABLE_GDPR_PROFILES ||
          tableName === TABLE_PERSON ||
          tableName === TABLE_CORRECTIONS ||
          tableName === TABLE_FUEL_CARD
        ) {
          setIsButtonRemoveClicked(true);
          setRemoveId(Number(id));
        } else {
          const removeParams: IHandleParams = {
            event,
            id: Number(id),
            customerId: data.customerId,
          };
          handleRemove(removeParams);
        }
        break;
      case DETAIL:
        if (tableName === TABLE_AUTO_REPORT) {
          if (props.openForm) {
            props.openForm(id, true, data.customerId || null, true);
          }
        } else {
          const editParams: IHandleParams = {
            id: Number(id),
            event,
            customerId: data.customerId,
            locked: data.locked ? data.locked : '',
          };

          handleEdit(editParams);
        }
        break;
      case ALARM_LOG:
        handleRedirectToLogs(event, Number(id));
        break;
      case HISTORY:
        handleRedirectToLogs(event, Number(id));
        break;
      case ACTIVATE_ALARM:
        if (tableName === TABLE_ALARM && id) {
          handleAlarmActivation(event, id);
        } else if (tableName === TABLE_AUTO_REPORT && id) {
          handleReportActivation(event, id);
        }
        break;
      case DEACTIVATE_ALARM:
        if (tableName === TABLE_ALARM && id) {
          handleAlarmDeactivation(event, id);
        } else if (tableName === TABLE_AUTO_REPORT && id) {
          handleReportDeactivation(event, id);
        }
        break;
      case PERIOD_LOCK_DEACTIVATE:
        handlePeriodLockDeactivation(event, id);
        break;
      case PERIOD_LOCK_ACTIVATE:
        handlePeriodLockActivation(event, id);
        break;
      case PERIOD_LOCK:
        setIsButtonPeriodClicked('lock');
        break;
      case PERIOD_UNLOCK:
        setIsButtonPeriodClicked('unlock');
        break;
      case ASSIGN_PROFILE:
        if (props.openForm) {
          let customerLabel = '';
          let fullname = '';
          let customerId = null;
          if (props.columns.customerId && props.columns.customerId.filter) {
            customerId = props.columns.customerId.filter.selectedValue;
            const customerValues = props.columns.customerId.filter.values;
            const customerData = customerValues.find(
              (i: any) => String(i.value) === String(customerId)
            );
            if (customerData) {
              customerLabel = customerData.item;
            }
          }
          if (props.columns.fullname && props.columns.fullname.filter) {
            const userId = data ? data.fullname : null;
            const userValues = props.columns.fullname.filter.values;
            const userData = userValues.find((i: any) => String(i.value) === String(userId));
            if (userData) {
              fullname = userData.item;
            }
          }
          // Reload table settings to get GDPR profile list from profile column filter values (facepalm)
          const loadProfiles = await postRequest<Record<string, any>, Record<string, any> | false>(
            '/v1/gdpr/user/list-settings',
            {
              filter: {
                customerId,
              },
              page: 1,
              paging: 10,
            }
          );
          let gdprProfileList = [];
          if (props.columns.profile.filter.values) {
            gdprProfileList = props.columns.profile.filter.values;
          }
          if (loadProfiles) {
            gdprProfileList =
              (loadProfiles as Record<string, any>).columns.profile.filter.values ||
              props.columns.profile.filter.values; // if something goes wrong - use old values from filter
          }
          props.openForm(id, false, customerId, gdprProfileList, fullname, customerLabel);
        }
        break;
      case PERIOD_LOCK_EDIT_LOCKAFTER:
        if (props.openForm) {
          props.openForm(
            id,
            'isEditLockAfter',
            props.data.customerId || null,
            lockDate,
            `${dayjs(props.data.dateFrom).format('DD.MM.YYYY')} - ${dayjs(props.data.dateTo).format(
              'DD.MM.YYYY'
            )}`
          );
        }
        break;
      case ADD_NOTE: {
        const editParams: IHandleParams = {
          id: Number(id),
          event,
          customerId: data.customerId,
        };
        handleEdit(editParams);
        break;
      }
      case LOG:
        handleLog(event, id);
        break;
      case EDIT_REFUELING: {
        if (props.tableName === TABLE_TEMPLATES) {
          if (
            (!PermissionsService.hasAccess(
              PERMISSION_ONLINE_MAP_TEMPLATES_CUSTOMER,
              ['read', 'update'],
              props.data.customerId || null
            ) &&
              props.data.type === 'public') ||
            (!PermissionsService.hasAccess(
              PERMISSION_ONLINE_MAP_TEMPLATES_PRIVATE,
              ['read', 'update'],
              props.data.customerId || null
            ) &&
              props.data.type === 'private') ||
            (!PermissionsService.hasAccess(PERMISSION_ONLINE_MAP_TEMPLATES_SYSTEM, ['update']) &&
              props.data.type === 'system')
          ) {
            showMessage(
              f({ id: 'toastr.warning' }),
              f({ id: 'toastr.onlineTemplateEdit.accessDenied' }),
              'warning'
            );
            break;
          }
        }
        const editParams: IHandleParams = {
          id: Number(id),
          event,
          customerId: data.customerId,
        };
        handleEdit(editParams);
        break;
      }
      case PENDING: {
        const editParams: IHandleParams = {
          id: Number(id),
          event,
          type,
          customerId: data.customerId,
        };
        handleEdit(editParams);
        break;
      }
      case IGNORE: {
        const editParams: IHandleParams = {
          id: Number(id),
          event,
          type,
          customerId: data.customerId,
        };
        handleEdit(editParams);
        break;
      }
      case CUSTOM_REMOVE: {
        if (
          (!PermissionsService.hasAccess(
            PERMISSION_ONLINE_MAP_TEMPLATES_CUSTOMER,
            ['update'],
            props.data.customerId || null
          ) &&
            props.data.type === 'public') ||
          (!PermissionsService.hasAccess(
            PERMISSION_ONLINE_MAP_TEMPLATES_PRIVATE,
            ['update'],
            props.data.customerId || null
          ) &&
            props.data.type === 'private') ||
          (!PermissionsService.hasAccess(PERMISSION_ONLINE_MAP_TEMPLATES_SYSTEM, ['update']) &&
            props.data.type === 'system')
        ) {
          showMessage(
            f({ id: 'toastr.warning' }),
            f({ id: 'toastr.onlineTemplateRemove.accessDenied' }),
            'warning'
          );
          break;
        }
        const removeParams: IHandleParams = {
          id: Number(id),
          event,
          customerId: data.customerId,
        };
        handleRemove(removeParams);
        break;
      }
      case CUSTOM_EDIT: {
        const editParams: IHandleParams = {
          id: Number(id),
          event,
          customerId: data.customerId,
        };
        handleEdit(editParams);
        break;
      }
      case ASSIGN:
        assignByType(type, Number(id));
        break;
      case ASSIGN_DEFAULT:
        assignByType(type, Number(id));
        break;
      case ACTION_PREVIEW:
        if (
          (!PermissionsService.hasAccess(
            PERMISSION_ONLINE_MAP_TEMPLATES_CUSTOMER,
            ['read'],
            props.data.customerId || null
          ) &&
            props.data.type === 'public') ||
          (!PermissionsService.hasAccess(
            PERMISSION_ONLINE_MAP_TEMPLATES_PRIVATE,
            ['read'],
            props.data.customerId || null
          ) &&
            props.data.type === 'private') ||
          (!PermissionsService.hasAccess(PERMISSION_ONLINE_MAP_TEMPLATES_SYSTEM, ['read']) &&
            props.data.type === 'system')
        ) {
          showMessage(
            f({ id: 'toastr.warning' }),
            f({ id: 'toastr.onlineTemplatePrevie.accessDenied' }),
            'warning'
          );
          break;
        }
        setIsOnlinePanelPreviewOpen(true);
        break;

      default:
        console.error('No action');
        break;
    }
  };

  React.useEffect(() => {
    setOpen(false);
  }, [pathname]);

  const handleConfirmCancel = (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent> | KeyboardEvent
  ) => {
    if (event) event.preventDefault();
    setIsButtonRemoveClicked(false);
  };

  const handleConfirmRemove = (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (event) {
      event.preventDefault();

      const removeParams: IHandleParams = {
        id: Number(removeId),
        event,
      };
      handleRemove(removeParams);
    }
    // handleRemove(event, removeId);
    setIsButtonRemoveClicked(false);
  };

  const handleCloseOnlinePreview = () => {
    setIsOnlinePanelPreviewOpen(false);
  };

  const handleTableRowActive = () => {
    setTables((oldState) =>
      setTableActiveRow(oldState, { name: props.tableName, id: props.data.id })
    );
  };

  const removeClass = 'text-center sticky-col remove';
  const detailClass = 'text-center sticky-col detail';

  React.useEffect(() => {
    if (props.data.locked) {
      setIsUpdatingAllowed(!getLockedRideBoolean(props.data.locked, 'update'));
    }

    setIsUpdatingAllowed(
      props.tableName === TABLE_CORRECTIONS
        ? PermissionsService.hasAccess(TABLE_CORRECTIONS, ['update'], props.data.customerId) &&
            PermissionsService.hasAccess(TABLE_CORRECTIONS, ['read'], props.data.customerId)
        : PermissionsService.hasAccess(
            reportTableName ||
              (props.tableName === TABLE_ONLINE_MAP_ASSIGN
                ? PERMISSION_ONLINE_MAP_TEMPLATES_ASSIGN
                : props.tableName),
            ['update'],
            props.data.customerId
          )
    );
  }, [
    getLockedRideBoolean,
    isUpdatingAllowed,
    props.data.customerId,
    props.data.locked,
    props.tableName,
    reportTableName,
  ]);

  React.useEffect(() => {
    setIsAction(false);

    if (props.tableName === TABLE_REFUELINGS_IMPORT_RECORDS) {
      let countStatus = 0;

      if (props.records) {
        props.records.map((item: any) => {
          if (item.status === 'changed' || item.status === 'pending') {
            countStatus += 1;
          }
        });

        if (props.records.length === countStatus) {
          setIsAction(true);
        }
      }
    }
  }, [getLockedRideBoolean, props.records, props.tableName, reportTableName]);

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    document.addEventListener('wheel', handleClickOutside, false);
    document.addEventListener('keydown', handleKeyboard, false);

    return () => {
      document.removeEventListener('click', handleClickOutside, false);
      document.addEventListener('wheel', handleClickOutside, false);
      document.removeEventListener('keydown', handleKeyboard, false);
    };
  }, [handleClickOutside]);

  const getCustomerName = () => {
    const customerData = props.columns.customerId.filter.values.find(
      (i: any) => String(i.value) === String(props.data.customerId)
    );
    if (customerData) {
      return customerData.tooltip;
    }
    return '';
  };

  const handleConfirmPeriodLockCancel = () => {
    setIsButtonPeriodClicked('');
  };

  const getIconName = (i: { icon: string; id: string }) => {
    let icon = '';
    if (i.icon === 'detail') {
      icon = 'cmd-icon cmd-icon-detail';
    } else if (i.icon === 'remove') {
      icon = 'cmd-icons cmd-icon-bin';
    } else if (i.icon === 'commenting-o') {
      icon = `cmd-icon cmd-icon-commentary`;
    } else if (i.icon === 'list-alt') {
      icon = 'cmd-icon cmd-icon-listing';
    } else if (i.id === 'action_history') {
      icon = 'cmd-icons cmd-icon-history';
    } else if (i.id === 'action_assign-profile') {
      icon = 'cmd-icons cmd-icon-swich-profile';
    } else {
      icon = `cmd-icon cmd-icon-${i.icon}`;
    }
    return icon;
  };

  const getIconInMenu = (i: { icon: string; id: string }) => {
    let className = '';
    switch (i.id) {
      case PERIOD_LOCK:
        className = `cmd-icon cmd-icon-lock fs-4 text-turn-off`;
        break;
      case PERIOD_UNLOCK:
        className = `cmd-icon cmd-icon-unlock fs-4 text-turn-on`;
        break;
      case PERIOD_LOCK_EDIT_LOCKAFTER:
        className = `cmd-icon cmd-icon-edit-lock-date fs-4`;
        break;
      default:
        className = `cmd-icon cmd-icon-${i.icon} fs-4`;
    }
    return className;
  };

  const getIconInDisabledAction = (i: { icon: string; id: string }) => {
    let className = '';
    if (i.icon === 'remove') {
      className = 'cmd-icons cmd-icon-bin';
    } else if (i.icon === 'list-alt') {
      className = 'cmd-icon cmd-icon-listing';
    } else if (i.id === 'action_assign-profile') {
      className = 'cmd-icons cmd-icon-swich-profile';
    } else {
      className = `cmd-icon cmd-icon-${i.icon}`;
    }
    return className;
  };

  const getIconInSingleAction = (icon: string) => {
    let className = '';
    if (icon === 'detail') {
      className = 'cmd-icon cmd-icon-detail fs-4';
    } else if (icon === 'remove') {
      className = 'cmd-icons cmd-icon-bin fs-4';
    } else if (icon === 'list-alt') {
      className = 'cmd-icon cmd-icon-listing';
    } else if (isUpdatingAllowed) {
      className = `cmd-icon cmd-icon-${icon} fs-4`;
    } else {
      className = '';
    }
    return className;
  };

  const renderSingleActionElement = (
    i: { disabled: boolean; id: string; icon: string; type: string; title: string },
    key: number
  ) => {
    let element: null | React.ReactNode = null;
    // Cannot delete employee when hasDriver or hasUser is true
    if (props.tableName === TABLE_PERSON && i.id === 'action_remove') {
      element = (
        <i
          style={{ color: '#ccc' }}
          className={`${getIconInDisabledAction(i)} fs-4`}
          data-cy={`table-filter-${i.id}-disabled`}
        />
      );
      if (props.data.hasUser) {
        return element;
      }
      if (props.data.hasDriver) {
        return element;
      }
    }
    if (
      (props.tableName !== TABLE_GDPR_PROFILES_HISTORY &&
        i.id !== 'action_assign-profile' &&
        i.id !== 'action_remove') ||
      (props.tableName === TABLE_GDPR_PROFILES_HISTORY &&
        props.data.changeType === 'edit' &&
        !i.disabled) ||
      (i.id === 'action_assign-profile' &&
        PermissionsService.hasAccess(
          PERMISSION_GDPR_USER_CHANGE_PROFILE,
          ['update'],
          props.data.customerId || null
        ) &&
        !i.disabled) ||
      (i.id === 'action_remove' &&
        PermissionsService.hasAccess(
          reportTableName || props.tableName,
          ['delete'],
          props.data.customerId || null
        ) &&
        !getLockedRideBoolean(props.data.locked, 'delete') &&
        !i.disabled)
    ) {
      if (props.data.failed + props.data.ignored + props.data.duplicities === 0 && key === 1) {
        element = (
          <div>
            <a
              key={i.id}
              title={f({ id: `log.refueling.no.data` })}
              data-cy={`table-filter-${i.id}`}
              className="text-gray"
              data-type={i.type === 'custom' ? i.id : i.type}
              data-id={props.data.id}
            >
              <i className={getIconInSingleAction(i.icon)} style={{ color: 'silver' }} />
            </a>
          </div>
        );
      } else {
        element = (
          <div>
            <a
              key={i.id}
              href="#"
              title={i.icon ? f({ id: `table.filter.${i.icon}` }) : i.title}
              data-cy={`table-filter-${i.id}`}
              className="text-gray"
              data-type={i.type === 'custom' ? i.id : i.type}
              data-id={props.data.id ? props.data.id : props.data.vehicleId}
              onClick={handleAction}
              style={{ padding: '10px 25px', margin: '-10px -25px' }}
            >
              <i className={`${getIconName(i)} fs-4`} />
              <span className="sr-only">{i.title}</span>
            </a>
          </div>
        );
      }
    } else {
      element = (
        <i
          style={{ color: '#ccc' }}
          className={`${getIconInDisabledAction(i)} fs-4`}
          data-cy={`table-filter-${i.id}-disabled`}
        />
      );
    }
    return element;
  };

  const getUpdatePermission = (name: string) => {
    return PermissionsService.hasAccess(name, ['update'], props.data.customerId || null);
  };

  const getIsNotAllowedItemInTableMenu = (actionId: string) => {
    return (
      ((actionId === 'action_activate' ||
        actionId === 'action_deactivate' ||
        actionId === PERIOD_LOCK_ACTIVATE ||
        actionId === PERIOD_LOCK_DEACTIVATE) &&
        !isUpdatingAllowed) ||
      (actionId === 'action_assignDefault' &&
        !getUpdatePermission(PERMISSION_ONLINE_MAP_TEMPLATES_ASSIGNMENT_DEFAULT)) ||
      (actionId === 'action_assign' &&
        !getUpdatePermission(PERMISSION_ONLINE_MAP_TEMPLATES_ASSIGNMENT))
    );
  };

  const getIsActionAllowed = (action: IAction) => {
    // remove disabled
    if (
      action.id === 'action_remove' &&
      (!PermissionsService.hasAccess(
        reportTableName || gdprProfileName || props.tableName,
        ['delete'],
        props.data.customerId || null
      ) ||
        (props.tableName === TABLE_GDPR_PROFILES && props.data.assignedUsers) ||
        (props.tableName === TABLE_TEMPLATES && props.data.vehicles !== '-'))
    ) {
      return null;
    }
    // edit disabled
    if (action.id === 'action_edit' && props.tableName === TABLE_TEMPLATES && !isUpdatingAllowed) {
      return null;
    }
    // another disabled
    if (getIsNotAllowedItemInTableMenu(action.id)) {
      return null;
    }
    // enabled
    return action;
  };

  const allowedActionsInMenu = () => {
    const actions: IAction[] = [];
    props.data.actions.forEach((action: IAction) => {
      if (getIsActionAllowed(action)) {
        actions.push(action);
      }
    });
    return actions;
  };

  const renderMenuButton = () => {
    let element: null | React.ReactNode = null;
    if (props.data.actions.length === 1 && props.tableName === TABLE_PERIOD_LOCK) {
      element = (
        <td className="sticky-col">
          {props.data.actions.map((i: any, key: number) => (
            <a
              href="#"
              key={`multiple-actions-${key}`}
              title={
                i.type === 'custom'
                  ? i.title
                  : f({
                      id: `table.filter.${i.icon}`,
                    })
              }
              data-cy={`table-filter-${i.id}`}
              className="actions px-1 py-xs d-flex text-decoration-none text-gray"
              data-type={i.type === 'custom' ? i.id : i.type}
              data-id={props.data.id}
              onClick={
                PermissionsService.hasAccess(
                  props.tableName,
                  ['update'],
                  props.data.customerId || null
                ) || i.id === PERIOD_LOCK_EDIT_LOCKAFTER
                  ? handleAction
                  : undefined
              }
              data-lock-date={props.data.lockDate || ''}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div
                style={{
                  width: '18px',
                  marginRight: '10px',
                  marginLeft: '5px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <i
                  className={getIconInMenu(i)}
                  title={i.title}
                  style={
                    PermissionsService.hasAccess(
                      props.tableName,
                      ['update'],
                      props.data.customerId || null
                    ) || i.id === PERIOD_LOCK_EDIT_LOCKAFTER
                      ? {}
                      : { color: '#ccc' }
                  }
                />
              </div>
            </a>
          ))}
        </td>
      );
    } else {
      element = (
        <td
          className={`text-center ${removeClass}  ${
            (props.lastItems !== props.firstItems && props.lastItems && !props.firstItems) ||
            clientBottom < 150
              ? 'dropup'
              : ''
          }`}
          style={{ position: 'relative', cursor: 'pointer', zIndex: open ? 10 : 4 }}
        >
          <div className="dropdown actions" onClick={openMenu} id={`actionsMenu-${props.data.id}`}>
            {allowedActionsInMenu().length ? (
              <a
                href=""
                title={f({
                  id: `table.actionsBtn`,
                })}
                className="dropdown-toggle text-gray fs-5"
                id="dropdownMenu2"
                data-toggle="dropdown"
                // aria-haspopup="true"
                aria-expanded="false"
              >
                <i
                  id={`actionsMenuIcon-${props.data.id}`}
                  className="cmd-icon cmd-icon-more-horizontal"
                  data-cy="actions-menu-icon"
                />
                <span className="sr-only">{f({ id: 'table.actionsBtn' })}</span>
              </a>
            ) : (
              <a
                href=""
                title={f({ id: 'table.noAction' })}
                className="dropdown-toggle text-gray fs-5"
                id="dropdownMenu2"
                data-toggle="dropdown"
                // aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="cmd-icon cmd-icon-more-horizontal" style={{ color: 'silver' }} />
                <span className="sr-only">{f({ id: 'table.noAction' })}</span>
              </a>
            )}
            {open && (
              <ActionsMenu
                id={props.data.id}
                lockDate={props.data.lockDate}
                actions={allowedActionsInMenu()}
                tableName={props.tableName}
                handleAction={handleAction}
                clientY={clientY}
                lastItem={props.lastItem}
              />
            )}
          </div>
        </td>
      );
    }
    return element;
  };

  const renderImportRecordElement = (i: {
    icon: string;
    type: string;
    title: string;
    id: string;
  }) => {
    let element: null | React.ReactNode = null;
    if (
      i.id !== 'action_remove' ||
      PermissionsService.hasAccess(props.tableName, ['delete'], props.data.customerId || null)
    ) {
      element = (
        <div>
          <a
            key={i.id}
            href="#"
            title={i.icon ? f({ id: `table.filter.${i.icon}` }) : i.title}
            data-cy={`table-filter-${i.id}`}
            className="text-gray"
            data-type={i.type === 'custom' ? i.id : i.type}
            data-id={props.data.id}
            onClick={handleAction}
            style={{ padding: '10px 25px', margin: '-10px -25px' }}
          >
            <i className={getIconInSingleAction(i.icon)} />
          </a>
        </div>
      );
    } else {
      element = (
        <i
          style={{ color: '#ccc' }}
          className={getIconInSingleAction(i.icon)}
          data-cy={`table-filter-${i.id}-disabled`}
        />
      );
    }
    return element;
  };

  const renderImportRecord = () => {
    let element: null | React.ReactNode = null;
    if (props.data.actions.length) {
      element = props.data.actions.map((i: any, key: number) => (
        <td
          key={`${i.id}`}
          className={key === 1 || props.data.actions.length === 1 ? removeClass : detailClass}
        >
          <div className="actions">{renderImportRecordElement(i)}</div>
        </td>
      ));
    } else if (!props.data.actions.length && !isAction) {
      element = (
        <td className={removeClass}>
          <div className="actions" title="Ziadna akcia">
            <div className="text-gray">{/* <a href="" /> */}</div>
          </div>
        </td>
      );
    }
    return element;
  };

  const getRemoveConfirmDialogText = (): string => {
    const { tableName, data, columns } = props;
    const date = new Date();
    let deleteMessage = '';
    const messages: string[] = [];

    switch (tableName) {
      case TABLE_PERSON:
        if (data.hasUser) {
          const firstName = columns.firstName.filter.values.find(
            (item) => item.value === data.firstName
          ).item;
          const lastName = columns.lastName.filter.values.find(
            (item) => item.value === data.lastName
          ).item;
          return f(
            { id: 'employee.confirm.remove.messsage', defaultMessage: '' },
            { name: `${firstName} ${lastName}` }
          );
        }
        return f({ id: 'table.confirm.dialog.remove.message' });
        break;

      case TABLE_GDPR_PROFILES:
        return `${f({ id: 'gdprProfile.confirm.remove.message' })} ${data.name}?`;

      case TABLE_TEMPLATES:
        return `${f({ id: 'onlineTemplates.confirm.delete.message' })}`;

      case TABLE_CORRECTIONS:
        if (data.rideCount && data.rideCount > 0) {
          const dateFrom = dayjs(data.rideMinTs).format('DD.MM.YYYY HH:mm');
          const dateTo = dayjs(data.rideMaxTs).format('DD.MM.YYYY HH:mm');
          return f(
            { id: 'confirm.dialog.corrections.message' },
            { count: data.rideCount, dateFrom, dateTo }
          );
        }
        break;

      case TABLE_FUEL_CARD:
        if (date <= new Date(data.validUntil)) {
          const validUntil = dayjs(data.validUntil).format('DD.MM.YYYY');
          messages.push(
            f(
              { id: 'table.confirm.dialog.remove.message.fuel.card.validUntil' },
              { date: validUntil }
            )
          );
        }
        if (data.assignedTo) {
          messages.push(
            f(
              { id: 'table.confirm.dialog.remove.message.fuel.card.vehicleId' },
              { name: data.assignedTo }
            )
          );
        }
        messages.push(
          `${date <= new Date(data.validUntil) || data.assignedTo ? '<br />' : ''}${f({
            id: 'table.confirm.dialog.remove.message.fuel.card',
          })}`
        );

        deleteMessage = messages.join('<br />');
        return deleteMessage;

      case TABLE_SUPPLIER:
        return f({ id: 'table.confirm.dialog.remove.message.supplier' });

      default:
        return f({ id: 'table.confirm.dialog.remove.message' });
    }

    return '';
  };

  const handleConfirmCancelButtonTitle = (): string => {
    if (props.tableName === TABLE_TEMPLATES) {
      return 'onlineTemplates.subPage.section.cardAndWidgetSettings.deleteCardConfirmDialog.cancel';
    }
    if (props.tableName === TABLE_CORRECTIONS) {
      return 'confirm.dialog.answerNo';
    }
    return 'waypoints.cancel';
  };

  const handleConfirmContinueButtonTitle = (): string => {
    if (props.tableName === TABLE_TEMPLATES) {
      return 'onlineTemplates.subPage.section.cardAndWidgetSettings.deleteCardConfirmDialog.continue';
    }
    if (props.tableName === TABLE_CORRECTIONS) {
      return 'confirm.dialog.answerYes';
    }
    if (props.tableName === TABLE_SUPPLIER) {
      return 'table.deleteBtn.supplier';
    }
    return 'table.deleteBtn';
  };

  const handleConfirmDialogType = () => {
    const buttons: CmdToastButton[] = [
      {
        title: f({
          id: handleConfirmCancelButtonTitle(),
        }),
        style: 'link',
        type: 'cancel',
        onClickCallback: handleConfirmCancel,
      },
      {
        title: f({
          id: handleConfirmContinueButtonTitle(),
        }),
        style: 'solid',
        type: 'confirm',
        onClickCallback: handleConfirmRemove,
      },
    ];
    if (props.tableName === TABLE_FUEL_CARD) {
      return (
        <ConfirmToaster
          id="toast"
          title={f({
            id: 'table.confirm.dialog.remove.title',
          })}
          message={getRemoveConfirmDialogText()}
          type="warning"
          duration={null}
          onClose={handleConfirmCancel}
          buttons={buttons}
        />
      );
    }
    return (
      <ConfirmDialog
        title={
          props.tableName === TABLE_PERSON
            ? f({
                id: 'employee.confirm.dialog.remove.title',
              })
            : f({
                id: 'table.confirm.dialog.remove.title',
              })
        }
        cancelCallback={handleConfirmCancel}
        continueCallback={handleConfirmRemove}
        cancelButtonTitle={f({
          id: handleConfirmCancelButtonTitle(),
        })}
        continueButtonTitle={f({
          id: handleConfirmContinueButtonTitle(),
        })}
      >
        {getRemoveConfirmDialogText()}
      </ConfirmDialog>
    );
  };

  return (
    <tr
      key={props.id}
      className={`${!props.rowHeight ? 'height-md' : ''}
          ${props.activeRows.findIndex((k: any) => k === props.data.id) !== -1 ? 'active' : ''}`}
      style={{ whiteSpace: 'nowrap' }}
    >
      {isButtonRemoveClicked && handleConfirmDialogType()}
      {isOpenLog && (
        <ShowLogRefueling
          title={f({ id: 'log.refueling.title' })}
          cancelCallback={handleCancelLog}
          data={logSummary}
        />
      )}

      {isButtonPeriodClicked && (
        <ConfirmDialog
          title={`${f({
            id:
              isButtonPeriodClicked === 'lock'
                ? 'form.modal.header.lock'
                : 'form.modal.header.unlock',
          })} ${dayjs(props.data.dateFrom).format('DD.MM.YYYY')} - ${dayjs(
            props.data.dateTo
          ).format('DD.MM.YYYY')}`}
          cancelCallback={handleConfirmPeriodLockCancel}
          continueCallback={
            isButtonPeriodClicked === 'lock' ? handlePeriodLock : handlePeriodUnlock
          }
          cancelButtonTitle={f({
            id: 'waypoints.cancel',
          })}
          continueButtonTitle={
            isButtonPeriodClicked === 'lock'
              ? f({
                  id: 'table_list.columns.periodLocks.action_status_lock',
                })
              : f({
                  id: 'table_list.columns.periodLocks.action_status_unlock',
                })
          }
        >
          {f(
            {
              id:
                isButtonPeriodClicked === 'lock'
                  ? 'form.modal.message.lock'
                  : 'form.modal.message.unlock',
            },
            {
              customerName: getCustomerName(),
            }
          )}
        </ConfirmDialog>
      )}

      {isOnlinePanelPreviewOpen && (
        <OnlinePanelPreview
          data={props.data.preview}
          closeCallback={handleCloseOnlinePreview}
          size={props.data.size}
        />
      )}

      {/* multiple actions checkbox */}
      {props.multipleActions.length > 0 && props.multipleActionsAllowed && (
        <td style={{ zIndex: 1 }}>
          <div className="e-checkbox checkbox-sm">
            <label>
              <input
                type="checkbox"
                data-cy={`checkbox-${props.data.id}`}
                checked={props.activeRows.findIndex((k: any) => k === props.data.id) !== -1}
                onChange={handleTableRowActive}
              />
              <span className="p-0" />
            </label>
          </div>
        </td>
      )}

      {/* table TD */}
      {Object.keys(props.columns)
        .filter((j) => !props.columns[j].system)
        .filter((j) => props.columns[j].visible)
        .map((i, index) => (
          <TableTd
            key={`${i}${index}`}
            i={i}
            isFirstColumn={firstColumn === i}
            title={props.data[i]}
            data={props.columns[i]}
            links={props.data.links}
            systemData={getSystemData(i)}
            firstItems={props.firstItems}
            lastItems={props.lastItems}
            dateFormat={props.dateFormat}
            countRows={props.countRow}
            tableName={props.tableName}
            id={props.data.id}
            customerId={props.data.customerId ? props.data.customerId : null}
          />
        ))}

      {/* actions: 2 and less */}
      {!props.moreThanTwoActions &&
        props.tableName !== TABLE_REFUELINGS_IMPORT_RECORDS &&
        props.data.actions &&
        props.data.actions.map((i: any, key: number) => (
          <td
            key={`${i.id}`}
            className={key === 1 || props.data.actions.length === 1 ? removeClass : detailClass}
          >
            <div className="actions">{renderSingleActionElement(i, key)}</div>
          </td>
        ))}

      {/* import record refueling table */}
      {!props.moreThanTwoActions &&
        props.tableName === TABLE_REFUELINGS_IMPORT_RECORDS &&
        props.data &&
        renderImportRecord()}

      {/* actions: 3 and more OR actionsInMenu={true} */}
      {props.moreThanTwoActions && props.data.actions && renderMenuButton()}
    </tr>
  );
}

export default React.memo(TableRow);
