import { Draft07, JSONSchema, JSONError } from 'json-schema-library';

const JSONValidationService = {
  validateSchema(schema: JSONSchema, response: any, schemaErrorMessage: string) {
    const jsonSchema = new Draft07(schema);
    const isValid = jsonSchema.isValid(response);
    const errors: JSONError[] = jsonSchema.validate(response);

    if (errors.length > 0) {
      return console.error(schemaErrorMessage, isValid, errors);
    }
    return null;
  },
};

export default JSONValidationService;
