import * as React from 'react';
import { useIntl } from 'react-intl';
import Modal from './Modal';

export default function Contact(): JSX.Element {
  const { formatMessage: f } = useIntl();
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const handleShowModal = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    setIsModalOpen(!isModalOpen);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <a href="/" className="subMenu menuLink" onClick={handleShowModal}>
        {' '}
        {f({ id: 'menu.contact' })}{' '}
      </a>
      {isModalOpen && <Modal closeCallback={handleClose} />}
    </>
  );
}
