import { IValidationRule } from '../../../Services/FormikValidatorService';

export const CAN_REFUELINGS_ID_KEY = 'id';
export const CAN_REFUELINGS_STATUS_KEY = 'status';
export const CAN_REFUELINGS_ACCOUNTING_KEY = 'accountingTs';
export const CAN_REFUELINGS_CUSTOMER_KEY = 'customer';
export const CAN_REFUELINGS_LICENSE_NUMBER_KEY = 'licenceNumber';
export const CAN_REFUELINGS_VEHICLE_KEY = 'vehicle';
export const CAN_REFUELINGS_RIDE_KEY = 'ride';
export const CAN_REFUELINGS_FUEL_TYPE_KEY = 'fuelType';
export const CAN_REFUELINGS_AMOUNT_KEY = 'amount';
export const CAN_REFUELINGS_DUPLICATE_KEY = 'duplicate';
export const CAN_REFUELINGS_MODIFIED_AT_KEY = 'modifiedAt';
export const CAN_REFUELINGS_MODIFIED_BY_KEY = 'modifiedBy';

export const CAN_REFUELINGS_TABLE_KEY = 'v1/refueling/can';
export const CAN_REFUELINGS_TABLE_SETTINGS = 'refueling_can';
export const CAN_REFUELINGS_QUERY_KEY = 'canRefuelingData';
// export const CAN_REFUELINGS_TABLE_NAME = 'refueling/can';

export const CAN_SOURCE = 'can';

export const CanRefuelingsTableSettingsKeys = {
  status: 'table_list.columns.canRefuelings.status',
  accountingTs: 'table_list.columns.canRefuelings.accountingTs',
  customer: 'table_list.columns.customerId',
  licenceNumber: 'table_list.columns.canRefuelings.licenceNumber',
  vehicle: 'table_list.columns.canRefuelings.vehicleName',
  ride: 'table_list.columns.canRefuelings.ride',
  fuelType: 'table_list.columns.canRefuelings.fuelType',
  amount: 'table_list.columns.canRefuelings.amount',
  duplicate: 'table_list.columns.canRefuelings.duplicate',
  modifiedBy: 'table_list.columns.modifiedBy',
  modifiedAt: 'table_list.columns.modifiedAt',
};

export type CanRefuelingsResponse = {
  count: number;
  records: CanRefuelingsRecord[];
  summaries: any[];
};

export type CanRefuelingsRecordRide = {
  anonymized: boolean;
  address: string | null;
  timestamp: string | null;
};

export type CanRefuelingDataToConvert = {
  vehicleId: number;
  customerId: number;
  rideId: string;
  amount: number;
  fuelTypeId: number;
  accountingTs: string;
  gasStationNetworkId?: null;
  source: 'can';
  refuelingCanId: number;
  id?: null;
  vat?: null;
  unitPriceIncVat?: null;
  unitPrice?: null;
  price?: null;
  vatPrice?: null;
  priceIncVat?: null;
  currencyId?: null;
  billId?: null;
  note?: null;
  attachments?: null;
  secondaryCurrencyId?: null;
  secondaryVat?: null;
  secondaryUnitPriceIncVat?: null;
  secondaryUnitPrice?: null;
  secondaryPrice?: null;
  secondaryVatPrice?: null;
  secondaryPriceIncVat?: null;
  refuelingAfterDistanceKm?: null;
  mileage?: null;
};

export type CanRefuelingsRecord = {
  id: number;
  vehicleId: number;
  fuelTypeId: number;
  rideId: number;
  status: string;
  accountingTs: string;
  customer: {
    id: number;
    label: string;
    name: string;
  };
  licenceNumber: string;
  vehicle: string;
  ride: CanRefuelingsRecordRide;
  fuelType: string;
  amount: string;
  duplicate: boolean;
  modifiedAt: string;
  modifiedBy: string;
};

export interface TableUserCell {
  label: string;
  name: string;
}

export interface IgnoreCanRefuelingApiResponse {
  accountingTs: string;
  amount: number;
  duplicate: boolean;
  fuelTypeId: number;
  id: number;
  modifiedAt: string;
  modifiedBy: string | null;
  rideId: number;
  status: string;
  vehicleId: number;
}

export interface DuplicateCanRefuelingApiResponse {
  accountingTs: string;
  amount: number;
  duplicate: boolean;
  fuelTypeId: number;
  id: number;
  modifiedAt: string;
  modifiedBy: string | null;
  rideId: number;
  status: string;
  vehicleId: number;
}

export interface IRefuelingInput {
  indexId: string;
  type: string;
  subtype?: string;
  title: string;
  placeholder: string;
  value?: string | undefined;
  values?: {
    item: string;
    value: string;
  };
  firstDisabled: boolean;
  multiple: boolean;
  disabled: boolean;
  readonly: boolean;
  sendOnChange: boolean;
  rules: IValidationRule[];
}
