import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  isOnlinePanelOpenAtom,
  IVehicleOnlinePanels,
  IWidgetGraph,
  onlinePanelAtom,
} from '../OnlinePanelState';
import { ICustomWidgetProps } from './Widget';
import * as VehiclesDataState from '../../../store/recoil/vehiclesData';
import { speedWidgetGraphAtom } from '../../Graphs/GraphState';
import { IVehicleData } from '../../Vehicles/types';
import RemoveButton from './RemoveButton';
import { getWidgetTooltip, getWidgetValue, isWidgetDisabled } from './WidgetService';
import useAnalytics from '../../../hooks/useAnalytics';

export const VELOCITY = 'speed';

export default function SpeedWidget(props: ICustomWidgetProps) {
  const { trackEvent } = useAnalytics();
  const { formatMessage: f } = useIntl();
  const [widgetData, setWidgetData] = React.useState<number | null>(131);
  const valueType = 'Km/h';
  const vehicleId = useRecoilValue<number | false>(isOnlinePanelOpenAtom);
  const onlinePanelData = useRecoilValue<IVehicleOnlinePanels>(onlinePanelAtom);
  const shortName = f({ id: 'widget.speed.valueName.short' });
  const longName = f({ id: 'widget.speed.valueName.long' });
  const setSpeedWidgetGraph = useSetRecoilState<IWidgetGraph | null>(speedWidgetGraphAtom);
  const vehicleData = useRecoilValue<IVehicleData | null>(
    VehiclesDataState.vehicleDataByIdSelector(vehicleId)
  );
  const HIGH_SPEED = 131;

  React.useMemo(() => {
    if (!props.isMockupMode) {
      const onlinePanel = onlinePanelData[Number(vehicleId)];

      if (onlinePanel && onlinePanel.speed !== null) {
        const roundedValue = Number(onlinePanel.speed).toFixed(2);
        setWidgetData(Number(roundedValue));
      } else {
        setWidgetData(null);
      }
    }
  }, [onlinePanelData, props.isMockupMode, vehicleId]);

  const handleDetail = () => {
    if (typeof widgetData === 'number' && vehicleData) {
      // const rideId = vehicleData.lastRide?.id;
      trackEvent(['Speed Widget', 'click', 'Open Speed graph']);
      setSpeedWidgetGraph({
        vehicleId: vehicleId || 0,
      });
    }
  };

  return (
    <div
      data-cy="speed-widget"
      onClick={props.isMockupMode ? undefined : handleDetail}
      className={`b-widget is-clickable b-widget--${props.data.rowSize}x${props.data.columnSize} ${
        props.data.hasIcon && props.data.columnSize > 1 ? 'b-widget--alignLeft' : ''
      } widget-grid-name-${props.data.gridName} ${props.isInGrid ? '' : 'droppable-element'}`}
      draggable={!props.isInGrid}
      onDragStart={props.isInGrid ? undefined : props.onDragCallback}
      title={getWidgetTooltip(
        f({ id: 'widget.speed.tooltip' }),
        f({ id: 'onlineTemplates.subPage.section.widgets.tooltip' }),
        props.isInGrid,
        props.isMockupMode
      )}
      style={props.isMockupMode ? undefined : { cursor: 'pointer' }}
    >
      {props.isInGrid && (
        <RemoveButton
          dataCy="speed-widget-in-grid"
          onRemoveCallback={props.onRemoveCallback}
          gridName={props.data.gridName}
        />
      )}
      {props.data.hasTitle && props.data.columnSize === 1 && !props.data.hasIcon && (
        <h4
          style={{ cursor: typeof widgetData === 'number' && !props.isMockupMode ? 'pointer' : '' }}
          data-cy="speed-widget-title-size-1"
          className="e-widget-title"
        >
          {shortName}
        </h4>
      )}
      {props.data.hasTitle && props.data.columnSize > 1 && !props.data.hasIcon && (
        <h4
          style={{ cursor: typeof widgetData === 'number' && !props.isMockupMode ? 'pointer' : '' }}
          data-cy="speed-widget-title-size-more-1"
          className="e-widget-title"
        >
          {longName}
        </h4>
      )}
      {props.data.hasTitle && props.data.columnSize === 2 && props.data.hasIcon && (
        <h4
          style={{ cursor: typeof widgetData === 'number' && !props.isMockupMode ? 'pointer' : '' }}
          data-cy="speed-widget-title-size-2"
          className="e-widget-title"
        >
          {longName}
        </h4>
      )}
      {props.data.hasIcon && props.data.columnSize > 1 && (
        <div
          style={{ cursor: typeof widgetData === 'number' && !props.isMockupMode ? 'pointer' : '' }}
          data-cy="speed-widget-icon-size-more-1"
          className="row no-gutters"
        >
          <div className="col-auto">
            {props.data.columnSize === 2 && (
              <i
                className={`e-widget-icon cmd-icon cmd-icon-speed ${
                  widgetData !== null && !props.isMockupMode && widgetData >= HIGH_SPEED
                    ? 'active'
                    : ''
                } ${isWidgetDisabled(widgetData)}`}
              />
            )}
            {props.data.columnSize === 3 && (
              <i
                className={`e-widget-icon e-widget-icon--md cmd-icon cmd-icon-speed ${
                  widgetData !== null && !props.isMockupMode && widgetData >= HIGH_SPEED
                    ? 'active'
                    : ''
                } ${isWidgetDisabled(widgetData)}`}
              />
            )}
          </div>
          <div className="col">
            {props.data.hasTitle && props.data.columnSize === 3 && (
              <h4 className="e-widget-title">{longName}</h4>
            )}
            <div className={`e-widget-value e-widget-value--md ${isWidgetDisabled(widgetData)}`}>
              {getWidgetValue(widgetData, props.data.hasUnitType, valueType)}
            </div>
          </div>
        </div>
      )}
      {!props.data.hasIcon && props.data.columnSize > 1 && (
        <div
          data-cy="speed-widget-value-size-more-1"
          style={{ cursor: typeof widgetData === 'number' && !props.isMockupMode ? 'pointer' : '' }}
          className={`e-widget-value e-widget-value--md ${isWidgetDisabled(widgetData)}`}
        >
          {getWidgetValue(widgetData, props.data.hasUnitType, valueType)}
        </div>
      )}
      {props.data.hasIcon && props.data.columnSize === 1 && (
        <i
          data-cy="speed-widget-icon-size-1"
          style={{ cursor: typeof widgetData === 'number' && !props.isMockupMode ? 'pointer' : '' }}
          className={`e-widget-icon cmd-icon cmd-icon-speed ${
            widgetData !== null && !props.isMockupMode && widgetData >= HIGH_SPEED ? 'active' : ''
          } ${isWidgetDisabled(widgetData)}`}
        />
      )}
      {props.data.columnSize === 1 && (
        <div
          data-cy="speed-widget-value-size-1"
          style={{ cursor: typeof widgetData === 'number' && !props.isMockupMode ? 'pointer' : '' }}
          className={`e-widget-value ${isWidgetDisabled(widgetData)}`}
        >
          {getWidgetValue(widgetData, props.data.hasUnitType, valueType)}
        </div>
      )}
    </div>
  );
}
