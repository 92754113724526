/* eslint-disable react/no-danger */
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import './styles.scss';
import * as MaintenanceState from './MaintenanceState';
import { IMaintenanceStatusResponse } from './MaintenanceService';

export default function Page(): JSX.Element | null {
  const { formatMessage: f } = useIntl();
  const maintenance = useRecoilValue<IMaintenanceStatusResponse | false>(
    MaintenanceState.maintenance
  );

  if (maintenance && maintenance.maintenance) {
    const mainText: string = f(
      { id: 'maintenancePage.message.info' },
      {
        fromDate: dayjs(maintenance.maintenance.start).format('DD.MM.YYYY'),
        fromTime: dayjs(maintenance.maintenance.start).format('H:mm'),
        toDate: dayjs(maintenance.maintenance.end).format('DD.MM.YYYY'),
        toTime: dayjs(maintenance.maintenance.end).format('H:mm'),
        sStart: '<strong>',
        sEnd: '</strong>',
      }
    );

    return (
      <div className="maintenance-container">
        <div className="maintenance">
          <div style={{ width: '192px', margin: '2rem auto', textAlign: 'center' }}>
            <img
              src={new URL('/assets/images/maintenancePage/banner.png', import.meta.url).href}
              alt=""
            />
          </div>
          <h2>{f({ id: 'maintenancePage.message.dearCustomers' })}</h2>
          <p dangerouslySetInnerHTML={{ __html: mainText }} />
          <div className="maintenance-main">
            <img
              src={new URL('/assets/images/maintenancePage/main.png', import.meta.url).href}
              alt=""
            />
            <div className="title">
              <strong>{f({ id: 'maintenancePage.message.workInProgress' })}</strong>
            </div>
            <div className="date">{dayjs(maintenance.maintenance.start).format('DD.MM.YYYY')}</div>
          </div>
          <p>{f({ id: 'maintenancePage.message.text1' })}</p>
          <p>{f({ id: 'maintenancePage.message.text2' })}</p>
          <p style={{ marginTop: '2rem' }}>{f({ id: 'maintenancePage.message.thankYou' })}</p>
          <p style={{ marginTop: '2rem' }}>
            <i>{f({ id: 'maintenancePage.message.yours' })}</i>
          </p>
          <div style={{ width: '80px' }}>
            <img
              src={
                new URL('/assets/images/maintenancePage/signature-image.png', import.meta.url).href
              }
              alt=""
            />
          </div>
        </div>
      </div>
    );
  }

  return null;
}
