import ApiService from './ApiService';

const RESPONSE_OK = 'ok';

const DatepickerService = {
  async getSettings(): Promise<any> {
    const response = await ApiService.get(`/v1/user-settings/date_range_picker`);
    if (response.data && response.data.status === RESPONSE_OK) {
      return response.data.settings;
    }
    return false;
  },
};

export default DatepickerService;
