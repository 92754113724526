import * as ToastrState from './ToastrState';
import { IToastr } from './ToastrState';
import { setRecoil } from '../RecoilOutside';

export const showMessage = (
  title: string,
  message: string,
  color: string,
  duration: number | null = null,
  messagePayload: Record<string, any> | null = null
): void => {
  setRecoil<IToastr | null>(ToastrState.toastr, {
    title,
    message,
    color,
    duration,
    messagePayload,
  });
};

export const hideMessage = (): void => {
  setRecoil<IToastr | null>(ToastrState.toastr, null);
};

export default showMessage;
