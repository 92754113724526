export const reportSettingsOptionsR01 = [
  `dailySummaryMileageStartEnd`,
  `dailySummaryDistanceBusinessPrivate`,
  `dailySummaryTimeStartEnd`,
  `dailySummaryTimeUsageRideParking`,
  `dailySummaryStopsCount`,
  `dailySummaryDrivers`,
  `dailySummaryAddressStartEnd`,
  `rideSummary`,
  `consumptionSummary`,
  `refuelingSummary`,
  `driverSummary`,
  `otherCostSummary`,
];

export const reportSettingsOptionsR02 = [
  'costCenter',
  'drivers',
  'state',
  'fuelTank',
  'refueling',
  'tankState',
  `otherCostPrice`,
  'woVat',
  'wVat',
  'refuelingConsumption',
  'consumption',
  'fuelNorm',
  // 'fuelBalance',
  // 'psu',
];

export const reportSettingsOptionsR02Differentiation = ['business', 'private', 'weekend'];

export const reportSettingsOptionsR03 = [
  'vehicleSummary',
  'dailySummaryDistanceBusinessPrivate',
  'dailySummaryTimeStartEnd',
  'dailySummaryTimeUsageRideParking',
  'dailySummaryStopsCount',
  'dailySummaryAddressStartEnd',
  'rideSummary',
  'refuelingSummary',
  'dailySummaryVehicles',
];

export const reportSettingsOptionsR04 = [
  'costCenter',
  'refueling',
  'woVat',
  'wVat',
  'refuelingConsumption',
  'consumption',
];

export const reportSettingsOptionsR04Differentiation = ['business', 'private', 'weekend'];

export const reportSettingsOptionsR05 = [
  'rideBook',
  'canConsumption',
  'refuelingConsumption',
  'vat',
  'ecoScore',
  'rideTag',
];

export const reportSettingsOptionsR06EcoDrive = ['summary', 'business', 'private'];

export const reportSettingsOptionsR06 = ['violations'];

export const reportSettingsOptionsR07 = [
  'costCenter',
  'drivers',
  'timeStartEnd',
  'addressStartEnd',
  'rideCount',
  'rideDuration',
  'stopsCount',
  'mileageStartEnd',
  'rideDistance',
  'currency',
  'refuelingAmount',
  'refuelingPrice',
  'otherCostPrice',
  'tankState',
  'ecoScore',
  'ridePrice',
  'consumption',
];

export const reportSettingsOptionsR07Differentiation = ['business', 'private'];

export const reportSettingsOptionsR08 = [];

export const reportSettingsOptionsR09 = ['consumption', 'unit'];

export const reportSettingsOptionsZ01 = [];
