import ApiService from './ApiService';

const PeriodLockService = {
  async activate(id: string): Promise<any> {
    const input = { enabled: true };
    const response = await ApiService.put(`/v1/period-lock-definition/${id}/enabled`, input, true);
    return response ? response.data : null;
  },

  async deactivate(id: string): Promise<any> {
    const input = { enabled: false };
    const response = await ApiService.put(`/v1/period-lock-definition/${id}/enabled`, input, true);
    return response ? response.data : null;
  },
  async lock(id: string): Promise<any> {
    const input = { status: 'locked' };
    const response = await ApiService.put(`/v1/period-lock/${id}/toggle-locked`, input, true);
    return response ? response.data : null;
  },

  async unlock(id: string): Promise<any> {
    const input = { status: 'unlocked' };
    const response = await ApiService.put(`/v1/period-lock/${id}/toggle-locked`, input, true);
    return response ? response.data : null;
  },
};

export default PeriodLockService;
