import { CmdToast } from '@commander-services/gui-components';
import { CmdToastButton } from '@commander-services/gui-components/build/components/CmdToast';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { useIntl } from 'react-intl';

interface IConfirmToaster {
  id: string;
  title: string;
  message?: string;
  type: 'success' | 'error' | 'warning' | 'info';
  onClose: () => void;
  duration?: number | null;
  width?: string;
  buttons?: CmdToastButton[];
}

export default function ConfirmToaster(props: IConfirmToaster): JSX.Element | null {
  const dialogRoot = document.getElementById('modal-root');

  const dialogContent = (
    <div
      className="modal modalOpened systemModal modal_onl_graphs"
      id="modalComponent"
      tabIndex={-1}
      role="dialog"
      data-keyboard="false"
      data-backdrop="static"
      aria-labelledby="systemModalLabel"
    >
      <CmdToast
        id={props.id}
        title={props.title}
        message={props.message}
        type={props.type}
        duration={props.duration}
        onClose={props.onClose}
        buttons={props.buttons}
        width={props.width}
      />
    </div>
  );

  if (dialogRoot) {
    return ReactDOM.createPortal(dialogContent, dialogRoot);
  }

  console.error('Dialog root element is not defined.');
  return null;
}
