import * as ToastrState from '../components/Toastr/ToastrState';
import { IToastr } from '../components/Toastr/ToastrState';
import { setRecoil } from '../components/RecoilOutside';

export const showMessage = (
  title: string,
  message: string,
  color: string,
  duration: number | null = null,
  messagePayload: Record<string, any> | null = null
): void => {
  setRecoil<IToastr | null>(ToastrState.toastr, {
    title,
    message,
    color,
    duration,
    messagePayload,
  });
};

export default showMessage;
