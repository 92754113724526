import * as React from 'react';
import ReactDOM from 'react-dom';
import GridLayout, { Layout } from 'react-grid-layout';
import { useIntl } from 'react-intl';
import { IWidgetCards } from '../../OnlineMapNew/PanelsLayer/types';
import Widget from '../Widgets/Widget';
import PreviewOnlinePanelModal from './PreviewOnlinePanelModal';
import { WORD_LENGTH } from '../../OnlineMapNew/PanelsLayer/constants';
import { IWidget } from '../Widgets/types';
import { getWidgetsByLayout } from '../Widgets/WidgetService';

interface IOnlinePanelPreviewProps {
  data: { cards: IWidgetCards[]; usedWidgets: IWidget[] };
  closeCallback: () => void;
  size: string;
}

export default function OnlinePanelPreview(props: IOnlinePanelPreviewProps): JSX.Element | null {
  const { formatMessage: f } = useIntl();
  const [clickedTabIndex, setClickedTabIndex] = React.useState<number>(0);
  const column = Number(props.size.split('x')[0]);
  const row = Number(props.size.split('x')[1]);

  const onlinePanelRoot = document.getElementById('modal-root');

  const handleClose = React.useCallback(() => {
    props.closeCallback();
  }, [props]);

  const handleTabClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    setClickedTabIndex(Number(event.currentTarget.id));
  };

  const getStaticWidgets = (widgets: Layout[]) => {
    const newWidgets = widgets.map((widgetItem: Layout) => ({
      ...widgetItem,
      static: true,
    }));
    return newWidgets;
  };

  const handleEscapeKey = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        handleClose();
      }
    },
    [handleClose]
  );

  React.useEffect(() => {
    window.addEventListener('keydown', handleEscapeKey);
    return () => {
      window.removeEventListener('keydown', handleEscapeKey);
    };
  }, [handleEscapeKey]);

  const onlinePanelContent: JSX.Element = (
    <div>
      <PreviewOnlinePanelModal
        id={`preview-modal-${clickedTabIndex}`}
        title={f({ id: 'preview.online.panel' })}
        closeCallback={handleClose}
      >
        <a
          // className="cmd-icon cmd-icon-remove-sm close"
          href="#"
          onClick={handleClose}
          data-cy="close-preview-panel"
        >
          {/* &nbsp; */}
        </a>

        <div className="b-widgets-info">
          <div className="b-tabs-toggler">
            <ul>
              {props.data.cards.length > 0 &&
                props.data.cards.map((card: IWidgetCards, index: number) => (
                  <li
                    className={clickedTabIndex === index ? 'active' : ''}
                    key={`${card.id}-${card.name}`}
                  >
                    <a id={`${index}`} onClick={handleTabClick} href="#" style={{ width: 'auto' }}>
                      {card.name.length > WORD_LENGTH
                        ? `${card.name.substring(0, WORD_LENGTH)} ...`
                        : card.name}
                    </a>
                  </li>
                ))}
            </ul>
          </div>

          <div className="b-widgets-info-tab-content">
            {props.data.cards.length > 0 && props.data.usedWidgets && (
              <GridLayout
                preventCollision={true}
                compactType={null}
                className="b-widget-grid b-widget-grid--rounded"
                key={`gridlayout-${props.data.cards[clickedTabIndex].name}`}
                style={{
                  gridTemplateColumns: `repeat(${column}, min-content)`,
                  gridTemplateRows: `repeat(${row}, min-content)`,
                  minWidth: column * 72 + (column - 1) * 4,
                  minHeight: row * 72 + (row - 1) * 4,
                }}
                layout={getStaticWidgets(props.data.cards[clickedTabIndex].widgets)}
                cols={column}
                maxRows={row}
                rowHeight={72}
                width={column * 72 + (column - 1) * 4}
                isResizable={false}
                isDroppable={false}
                margin={[4, 4]}
              >
                {getWidgetsByLayout(
                  props.data.usedWidgets,
                  getStaticWidgets(props.data.cards[clickedTabIndex].widgets)
                ).map((widget: IWidget, widgetIndex: number) => (
                  <div className="item" key={`${widget.gridName}`}>
                    <Widget
                      key={`${widgetIndex}-${widget.gridName}`}
                      index={widgetIndex}
                      data={widget}
                      isMockupMode={true}
                      isInGrid={false}
                    />
                  </div>
                ))}
              </GridLayout>
            )}
          </div>
        </div>
      </PreviewOnlinePanelModal>
    </div>
  );

  if (onlinePanelRoot) {
    return ReactDOM.createPortal(onlinePanelContent, onlinePanelRoot);
  }

  console.error('dialog root element is not defined.');
  return null;
}
