import * as React from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import Config from '../../config';

interface IUseDocumentTitle {
  (value: string): void;
}

function useDocumentTitle(
  title?: string | undefined,
  titlePrefix = Config.pageTitle.titlePrefix,
  siteName = Config.pageTitle.siteName,
  prevailOnUnmount = false
): { setDocumentTitle: IUseDocumentTitle } {
  const { trackPageView } = useMatomo();
  const defaultTitle = React.useRef<string>(document.title);
  // const paq = React.useRef(((window as any)._paq = (window as any)._paq || []));

  React.useEffect(() => {
    // eslint-disable-next-line no-multi-assign
    // const _paq = ((window as any)._paq = (window as any)._paq || []);
    // _paq.push(['setCustomUrl', window.location.pathname]);
    // _paq.push(['setDocumentTitle', documentTitle]);
    // _paq.push(['trackPageView']);
    if (siteName && title) {
      const href = `${window.location.pathname}${window.location.search}`;
      const documentTitle = title ? `${titlePrefix}${title} - ${siteName}` : siteName;
      document.title = documentTitle;
      trackPageView({
        documentTitle,
        href,
      });
    }
  }, [siteName, title, trackPageView]);

  React.useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const setDocumentTitle = (value: string) => {
    const documentTitle = `${titlePrefix}${value} - ${siteName}`;
    // eslint-disable-next-line no-multi-assign
    // const _paq = ((window as any)._paq = (window as any)._paq || []);
    // _paq.push(['setCustomUrl', window.location.pathname]);
    // _paq.push(['setDocumentTitle', documentTitle]);
    // _paq.push(['trackPageView']);
    if (value && siteName) {
      const href = `${window.location.pathname}${window.location.search}`;
      document.title = documentTitle;
      // console.info('Track pageview', documentTitle, href);
      trackPageView({
        documentTitle,
        href,
      });
    }
  };

  return { setDocumentTitle };
}

export default useDocumentTitle;
