import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilState } from 'recoil';
import useOutsideClick from '../../../hooks/useOutsideClick';
import CmdTableSettingsMenu from './CmdTableSettingsMenu';
import * as CmdTableState from '../CmdTableState';

interface ICmdTableSettingsProps {
  tableId: string;
  actionColumnEnabled?: boolean;
  sort?: {
    column: string;
    direction: string;
  };
  tableName: string;
  translationKeys: { [key: string]: string };
}

function CmdTableSettings(props: ICmdTableSettingsProps): JSX.Element {
  const { formatMessage: f } = useIntl();
  const clickOutsideRef: React.RefObject<any> = React.useRef();
  const [isMenuOpened, setIsMenuOpened] = useRecoilState(CmdTableState.isTableSettingsMenuOpened);

  useOutsideClick(clickOutsideRef, () => {
    setIsMenuOpened(false);
  });

  const handleShowTableSettingsMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsMenuOpened(!isMenuOpened);
  };

  return (
    <div
      ref={clickOutsideRef}
      className={`b-dropdown b-dropdown--switcher ${isMenuOpened ? 'open' : ''} `}
      style={{ width: '250px' }}
    >
      <div
        onClick={handleShowTableSettingsMenu}
        className="form-control-labels"
        data-cy="tlclite-open-table-settings"
      >
        {/* <i style={{ color: '#494949' }} className="cmd-icon cmd-icon-cog fs-4" /> */}
        <img src="/images/icons/drag-drop-row.svg" alt="settings" />
        {/* <span className="ml-2">{f({ id: 'tables.tripsOverview.column.tableSettings' })}</span> */}
        <span className="ml-2">{f({ id: 'tables.tripsOverview.tableSettings.header' })}</span>
      </div>
      {isMenuOpened && (
        <CmdTableSettingsMenu
          tableId={props.tableId}
          actionColumnEnabled={props.actionColumnEnabled}
          sort={props.sort}
          tableName={props.tableName}
          translationKeys={props.translationKeys}
        />
      )}
    </div>
  );
}

export default CmdTableSettings;
