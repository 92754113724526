import * as React from 'react';
import { Layout } from 'react-grid-layout';
import { IRefuelingOverviewWidgetData } from '../../OnlineMapNew/PanelsLayer/types';
import { IWidget, WidgetApiResponse, IWidgetCategory, IWidgetType } from './types';
import { getRequest } from '../../../Services/HttpService';

export const getWidgetById = (widgets: IWidget[], id: number): IWidget | undefined => {
  return widgets.find((widget: IWidget) => widget.id === id);
};

export const getWidgetByGridName = (widgets: IWidget[], name: string): IWidget | undefined => {
  return widgets.find((widget: IWidget) => widget.gridName === name);
};

export const getWidgets = async (): Promise<WidgetApiResponse[] | null> => {
  const response = await getRequest<WidgetApiResponse[] | false>('/v1/online-map/widgets');
  if (response) {
    return response as WidgetApiResponse[];
  }
  return null;
};

export const getWidgetCategoryNameById = (categories: IWidgetCategory[], id: string): string => {
  const category = categories.find(
    (widgetCategory: IWidgetCategory) => widgetCategory.value === id
  );
  return category ? category.item : '';
};

export const getWidgetTypeNameById = (types: IWidgetType[], id: string): string => {
  const type = types.find((widgetType: IWidgetType) => widgetType.value === id);
  return type ? type.item : '';
};

export const getWidgetsByLayout = (widgets: IWidget[], layout: Layout[]): IWidget[] => {
  const widgetItems: IWidget[] = [];
  layout.map((item: Layout) => {
    const widgetData = getWidgetByGridName(widgets, item.i);
    if (widgetData) {
      widgetItems.push(widgetData);
    }
  });
  return widgetItems;
};

export const getWidgetValue = (
  value: number | null | undefined,
  hasUnitType?: boolean,
  valueType?: string
): JSX.Element => {
  if (value === null || value === undefined) {
    return <strong>-</strong>;
  }
  return (
    <>
      <strong>{value}</strong> {hasUnitType && valueType && <small>{valueType}</small>}
    </>
  );
};

export const getWidgetTooltip = (
  tooltip: string,
  mockupModeTooltip: string,
  isInGrid?: boolean,
  isMockupMode?: boolean
): string => {
  let text = tooltip;
  if (!isInGrid && isMockupMode) {
    text = mockupModeTooltip;
  }
  return text;
};

export const isWidgetDisabled = (widgetData: number | string | null | undefined): string => {
  return widgetData === null || widgetData === undefined ? 'disabled' : '';
};

export const formatDateString = (dateStr) => {
  const date = new Date(dateStr);

  return date.toISOString();
};

export const formatDataForWidget = (data: { distance: number; duration: string } | undefined) => {
  if (data === undefined) {
    return '—';
  }
  if (data.duration === '0s' && data.distance === 0) {
    return '—';
  }
  let formattedDistance = '';
  if (data.distance !== null && data.distance !== undefined) {
    formattedDistance = data.distance.toLocaleString('sk-SK');
  }
  // Formatting the distance
  formattedDistance = `${formattedDistance}km`;

  // Initialize variables for hours and minutes
  let days = 0;
  let hours = 0;
  let minutes = 0;

  // Extract days, if present
  const daysMatch = data.duration.match(/(\d+)d/);
  if (daysMatch) {
    days = parseInt(daysMatch[1], 10);
  }

  // Extract hours, if present
  const hoursMatch = data.duration.match(/(\d+)h/);
  if (hoursMatch) {
    hours = parseInt(hoursMatch[1], 10);
  }

  // Extract minutes, if present
  const minutesMatch = data.duration.match(/(\d+)m/);
  if (minutesMatch) {
    minutes = parseInt(minutesMatch[1], 10);
  }

  // Formatting the duration
  let formattedDuration = '';
  if (days > 0) {
    formattedDuration += `${days}d `;
  }
  if (hours > 0) {
    formattedDuration += `${hours}h `;
  }
  if (minutes > 0) {
    formattedDuration += `${minutes}min`;
  }

  // Handling the case where duration is 0 seconds
  if (formattedDuration === '' && data.duration.match(/0s/)) {
    formattedDuration = '0min';
  }

  // Combining the formatted distance and duration
  return `${formattedDistance}, ${formattedDuration}`;
};

export const formatValuesRefuelingWidget = (
  data: IRefuelingOverviewWidgetData | undefined,
  currencyData: { id: number; code: string }[],
  units = 'l'
) => {
  const getCurrencyCode = (currencyId: number) => {
    const currency = currencyData.find((c) => c.id === currencyId);
    return currency ? currency.code : 'EUR';
  };

  if (!data) {
    return {
      last: '—',
      currentMonth: ['—'],
      prevMonth: ['—'],
    };
  }

  const formatMonthData = (monthData) => {
    if (monthData.prices.length === 0 && monthData.timestamp.length === 0) {
      return ['—'];
    }

    return monthData.prices.map(({ amount, price, currencyId }) => {
      const formattedAmount =
        amount !== undefined ? `${amount.toFixed(1).replace('.', ',')} ${units}` : '';
      const currencyCode = getCurrencyCode(currencyId);
      const formattedPrice = `${price.toFixed(2).replace('.', ',')} ${currencyCode}`;
      return `${formattedAmount} / ${formattedPrice}`;
    });
  };

  return {
    last: data.last.timestamp
      ? `${data.last.amount.toFixed(1).replace('.', ',')} ${units} / ${data.last.price
          .toFixed(2)
          .replace('.', ',')} ${getCurrencyCode(data.last.currencyId)}`
      : '—',
    currentMonth: formatMonthData(data.currentMonth),
    prevMonth: formatMonthData(data.prevMonth),
  };
};

export const formatTimestampRefuelingOverviewWidget = (timestamp: string) => {
  if (!timestamp) return '';

  const date = new Date(timestamp);
  return `(${date.toLocaleDateString('en-GB').replace(/\//g, '.')})`; // Replace slashes with dots
};

export const formatDateLastRideWidget = (timestamp: string, swap?: boolean) => {
  if (!timestamp) return '';

  const date = new Date(timestamp);

  const formattedTime = date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
  const formattedDate = date.toLocaleDateString('en-GB').replace(/\//g, '.');

  if (swap) return `${formattedDate}, ${formattedTime}`; // Swap date and time (e.g., 12.12.2020, 12:00

  return `${formattedTime}, ${formattedDate}`;
};
