import * as React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { isEqual } from 'lodash';
import { useState } from 'react';
import { TCheckboxState } from './types';
import { IVehicleStatuses, IVehicles, IVehiclesData, TVehicleStatus } from '../Vehicles/types';
import {
  selectedVehiclesAtom,
  selectedVehicleStatusAtom,
  vehiclesAtom,
} from '../../store/recoil/vehicles';
import { vehiclesDataAtom } from '../../store/recoil/vehiclesData';
import {
  getVehiclesWithDataByGroupId,
  unselectAllVehiclesByGroupId,
} from '../../Services/VehicleService';
import { isOnlinePanelOpenAtom } from '../OnlinePanel/OnlinePanelState';
import { autofocusAtom, vehicleFocusAtom } from '../OnlineMapNew/OnlineMapState';
import { filteredVehiclesAtom } from './NavigatorState';
import { setShowAllVehiclesByGroup } from './NavigatorService';

interface INavigatorGroupsProps {
  id: number;
  name: string;
}

export default function NavigatorGroups(props: INavigatorGroupsProps) {
  const vehicles = useRecoilValue<IVehicles>(vehiclesAtom);
  const vehiclesData = useRecoilValue<IVehiclesData>(vehiclesDataAtom);
  const filteredVehicles = useRecoilValue<number[]>(filteredVehiclesAtom);
  const [selectedVehicles, setSelectedVehicles] = useRecoilState(selectedVehiclesAtom);
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const selectedVehicleStatus = useRecoilValue<string>(selectedVehicleStatusAtom);
  const isOnlinePanelOpen = useRecoilValue<number | false>(isOnlinePanelOpenAtom);
  const setVehicleFocus = useSetRecoilState<boolean>(vehicleFocusAtom);
  const setAutofocus = useSetRecoilState<boolean>(autofocusAtom);
  const [checkboxState, setCheckboxState] = useState<TCheckboxState>('unChecked');

  const handleGroupsChecked = (): boolean => {
    const vehiclesKeys: string[] = Object.keys(vehicles);
    if (vehiclesKeys.length > 0) {
      let countVehiclesByGroupId = 0;
      vehiclesKeys.forEach((vehicleId: string) => {
        const vehicle = vehicles[Number(vehicleId)];

        if (vehicle && vehicle?.vehicleGroups?.includes(props.id)) {
          countVehiclesByGroupId += 1;
        }
      });
      let countSelectedVehiclesByGroupId = 0;
      vehiclesKeys.forEach((vehicleId: string) => {
        const vehicle = vehicles[Number(vehicleId)];
        if (
          vehicle &&
          vehicle?.vehicleGroups?.includes(props.id) &&
          selectedVehicles.includes(vehicle.id)
        ) {
          countSelectedVehiclesByGroupId += 1;
        }
      });

      if (selectedVehicles.length === 0) {
        return false;
      }
      if (
        countSelectedVehiclesByGroupId > 0 &&
        countVehiclesByGroupId === countSelectedVehiclesByGroupId
      ) {
        return true;
      }
      if (
        countVehiclesByGroupId > 0 &&
        countSelectedVehiclesByGroupId > 0 &&
        countVehiclesByGroupId !== countSelectedVehiclesByGroupId
      ) {
        return false;
      }

      return false;
    }
    return false;
  };

  const handleSelectAllVehiclesByGroupId = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (!isOnlinePanelOpen) {
      setVehicleFocus(false);
      setAutofocus(true);
    }
    const groupId = event.currentTarget.getAttribute('data-group-id');

    if (groupId) {
      const groupVehicles = getVehiclesWithDataByGroupId(
        vehicles,
        vehiclesData,
        filteredVehicles,
        Number(groupId)
      );

      if (!handleGroupsChecked()) {
        const tempSelectedVehicles = setShowAllVehiclesByGroup(
          vehicles,
          vehiclesData as IVehicleStatuses,
          selectedVehicleStatus as TVehicleStatus,
          props.id
        ).selectedVehicles;

        if (checkboxState === 'semiChecked') {
          setSelectedVehicles((state: number[]) =>
            unselectAllVehiclesByGroupId(state, vehicles, props.id)
          );
          return;
        }

        if (isEqual(tempSelectedVehicles, selectedVehicles)) {
          setSelectedVehicles((state: number[]) =>
            unselectAllVehiclesByGroupId(state, vehicles, props.id)
          );
        } else {
          setSelectedVehicles((oldSelectedVehicles) => {
            if (oldSelectedVehicles) {
              const newSelectedVehicles = setShowAllVehiclesByGroup(
                vehicles,
                vehiclesData as IVehicleStatuses,
                selectedVehicleStatus as TVehicleStatus,
                props.id
              ).selectedVehicles;

              const filteredVehiclesByGroup = Object.keys(vehicles)
                .filter(
                  (item) =>
                    !vehicles[Number(item)].vehicleGroups.includes(props.id) &&
                    oldSelectedVehicles.includes(Number(item))
                )
                .map((item) => vehicles[Number(item)].id);
              const joinedSelectedVehicles = [...filteredVehiclesByGroup, ...newSelectedVehicles];
              return [...new Set(joinedSelectedVehicles)];
            }
            return setShowAllVehiclesByGroup(
              vehicles,
              vehiclesData as IVehicleStatuses,
              selectedVehicleStatus as TVehicleStatus,
              props.id
            ).selectedVehicles;
          });
        }
      } else {
        setSelectedVehicles((state: number[]) =>
          unselectAllVehiclesByGroupId(state, vehicles, props.id)
        );
      }
    }
  };

  const handleToggleGroup = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();

    const collapseElement = document.getElementById(`group-vehicles-${props.id}`);

    if (collapseElement) {
      collapseElement.classList.toggle('in');
      if (collapseElement.classList.contains('in')) {
        setIsCollapsed(false);
      } else {
        setIsCollapsed(true);
      }
    }
  };

  const handleCheckboxRef = (input: HTMLInputElement | null) => {
    if (input) {
      const vehiclesKeys: string[] = Object.keys(vehicles);
      if (vehiclesKeys.length > 0) {
        let countVehiclesByGroupId = 0;
        vehiclesKeys.forEach((vehicleId: string) => {
          const vehicle = vehicles[Number(vehicleId)];

          if (vehicle && vehicle?.vehicleGroups?.includes(props.id)) {
            countVehiclesByGroupId += 1;
          }
        });
        let countSelectedVehiclesByGroupId = 0;
        vehiclesKeys.forEach((vehicleId: string) => {
          const vehicle = vehicles[Number(vehicleId)];
          if (
            vehicle &&
            vehicle?.vehicleGroups?.includes(props.id) &&
            selectedVehicles.includes(vehicle.id)
          ) {
            countSelectedVehiclesByGroupId += 1;
          }
        });

        // if (
        //   countSelectedVehiclesByGroupId > 0 &&
        //   countVehiclesByGroupId === countSelectedVehiclesByGroupId
        // ) {
        //   setIsChecked(true);
        // }

        if (selectedVehicles.length === 0) {
          input.indeterminate = false;
          input.checked = false;
          setCheckboxState('unChecked');
        } else if (
          countSelectedVehiclesByGroupId > 0 &&
          countVehiclesByGroupId === countSelectedVehiclesByGroupId
        ) {
          input.indeterminate = false;
          input.checked = true;
          setCheckboxState('checked');
        } else if (
          countVehiclesByGroupId > 0 &&
          countSelectedVehiclesByGroupId > 0 &&
          countVehiclesByGroupId !== countSelectedVehiclesByGroupId
        ) {
          setCheckboxState('semiChecked');
          input.indeterminate = true;
        } else {
          input.indeterminate = false;
          input.checked = false;
          setCheckboxState('unChecked');
        }
      }
      if (selectedVehicles.length === 0) {
        if (input) {
          input.indeterminate = false;
          input.checked = false;
          setCheckboxState('unChecked');
        }
      }
    }
  };

  return (
    <div className="b-navigator-actions">
      <div className="b-navigator-actions-group">
        <div
          className="e-checkbox"
          data-group-id={props.id}
          onClick={handleSelectAllVehiclesByGroupId}
          data-cy={`navigator-group-${props.id}`}
        >
          <label>
            <input
              type="checkbox"
              ref={handleCheckboxRef}
              // checked={isChecked}
              className="js-checkbox-indeterminate"
              id="navigatorGroupCheckboxIndeterminate"
              data-cy={`navigator-customer-${props.id}-input-${checkboxState}`}
            />
            <span>
              <strong>{props.name}</strong>
            </span>
          </label>
        </div>
        <a
          href="#"
          title=""
          className={`cmd-icons cmd-icon-${
            isCollapsed ? 'dropdown' : 'dropup'
          } b-navigator-actions-group-toggler`}
          onClick={handleToggleGroup}
          data-group-id={props.id}
        >
          {/* {test} */}
        </a>
      </div>
    </div>
  );
}
