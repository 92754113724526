import * as React from 'react';
import Download, { IFile } from '../Download';
import DownloadMenu from './DownloadMenu';

interface IDownloadSelectProps {
  data: IFile[];
  lastItems?: any;
  countRows?: number;
}

const DownloadSelect: React.FC<IDownloadSelectProps> = ({ data, lastItems, countRows }) => {
  if (!data || data.length === 0) {
    return <></>;
  }

  return data.length > 1 ? (
    <DownloadMenu files={data} lastItems={lastItems} countRows={countRows} />
  ) : (
    <Download file={data[0]} />
  );
};

export default DownloadSelect;
