import { CmdProgressBar, CmdToast } from '@commander-services/gui-components';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { usePageVisibility } from 'react-page-visibility';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useQuery } from '@tanstack/react-query';
import * as ExportState from './ExportState';
import { IDownload, IDownloadLater } from './ExportState';
import showMessage from '../Toastr/ToastService';
import ExportService from './ExportService';
import { IMessageReportCreated } from '../SocketConnection/types';

export default function ReportDownload(): JSX.Element | null {
  const { formatMessage: f } = useIntl();
  const isTabActive = usePageVisibility();
  const [isActiveExport, setIsActiveExport] = useRecoilState<false | string>(ExportState.isActive);
  const exportData = useRecoilValue<IMessageReportCreated | null>(ExportState.data);
  const [downloads, setDownloads] = useRecoilState<IDownload[]>(ExportState.downloads);
  const [showDownloadLaterToast, setShowDownloadLaterToast] = React.useState<boolean>(false);

  const getIsCurrentTimestamp = (reportStart: string) => {
    return new Date(reportStart).getTime() + 300000 >= new Date().getTime();
  };

  const numberOfFilesToDownload = (): number => {
    return downloads.filter(
      (item: IDownload) => !item.isDownloaded && getIsCurrentTimestamp(item.generating_start)
    ).length;
  };

  const notDownloadedFiles = (): IDownload[] => {
    return downloads.filter((item: IDownload) => !item.isDownloaded && item.percentage > 60);
  };

  const handleDownloadExport = () => {
    if (exportData && !isTabActive) {
      setShowDownloadLaterToast(true);
    }
    if (exportData && isTabActive) {
      if (exportData.elapsedTime !== undefined) {
        console.info('jobId before downloading ', exportData.jobId);
        const job = downloads.find((item: IDownload) => item.jobId === exportData.jobId);
        if (job) {
          ExportService.downloadExport(job, isTabActive, setDownloads, setIsActiveExport);
        }
      } else {
        showMessage(
          '',
          exportData.toast.message,
          exportData.toast.type.toLowerCase(),
          exportData.toast.duration,
          exportData.toast.messagePayload || null
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useQuery({
    queryKey: [`export-${exportData?.jobId || 0}`, exportData],
    queryFn: () => handleDownloadExport(),
    staleTime: 9000,
    enabled: !!exportData, // handleLoading(),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const handleExportDownload = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    const jobId = event.currentTarget.dataset.jobId;
    if (jobId) {
      const reportData = downloads.find((item: IDownload) => item.jobId === jobId);
      if (reportData) {
        ExportService.downloadExport(reportData, isTabActive, setDownloads, setIsActiveExport);
      }
    }
  };

  const handleOnCloseProgressBar = () => {
    setIsActiveExport(false);
  };

  const handleCloseDownloadLaterToast = () => {
    setShowDownloadLaterToast(false);
  };

  if (showDownloadLaterToast && notDownloadedFiles().length > 0) {
    return (
      <CmdToast
        id="export-later-download"
        title={f({ id: 'reports.toastr.downloadReportAgainTitle' })}
        type="info"
        onClose={handleCloseDownloadLaterToast}
        width="500px"
      >
        <p>
          {f({ id: 'reports.toastr.downloadReportAgain' }, { _count: numberOfFilesToDownload() })}:{' '}
          {notDownloadedFiles().map((item: IDownloadLater, index: number) => (
            <>
              <a
                href="#"
                key={`report-${item.jobId}`}
                data-job-id={item.jobId}
                onClick={handleExportDownload}
              >
                {item.code}
              </a>
              {notDownloadedFiles().length - 1 !== index && <span>, </span>}
            </>
          ))}
        </p>
      </CmdToast>
    );
  }

  const activeDownload = downloads.find((item: IDownload) => item.jobId === isActiveExport);

  if (isActiveExport && isTabActive && activeDownload) {
    return (
      <CmdProgressBar
        // label={`${f({ id: 'reports.generation' })}  ${
        //   exportRequests.length > 1 ? `(${exportRequests.length}) ` : ''
        // }`}
        label={`${f({ id: 'reports.generation' })}  ${
          numberOfFilesToDownload() > 1 ? `(${numberOfFilesToDownload()}) ` : ''
        }`}
        success={f({ id: 'reports.complete' })}
        onCloseCallback={handleOnCloseProgressBar}
        value={
          numberOfFilesToDownload() > 0 && activeDownload.percentage === 100
            ? 0
            : activeDownload.percentage
        }
      />
    );
  }
  return null;
}
