import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import useFilter from '../hooks/useFilter';
import * as CmdTableState from '../CmdTableState';
import { ASCENDING_KEY } from '../constants';

interface ISortButtonProps {
  id: string;
  tableName: string;
  alwaysActive?: boolean;
}

function SortButton(props: ISortButtonProps): JSX.Element {
  const { formatMessage: f } = useIntl();
  const { handleOnSort } = useFilter(props.tableName);
  const sortFilter = useRecoilValue<{ [key: string]: string }>(
    CmdTableState.sortFilter(props.tableName)
  );

  return (
    <a
      id={`sort-button-${props.id}`}
      href="#"
      title={
        sortFilter[props.id] === ASCENDING_KEY
        ? f({ id: 'table.filter.desc' })
        : f({ id: 'table.filter.asc' })
      }
      className={`sort cmd-icon cmd-icon-sorting${
        sortFilter[props.id] === ASCENDING_KEY ? '-a-z' : '-z-a'
      } ${sortFilter[props.id] || props.alwaysActive ? 'cmd-sort-active' : ''} text-decoration-none position-relative  ml-2`}
      onClick={handleOnSort}
      data-column-id={props.id}
      data-cy="filter-buttons-sorting-btn"
    >
      {/* test */}
    </a>
  );
}

export default SortButton;
