interface IBytesSize {
  val: string | number;
  type: string;
}

export const getIcon = (type: string): string => {
  let icon = '';
  const typeArr = type.split('/');
  if (typeArr.length > 1 && typeArr[1] === 'pdf') {
    icon = '-pdf';
  } else if (typeArr[0] !== 'application') {
    icon = `-${typeArr[0]}`;
  }
  return icon;
};

export const bytesToSize = (bytes: number): null | IBytesSize => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0 || bytes === null) {
    return null;
  }
  const i = Number(Math.floor(Math.log(bytes) / Math.log(1024)));
  if (i === 0) {
    return {
      val: bytes,
      type: sizes[i],
    };
  }
  return {
    val: (bytes / 1024 ** i).toFixed(1),
    type: sizes[i],
  };
};

export const simulateClick = (element: Element): void => {
  const event = new MouseEvent('click', {
    bubbles: true,
    cancelable: true,
    view: window,
  });
  element.dispatchEvent(event);
};

export const previewFile = (file: Blob): void => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onloadend = () => {
    const img = document.createElement('img');
    const gallery = document.getElementById('gallery');
    if (gallery && img && reader.result) {
      gallery.appendChild(img);
      img.src = reader.result as string;
    }
  };
};
