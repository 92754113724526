import * as React from 'react';
import { useIntl } from 'react-intl';
import ApiService from '../../Services/ApiService';
import { getOnlineHelpTopic } from './topics';
import Config from '../../../config';

interface IOnlineHelpProps {
  topic: string;
}

export default function OnlineHelp(props: IOnlineHelpProps) {
  const { formatMessage: f } = useIntl();

  const handleOnlineHelpData = async () => {
    const topic = getOnlineHelpTopic(props.topic);
    const res = await ApiService.get(`/v2/manual/${topic}`);
    if (res && res.status === 200 && res.data && res.data.link) {
      window.open(res.data.link, '_blank');
    }
  };

  if (Config.onlineHelp.isEnabled) {
    return (
      <div
        style={{
          display: 'inline-block',
          paddingLeft: '5px',
          cursor: 'pointer',
          position: 'relative',
          bottom: '3px',
        }}
      >
        <i
          onClick={handleOnlineHelpData}
          title={f({ id: 'onlineHelp.tooltip' })}
          className="cmd-icon text-gray-lighten cmd-icon-question"
        />
      </div>
    );
  }

  return null;
}
