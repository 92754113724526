import * as React from 'react';
// import dayjs from 'dayjs';
// import timezone from 'dayjs/plugin/timezone';
// import utc from 'dayjs/plugin/utc';
import { useRecoilState } from 'recoil';
import { useQuery } from '@tanstack/react-query';
import * as MaintenanceService from '../MaintenanceService';
import {
  IMaintenanceStatusResponse,
  IS_MAINTENANCE_ACTIVE,
  IS_MAINTENANCE_PLANNED,
  isMaintenanceTokenValid,
} from '../MaintenanceService';
import Config from '../../../../config';
import * as MaintenanceState from '../MaintenanceState';

interface IUseMaintenance {
  isMaintenancePageActive: boolean;
  isMaintenancePlanned: boolean;
  isMaintenanceTokenPresent: boolean;
  isMaintenanceTokenValid: boolean;
}

const useMaintenance = (): IUseMaintenance => {
  const [maintenance, setMaintenance] = useRecoilState<IMaintenanceStatusResponse | false>(
    MaintenanceState.maintenance
  );
  const { data: requestData, isFetched } = useQuery({
    queryKey: ['maintenance'],
    queryFn: () => MaintenanceService.checkStatus(Config.appStatus.isEnabled),
    staleTime: 9000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  // const checkStatus = async () => {
  //   const data = await MaintenanceService.checkStatus(true);
  //   if (data && data.maintenance) {
  //     setMaintenance(data);
  //     if (data.maintenance.status === 'up') {
  //       localStorage.removeItem('hideMaintenanceToast');
  //     }
  //   }
  // };

  const isMaintenancePageActive = (): boolean => {
    if (
      Config.appStatus.isEnabled &&
      maintenance &&
      maintenance.maintenance &&
      maintenance.maintenance.status === IS_MAINTENANCE_ACTIVE &&
      localStorage.getItem('maintenanceToken') !== Config.maintenanceToken
    ) {
      return true;
    }
    return false;
  };

  const isMaintenancePlanned = React.useCallback((): boolean => {
    if (
      Config.appStatus.isEnabled &&
      maintenance &&
      maintenance.maintenance &&
      maintenance.maintenance.status === IS_MAINTENANCE_PLANNED
    ) {
      return true;
    }
    return false;
  }, [maintenance]);

  React.useEffect(() => {
    // checkStatus();
    if (requestData && isFetched) {
      setMaintenance(requestData as IMaintenanceStatusResponse);
      // const maintenanceData = (requestData as IMaintenanceStatusResponse).maintenance;
      if (requestData && requestData.status === 'up') {
        localStorage.removeItem('hideMaintenanceToast');
        localStorage.removeItem('maintenanceToken');
      }
    }
  }, [isFetched, requestData, setMaintenance]);

  const data: IUseMaintenance = {
    isMaintenancePageActive: isMaintenancePageActive(),
    isMaintenancePlanned: isMaintenancePlanned(),
    isMaintenanceTokenPresent: MaintenanceService.isMaintenanceTokenPresent(),
    isMaintenanceTokenValid: isMaintenanceTokenValid(),
  };

  return data;
};

export default useMaintenance;
