import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import IconService from '../../Services/IconService';
import {
  DRIVING_STATUS,
  NO_GPS_STATUS,
  NO_GSM_STATUS,
  PRIVATE_STATUS,
  STOP_STATUS,
  UNKNOWN_STATUS,
} from './constants';
import { selectedCustomersAtom } from '../../store/recoil/customers';
import { IVehicleStatuses, IVehicles, IVehiclesData } from '../Vehicles/types';
import { vehiclesDataAtom } from '../../store/recoil/vehiclesData';
import {
  selectedVehiclesAtom,
  selectedVehicleStatusAtom,
  vehiclesAtom,
} from '../../store/recoil/vehicles';
import {
  countAllSelectedVehicles,
  countAllSelectedVehiclesByCustomer,
  countAllSelectedVehiclesByGroupId,
  countAllSelectedVehiclesByGroupIdAndStatus,
  countAllSelectedVehiclesByStatus,
  countAllSelectedVehiclesByStatusAndCustomer,
  countAllVehicles,
  countAllVehiclesByCustomer,
  countAllVehiclesByGroupId,
  countAllVehiclesByStatus,
  countAllVehiclesByStatusAndCustomer,
  countAllVehiclesByStatusAndGroup,
} from '../../Services/VehicleService';
import { filteredVehiclesAtom, isMinimalizedCarsIconsAtom } from './NavigatorState';
import { setShowAllVehicles, setShowAllVehiclesByGroup } from './NavigatorService';

interface IVehicleStatusToolbarProps {
  customerId?: number;
  groupId?: number;
}

function VehicleStatusToolbarNew({ customerId, groupId }: IVehicleStatusToolbarProps) {
  const { formatMessage: f } = useIntl();
  // const selectedVehicles = useRecoilValue<number[]>(VehiclesState.selectedVehicles);
  const filteredVehicles = useRecoilValue<number[]>(filteredVehiclesAtom);
  const selectedCustomers = useRecoilValue<number[]>(selectedCustomersAtom);
  const vehiclesData = useRecoilValue<IVehiclesData>(vehiclesDataAtom);
  const vehicles = useRecoilValue<IVehicles>(vehiclesAtom);
  const setVehicles = useSetRecoilState<IVehicles>(vehiclesAtom);
  const [selectedVehicleStatus, setSelectedVehicleStatus] =
    useRecoilState<string>(selectedVehicleStatusAtom);
  const isMinimalizedCarsIcons = useRecoilValue<boolean>(isMinimalizedCarsIconsAtom);
  const [selectedVehicles, setSelectedVehicles] = useRecoilState(selectedVehiclesAtom);

  const handleShowAllVehicles = (
    event:
      | React.MouseEvent<HTMLInputElement, MouseEvent>
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLDivElement>
  ) => {
    event.preventDefault();
    const element = event.currentTarget;

    const dataCustomerId = element.getAttribute('data-customer-id');
    const dataGroupId = element.getAttribute('data-group-id');

    if (dataCustomerId) {
      const tempCustomerId = parseInt(dataCustomerId, 10);

      const toolbarElements = document.querySelectorAll(
        `#customer-choose-${tempCustomerId} .changeNavigatorStatus`
      );
      toolbarElements.forEach((elem) => {
        elem.classList.remove('active');
      });
      element.classList.add('active');
      // setStatusCarIcon(UNKNOWN_STATUS);

      setVehicles(
        (prevState) =>
          setShowAllVehicles(prevState, vehiclesData as IVehicleStatuses, null, tempCustomerId)
            .vehicles
      );

      setSelectedVehicleStatus('');

      if (customerId === 0) {
        const tempToolbarElements = document.querySelectorAll(
          '.navigator-cars-choose .changeNavigatorStatus'
        );
        tempToolbarElements.forEach((elem: Element) => {
          elem.classList.remove('active');
        });
        const noGpsToolbarsElements = document.querySelectorAll(
          '.changeNavigatorStatus.status-all'
        );
        noGpsToolbarsElements.forEach((elem: Element) => {
          elem.classList.add('active');
        });
      }
    }

    if (dataGroupId) {
      const tempGroupId = parseInt(dataGroupId, 10);
      const toolbarElements = document.querySelectorAll(
        `#group-cars-choose-${tempGroupId} .changeNavigatorStatus`
      );
      toolbarElements.forEach((elem) => {
        elem.classList.remove('active');
      });
      element.classList.add('active');

      setVehicles(
        (prevState) =>
          setShowAllVehiclesByGroup(prevState, vehiclesData as IVehicleStatuses, null, tempGroupId)
            .vehicles
      );

      setSelectedVehicleStatus('');
    }
  };

  const handleShowDrivingVehicles = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    const element = event.currentTarget;

    const dataCustomerId = element.getAttribute('data-customer-id');
    const dataGroupId = element.getAttribute('data-group-id');

    if (dataCustomerId) {
      const tempCustomerId = parseInt(dataCustomerId, 10);
      const toolbarElements = document.querySelectorAll(
        `#customer-choose-${tempCustomerId} .changeNavigatorStatus`
      );
      toolbarElements.forEach((elem) => {
        elem.classList.remove('active');
      });
      element.classList.add('active');

      setVehicles(
        (prevState) =>
          setShowAllVehicles(
            prevState,
            vehiclesData as IVehicleStatuses,
            DRIVING_STATUS,
            tempCustomerId
          ).vehicles
      );

      setSelectedVehicleStatus(DRIVING_STATUS);

      if (customerId === 0) {
        const tempToolbarElements = document.querySelectorAll(
          '.navigator-cars-choose .changeNavigatorStatus'
        );
        tempToolbarElements.forEach((elem: Element) => {
          elem.classList.remove('active');
        });
        const noGpsToolbarsElements = document.querySelectorAll(
          '.changeNavigatorStatus.status-driving'
        );
        noGpsToolbarsElements.forEach((elem: Element) => {
          elem.classList.add('active');
        });
      }
    }

    if (dataGroupId) {
      const tempGroupId = parseInt(dataGroupId, 10);
      if (tempGroupId !== 0) {
        const toolbarElements = document.querySelectorAll(
          `#group-cars-choose-${tempGroupId} .changeNavigatorStatus`
        );
        toolbarElements.forEach((elem) => {
          elem.classList.remove('active');
        });
        element.classList.add('active');

        setVehicles(
          (prevState) =>
            setShowAllVehiclesByGroup(
              prevState,
              vehiclesData as IVehicleStatuses,
              DRIVING_STATUS,
              tempGroupId
            ).vehicles
        );

        setSelectedVehicleStatus(DRIVING_STATUS);
      }
    }
  };

  const handleShowStoppedVehicles = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    const element = event.currentTarget;

    const dataCustomerId = element.getAttribute('data-customer-id');
    const dataGroupId = element.getAttribute('data-group-id');

    if (dataCustomerId) {
      const tempCustomerId = parseInt(dataCustomerId, 10);
      const toolbarElements = document.querySelectorAll(
        `#customer-choose-${tempCustomerId} .changeNavigatorStatus`
      );
      toolbarElements.forEach((elem) => {
        elem.classList.remove('active');
      });
      element.classList.add('active');

      setVehicles(
        (prevState) =>
          setShowAllVehicles(
            prevState,
            vehiclesData as IVehicleStatuses,
            STOP_STATUS,
            tempCustomerId
          ).vehicles
      );

      setSelectedVehicleStatus(STOP_STATUS);

      if (customerId === 0) {
        const tempToolbarElements = document.querySelectorAll(
          '.navigator-cars-choose .changeNavigatorStatus'
        );
        tempToolbarElements.forEach((elem: Element) => {
          elem.classList.remove('active');
        });
        const noGpsToolbarsElements = document.querySelectorAll(
          '.changeNavigatorStatus.status-stopped'
        );
        noGpsToolbarsElements.forEach((elem: Element) => {
          elem.classList.add('active');
        });
      }
    }

    if (dataGroupId) {
      const tempGroupId = parseInt(dataGroupId, 10);
      if (tempGroupId !== 0) {
        const toolbarElements = document.querySelectorAll(
          `#group-cars-choose-${tempGroupId} .changeNavigatorStatus`
        );
        toolbarElements.forEach((elem) => {
          elem.classList.remove('active');
        });
        element.classList.add('active');

        setVehicles(
          (prevState) =>
            setShowAllVehiclesByGroup(
              prevState,
              vehiclesData as IVehicleStatuses,
              STOP_STATUS,
              tempGroupId
            ).vehicles
        );

        setSelectedVehicleStatus(STOP_STATUS);
      }
    }
  };

  const handleShowNoGpsVehicles = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    const element = event.currentTarget;

    const dataCustomerId = element.getAttribute('data-customer-id');
    const dataGroupId = element.getAttribute('data-group-id');

    if (dataCustomerId) {
      const tempCustomerId = parseInt(dataCustomerId, 10);
      const toolbarElements = document.querySelectorAll(
        `#customer-choose-${customerId} .changeNavigatorStatus`
      );
      toolbarElements.forEach((elem: Element) => {
        elem.classList.remove('active');
      });
      element.classList.add('active');

      setVehicles(
        (prevState) =>
          setShowAllVehicles(
            prevState,
            vehiclesData as IVehicleStatuses,
            NO_GPS_STATUS,
            tempCustomerId
          ).vehicles
      );

      setSelectedVehicleStatus(NO_GPS_STATUS);

      if (customerId === 0) {
        const tempToolbarElements = document.querySelectorAll(
          '.navigator-cars-choose .changeNavigatorStatus'
        );
        tempToolbarElements.forEach((elem: Element) => {
          elem.classList.remove('active');
        });
        const noGpsToolbarsElements = document.querySelectorAll(
          '.changeNavigatorStatus.status-no-gps'
        );
        noGpsToolbarsElements.forEach((elem: Element) => {
          elem.classList.add('active');
        });
      }
    }

    if (dataGroupId) {
      const tempGroupId = parseInt(dataGroupId, 10);
      if (groupId !== 0) {
        const toolbarElements = document.querySelectorAll(
          `#group-cars-choose-${tempGroupId} .changeNavigatorStatus`
        );
        toolbarElements.forEach((elem) => {
          elem.classList.remove('active');
        });
        element.classList.add('active');

        setVehicles(
          (prevState) =>
            setShowAllVehiclesByGroup(
              prevState,
              vehiclesData as IVehicleStatuses,
              NO_GPS_STATUS,
              tempGroupId
            ).vehicles
        );

        setSelectedVehicleStatus(NO_GPS_STATUS);
      }
    }
  };

  const handleShowNoGsmVehicles = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    const element = event.currentTarget;

    const dataCustomerId = element.getAttribute('data-customer-id');
    const dataGroupId = element.getAttribute('data-group-id');

    if (dataCustomerId) {
      const tempCustomerId = parseInt(dataCustomerId, 10);
      const toolbarElements = document.querySelectorAll(
        `#customer-choose-${customerId} .changeNavigatorStatus`
      );
      toolbarElements.forEach((elem) => {
        elem.classList.remove('active');
      });
      element.classList.add('active');

      setVehicles(
        (prevState) =>
          setShowAllVehicles(
            prevState,
            vehiclesData as IVehicleStatuses,
            NO_GSM_STATUS,
            tempCustomerId
          ).vehicles
      );

      setSelectedVehicleStatus(NO_GSM_STATUS);

      if (customerId === 0) {
        const tempToolbarElements = document.querySelectorAll(
          '.navigator-cars-choose .changeNavigatorStatus'
        );
        tempToolbarElements.forEach((elem: Element) => {
          elem.classList.remove('active');
        });
        const noGpsToolbarsElements = document.querySelectorAll(
          '.changeNavigatorStatus.status-no-gsm'
        );
        noGpsToolbarsElements.forEach((elem: Element) => {
          elem.classList.add('active');
        });
      }
    }

    if (dataGroupId) {
      const tempGroupId = parseInt(dataGroupId, 10);
      if (tempGroupId !== 0) {
        const toolbarElements = document.querySelectorAll(
          `#group-cars-choose-${tempGroupId} .changeNavigatorStatus`
        );
        toolbarElements.forEach((elem) => {
          elem.classList.remove('active');
        });
        element.classList.add('active');

        setVehicles(
          (prevState) =>
            setShowAllVehiclesByGroup(
              prevState,
              vehiclesData as IVehicleStatuses,
              NO_GSM_STATUS,
              tempGroupId
            ).vehicles
        );

        setSelectedVehicleStatus(NO_GSM_STATUS);
      }
    }
  };

  const handleShowPrivateVehicles = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    const element = event.currentTarget;

    const dataCustomerId = element.getAttribute('data-customer-id');
    const dataGroupId = element.getAttribute('data-group-id');

    if (dataCustomerId) {
      const tempCustomerId = parseInt(dataCustomerId, 10);
      const toolbarElements = document.querySelectorAll(
        `#customer-choose-${tempCustomerId} .changeNavigatorStatus`
      );
      toolbarElements.forEach((elem) => {
        elem.classList.remove('active');
      });
      element.classList.add('active');

      setVehicles(
        (prevState) =>
          setShowAllVehicles(
            prevState,
            vehiclesData as IVehicleStatuses,
            PRIVATE_STATUS,
            tempCustomerId
          ).vehicles
      );

      setSelectedVehicleStatus(PRIVATE_STATUS);

      if (customerId === 0) {
        const tempToolbarElements = document.querySelectorAll(
          '.navigator-cars-choose .changeNavigatorStatus'
        );
        tempToolbarElements.forEach((elem: Element) => {
          elem.classList.remove('active');
        });
        const privateToolbarsElements = document.querySelectorAll(
          '.changeNavigatorStatus.status-private'
        );
        privateToolbarsElements.forEach((elem: Element) => {
          elem.classList.add('active');
        });
      }
    }

    if (dataGroupId) {
      const tempGroupId = parseInt(dataGroupId, 10);
      if (tempGroupId !== 0) {
        const toolbarElements = document.querySelectorAll(
          `#group-cars-choose-${tempGroupId} .changeNavigatorStatus`
        );
        toolbarElements.forEach((elem) => {
          elem.classList.remove('active');
        });
        element.classList.add('active');

        setVehicles(
          (prevState) =>
            setShowAllVehiclesByGroup(
              prevState,
              vehiclesData as IVehicleStatuses,
              PRIVATE_STATUS,
              tempGroupId
            ).vehicles
        );
        setSelectedVehicleStatus(PRIVATE_STATUS);
      }
    }
  };

  const getAllVehicles = () => {
    if (customerId) {
      return countAllVehiclesByCustomer(vehicles, filteredVehicles, customerId);
    }
    if (groupId) {
      return countAllVehiclesByGroupId(vehicles, filteredVehicles, groupId);
    }
    return countAllVehicles(vehicles, filteredVehicles, selectedCustomers);
  };
  const getAllSelectedVehicles = () => {
    if (customerId) {
      return countAllSelectedVehiclesByCustomer(selectedVehicles, vehicles, customerId);
    }
    if (groupId) {
      return countAllSelectedVehiclesByGroupId(selectedVehicles, vehicles, groupId);
    }
    return countAllSelectedVehicles(selectedVehicles, vehicles);
  };

  const getAllDrivingVehicles = () => {
    if (customerId) {
      return countAllVehiclesByStatusAndCustomer(
        vehicles,
        vehiclesData as IVehicleStatuses,
        filteredVehicles,
        DRIVING_STATUS,
        customerId
      );
    }
    if (groupId) {
      return countAllVehiclesByStatusAndGroup(
        vehicles,
        vehiclesData as IVehicleStatuses,
        filteredVehicles,
        DRIVING_STATUS,
        groupId
      );
    }
    return countAllVehiclesByStatus(
      vehicles,
      vehiclesData as IVehicleStatuses,
      filteredVehicles,
      selectedCustomers,
      DRIVING_STATUS
    );
  };

  const getAllSelectedDrivingVehicles = () => {
    if (customerId) {
      return countAllSelectedVehiclesByStatusAndCustomer(
        vehicles,
        vehiclesData as IVehicleStatuses,
        selectedVehicles,
        DRIVING_STATUS,
        customerId
      );
    }
    if (groupId) {
      return countAllSelectedVehiclesByGroupIdAndStatus(
        vehicles,
        vehiclesData as IVehicleStatuses,
        selectedVehicles,
        DRIVING_STATUS,
        groupId
      );
    }
    return countAllSelectedVehiclesByStatus(
      vehicles,
      vehiclesData as IVehicleStatuses,
      selectedVehicles,
      DRIVING_STATUS
    );
  };

  const getAllStoppedVehicles = () => {
    if (customerId) {
      return countAllVehiclesByStatusAndCustomer(
        vehicles,
        vehiclesData as IVehicleStatuses,
        filteredVehicles,
        STOP_STATUS,
        customerId
      );
    }
    if (groupId) {
      return countAllVehiclesByStatusAndGroup(
        vehicles,
        vehiclesData as IVehicleStatuses,
        filteredVehicles,
        STOP_STATUS,
        groupId
      );
    }
    return countAllVehiclesByStatus(
      vehicles,
      vehiclesData as IVehicleStatuses,
      filteredVehicles,
      selectedCustomers,
      STOP_STATUS
    );
  };

  const getAllSelectedStoppedVehicles = () => {
    if (customerId) {
      return countAllSelectedVehiclesByStatusAndCustomer(
        vehicles,
        vehiclesData as IVehicleStatuses,
        selectedVehicles,
        STOP_STATUS,
        customerId
      );
    }
    if (groupId) {
      return countAllSelectedVehiclesByGroupIdAndStatus(
        vehicles,
        vehiclesData as IVehicleStatuses,
        selectedVehicles,
        STOP_STATUS,
        groupId
      );
    }
    return countAllSelectedVehiclesByStatus(
      vehicles,
      vehiclesData as IVehicleStatuses,
      selectedVehicles,
      STOP_STATUS
    );
  };

  const getAllNoGpsVehicles = () => {
    if (customerId) {
      return countAllVehiclesByStatusAndCustomer(
        vehicles,
        vehiclesData as IVehicleStatuses,
        filteredVehicles,
        NO_GPS_STATUS,
        customerId
      );
    }
    if (groupId) {
      return countAllVehiclesByStatusAndGroup(
        vehicles,
        vehiclesData as IVehicleStatuses,
        filteredVehicles,
        NO_GPS_STATUS,
        groupId
      );
    }
    return countAllVehiclesByStatus(
      vehicles,
      vehiclesData as IVehicleStatuses,
      filteredVehicles,
      selectedCustomers,
      NO_GPS_STATUS
    );
  };

  const getAllSelectedNoGpsVehicles = () => {
    if (customerId) {
      return countAllSelectedVehiclesByStatusAndCustomer(
        vehicles,
        vehiclesData as IVehicleStatuses,
        selectedVehicles,
        NO_GPS_STATUS,
        customerId
      );
    }
    if (groupId) {
      return countAllSelectedVehiclesByGroupIdAndStatus(
        vehicles,
        vehiclesData as IVehicleStatuses,
        selectedVehicles,
        NO_GPS_STATUS,
        groupId
      );
    }
    return countAllSelectedVehiclesByStatus(
      vehicles,
      vehiclesData as IVehicleStatuses,
      selectedVehicles,
      NO_GPS_STATUS
    );
  };

  const getAllNoGsmVehicles = () => {
    if (customerId) {
      return countAllVehiclesByStatusAndCustomer(
        vehicles,
        vehiclesData as IVehicleStatuses,
        filteredVehicles,
        NO_GSM_STATUS,
        customerId
      );
    }
    if (groupId) {
      return countAllVehiclesByStatusAndGroup(
        vehicles,
        vehiclesData as IVehicleStatuses,
        filteredVehicles,
        NO_GSM_STATUS,
        groupId
      );
    }
    return countAllVehiclesByStatus(
      vehicles,
      vehiclesData as IVehicleStatuses,
      filteredVehicles,
      selectedCustomers,
      NO_GSM_STATUS
    );
  };

  const getAllSelectedNoGsmVehicles = () => {
    if (customerId) {
      return countAllSelectedVehiclesByStatusAndCustomer(
        vehicles,
        vehiclesData as IVehicleStatuses,
        selectedVehicles,
        NO_GSM_STATUS,
        customerId
      );
    }
    if (groupId) {
      return countAllSelectedVehiclesByGroupIdAndStatus(
        vehicles,
        vehiclesData as IVehicleStatuses,
        selectedVehicles,
        NO_GSM_STATUS,
        groupId
      );
    }
    return countAllSelectedVehiclesByStatus(
      vehicles,
      vehiclesData as IVehicleStatuses,
      selectedVehicles,
      NO_GSM_STATUS
    );
  };

  const getAllPrivateVehicles = () => {
    if (customerId) {
      return countAllVehiclesByStatusAndCustomer(
        vehicles,
        vehiclesData as IVehicleStatuses,
        filteredVehicles,
        PRIVATE_STATUS,
        customerId
      );
    }
    if (groupId) {
      return countAllVehiclesByStatusAndGroup(
        vehicles,
        vehiclesData as IVehicleStatuses,
        filteredVehicles,
        PRIVATE_STATUS,
        groupId
      );
    }
    return countAllVehiclesByStatus(
      vehicles,
      vehiclesData as IVehicleStatuses,
      filteredVehicles,
      selectedCustomers,
      PRIVATE_STATUS
    );
  };
  const getAllSelectedPrivateVehicles = () => {
    if (customerId) {
      return countAllSelectedVehiclesByStatusAndCustomer(
        vehicles,
        vehiclesData as IVehicleStatuses,
        selectedVehicles,
        PRIVATE_STATUS,
        customerId
      );
    }
    if (groupId) {
      return countAllSelectedVehiclesByGroupIdAndStatus(
        vehicles,
        vehiclesData as IVehicleStatuses,
        selectedVehicles,
        PRIVATE_STATUS,
        groupId
      );
    }
    return countAllSelectedVehiclesByStatus(
      vehicles,
      vehiclesData as IVehicleStatuses,
      selectedVehicles,
      PRIVATE_STATUS
    );
  };

  const svgAll = (
    <svg
      width="35px"
      viewBox="-10 5 40 32"
      version="1.1"
      className="rotate90"
      data-cy="navigator-toolbar-icon-UNKNOWN"
    >
      <path
        id="path"
        d={IconService.getPath(1)}
        fill={`#${IconService.getStatusColor('UNKNOWN')}`}
        fillOpacity="1"
        stroke="none"
      />
    </svg>
  );

  const svgDriving = (
    <svg
      width="35px"
      viewBox="-10 5 40 32"
      version="1.1"
      className="rotate90"
      data-cy="navigator-toolbar-icon-DRIVING"
    >
      <path
        id="path"
        d={IconService.getPath(1)}
        fill={`#${IconService.getStatusColor('DRIVING')}`}
        fillOpacity="1"
        stroke="none"
      />
    </svg>
  );

  const svgStop = (
    <svg
      width="35px"
      viewBox="-10 5 40 32"
      version="1.1"
      className="rotate90"
      data-cy="navigator-toolbar-icon-STOP"
    >
      <path
        id="path"
        d={IconService.getPath(1)}
        fill={`#${IconService.getStatusColor('STOP')}`}
        fillOpacity="1"
        stroke="none"
      />
    </svg>
  );

  const svgNoGPS = (
    <svg
      width="35px"
      viewBox="-10 5 40 32"
      version="1.1"
      className="rotate90"
      data-cy="navigator-toolbar-icon-NO_GPS"
    >
      <path
        id="path"
        d={IconService.getPath(1)}
        fill={`#${IconService.getStatusColor('NO_GPS')}`}
        fillOpacity="1"
        stroke="none"
      />
    </svg>
  );

  const svgNoGSM = (
    <svg
      width="35px"
      viewBox="-10 5 40 32"
      version="1.1"
      className="rotate90"
      data-cy="navigator-toolbar-icon-NO_GSM"
    >
      <path
        id="path"
        d={IconService.getPath(1)}
        fill={`#${IconService.getStatusColor('NO_GSM')}`}
        fillOpacity="1"
        stroke="none"
      />
    </svg>
  );
  const svgPrivate = (
    <svg
      width="35px"
      viewBox="-10 5 40 32"
      version="1.1"
      className="rotate90"
      data-cy="navigator-toolbar-icon-PRIVATE"
    >
      <path id="path" d={IconService.getPath(1)} fill="#737373" fillOpacity="1" stroke="none" />
    </svg>
  );

  const renderCustomer = () => {
    const allVehicles = getAllVehicles();
    const allSelectedVehicles = getAllSelectedVehicles();

    const allDrivingVehicles = getAllDrivingVehicles();
    const allSelectedDrivingVehicles = getAllSelectedDrivingVehicles();

    const allStoppedVehicles = getAllStoppedVehicles();
    const allSelectedStoppedVehicles = getAllSelectedStoppedVehicles();

    const allNoGpsVehicles = getAllNoGpsVehicles();
    const allSelectedNoGpsVehicles = getAllSelectedNoGpsVehicles();

    const allNoGsmVehicles = getAllNoGsmVehicles();
    const allSelectedNoGsmVehicles = getAllSelectedNoGsmVehicles();

    const allPrivateVehicles = getAllPrivateVehicles();
    const allSelectedPrivateVehicles = getAllSelectedPrivateVehicles();

    const classList = 'navigator-cars-choose navigator-section';
    const id = `customer-choose-${customerId || 0}`;

    return (
      <div
        className={`b-navigator-filter ${isMinimalizedCarsIcons ? 'b-navigator-filter' : ''}`}
        id={id}
      >
        <div className="row no-gutters">
          <div
            className="col"
            onClick={handleShowAllVehicles}
            data-customer-id={customerId || 0}
            title={f({
              id: 'navigator.status.white',
            })}
            data-cy="navigator-car-button-status-all"
          >
            <label
              className={`b-navigator-filter-item ${
                isMinimalizedCarsIcons ? 'b-navigator-filter-item--collapsed' : ''
              }`}
            >
              <input
                type="checkbox"
                checked={selectedVehicleStatus === '' || selectedVehicleStatus === UNKNOWN_STATUS}
                onChange={handleShowAllVehicles}
              />
              {isMinimalizedCarsIcons ? (
                <span className="b-navigator-listing-item-vehicle-state b-navigator-listing-item-vehicle-state--full-gray" />
              ) : (
                <>
                  <img
                    src={
                      new URL(
                        '/assets/images/navigator/vehicle-state-full-gray.svg',
                        import.meta.url
                      ).href
                    }
                    alt="#"
                    className="b-navigator-filter-item-icon--full"
                  />
                  <img
                    src={
                      new URL(
                        '/assets/images/navigator/vehicle-state-solid-gray.svg',
                        import.meta.url
                      ).href
                    }
                    alt="#"
                    className="b-navigator-filter-item-icon--solid"
                  />
                </>
              )}

              <span className="b-navigator-filter-item-status" />
              <span className="b-navigator-filter-item-title">{allVehicles}</span>
            </label>
          </div>
          <div
            className="col"
            data-cy="navigator-car-button-status-driving"
            onClick={handleShowDrivingVehicles}
            data-customer-id={customerId || 0}
            title={f({
              id: 'navigator.status.green',
            })}
          >
            <label
              className={`b-navigator-filter-item ${
                isMinimalizedCarsIcons ? 'b-navigator-filter-item--collapsed' : ''
              }`}
            >
              <input
                type="checkbox"
                checked={selectedVehicleStatus === DRIVING_STATUS}
                onChange={handleShowDrivingVehicles}
              />

              {isMinimalizedCarsIcons ? (
                <span className="b-navigator-listing-item-vehicle-state b-navigator-listing-item-vehicle-state--full-green" />
              ) : (
                <>
                  <img
                    src={
                      new URL(
                        '/assets/images/navigator/vehicle-state-full-green.svg',
                        import.meta.url
                      ).href
                    }
                    alt="#"
                    className="b-navigator-filter-item-icon--full"
                  />
                  <img
                    src={
                      new URL(
                        '/assets/images/navigator/vehicle-state-solid-green.svg',
                        import.meta.url
                      ).href
                    }
                    alt="#"
                    className="b-navigator-filter-item-icon--solid"
                  />
                </>
              )}
              <span className="b-navigator-filter-item-status" />
              <span className="b-navigator-filter-item-title">{allDrivingVehicles}</span>
            </label>
          </div>
          <div
            className="col"
            data-cy="navigator-car-button-status-stopped"
            onClick={handleShowStoppedVehicles}
            data-customer-id={customerId || 0}
            title={f({
              id: 'navigator.status.red',
            })}
          >
            <label
              className={`b-navigator-filter-item ${
                isMinimalizedCarsIcons ? 'b-navigator-filter-item--collapsed' : ''
              }`}
            >
              <input
                type="checkbox"
                checked={selectedVehicleStatus === STOP_STATUS}
                onChange={handleShowStoppedVehicles}
              />

              {isMinimalizedCarsIcons ? (
                <span className="b-navigator-listing-item-vehicle-state b-navigator-listing-item-vehicle-state--full-red" />
              ) : (
                <>
                  <img
                    src={
                      new URL(
                        '/assets/images/navigator/vehicle-state-full-red.svg',
                        import.meta.url
                      ).href
                    }
                    alt="#"
                    className="b-navigator-filter-item-icon--full"
                  />
                  <img
                    src={
                      new URL(
                        '/assets/images/navigator/vehicle-state-solid-red.svg',
                        import.meta.url
                      ).href
                    }
                    alt="#"
                    className="b-navigator-filter-item-icon--solid"
                  />
                </>
              )}
              <span className="b-navigator-filter-item-status" />
              <span className="b-navigator-filter-item-title">{allStoppedVehicles}</span>
            </label>
          </div>
          <div
            className="col"
            data-cy="navigator-car-button-status-private"
            onClick={handleShowPrivateVehicles}
            data-customer-id={customerId || 0}
            title={f({
              id: 'navigator.status.grey',
            })}
          >
            <label
              className={`b-navigator-filter-item ${
                isMinimalizedCarsIcons ? 'b-navigator-filter-item--collapsed' : ''
              }`}
            >
              <input
                type="checkbox"
                checked={selectedVehicleStatus === PRIVATE_STATUS}
                onChange={handleShowPrivateVehicles}
              />

              {isMinimalizedCarsIcons ? (
                <span className="b-navigator-listing-item-vehicle-state b-navigator-listing-item-vehicle-state--full-dark-gray" />
              ) : (
                <>
                  <img
                    src={
                      new URL(
                        '/assets/images/navigator/vehicle-state-full-dark-gray.svg',
                        import.meta.url
                      ).href
                    }
                    alt="#"
                    className="b-navigator-filter-item-icon--full"
                  />
                  <img
                    src={
                      new URL(
                        '/assets/images/navigator/vehicle-state-solid-dark-gray.svg',
                        import.meta.url
                      ).href
                    }
                    alt="#"
                    className="b-navigator-filter-item-icon--solid"
                  />
                </>
              )}
              <span className="b-navigator-filter-item-status" />
              <span className="b-navigator-filter-item-title">{allPrivateVehicles}</span>
            </label>
          </div>
          <div
            className="col"
            data-cy="navigator-car-button-status-no-gsm"
            onClick={handleShowNoGsmVehicles}
            data-customer-id={customerId || 0}
            title={f({
              id: 'navigator.status.yellow',
            })}
          >
            <label
              className={`b-navigator-filter-item ${
                isMinimalizedCarsIcons ? 'b-navigator-filter-item--collapsed' : ''
              }`}
            >
              <input
                type="checkbox"
                checked={selectedVehicleStatus === NO_GSM_STATUS}
                onChange={handleShowNoGpsVehicles}
              />

              {isMinimalizedCarsIcons ? (
                <span className="b-navigator-listing-item-vehicle-state b-navigator-listing-item-vehicle-state--full-yellow" />
              ) : (
                <>
                  <img
                    src={
                      new URL(
                        '/assets/images/navigator/vehicle-state-full-yellow.svg',
                        import.meta.url
                      ).href
                    }
                    alt="#"
                    className="b-navigator-filter-item-icon--full"
                  />
                  <img
                    src={
                      new URL(
                        '/assets/images/navigator/vehicle-state-solid-yellow.svg',
                        import.meta.url
                      ).href
                    }
                    alt="#"
                    className="b-navigator-filter-item-icon--solid"
                  />
                </>
              )}
              <span className="b-navigator-filter-item-status" />
              <span className="b-navigator-filter-item-title">{allNoGsmVehicles}</span>
            </label>
          </div>
          <div
            className="col"
            data-cy="navigator-car-button-status-no-gps"
            onClick={handleShowNoGpsVehicles}
            data-customer-id={customerId || 0}
            title={f({
              id: 'navigator.status.blue',
            })}
          >
            <label
              className={`b-navigator-filter-item ${
                isMinimalizedCarsIcons ? 'b-navigator-filter-item--collapsed' : ''
              }`}
            >
              <input
                type="checkbox"
                checked={selectedVehicleStatus === NO_GPS_STATUS}
                onChange={handleShowNoGpsVehicles}
              />

              {isMinimalizedCarsIcons ? (
                <span className="b-navigator-listing-item-vehicle-state b-navigator-listing-item-vehicle-state--full-blue" />
              ) : (
                <>
                  <img
                    src={
                      new URL(
                        '/assets/images/navigator/vehicle-state-full-blue.svg',
                        import.meta.url
                      ).href
                    }
                    alt="#"
                    className="b-navigator-filter-item-icon--full"
                  />
                  <img
                    src={
                      new URL(
                        '/assets/images/navigator/vehicle-state-solid-blue.svg',
                        import.meta.url
                      ).href
                    }
                    alt="#"
                    className="b-navigator-filter-item-icon--solid"
                  />
                </>
              )}
              <span className="b-navigator-filter-item-status" />
              <span className="b-navigator-filter-item-title">{allNoGpsVehicles}</span>
            </label>
          </div>
        </div>
      </div>
    );
  };

  const renderGroup = () => {
    const allVehicles = getAllVehicles();
    const allSelectedVehicles = getAllSelectedVehicles();

    const allDrivingVehicles = getAllDrivingVehicles();
    const allSelectedDrivingVehicles = getAllSelectedDrivingVehicles();

    const allStoppedVehicles = getAllStoppedVehicles();
    const allSelectedStoppedVehicles = getAllSelectedStoppedVehicles();

    const allNoGpsVehicles = getAllNoGpsVehicles();
    const allSelectedNoGpsVehicles = getAllSelectedNoGpsVehicles();

    const allNoGsmVehicles = getAllNoGsmVehicles();
    const allSelectedNoGsmVehicles = getAllSelectedNoGsmVehicles();

    const allPrivateVehicles = getAllPrivateVehicles();
    const allSelectedPrivateVehicles = getAllSelectedPrivateVehicles();

    const classList = 'group-cars-choose';
    const id = `group-cars-choose-${groupId || 0}`;

    return (
      <div
        className={`b-navigator-filter ${isMinimalizedCarsIcons ? 'b-navigator-filter' : ''}`}
        id={id}
      >
        <div className="row no-gutters">
          <div
            className="col"
            onClick={handleShowAllVehicles}
            data-group-id={groupId || 0}
            title={f({
              id: 'navigator.status.white',
            })}
            data-cy="navigator-car-button-status-all"
          >
            <label
              className={`b-navigator-filter-item ${
                isMinimalizedCarsIcons ? 'b-navigator-filter-item--collapsed' : ''
              }`}
            >
              <input
                type="checkbox"
                checked={selectedVehicleStatus === '' || selectedVehicleStatus === UNKNOWN_STATUS}
                onChange={handleShowAllVehicles}
              />
              {isMinimalizedCarsIcons ? (
                <span className="b-navigator-listing-item-vehicle-state b-navigator-listing-item-vehicle-state--full-gray" />
              ) : (
                <>
                  <img
                    src={
                      new URL(
                        '/assets/images/navigator/vehicle-state-full-gray.svg',
                        import.meta.url
                      ).href
                    }
                    alt="#"
                    className="b-navigator-filter-item-icon--full"
                  />
                  <img
                    src={
                      new URL(
                        '/assets/images/navigator/vehicle-state-solid-gray.svg',
                        import.meta.url
                      ).href
                    }
                    alt="#"
                    className="b-navigator-filter-item-icon--solid"
                  />
                </>
              )}

              <span className="b-navigator-filter-item-status" />
              <span className="b-navigator-filter-item-title">{allVehicles}</span>
            </label>
          </div>
          <div
            className="col"
            data-cy="navigator-car-button-status-driving"
            onClick={handleShowDrivingVehicles}
            data-group-id={groupId || 0}
            title={f({
              id: 'navigator.status.green',
            })}
          >
            <label
              className={`b-navigator-filter-item ${
                isMinimalizedCarsIcons ? 'b-navigator-filter-item--collapsed' : ''
              }`}
            >
              <input
                type="checkbox"
                checked={selectedVehicleStatus === DRIVING_STATUS}
                onChange={handleShowDrivingVehicles}
              />

              {isMinimalizedCarsIcons ? (
                <span className="b-navigator-listing-item-vehicle-state b-navigator-listing-item-vehicle-state--full-green" />
              ) : (
                <>
                  <img
                    src={
                      new URL(
                        '/assets/images/navigator/vehicle-state-full-green.svg',
                        import.meta.url
                      ).href
                    }
                    alt="#"
                    className="b-navigator-filter-item-icon--full"
                  />
                  <img
                    src={
                      new URL(
                        '/assets/images/navigator/vehicle-state-solid-green.svg',
                        import.meta.url
                      ).href
                    }
                    alt="#"
                    className="b-navigator-filter-item-icon--solid"
                  />
                </>
              )}
              <span className="b-navigator-filter-item-status" />
              <span className="b-navigator-filter-item-title">{allDrivingVehicles}</span>
            </label>
          </div>
          <div
            className="col"
            data-cy="navigator-car-button-status-stopped"
            onClick={handleShowStoppedVehicles}
            data-group-id={groupId || 0}
            title={f({
              id: 'navigator.status.red',
            })}
          >
            <label
              className={`b-navigator-filter-item ${
                isMinimalizedCarsIcons ? 'b-navigator-filter-item--collapsed' : ''
              }`}
            >
              <input
                type="checkbox"
                checked={selectedVehicleStatus === STOP_STATUS}
                onChange={handleShowStoppedVehicles}
              />

              {isMinimalizedCarsIcons ? (
                <span className="b-navigator-listing-item-vehicle-state b-navigator-listing-item-vehicle-state--full-red" />
              ) : (
                <>
                  <img
                    src={
                      new URL(
                        '/assets/images/navigator/vehicle-state-full-red.svg',
                        import.meta.url
                      ).href
                    }
                    alt="#"
                    className="b-navigator-filter-item-icon--full"
                  />
                  <img
                    src={
                      new URL(
                        '/assets/images/navigator/vehicle-state-solid-red.svg',
                        import.meta.url
                      ).href
                    }
                    alt="#"
                    className="b-navigator-filter-item-icon--solid"
                  />
                </>
              )}
              <span className="b-navigator-filter-item-status" />
              <span className="b-navigator-filter-item-title">{allStoppedVehicles}</span>
            </label>
          </div>
          <div
            className="col"
            data-cy="navigator-car-button-status-private"
            onClick={handleShowPrivateVehicles}
            data-group-id={groupId || 0}
            title={f({
              id: 'navigator.status.grey',
            })}
          >
            <label
              className={`b-navigator-filter-item ${
                isMinimalizedCarsIcons ? 'b-navigator-filter-item--collapsed' : ''
              }`}
            >
              <input
                type="checkbox"
                checked={selectedVehicleStatus === PRIVATE_STATUS}
                onChange={handleShowPrivateVehicles}
              />

              {isMinimalizedCarsIcons ? (
                <span className="b-navigator-listing-item-vehicle-state b-navigator-listing-item-vehicle-state--full-dark-gray" />
              ) : (
                <>
                  <img
                    src={
                      new URL(
                        '/assets/images/navigator/vehicle-state-full-dark-gray.svg',
                        import.meta.url
                      ).href
                    }
                    alt="#"
                    className="b-navigator-filter-item-icon--full"
                  />
                  <img
                    src={
                      new URL(
                        '/assets/images/navigator/vehicle-state-solid-dark-gray.svg',
                        import.meta.url
                      ).href
                    }
                    alt="#"
                    className="b-navigator-filter-item-icon--solid"
                  />
                </>
              )}
              <span className="b-navigator-filter-item-status" />
              <span className="b-navigator-filter-item-title">{allPrivateVehicles}</span>
            </label>
          </div>
          <div
            className="col"
            data-cy="navigator-car-button-status-no-gsm"
            onClick={handleShowNoGsmVehicles}
            data-group-id={groupId || 0}
            title={f({
              id: 'navigator.status.yellow',
            })}
          >
            <label
              className={`b-navigator-filter-item ${
                isMinimalizedCarsIcons ? 'b-navigator-filter-item--collapsed' : ''
              }`}
            >
              <input
                type="checkbox"
                checked={selectedVehicleStatus === NO_GSM_STATUS}
                onChange={handleShowNoGsmVehicles}
              />

              {isMinimalizedCarsIcons ? (
                <span className="b-navigator-listing-item-vehicle-state b-navigator-listing-item-vehicle-state--full-yellow" />
              ) : (
                <>
                  <img
                    src={
                      new URL(
                        '/assets/images/navigator/vehicle-state-full-yellow.svg',
                        import.meta.url
                      ).href
                    }
                    alt="#"
                    className="b-navigator-filter-item-icon--full"
                  />
                  <img
                    src={
                      new URL(
                        '/assets/images/navigator/vehicle-state-solid-yellow.svg',
                        import.meta.url
                      ).href
                    }
                    alt="#"
                    className="b-navigator-filter-item-icon--solid"
                  />
                </>
              )}
              <span className="b-navigator-filter-item-status" />
              <span className="b-navigator-filter-item-title">{allNoGsmVehicles}</span>
            </label>
          </div>
          <div
            className="col"
            data-cy="navigator-car-button-status-no-gps"
            onClick={handleShowNoGpsVehicles}
            data-group-id={groupId || 0}
            title={f({
              id: 'navigator.status.blue',
            })}
          >
            <label
              className={`b-navigator-filter-item ${
                isMinimalizedCarsIcons ? 'b-navigator-filter-item--collapsed' : ''
              }`}
            >
              <input
                type="checkbox"
                checked={selectedVehicleStatus === NO_GPS_STATUS}
                onChange={handleShowNoGpsVehicles}
              />

              {isMinimalizedCarsIcons ? (
                <span className="b-navigator-listing-item-vehicle-state b-navigator-listing-item-vehicle-state--full-blue" />
              ) : (
                <>
                  <img
                    src={
                      new URL(
                        '/assets/images/navigator/vehicle-state-full-blue.svg',
                        import.meta.url
                      ).href
                    }
                    alt="#"
                    className="b-navigator-filter-item-icon--full"
                  />
                  <img
                    src={
                      new URL(
                        '/assets/images/navigator/vehicle-state-solid-blue.svg',
                        import.meta.url
                      ).href
                    }
                    alt="#"
                    className="b-navigator-filter-item-icon--solid"
                  />
                </>
              )}
              <span className="b-navigator-filter-item-status" />
              <span className="b-navigator-filter-item-title">{allNoGpsVehicles}</span>
            </label>
          </div>
        </div>
      </div>
    );
  };

  if (groupId) {
    return renderGroup();
  }
  return renderCustomer();
}

export default VehicleStatusToolbarNew;
