import { atom } from 'recoil';
import { IMaintenanceStatusResponse } from './MaintenanceService';

const hideMaintenanceToast = localStorage.getItem('hideMaintenanceToast');

export const maintenance = atom<IMaintenanceStatusResponse | false>({
  key: 'maintenance',
  default: false,
});

export const showMaintenanceToast = atom<boolean>({
  key: 'showMaintenanceToast',
  default: hideMaintenanceToast !== 'true',
});
