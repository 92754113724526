import { useState, useRef, MutableRefObject } from 'react';

function useTooltip<T extends HTMLElement = HTMLDivElement>(defaultPosition = { top: 0, left: 0 }) {
  const [hover, setHover] = useState(false);
  const [tooltipPos, setTooltipPos] = useState(defaultPosition);
  const textRef: MutableRefObject<T | null> = useRef<T>(null);

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    const cellRect = event.currentTarget.getBoundingClientRect();
    const containerScroll = {
      left: textRef.current ? textRef.current.scrollLeft : 0,
      top: textRef.current ? textRef.current.scrollTop : 0,
    };

    const tooltipPosition = {
      left: cellRect.left - containerScroll.left,
      top: cellRect.bottom - containerScroll.top,
    };

    setTooltipPos(tooltipPosition);
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  return {
    hover,
    tooltipPos,
    textRef,
    handleMouseEnter,
    handleMouseLeave,
  };
}

export default useTooltip;
