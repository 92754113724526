import * as React from 'react';
import { useRef, useCallback } from 'react';

const useDragScroll = (ref) => {
  const isDragging = useRef(false);

  const onMouseDown = useCallback(
    (e) => {
      // check if target element is input element
      const isInputElement = ['INPUT', 'TEXTAREA', 'SELECT', 'BUTTON'].includes(e.target.tagName);

      // if modal-root contains target element, it means that the target element is in modal
      const isInModal = document.getElementById('modal-root')?.contains(e.target);

      if (isInputElement || isInModal) {
        return;
      }

      isDragging.current = true;
      ref.current.style.cursor = 'grabbing';
      ref.current.style.userSelect = 'none';

      const startX = e.pageX;
      const scrollLeft = ref.current.scrollLeft;

      const onMouseMove = (event) => {
        if (!isDragging.current) return;
        const x = event.pageX - startX;
        ref.current.scrollLeft = scrollLeft - x;
      };

      const onMouseUp = () => {
        isDragging.current = false;
        ref.current.style.cursor = '';
        ref.current.style.userSelect = '';
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      };

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
      e.preventDefault();
    },
    [ref]
  );

  return onMouseDown;
};

export default useDragScroll;
