import axios, {
  AxiosError,
  AxiosResponse,
  CreateAxiosDefaults,
  InternalAxiosRequestConfig,
} from 'axios';
import { getRefreshToken } from '../components/UserData/RefreshTokenService';
import {
  setAuthToken,
  setAuthTokenExpiresAt,
  setRefreshToken,
  setRefreshTokenExpiresAt,
  setSocketServerToken,
} from './LoginService';
import config from '../../config';
import showMessage from './ToastService';
import JWTService from './JWTService';
import { logOut } from './UserService';

export const AXIOS_BASE_URL = `${config.Backend.url}/api`;
export const AXIOS_TIMEOUT = 90000;
const ERR_NETWORK = 'ERR_NETWORK';

interface CustomAxiosDefaults extends CreateAxiosDefaults {
  showToaster?: boolean;
  returnAxiosResponse?: boolean;
  returnAxiosError?: boolean;
  ignoreRefreshTokenMiddleware?: boolean;
  showErrorToaster?: boolean;
}

const axiosConfig: CustomAxiosDefaults = {
  baseURL: AXIOS_BASE_URL,
  timeout: AXIOS_TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
  },
  ignoreRefreshTokenMiddleware: false,
};

const axiosInstance = axios.create(axiosConfig);

let isRefreshing = false;
let failedQueue: {
  resolve: (token: string) => void;
  reject: (error: AxiosError) => void;
}[] = [];

interface Interceptors {
  toasterInterceptor: number | null;
  errorToasterInterceptor: number | null;
  authInterceptor: number | null;
}

export const interceptors: Interceptors = {
  toasterInterceptor: null,
  errorToasterInterceptor: null,
  authInterceptor: null,
};

const detailed500Error = (error: any) => {
  if (error.code !== ERR_NETWORK) {
    const additionalMessage =
      config.apiService.detailed500Error && error.response.data && error.response.data.message
        ? error.response.data.message
        : null;

    let errorMessage = 'Unexpected Api Error';
    if (error.response.data && error.response.data.toast) {
      errorMessage = error.response.data.toast.message;
    }
    if (additionalMessage) {
      errorMessage = `Unexpected Api Error ${additionalMessage}`;
    }
    if (error.response.data.toast) {
      showMessage(
        '',
        errorMessage,
        error.response.data.toast.type.toLowerCase(),
        error.response.data.toast.duration,
        error.response.data.toast.messagePayload || null
      );
    } else {
      showMessage('', errorMessage, 'error', null, null);
    }
  }
};

export const handleSuccessResponseToast = (response: AxiosResponse) => {
  if (!response.config.showToaster) {
    return response;
  }

  if (response.data.toast) {
    showMessage(
      '',
      response.data.toast.message,
      response.data.toast.type.toLowerCase(),
      response.data.toast.duration,
      response.data.toast.messagePayload || null
    );
  }
  return response;
};

export const handleAuth = (request: InternalAxiosRequestConfig<any>) => {
  if (request.noAuthHeader) {
    return request;
  }
  const authToken = JWTService.getToken();
  if (authToken && request.headers) {
    request.headers.Authorization = `${authToken}`;
  }
  return request;
};

export const handleErrorResponseToast = (error: AxiosError) => {
  if (!error.response && error.code !== ERR_NETWORK) {
    showMessage('', 'Unexpected API Error', 'error');
  }
  console.info('HTTPService error: ', error);
  if (error.config?.showErrorToaster === false) {
    return Promise.reject(error);
  }
  if (Array.isArray(error.config?.showErrorToaster) && error.config?.showErrorToaster.length > 0) {
    if (error.config?.showErrorToaster.includes(error.response.status)) {
      detailed500Error(error);
    }
  }
  if (error.config?.showErrorToaster === true) {
    detailed500Error(error);
  }
  return Promise.reject(error);
};

interceptors.toasterInterceptor = axiosInstance.interceptors.response.use(
  handleSuccessResponseToast
);

interceptors.authInterceptor = axiosInstance.interceptors.request.use(handleAuth);

interceptors.errorToasterInterceptor = axiosInstance.interceptors.response.use(
  undefined,
  handleErrorResponseToast
);

const processQueue = (error: AxiosError | null, token: string | null = null): void => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token as string);
    }
  });
  failedQueue = [];
};

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error: AxiosError) => {
    // console.log('Axios error', error);
    const originalRequest: InternalAxiosRequestConfig<any> | undefined = error.config;
    // Check if the request should skip the refresh token logic
    if (originalRequest?.ignoreRefreshTokenMiddleware) {
      return Promise.reject(error);
    }

    if (error.response?.status === 401 && !originalRequest?._retry) {
      if (isRefreshing) {
        return new Promise<string>((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            if (originalRequest) {
              originalRequest.headers.Authorization = token;
              return axiosInstance(originalRequest);
            }
            return Promise.reject(error);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const refreshToken = getRefreshToken();

      return new Promise<AxiosResponse>((resolve, reject) => {
        axios
          .post('/v2/auth/refresh-token', { refreshToken })
          .then(({ data }) => {
            setAuthToken(data.authToken);
            setAuthTokenExpiresAt(data.authTokenExpiresAt);
            setRefreshToken(data.refreshToken);
            setRefreshTokenExpiresAt(data.refreshTokenExpiresAt);
            setSocketServerToken(data.socketServerToken);
            axiosInstance.defaults.headers.common.Authorization = `${data.authToken}`;
            originalRequest.headers.Authorization = `${data.authToken}`;
            processQueue(null, data.authToken);
            resolve(axiosInstance(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            // clearTokens();
            // console.log('Error refreshing token', err);
            logOut();
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
