import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import CmdSkeletonLoader from '@commander-services/cmd-skeleton-loader';
import { ICustomer } from '../../interfaces/customer';
import { showMessage } from '../Toastr/ToastService';
import { PRIVATE_STATUS } from './constants';
import VehicleStatusToolbarNew from './VehicleStatusToolbarNew';
import VehicleNew from './VehicleNew';
import CustomerNew from './CustomerNew';
import FilterNew from './FilterNew';
import NavigatorGroups from './NavigatorGroups';
import Config from '../../../config';
import { TCheckboxState } from './types';
import { customersAtom, selectedCustomersAtom } from '../../store/recoil/customers';
import { IVehicle, IVehicleGroup, IVehicles, IVehiclesData } from '../Vehicles/types';
import {
  selectedVehiclesAtom,
  selectedVehicleStatusAtom,
  vehicleGroupsBySelectedCustomersSelector,
  vehiclesAtom,
} from '../../store/recoil/vehicles';
import { vehiclesDataAtom } from '../../store/recoil/vehiclesData';
import {
  getVehiclesWithDataByGroupId,
  getVehicleWithDataByCustomerId,
} from '../../Services/VehicleService';
import { isOnlinePanelOpenAtom } from '../OnlinePanel/OnlinePanelState';
import {
  autofocusAtom,
  isRidePlayerOpenAtom,
  vehicleFocusAtom,
} from '../OnlineMapNew/OnlineMapState';
import { getSelectedCustomers, getSelectedVehicles, isVehicleSelected } from './NavigatorService';
import {
  filteredVehiclesAtom,
  filterStringAtom,
  isMinimalizedCarsIconsAtom,
  isOpenAtom,
  showLoaderAtom,
} from './NavigatorState';

const TAB_VEHICLE = 'vehicle';
const TAB_VEHICLE_GROUPS = 'vehicleGroups';

function NavigatorNew() {
  const { formatMessage: f } = useIntl();
  const customers = useRecoilValue<ICustomer[]>(customersAtom);
  const selectedCustomers = useRecoilValue<number[]>(selectedCustomersAtom);
  const vehicles = useRecoilValue<IVehicles>(vehiclesAtom);
  const [selectedVehicles, setSelectedVehicles] = useRecoilState(selectedVehiclesAtom);
  const filterString = useRecoilValue<string>(filterStringAtom);
  const filteredVehicles = useRecoilValue<number[]>(filteredVehiclesAtom);
  const vehicleGroupsBySelectedCustomers = useRecoilValue<IVehicleGroup[]>(
    vehicleGroupsBySelectedCustomersSelector
  );
  const [isNavigatorOpen, setIsNavigatorOpen] = useRecoilState<boolean>(isOpenAtom);
  const vehiclesData = useRecoilValue<IVehiclesData>(vehiclesDataAtom);
  const showNavigatorLoader = useRecoilValue<boolean>(showLoaderAtom);
  const selectedVehicleStatus = useRecoilValue<string>(selectedVehicleStatusAtom);
  const [isRidePlayerOpen, setIsRidePlayerOpen] = useRecoilState<number | boolean>(
    isRidePlayerOpenAtom
  );
  const [isMinimalizedCarsIcons, setIsMinimalizedCarsIcons] = useRecoilState<boolean>(
    isMinimalizedCarsIconsAtom
  );
  const isOnlinePanelOpen = useRecoilValue<number | false>(isOnlinePanelOpenAtom);
  const setVehicleFocus = useSetRecoilState<boolean>(vehicleFocusAtom);
  const setAutofocus = useSetRecoilState<boolean>(autofocusAtom);
  const [checkboxState, setCheckboxState] = React.useState<TCheckboxState>('unChecked');
  const sectionRef = React.useRef<HTMLElement>(null);
  const headerRef = React.useRef<HTMLElement>(null);
  const [navTab, setNavTab] = React.useState<string | null>(TAB_VEHICLE);

  const handleToggleShowNavigator = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    setIsNavigatorOpen(!isNavigatorOpen);
  };

  const handleSelectAllChecked = (): boolean => {
    if (selectedVehicles.length === Object.keys(vehicles).length) {
      // input.indeterminate = false;
      // input.checked = true;
      return true;
    }
    if (selectedVehicles.length > 0) {
      // input.indeterminate = true;
      // input.checked = false;
      return false;
    }
    // input.indeterminate = false;
    // input.checked = false;
    return false;
  };

  React.useEffect(() => {
    if (isRidePlayerOpen && !selectedVehicles.includes(Number(isRidePlayerOpen))) {
      setIsRidePlayerOpen(false);
    }
  }, [isRidePlayerOpen, selectedVehicles, setIsRidePlayerOpen]);

  const handleSelectAllVehicles = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();

    if (isRidePlayerOpen) {
      showMessage(
        'navigator.toastr.selectVehicleDisabled.title',
        'navigator.toastr.selectVehicleDisabled.message',
        'warning',
        4000
      );

      return false;
    }
    if (!isOnlinePanelOpen) {
      setVehicleFocus(false);
      setAutofocus(true);
    }

    if (!handleSelectAllChecked()) {
      if (
        Config.selectedVehiclesLimit.enabled &&
        selectedVehicles.length === Config.selectedVehiclesLimit.limit
      ) {
        setSelectedVehicles([]);
        return false;
      }
      if (selectedVehicles.length > 0 && selectedVehicles.length < Object.keys(vehicles).length) {
        setSelectedVehicles([]);
        return false;
      }
      const selectAllVehicles = getSelectedVehicles(selectedVehicles);
      setSelectedVehicles(selectAllVehicles); // Recoil

      // const selectAllVehicles = Object.keys(vehicles).map((item) => Number(item));
    } else {
      setSelectedVehicles([]); // Recoil
    }
    return true;
  };

  const handleShowVehicles = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setNavTab(TAB_VEHICLE);
  };

  const handleShowVehicleGroups = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setNavTab(TAB_VEHICLE_GROUPS);
  };

  const handleStatusVehicle = (status: string | null, vehicle: IVehicle): boolean => {
    if (status === vehicle.status && vehicle.drivingMode === 'business') {
      return true;
    }
    if (status === PRIVATE_STATUS && vehicle.drivingMode === 'private') {
      return true;
    }
    if (!status) {
      return true;
    }

    return false;
  };

  // const navigatorWrapperStyles = {
  //   marginRight: isNavigatorOpen && !isNavigatorDisabled ? 0 : '-278px',
  // };

  const handleCollapseIcons = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setIsMinimalizedCarsIcons(!isMinimalizedCarsIcons);
  };

  const handleCheckboxRef = (input: HTMLInputElement | null) => {
    if (input) {
      if (selectedVehicles.length === Object.keys(vehicles).length) {
        input.indeterminate = false;
        input.checked = true;
        setCheckboxState('checked');
      } else if (selectedVehicles.length > 0) {
        input.indeterminate = true;
        input.checked = false;
        setCheckboxState('semiChecked');
      } else {
        input.indeterminate = false;
        input.checked = false;
        setCheckboxState('unChecked');
      }
    }
  };

  return (
    <aside className={`w-navigator navigator-${isNavigatorOpen ? 'on' : 'off'}`}>
      {showNavigatorLoader ? (
        <CmdSkeletonLoader type="navigator" padding="0.750rem" />
      ) : (
        <section ref={sectionRef} id="navigator-section" className="b-navigator">
          <header ref={headerRef} id="navigator-header" className="b-navigator-header">
            {/* <!-- Tabs --> */}
            <div className="b-navigator-tabs">
              <a
                id="allCars"
                href="#"
                title=""
                className={`b-navigator-tabs-item ${
                  navTab !== TAB_VEHICLE_GROUPS ? 'b-navigator-tabs-item--active' : ''
                }`}
                onClick={handleShowVehicles}
                style={navTab !== TAB_VEHICLE_GROUPS ? { color: '#dd6486' } : {}}
                data-cy="navigator-showVehicles"
              >
                {f({ id: 'navigator.tabs.allCars' })}
              </a>
              <a
                id="carsGroups"
                href="#"
                title=""
                className={`b-navigator-tabs-item ${
                  navTab === TAB_VEHICLE_GROUPS ? 'b-navigator-tabs-item--active' : ''
                }`}
                onClick={handleShowVehicleGroups}
                style={navTab === TAB_VEHICLE_GROUPS ? { color: '#dd6486' } : {}}
                data-cy="navigator-showGroups"
              >
                {f({ id: 'navigator.tabs.groups' })}
              </a>
              <a
                href="#"
                title=""
                className={`b-navigator-tabs-item b-navigator-tabs-item-actions cmd-icons cmd-icon-row-${
                  isMinimalizedCarsIcons ? 'expand' : 'colapse'
                } text-white`}
                onClick={handleCollapseIcons}
              >
                {/* {test} */}
              </a>
            </div>

            {/* <!-- Search --> */}
            <FilterNew />
            {/* <!-- Vehicle status bar --> */}
            {navTab !== TAB_VEHICLE_GROUPS ? <VehicleStatusToolbarNew /> : null}

            {/* <!-- Select All --> */}
            <div className="b-navigator-actions">
              <div
                // id="selectAll"
                className="b-navigator-actions-select-all"
                data-cy="navigator-button-select-all"
                onClick={handleSelectAllVehicles}
              >
                <div className="e-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      onChange={handleSelectAllVehicles}
                      ref={handleCheckboxRef}
                      className="js-checkbox-indeterminate"
                      id="navigatorVehicleCheckboxIndeterminate"
                      data-cy={`navigator-button-select-all-input-${checkboxState}`}
                    />
                    <span>
                      <strong>{`${f({ id: 'navigator.vehicles.chooseSelected' })} (${
                        selectedVehicles.length
                      }/${Object.keys(vehicles).length})`}</strong>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </header>
          <div className="b-custom-scrollbar">
            {navTab !== TAB_VEHICLE_GROUPS && (
              <div className="overflow">
                {getSelectedCustomers(customers, selectedCustomers).map((customer: ICustomer) => (
                  <div key={customer.id}>
                    <CustomerNew id={customer.id} name={customer.name} />
                    {selectedCustomers.length > 1 && (
                      <VehicleStatusToolbarNew customerId={customer.id} />
                    )}
                    <div
                      className="collapse-block in customer-vehicles"
                      id={`customer-vehicles-${customer.id}`}
                    >
                      {getVehicleWithDataByCustomerId(
                        vehicles,
                        vehiclesData,
                        filteredVehicles,
                        customer.id,
                        filterString
                      ).map(
                        (vehicle: IVehicle) =>
                          handleStatusVehicle(selectedVehicleStatus, vehicle) && (
                            <VehicleNew
                              key={`${customer.id}-${vehicle.id}`}
                              id={vehicle.id}
                              name={vehicle.name}
                              status={
                                vehicle.status && vehicle.drivingMode === 'business'
                                  ? vehicle.status
                                  : vehicle.drivingMode === 'private'
                                    ? 'PRIVATE'
                                    : 'UNKNOWN'
                              }
                              licenceNumber={vehicle.licenceNumber}
                              driver={vehicle.driver}
                              vehicleTypeId={vehicle.vehicleTypeId}
                              selected={isVehicleSelected(selectedVehicles, vehicle.id)}
                              customerId={customer.id}
                            />
                          )
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}

            {navTab === TAB_VEHICLE_GROUPS && (
              <div className="overflow">
                <div className="group-section">
                  {vehicleGroupsBySelectedCustomers.map((group: IVehicleGroup) => (
                    <div key={group.id}>
                      <NavigatorGroups id={group.id} name={group.name} />
                      <VehicleStatusToolbarNew groupId={group.id} />

                      <div
                        className="collapse-block in customer-vehicles"
                        id={`group-vehicles-${group.id}`}
                      >
                        {getVehiclesWithDataByGroupId(
                          vehicles,
                          vehiclesData,
                          filteredVehicles,
                          group.id,
                          filterString
                        ).map(
                          (vehicle: IVehicle) =>
                            handleStatusVehicle(selectedVehicleStatus, vehicle) && (
                              <VehicleNew
                                key={`${group.id}-${vehicle.id}`}
                                id={vehicle.id}
                                name={vehicle.name}
                                status={
                                  vehicle.status && vehicle.drivingMode === 'business'
                                    ? vehicle.status
                                    : vehicle.drivingMode === 'private'
                                      ? 'PRIVATE'
                                      : 'UNKNOWN'
                                }
                                licenceNumber={vehicle.licenceNumber}
                                driver={vehicle.driver}
                                vehicleTypeId={vehicle.vehicleTypeId}
                                selected={isVehicleSelected(selectedVehicles, vehicle.id)}
                                groupId={group.id}
                              />
                            )
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </section>
      )}
      <a
        href="#"
        title={!isNavigatorOpen ? f({ id: 'navigator.show' }) : f({ id: 'navigator.hide' })}
        className="b-navigator-toggler"
        onClick={handleToggleShowNavigator}
        data-cy={`navigator-icon-${!isNavigatorOpen ? 'show' : 'hide'}`}
      >
        {isNavigatorOpen && <i className="cmd-icons cmd-icon-expander-arrow-right" />}
        {!isNavigatorOpen && <i className="cmd-icons cmd-icon-expander-arrow-left" />}
      </a>
    </aside>
  );
}

export default React.memo(NavigatorNew);
