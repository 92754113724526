import * as React from 'react';
import './styles.scss';
import { ITab } from './interfaces';
import TabElement from './TabElement';

interface ITabProps {
  tabs: ITab[];
  children?: React.ReactNode;
  headerStyles?: React.CSSProperties;
}

function TabComponent(props: ITabProps) {
  return (
    <div>
      <div className="tabs-header" style={props.headerStyles}>
        <div className="togglable-tabs">
          <ul className="nav nav-tabs">
            {Object.values(props.tabs).map((tab: ITab, index: number) => (
              <TabElement key={`tab-${index}`} data={tab} />
            ))}
          </ul>
        </div>
        {props.children}
      </div>
    </div>
  );
}

export default TabComponent;
