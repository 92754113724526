import { atom } from 'recoil';

export interface IToastr {
  title: string;
  color: string;
  message: string;
  duration: number | null;
  messagePayload: Record<string, any> | null;
  shown?: boolean;
}

export const toastr = atom<IToastr | null>({
  key: 'toastr',
  default: null,
});
