import { JsonSchema } from 'json-schema-library';
import { AxiosResponse } from 'axios';
import { RESPONSE_OK } from '../../Services/ApiService';
import { ACTIONS_KEY, CUSTOMERS_KEY, REQUIRED, VISIBILITY_SYSTEM } from './constants';
import {
  ICmdTableColumn,
  ICmdTableSettingsColumn,
  ICmdTableSettingsData,
  ICmdTableSaveSettingsResponse,
  ITLCFilterTable,
  ICmdTableSettingsDataResponse,
} from './interfaces';
import showMessage from '../Toastr/ToastService';
import JSONValidationService from '../../Services/JSONValidationService';
import { getRequest, postRequest } from '../../Services/HttpService';
import { ICustomer } from '../../store/recoil/customers';

export const getForm = async (
  table: string,
  formId?: number,
  customerId?: number
): Promise<any> => {
  let url = `/v1/${table}/form`;
  if (formId && customerId) {
    url = `/v1/${table}/${formId}/form?customerId=${customerId}`;
  }
  const response: AxiosResponse | false = await getRequest(url, { returnAxiosResponse: true });

  if (response) {
    return response.data.form.inputs;
  }
  return null;
};
export const getTableFilterData = async (
  tableName: string,
  columnId: string,
  enableMessage?: boolean
): Promise<ITLCFilterTable | null> => {
  const response: AxiosResponse | false = await getRequest(`${tableName}/filter/${columnId}`, {
    returnAxiosResponse: true,
  });

  if (response && response.data) {
    return response.data;
  }

  return null;
};

export const arrayOrder = (
  array: ICmdTableColumn[],
  order: string[],
  key: string
): ICmdTableColumn[] => {
  array.sort((a, b) => {
    const A = a[key];
    const B = b[key];

    if (order.indexOf(A) < order.indexOf(B)) {
      return -1;
    }
    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    }
    return 0;
  });

  const sortedArray = [
    ...array.filter((item) => item.key !== ACTIONS_KEY),
    // ...array.filter((item) => item.key === ACTIONS_KEY),
  ];
  return sortedArray;
};

export const saveTableSettings = async (
  data: ICmdTableSettingsData,
  tableName: string
): Promise<ICmdTableSaveSettingsResponse | null> => {
  const url = `/v1/user-settings/list_config_${tableName}/save`;
  const response: AxiosResponse | false = await postRequest(url, data, {
    returnAxiosResponse: true,
  });

  if (response && response.data && response.data.status === RESPONSE_OK && response.data.toast) {
    showMessage('toastr.warning', response.data.toast.message, 'warning', null);
    return null;
  }
  if (response && response.data && response.data.status === RESPONSE_OK && !response.data.toast) {
    return response.data;
  }
  return null;
};

export const getTableSettings = async (
  tableName: string,
  jsonSchema?: JsonSchema
): Promise<ICmdTableSettingsDataResponse | null> => {
  const response: AxiosResponse | false = await postRequest(
    `/${tableName}/settings`,
    {},
    {
      returnAxiosResponse: true,
    }
  );
  if (response) {
    if (jsonSchema) {
      const responseForJsonSchema = { ...response.data, status: response.status };
      JSONValidationService.validateSchema(
        jsonSchema,
        responseForJsonSchema,
        `CmdTable ${tableName} Settings Schema`
      );
    }

    if (response.data && response.status === 200) {
      const newData: ICmdTableSettingsDataResponse = {
        ...response.data,
        columns: response.data.columns.filter(
          (column: ICmdTableSettingsColumn) => column.visibility !== VISIBILITY_SYSTEM
        ),
      };
      return newData;
    }
    return null;
  }
  return null;
};

export const getTableData = async <T>(
  tableName: string,
  data: Record<string, any>,
  jsonSchema?: JsonSchema
): Promise<T | null> => {
  const response: AxiosResponse | false = await postRequest(`/${tableName}/data`, data, {
    returnAxiosResponse: true,
    // showErrorToaster: false,
  });
  if (response) {
    if (jsonSchema) {
      const responseForJsonSchema = { ...response.data, status: response.status };
      JSONValidationService.validateSchema(
        jsonSchema,
        responseForJsonSchema,
        `CmdTable ${tableName} Data Schema`
      );
    }
    if (response.data && response.status === 200) {
      return response.data;
    }
    // if (response.data && response.status === 400) {
    //   return null;
    // }
    return null;
  }
  return null;
};

export const handleOrderColumns = (
  newOrderColumns: { name: string; hidden: boolean; visibility: string; sort: boolean }[],
  customer: ICustomer[]
) => {
  const tempOrder = newOrderColumns.map((item) => item.name);

  const tempTableSettingOrder: ICmdTableColumn[] = [];

  newOrderColumns.map((item) => {
    if (customer.length === 1 && item.name === CUSTOMERS_KEY) {
      tempTableSettingOrder.push({
        key: item.name,
        default: item.visibility === REQUIRED,
        hidden: true,
      });
    } else {
      tempTableSettingOrder.push({
        key: item.name,
        default: item.visibility === REQUIRED,
        hidden: item.hidden,
      });
    }
  });

  tempTableSettingOrder.push({ key: 'actions', hidden: false, default: false });

  const orderColumns = arrayOrder([...tempTableSettingOrder], [...tempOrder], 'key');

  return orderColumns;
};
