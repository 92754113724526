import React, { useEffect, useState } from 'react';
import useTable from '../hooks/useTable';
import CustomTooltip from '../CustomTooltip';
import useTooltip from '../hooks/useTooltip'; // předpoklad cesty k hooku

interface ITextCellProps {
  text: string | number | null;
  align?: string;
  tooltipEnabled?: boolean;
}

function TextCell({ text, align = 'left', tooltipEnabled = false }: ITextCellProps) {
  const { handleAnonymizedData, isAnonymizedValue } = useTable();
  const value = handleAnonymizedData(text);
  const { hover, tooltipPos, textRef, handleMouseEnter, handleMouseLeave } = useTooltip();
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const isTextTruncated = () => {
      if (!textRef.current) return false;

      return textRef.current.offsetWidth < textRef.current.scrollWidth;
    };

    setShowTooltip(isTextTruncated() && !isAnonymizedValue(text));
  }, [text, value, isAnonymizedValue, textRef]);

  return (
    <div
      className={`text-cell align-${align}`}
      style={{ flex: 1 }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <CustomTooltip
        content={value}
        show={hover && (showTooltip || tooltipEnabled)}
        position={tooltipPos}
      >
        <div className="text-cell" ref={textRef}>
          {value}
        </div>
      </CustomTooltip>
    </div>
  );
}

export default TextCell;
