import { AxiosResponse } from 'axios';
import ApiService from '../../Services/ApiService';
import { getRequest } from '../../Services/HttpService';
import LoaderService from '../../Services/LoaderService';
import PermissionsService, { PERMISSION_AUTO_REPORT } from '../../Services/PermissionsService';
import { IOption } from '../Forms/CmdField';
import { REPORT_05, REPORT_Z01 } from '../Reports';
import { IReportItem } from '../Reports/types';
import { IAction, IAutoreportColumn, IExport } from './types';

export interface IAutoreportSettingsApiResponse {
  actions: { multipleActions: IAction[]; rowActions: { maxActionCountPerRow: number } };
  columns: IAutoreportColumn;
  export: IExport;
  sorting: { [key: string]: string }[];
  status: number;
  userSettingsKey: string;
}

export const getAllowedReports = (reports: IReportItem[], selectedCustomers: number[]) => {
  const filteredOptions: IReportItem[] = [];
  reports.forEach((report) => {
    if (
      PermissionsService.hasAccess(
        `17${report.value === REPORT_Z01 ? '11' : `0${report.value}`}`,
        ['read'],
        selectedCustomers
      )
    ) {
      filteredOptions.push(report);
    }
  });
  return filteredOptions;
};

export const getReportlabel = (id: string, options: IOption[]) => {
  let label = '';
  let object: IOption | undefined;
  if (options && id) {
    object = Object.values(options).find((i) => String(i.value) === String(id));
  }
  if (object && object.item) {
    label = object.item;
  }
  return label;
};

export const getReports = async (selectedCustomers: number[]) => {
  LoaderService.showLoader();
  const response = await ApiService.get(`/v1/auto-report/list`);
  LoaderService.showLoader(false);
  if (response.data && response.data.reports && response.status === 200) {
    const options = response.data.reports.map((i) => {
      return {
        item: `${i.code} - ${i.name}`,
        value: String(i.id),
        tooltip: i.description,
        actionUrl: i.actionUrl,
      };
    });
    return getAllowedReports(options, selectedCustomers);
  }
  return false;
};

export const getVehicles = async (reportId: string) => {
  LoaderService.showLoader();
  const response = await ApiService.get(`/v1/auto-report/form/vehicleIds`, {
    reportId,
  });
  LoaderService.showLoader(false);
  if (response.data && response.status === 200) {
    const vehicles = [...response.data];
    vehicles.map((item) => {
      item.value = String(item.value);
      return item;
    });
    return vehicles;
  }
  return false;
};

export const getFrequencyParamDaysInWeek = (f) => {
  return [
    { item: f({ id: 'date.monday' }), value: '1' },
    { item: f({ id: 'date.tuesday' }), value: '2' },
    { item: f({ id: 'date.wednesday' }), value: '3' },
    { item: f({ id: 'date.thursday' }), value: '4' },
    { item: f({ id: 'date.friday' }), value: '5' },
    { item: f({ id: 'date.saturday' }), value: '6' },
    { item: f({ id: 'date.sunday' }), value: '7' },
  ];
};

export const getFrequencyParamMonths = (f) => {
  return [
    { item: f({ id: 'date.january' }), value: '1' },
    { item: f({ id: 'date.february' }), value: '2' },
    { item: f({ id: 'date.march' }), value: '3' },
    { item: f({ id: 'date.april' }), value: '4' },
    { item: f({ id: 'date.may' }), value: '5' },
    { item: f({ id: 'date.june' }), value: '6' },
    { item: f({ id: 'date.july' }), value: '7' },
    { item: f({ id: 'date.august' }), value: '8' },
    { item: f({ id: 'date.september' }), value: '9' },
    { item: f({ id: 'date.october' }), value: '10' },
    { item: f({ id: 'date.november' }), value: '11' },
    { item: f({ id: 'date.december' }), value: '12' },
  ];
};

export const getFrequencyParamDaysInMonth = (f) => {
  return [
    { item: f({ id: 'reports.firstDay' }), value: '1' },
    { item: f({ id: 'reports.lastDay' }), value: '31' },
    { item: '2', value: '2' },
    { item: '3', value: '3' },
    { item: '4', value: '4' },
    { item: '5', value: '5' },
    { item: '6', value: '6' },
    { item: '7', value: '7' },
    { item: '8', value: '8' },
    { item: '9', value: '9' },
    { item: '10', value: '10' },
    { item: '11', value: '11' },
    { item: '12', value: '12' },
    { item: '13', value: '13' },
    { item: '14', value: '14' },
    { item: '15', value: '15' },
    { item: '16', value: '16' },
    { item: '17', value: '17' },
    { item: '18', value: '18' },
    { item: '19', value: '19' },
    { item: '20', value: '20' },
    { item: '21', value: '21' },
    { item: '22', value: '22' },
    { item: '23', value: '23' },
    { item: '24', value: '24' },
    { item: '25', value: '25' },
    { item: '26', value: '26' },
    { item: '27', value: '27' },
    { item: '28', value: '28' },
    { item: '29', value: '29' },
    { item: '30', value: '30' },
  ];
};

export const createReport = async (values): Promise<boolean> => {
  LoaderService.showLoader();
  const response = await ApiService.post(`/v1/auto-report/add`, values);
  LoaderService.showLoader(false);
  return !!(response && response.status === 200 && response.data);
};

export const updateReport = async (id, values): Promise<boolean> => {
  LoaderService.showLoader();
  const response = await ApiService.put(`/v1/auto-report/${id}/edit`, values);
  LoaderService.showLoader(false);
  return !!(response && response.status === 200 && response.data);
};

export const getFrequency = async (reportId: string) => {
  const url = `/v1/auto-report/form/frequency`;
  const response = await ApiService.get(url, { reportId });
  if (response.data && response.status === 200) {
    const options = response.data.map((i) => {
      if (i.name) {
        i.item = i.name;
      }
      return i;
    });
    return options;
  }
  return false;
};

export const handleReportSettings = (
  userSettingsFromResponse: { [key: string]: boolean }[] | null,
  reportSettingsOptions: string[],
  reportId?: string
) => {
  let userSettings: { [key: string]: boolean }[] | null = null;
  if (userSettingsFromResponse && Object.keys(userSettingsFromResponse).length > 0) {
    userSettings = userSettingsFromResponse;
  }
  if (userSettings) {
    const reportSettings: string[] = [];
    Object.keys(userSettings).forEach((key: string) => {
      if (userSettings && userSettings[key] === true && reportSettingsOptions.includes(key)) {
        reportSettings.push(key);
      }
    });
    if (reportId === REPORT_05) {
      reportSettings.push('rideBook');
    }
    return reportSettings;
  }
  return reportId === REPORT_05 ? ['rideBook'] : reportSettingsOptions;
};

export const getDrivers = async (reportId: string) => {
  LoaderService.showLoader();
  const response = await ApiService.get(`/v1/auto-report/form/driverId`, {
    reportId,
  });
  LoaderService.showLoader(false);
  if (response.data && response.status === 200) {
    const drivers = [...response.data];
    drivers.map((item) => {
      item.value = String(item.value);
      return item;
    });
    return drivers;
  }
  return false;
};

export const getCustomers = async (reportId: string) => {
  LoaderService.showLoader();
  const response = await ApiService.get(`/v1/auto-report/form/customerIds`, {
    reportId,
  });
  LoaderService.showLoader(false);
  if (response.data && response.status === 200) {
    const customers = [...response.data];
    customers.map((item) => {
      item.value = String(item.value);
      return item;
    });
    return customers;
  }
  return false;
};
export const getBluecoins = async (reportId: string) => {
  LoaderService.showLoader();
  const response: AxiosResponse | false = await getRequest(
    `/v1/auto-report/form/bluecoinIds?reportId=${reportId}`,
    {
      returnAxiosResponse: true,
    }
  );
  LoaderService.showLoader(false);
  if ((response as AxiosResponse).data && (response as AxiosResponse).status === 200) {
    const bluecoins = [...(response as AxiosResponse).data];
    bluecoins.map((item) => {
      item.value = String(item.value);
      return item;
    });
    return bluecoins;
  }
  return false;
};

export const getValidation = async (reportId: string) => {
  LoaderService.showLoader();
  const response = await ApiService.get(`/v1/auto-report/validation?reportId=${reportId}`);
  LoaderService.showLoader(false);
  if (response.data && response.data.form && response.status === 200) {
    return response.data;
  }
  return false;
};

export const getValidationWithEntity = async (reportId: string, entity: string) => {
  LoaderService.showLoader();
  const response = await ApiService.get(
    `/v1/auto-report/validation?reportId=${reportId}&entity=${entity}`
  );
  LoaderService.showLoader(false);
  if (response.data && response.data.form && response.status === 200) {
    return response.data;
  }
  return false;
};

export const getReportsList = async () => {
  const url = '/v1/auto-report/form/reportId';
  LoaderService.showLoader();
  const response = await ApiService.get(url);
  LoaderService.showLoader(false);
  if (response.data && response.status === 200) {
    return response.data;
  }
  return false;
};

export const getDefaultRecipinet = async () => {
  LoaderService.showLoader();
  const response = await ApiService.get('/v1/auto-report/form/default-recipient');
  LoaderService.showLoader(false);
  if (response && response.data && response.status === 200) {
    return response.data;
  }
  return false;
};

export const getData = async (id: string) => {
  const url = `/v1/auto-report/${id}`;
  LoaderService.showLoader();
  const response = await ApiService.get(url);
  LoaderService.showLoader(false);
  if (response && response.data && response.status === 200) {
    return response.data;
  }
  return false;
};

export const getAutoReportsAccess = (types: string[], selectedCustomers: number[]) => {
  return (
    PermissionsService.hasAccess(`${PERMISSION_AUTO_REPORT}01`, types, selectedCustomers) ||
    PermissionsService.hasAccess(`${PERMISSION_AUTO_REPORT}02`, types, selectedCustomers) ||
    PermissionsService.hasAccess(`${PERMISSION_AUTO_REPORT}03`, types, selectedCustomers) ||
    PermissionsService.hasAccess(`${PERMISSION_AUTO_REPORT}04`, types, selectedCustomers) ||
    PermissionsService.hasAccess(`${PERMISSION_AUTO_REPORT}05`, types, selectedCustomers) ||
    PermissionsService.hasAccess(`${PERMISSION_AUTO_REPORT}06`, types, selectedCustomers) ||
    PermissionsService.hasAccess(`${PERMISSION_AUTO_REPORT}07`, types, selectedCustomers) ||
    PermissionsService.hasAccess(`${PERMISSION_AUTO_REPORT}08`, types, selectedCustomers) ||
    PermissionsService.hasAccess(`${PERMISSION_AUTO_REPORT}09`, types, selectedCustomers) ||
    PermissionsService.hasAccess(`${PERMISSION_AUTO_REPORT}11`, types, selectedCustomers)
  );
};
