/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { EngineFailureActiveIcon, EngineFailureInActive } from '@commander-services/cmd-icon';
import { isOnlinePanelOpenAtom, IVehicleOnlinePanels, onlinePanelAtom } from '../OnlinePanelState';
import { ICustomWidgetProps } from './Widget';
import RemoveButton from './RemoveButton';

export default function CheckEngineWidget(props: ICustomWidgetProps): JSX.Element {
  const { formatMessage: f } = useIntl();
  const [widgetData, setWidgetData] = React.useState<boolean | null>(true);

  const vehicleId = useRecoilValue<number | false>(isOnlinePanelOpenAtom);
  const onlinePanelData = useRecoilValue<IVehicleOnlinePanels>(onlinePanelAtom);

  React.useMemo(() => {
    if (!props.isMockupMode) {
      const onlinePanel = onlinePanelData[Number(vehicleId)];

      if (
        onlinePanel &&
        typeof vehicleId === 'number' &&
        typeof onlinePanel.checkEngine === 'boolean'
      ) {
        setWidgetData(onlinePanel.checkEngine);
      } else {
        setWidgetData(null);
      }
    }
  }, [onlinePanelData, props.isMockupMode, vehicleId]);

  let widgetTooltip;

  if (widgetData === null) {
    widgetTooltip = f({ id: 'enum.onlineMap.widget.types.checkEngine' });
  } else if (widgetData === true) {
    widgetTooltip = f({ id: 'widget.checkEngine.tooltip.yes' });
  } else if (widgetData === false) {
    widgetTooltip = f({ id: 'widget.checkEngine.tooltip.no' });
  }

  if (!props.isInGrid && props.isMockupMode) {
    widgetTooltip = f({ id: 'onlineTemplates.subPage.section.widgets.tooltip' });
  }

  const onDragStart = props.isInGrid ? undefined : props.onDragCallback;

  const renderWidget = () => {
    if (widgetData === true && !props.isMockupMode) {
      return (
        <EngineFailureActiveIcon className="e-widget-icon" data-cy="check-engine-widget-active" />
      );
    }
    return <EngineFailureInActive className="e-widget-icon" data-cy="check-engine-widget-mode" />;
  };

  return (
    <div
      data-cy="check-engine-widget"
      className={`b-widget b-widget--${props.data.rowSize}x${props.data.columnSize}
      widget-grid-name-${props.data.gridName} ${props.isInGrid ? '' : 'droppable-element'}`}
      draggable={!props.isInGrid}
      onDragStart={onDragStart}
      title={widgetTooltip}
      style={{ position: 'relative' }}
    >
      {props.isInGrid && (
        <RemoveButton
          dataCy="check-engine-widget-in-grid"
          onRemoveCallback={props.onRemoveCallback}
          gridName={props.data.gridName}
        />
      )}
      {props.data.columnSize === 1 && props.data.rowSize === 1 && widgetData !== null ? (
        renderWidget()
      ) : (
        <EngineFailureInActive className="e-widget-icon" color="#b7b7b7" />
      )}
    </div>
  );
}
