import { PENDING, IGNORE } from '../components/DynamicTable/TableBody/TableRow';
import ApiService from './ApiService';

export const TABLE_REFUELING = 'refueling';
export const TABLE_CAN_REFUELING = 'can-refueling';
export const TABLE_ALARM = 'alarm';
export const TABLE_ALARM_LOG = 'alarm/log';
export const TABLE_REFUELINGS_IMPORT = 'expense/import';
export const TABLE_CORRECTIONS = 'tacho-correction';
export const TABLE_AUTO_REPORT = 'auto-report';
export const TABLE_AUTO_REPORT_LOG = 'auto-report/log';
export const TABLE_DRIVER_SETTINGS = 'driver';
export const TABLE_REFUELINGS_IMPORT_RECORDS = 'expense/import/record';
export const TABLE_SUPPLIER = 'supplier';
export const TABLE_OPERATING_COSTS = 'costs';
export const TABLE_OPERATING_COSTS_TYPES = 'costs/types';
export const TABLE_TEMPLATES = 'online-map/templates';
export const TABLE_PERIOD_LOCK = 'period-lock';
export const TABLE_PERIOD_LOCK_DEFINITION = 'period-lock-definition';
export const TABLE_GDPR_PROFILES = 'gdpr/profile';
export const TABLE_GDPR_PROFILES_HISTORY = 'gdpr/profile/history';
export const TABLE_CODEBOOKS_RIDE = 'ride-tag';
export const TABLE_CODEBOOKS_PARKING = 'parking-tag';
export const TABLE_PERSON = 'person';
export const TABLE_GDPR_USER = 'gdpr/user';
export const TABLE_ECO_DRIVE = 'ride-eco-drive-violation';
export const TABLE_ONLINE_MAP_ASSIGN = 'online-map/assign';
export const TABLE_TRIP_BOOK = 'ride-book';
export const TABLE_GDPR_USER_HISTORY = 'gdpr/user/history';
export const TABLE_FUEL_CARD = 'fuel-card';
export const TABLE_REFUELING_BLACKLIST = 'refueling/blacklist';
export const TABLE_IMPORT_TEMPLATE = 'expense/import-template';
export const TABLE_VEHICLE = 'vehicle';
export const TABLE_RIDE_POSITIONS = 'ride_positions';
export const TABLE_BLUECOINS = 'bluecoins';

export const COLUMN_ASSEGNED_USERS = 'assignedUsers';

const RESPONSE_OK = 'ok';

interface IFormData {
  [key: string]: any;
}

const TableService = {
  async getForm(table: string): Promise<any> {
    const url = `/v1/${table}`;
    const response = await ApiService.get(url, null);

    if (response) {
      return response.data;
    }
    return null;
  },

  async resendDynamicForm(table: string, formData: IFormData): Promise<any> {
    const url = `/v1/${table}/form`;

    const response = await ApiService.post(url, formData);
    if (response.data && response.data.status === RESPONSE_OK) {
      return response.data;
    }
    return null;
  },

  async resendDynamicFormById(
    table: string,
    id: number,
    formData: IFormData,
    customerId?: number | null
  ): Promise<any> {
    const tempFormData = { ...formData, customerId };

    const url = `/v1/${table}/${id}/form${customerId ? `?customerId=${customerId}` : ''}`;

    const response = await ApiService.post(url, tempFormData);
    if (response.data && response.data.status === RESPONSE_OK) {
      return response.data;
    }
    return null;
  },

  async getDateSpans(customerId: number): Promise<any> {
    const formData = {
      customerId,
      state: 'editable',
    };
    const url = `/v1/period-lock/get-date-spans`;
    const response = await ApiService.put(url, formData);
    if (
      response.data &&
      response.data.status === RESPONSE_OK &&
      response.data.periodLockDateSpans
    ) {
      return response.data.periodLockDateSpans;
    }
    return null;
  },

  async saveNewForm(table: string, formData: IFormData): Promise<any> {
    const type = 'add';
    const url = `/v1/${table}/${type}`;

    const response = await ApiService.post(url, formData);
    if (response.data && response.data.status === RESPONSE_OK) {
      return response.data;
    }
    return null;
  },

  async saveEditForm(
    table: string,
    id: number,
    formData: IFormData,
    statusType?: string,
    customerId?: number | undefined
  ): Promise<any> {
    const type = 'edit';

    let url = `/v1/${table}/${id}/${type}`;

    switch (table) {
      case TABLE_REFUELINGS_IMPORT:
        url = `/v1/${table}/${id}/note/${type}?customerId=${customerId}`;
        break;
      default:
        url = `/v1/${table}/${id}/${type}`;
        break;
    }

    if (table === TABLE_REFUELINGS_IMPORT_RECORDS) {
      url =
        statusType === 'pending' || statusType === 'ignored'
          ? `/v1/${table}/${id}/change-status`
          : `/v1/${table}/${id}/${type}`;
      // const dataForm = { expenseImportRecordId: [id], status: statusType, note: formData.note };
      const dataForm = {
        vehicleId: formData.vehicleId,
        rideId: formData.rideId,
        note: formData.note,
        status: statusType,
        customerId,
      };

      const response = await ApiService.put(url, dataForm, true);
      return response ? response.data : null;
    }

    const response = await ApiService.put(url, formData, true);
    return response ? response.data : null;
  },

  async saveEditFormBulk(
    table: string,
    id: number,
    formData: IFormData,
    statusType?: string
  ): Promise<any> {
    const url = `/v1/${table}/change-status`;
    const data = { expenseImportRecordId: id, status: statusType, note: formData.note.value };
    const response = await ApiService.put(url, data);
    return response ? response.data : null;
  },

  async getFormData(
    table: string,
    id: number,
    customerId?: number | null,
    actionTypes?: string
  ): Promise<any> {
    let url = `/v1/${table}/${id}/form`;

    switch (table) {
      case TABLE_REFUELINGS_IMPORT:
        url = `/v1/${table}/${id}/note/form${customerId ? `?customerId=${customerId}` : ''}`;
        break;
      case TABLE_REFUELINGS_IMPORT_RECORDS:
        url =
          actionTypes === IGNORE || actionTypes === PENDING
            ? `/v1/${table}/${id}/status-form${customerId ? `?customerId=${customerId}` : ''}`
            : `/v1/${table}/${id}/form${customerId ? `?customerId=${customerId}` : ''}`;
        break;
      default:
        url = `/v1/${table}/${id}/form${customerId ? `?customerId=${customerId}` : ''}`;
        break;
    }

    const response = await ApiService.get(url, null);
    return response ? response.data : null;
  },

  async getFormDataBulk(table: string): Promise<any> {
    const url = `/v1/${table}/status-form`;
    const response = await ApiService.get(url, null);
    return response ? response.data : null;
  },

  async getLog(table: string, id: number, customerId?: number | null): Promise<any> {
    const url = `/v1/${table}/${id}/log?customerId=${customerId}`;
    const response = await ApiService.get(url);
    return response ? response.data : null;
  },

  async activateAlarm(id: string): Promise<any> {
    const response = await ApiService.put(`/v1/alarm/${id}/activate`, {}, true);
    return response ? response.data : null;
  },

  async deactivateAlarm(id: string): Promise<any> {
    const response = await ApiService.put(`/v1/alarm/${id}/deactivate`, {}, true);
    return response ? response.data : null;
  },

  async remove(table: string, id: number, customerId: number): Promise<any> {
    let url = `/v1/${table}/remove`;

    if (
      table === TABLE_DRIVER_SETTINGS ||
      table === TABLE_AUTO_REPORT ||
      table === TABLE_PERIOD_LOCK_DEFINITION ||
      table === TABLE_GDPR_PROFILES ||
      table === TABLE_PERSON
    ) {
      url = `/v1/${table}/${id}/remove`;
    } else if (table === TABLE_TEMPLATES) {
      url = `/v1/online-map/templates/${id}/remove`;
    } else if (table === TABLE_CORRECTIONS) {
      url = `/v1/${table}/${id}/remove?customerId=${customerId}`;
      const response = await ApiService.put(url, { correctionId: Number(id) }, true);
      return response ? response.data : null;
    } else if (
      table === TABLE_SUPPLIER ||
      table === TABLE_OPERATING_COSTS ||
      table === TABLE_OPERATING_COSTS_TYPES ||
      table === TABLE_FUEL_CARD
    ) {
      url = `/v1/${table}/${id}/remove?customerId=${customerId}`;
      const response = await ApiService.put(url, { id: Number(id) }, true);
      return response ? response.data : null;
    } else if (table === TABLE_ALARM) {
      const response = await ApiService.put(`/v1/alarm/${id}/remove`, {}, true);
      return response ? response.data : null;
    } else if (table === TABLE_REFUELINGS_IMPORT_RECORDS) {
      const dataIdRefueling = { expenseImportRecordId: { [customerId]: [id] } };
      const response = await ApiService.post(url, dataIdRefueling, true);
      return response ? response.data : null;
    }

    const data = { ids: { [customerId]: [id] } };
    const response = await ApiService.put(url, data, true);

    return response ? response.data : null;
  },
};

export default TableService;
