import { Buffer } from 'buffer';
import { AxiosResponse } from 'axios';
import { IPermissions } from '../interfaces/permission';
import PermissionsService from './PermissionsService';
import {
  setCookieConsent,
  setCustomerId,
  setDefaultCustomer,
  setDriverId,
  setDriverIdInIndexedDb,
  setMapSettings,
  setSpeedLimitSettings,
  setUserId,
  setUsername,
} from './UserService';
import { postRequest } from './HttpService';
import { LoginData } from '../components/Login/types';

interface ICustomers {
  [customerId: number]: {
    id: number;
    permissions: IPermissions;
  };
}

interface IUser {
  [index: string]: any;
}

const LoginService = {
  setPermissions(customers: ICustomers): void {
    const permissions: IPermissions = {};
    const customerIds = Object.keys(customers);
    customerIds.map((customerId: string) => {
      // Old ACL structure vs. New (soon again also old) ACL structure :D
      permissions[customerId] = customers[customerId].permissions[customerId]
        ? customers[customerId].permissions[customerId]
        : customers[customerId].permissions;
    });

    PermissionsService.save(permissions);
  },

  setUserData(user: IUser): void {
    setUsername(user.username);
    setUserId(user.id);
    setDriverId(user.driverId);
    setDriverIdInIndexedDb(user.driverId);
    // setLanguage(user.language);
    setDefaultCustomer(user.selectedCustomers);
    setCustomerId(user.customerId);
    setMapSettings(
      user.mapSettings
        ? user.mapSettings
        : {
            customerId: null,
            id: null,
            key: 'map_settings',
            value: {
              identification: true,
              licenceNumber: true,
              driverName: true,
              vehicleName: true,
              alarms: true,
              events: {
                enabled: true,
                doors: true,
                additional: true,
              },
              clusters: true,
            },
          }
    );
    if (user.speedLimits) {
      setSpeedLimitSettings(user.speedLimits.value);
    }
    setCookieConsent(user.cookies);
  },

  async signIn(
    username: string,
    password: string,
    language: string,
    recaptchaToken: string | null
  ): Promise<any> {
    const convertedPassword = Buffer.from(password).toString('base64');
    return postRequest(
      'v1/auth/sign-in',
      !recaptchaToken
        ? { username, password: convertedPassword, language }
        : {
            username,
            password: convertedPassword,
            language,
            'g-recaptcha-response': recaptchaToken,
          },
      {
        showToaster: false,
        returnAxiosResponse: true,
      }
    );
  },
};

export const setAuthToken = (authToken: string): void => {
  localStorage.setItem('JWT', authToken);
};
export const setAuthTokenExpiresAt = (expiresAt: string): void => {
  localStorage.setItem('authTokenExpiresAt', expiresAt);
};
export const setRefreshToken = (refreshToken: string): void => {
  localStorage.setItem('refreshToken', refreshToken);
};
export const setRefreshTokenExpiresAt = (expiresAt: string): void => {
  localStorage.setItem('refreshTokenExpiresAt', expiresAt);
};
export const setSocketServerToken = (socketServerToken: string): void => {
  localStorage.setItem('socketServerToken', socketServerToken);
};

export const signIn = async (
  username: string,
  password: string,
  language: string,
  recaptchaToken: string | null
): Promise<AxiosResponse | false> => {
  const convertedPassword = Buffer.from(password).toString('base64');
  return postRequest<LoginData, AxiosResponse>(
    'v2/auth/sign-in',
    !recaptchaToken
      ? { username, password: convertedPassword, language }
      : {
          username: username || '',
          password: convertedPassword,
          language,
          'g-recaptcha-response': recaptchaToken,
        },
    {
      returnAxiosError: true,
      returnAxiosResponse: true,
      showToaster: false,
      ignoreRefreshTokenMiddleware: true,
    }
  );
};

export default LoginService;
