import * as React from 'react';
import GravatarService from './GravatarService';
import { getUserEmail, getUsername } from '../../../Services/UserService';

const Gravatar = (): JSX.Element => {
  const userEmail = getUserEmail();
  if (!userEmail) {
    return (
      <span className="cmd-font-stack cmd-font-lg pull-left">
        <i className="cmd-icon cmd-icon-personal-profile cmd-font-stack-1x" />
      </span>
    );
  }
  const url = GravatarService.url(userEmail, { defaultIcon: 'retro', size: 30 });
  return (
    <span className="cmd-font-stack cmd-font-lg pull-left">
      <img
        src={url}
        alt=""
        title={getUsername() || ''}
        style={{ borderRadius: '50%', border: '1px solid #494949', display: 'inline-block' }}
      />
    </span>
  );
};

export default Gravatar;
