/**
 * Get data image/svg string for arrow icon
 * @param {number} angle
 * @param {string} color
 * @returns {string}
 */
export const arrowIcon = (angle: number, color: string = '#146755'): string => {
  const svgXml = `data:image/svg+xml;utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0A%3Csvg%20width%3D%2216px%22%20height%3D%2212px%22%20viewBox%3D%220%200%2016%2012%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20transform%3D%22rotate%28${angle}%29%22%3E%0A%20%20%3Cg%20id%3D%221.4.1-nastavenie-jazdy-ciara%22%20transform%3D%22translate%28-1081.000000%2C%20-367.000000%29%22%20fill%3D%22${color.replace(
    '#',
    '%23'
  )}%22%20fill-rule%3D%22nonzero%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M1096.73511%2C378.6325%20C1096.54111%2C378.8425%201096.27311%2C378.9555%201095.99911%2C378.9555%20C1095.87411%2C378.9555%201095.74811%2C378.9325%201095.62711%2C378.8845%20L1092.13837%2C376.684522%20C1090.15282%2C375.432448%201087.61946%2C375.453243%201085.65473%2C376.737742%20L1082.37111%2C378.8845%20L1082.37111%2C378.8845%20C1081.98611%2C379.0385%201081.54511%2C378.9385%201081.26411%2C378.6335%20C1080.98311%2C378.3275%201080.92011%2C377.8805%201081.10511%2C377.5085%20L1088.10511%2C367.5085%20C1088.44411%2C366.8305%201089.55511%2C366.8305%201089.89411%2C367.5085%20L1096.89411%2C377.5085%20C1097.07911%2C377.8795%201097.01611%2C378.3275%201096.73511%2C378.6325%20Z%22%20id%3D%22Path%22%20%2F%3E%0A%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A`;
  return svgXml;
};

/**
 * Position marker icon based on color
 * @param {string} color
 * @returns {string}
 */
export const positionMarkerIcon = (color: string = '#027d63'): string => {
  const svgXml = `data:image/svg+xml;utf8,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='13px' height='13px' viewBox='0 0 13 13' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1'%3E%3Cpath id='Path' d='M12.5 6.5 C12.5 9.814 9.814 12.5 6.5 12.5 3.186 12.5 0.5 9.814 0.5 6.5 0.5 3.186 3.186 0.5 6.5 0.5 9.814 0.5 12.5 3.186 12.5 6.5 Z' fill='${color.replace(
    '#',
    '%23'
  )}' fill-opacity='1' stroke='%23ffffff' stroke-width='1' stroke-opacity='1' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A`;
  return svgXml;
};

export const numberMarkerIcon = (value: number): string => {
  const svgXml = `data:image/svg+xml;utf8,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='31px' height='33px' viewBox='0 0 31 33' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1'%3E%3Cg id='🎨-background-secondary-enabled' withd='31' height='33'%3E%3Cpath id='path-1-1' d='M29 15.5 C29 22.956 22.956 29 15.5 29 8.044 29 2 22.956 2 15.5 2 8.044 8.044 2 15.5 2 22.956 2 29 8.044 29 15.5 Z' fill='%23ffffff' fill-opacity='1' stroke='none'/%3E%3C/g%3E%3Cpath id='🎨-background-primary' d='M27 15.5 C27 21.851 21.851 27 15.5 27 9.149 27 4 21.851 4 15.5 4 9.149 9.149 4 15.5 4 21.851 4 27 9.149 27 15.5 Z' fill='%2305b590' fill-opacity='1' stroke='none'/%3E%3Cdefs%3E%3Ctext id='string' x='50%25' y='50%25' font-family='Helvetica, Helvetica' font-size='11' text-decoration='none' text-anchor='middle' dominant-baseline='middle'%3E${value}.%3C/text%3E%3C/defs%3E%3Cuse id='1' xlink:href='%23string' fill='%23fff' fill-opacity='1' stroke='none' width='10'/%3E%3C/svg%3E%0A`;
  return svgXml;
};

export const numberMarkerIconDisabled = (value: number): string => {
  const svgXml = `data:image/svg+xml;utf8,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='31px' height='33px' viewBox='0 0 31 33' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1'%3E%3Cg id='🎨-background-secondary-disbaled' withd='31' height='33'%3E%3Cpath id='path-1-1' d='M29 15.5 C29 22.956 22.956 29 15.5 29 8.044 29 2 22.956 2 15.5 2 8.044 8.044 2 15.5 2 22.956 2 29 8.044 29 15.5 Z' fill='%23ffffff' fill-opacity='1' stroke='none'/%3E%3C/g%3E%3Cpath id='🎨-background-primary' d='M27 15.5 C27 21.851 21.851 27 15.5 27 9.149 27 4 21.851 4 15.5 4 9.149 9.149 4 15.5 4 21.851 4 27 9.149 27 15.5 Z' fill='%23C9C9C9' fill-opacity='1' stroke='none'/%3E%3Cdefs%3E%3Ctext id='string' x='50%25' y='50%25' font-family='Helvetica, Helvetica' font-size='11' text-decoration='none' text-anchor='middle' dominant-baseline='middle'%3E${value}.%3C/text%3E%3C/defs%3E%3Cuse id='1' xlink:href='%23string' fill='%23fff' fill-opacity='1' stroke='none' width='10'/%3E%3C/svg%3E%0A`;
  return svgXml;
};

export const stopMarkerIcon = (): string => {
  const svgXml = `data:image/svg+xml;utf8,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='20px' height='20px' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1'%3E%3Cg id='koniec-jazdy'%3E%3Cpath id='Oval' d='M20 10 C20 15.523 15.523 20 10 20 4.477 20 0 15.523 0 10 0 4.477 4.477 -0 10 -0 15.523 -0 20 4.477 20 10 Z' fill='%23ffffff' fill-opacity='1' stroke='none'/%3E%3Cpath id='Oval-1' d='M18 10 C18 14.418 14.418 18 10 18 5.582 18 2 14.418 2 10 2 5.582 5.582 2 10 2 14.418 2 18 5.582 18 10 Z' fill='%23b50536' fill-opacity='1' stroke='none'/%3E%3Cpath id='Path' d='M8 6 L12 6 C13.105 6 14 6.895 14 8 L14 12 C14 13.105 13.105 14 12 14 L8 14 C6.895 14 6 13.105 6 12 L6 8 C6 6.895 6.895 6 8 6 Z' fill='%23ffffff' fill-opacity='1' stroke='none'/%3E%3C/g%3E%3C/svg%3E%0A`;
  return svgXml;
};

export const stopMarkerIconDisabled = (): string => {
  const svgXml = `data:image/svg+xml;utf8,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='20px' height='20px' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1'%3E%3Cg id='koniec-jazdy'%3E%3Cpath id='Oval' d='M20 10 C20 15.523 15.523 20 10 20 4.477 20 0 15.523 0 10 0 4.477 4.477 -0 10 -0 15.523 -0 20 4.477 20 10 Z' fill='%23ffffff' fill-opacity='1' stroke='none'/%3E%3Cpath id='Oval-1' d='M18 10 C18 14.418 14.418 18 10 18 5.582 18 2 14.418 2 10 2 5.582 5.582 2 10 2 14.418 2 18 5.582 18 10 Z' fill='%23C9C9C9' fill-opacity='1' stroke='none'/%3E%3Cpath id='Path' d='M8 6 L12 6 C13.105 6 14 6.895 14 8 L14 12 C14 13.105 13.105 14 12 14 L8 14 C6.895 14 6 13.105 6 12 L6 8 C6 6.895 6.895 6 8 6 Z' fill='%23ffffff' fill-opacity='1' stroke='none'/%3E%3C/g%3E%3C/svg%3E%0A`;
  return svgXml;
};
