import * as React from 'react';

export interface IFormSectionButton {
  title: string;
  buttonCallback: (event: React.MouseEvent<any, any>) => void;
  tooltip?: string;
  disabled?: boolean;
}

interface IFormSectionProps {
  title: string;
  children: React.ReactNode;
  headerButtons?: IFormSectionButton[];
  buttons?: IFormSectionButton[];
  submitButton?: IFormSectionButton;
  id: string;
  label?: string;
}

const Section: React.FC<IFormSectionProps> = (props) => {
  return (
    <section className="basic-box">
      <header className="basic-box__header" data-cy={props.id ? `section-${props.id}` : ''}>
        {props.title}
      </header>
      {props.headerButtons && (
        <div
          className="basic-box__content"
          style={{ marginBottom: '-16px', marginTop: '8px', marginLeft: '8px' }}
        >
          {props.headerButtons.map((button: IFormSectionButton, index: number) => (
            <a
              key={`btn-${props.title}-${index}`}
              href="#"
              title={button.tooltip || ''}
              className="e-button e-button--gray"
              onClick={button.buttonCallback}
              data-cy={`header-buttons-${props.id}`}
            >
              {button.title}
            </a>
          ))}
        </div>
      )}
      <div className="basic-box__content">
        {props.label && (
          <label
            htmlFor={props.id}
            style={{
              paddingBottom: '11px',
              fontWeight: 'bold',
              fontSize: '11px',
              color: '#b3b3b3',
              paddingLeft: '16px',
            }}
          >
            {props.label}
          </label>
        )}
        {props.children}
      </div>
      {props.buttons && (
        <div className="basic-box__footer">
          {props.buttons.map((button: IFormSectionButton, index: number) => (
            <a
              key={`btn-${props.title}-${index}`}
              href="#"
              title={button.tooltip || ''}
              className="e-button e-button--gray"
              onClick={button.buttonCallback}
              data-cy={`footer-buttons-${props.id}`}
              style={button.disabled ? { pointerEvents: 'none', opacity: '0.65' } : {}}
            >
              {button.title}
            </a>
          ))}
        </div>
      )}
      {props.submitButton && (
        <div className="basic-box__footer">
          <button
            key={`btn-${props.title}`}
            type="submit"
            title={props.submitButton.tooltip || ''}
            className="e-button e-button--gray"
            onClick={props.submitButton.buttonCallback}
            data-cy={`footer-buttons-${props.id}`}
            disabled={props.submitButton.disabled || false}
          >
            {props.submitButton.title}
          </button>
        </div>
      )}
    </section>
  );
};

export default Section;
