import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  IWidgetGraph,
  IVehicleOnlinePanels,
  isOnlinePanelOpenAtom,
  onlinePanelAtom,
} from '../OnlinePanelState';
import { ICustomWidgetProps } from './Widget';
import { ecoDriveWidgetGraphAtom } from '../../Graphs/GraphState';
import { IVehicleData } from '../../Vehicles/types';
import RemoveButton from './RemoveButton';
import { getWidgetTooltip, getWidgetValue, isWidgetDisabled } from './WidgetService';
import { vehicleDataByIdSelector } from '../../../store/recoil/vehiclesData';
import useAnalytics from '../../../hooks/useAnalytics';

export default function EcoDriveWidget(props: ICustomWidgetProps): JSX.Element {
  const { trackEvent } = useAnalytics();
  const { formatMessage: f } = useIntl();
  const [widgetData, setWidgetData] = React.useState<number | null | undefined>(9.8);
  const setEcoDriveWidgetGraph = useSetRecoilState<IWidgetGraph | null>(ecoDriveWidgetGraphAtom);
  const vehicleId = useRecoilValue<number | false>(isOnlinePanelOpenAtom);
  const vehicleData = useRecoilValue<IVehicleData | null>(vehicleDataByIdSelector(vehicleId));
  const onlinePanelData = useRecoilValue<IVehicleOnlinePanels>(onlinePanelAtom);

  React.useMemo(() => {
    if (!props.isMockupMode) {
      const onlinePanel = onlinePanelData[Number(vehicleId)];
      if (onlinePanel && typeof onlinePanel.ecoDriveScore === 'number') {
        setWidgetData(onlinePanel.ecoDriveScore);
      } else {
        setWidgetData(null);
      }
    }
  }, [onlinePanelData, props.isMockupMode, vehicleId]);

  let iconColor = '#1C957A';
  let iconType = 'good';
  if (
    (widgetData !== null || widgetData !== undefined) &&
    typeof widgetData === 'number' &&
    widgetData <= 4.9
  ) {
    iconColor = '#b50536';
    iconType = 'bad';
  } else if (
    (widgetData !== null || widgetData !== undefined) &&
    widgetData &&
    widgetData >= 5 &&
    widgetData <= 7.9
  ) {
    iconColor = '#df731d';
    iconType = 'not-good';
  }
  if (widgetData === null || widgetData === undefined || props.isMockupMode) {
    iconColor = '#C8C8C8';
  }

  const handleEcoDriveDetail = () => {
    if (typeof widgetData === 'number' && vehicleData) {
      // const rideId = vehicleData.lastRide?.id;
      trackEvent(['EcoDrive Widget', 'click', 'Open EcoDrive page']);
      setEcoDriveWidgetGraph(() => {
        return {
          vehicleId: vehicleId || 0,
        };
      });
    }
  };

  return (
    <div
      data-cy="eco-drive-widget"
      className={`b-widget is-clickable b-widget--${props.data.rowSize}x${props.data.columnSize} ${
        props.data.hasIcon && props.data.columnSize > 1 ? 'b-widget--alignLeft' : ''
      } widget-grid-name-${props.data.gridName} ${props.isInGrid ? '' : 'droppable-element'}`}
      draggable={!props.isInGrid}
      onDragStart={props.isInGrid ? undefined : props.onDragCallback}
      title={getWidgetTooltip(
        f({ id: 'widget.ecoDrive.tooltip' }),
        f({ id: 'onlineTemplates.subPage.section.widgets.tooltip' }),
        props.isInGrid,
        props.isMockupMode
      )}
    >
      {props.isInGrid && (
        <RemoveButton
          dataCy="eco-drive-widget-in-grid"
          onRemoveCallback={props.onRemoveCallback}
          gridName={props.data.gridName}
        />
      )}

      {props.data.hasIcon && props.data.columnSize === 1 && (
        <div
          data-cy="eco-drive-widget-icon-size-1"
          style={{ cursor: typeof widgetData === 'number' && !props.isMockupMode ? 'pointer' : '' }}
          onClick={props.isMockupMode ? undefined : handleEcoDriveDetail}
        >
          <i
            className={`e-widget-icon cmd-icon cmd-icon-eco-drive-${iconType} ${isWidgetDisabled(
              widgetData
            )}`}
            style={{ color: iconColor }}
          />
          <div
            className={`e-widget-value ${isWidgetDisabled(widgetData)}`}
            style={{ textAlign: 'center' }}
          >
            <strong>{getWidgetValue(widgetData)}</strong>
          </div>
        </div>
      )}

      {props.data.hasIcon && props.data.columnSize === 2 && (
        <div
          data-cy="eco-drive-widget-icon-size-2"
          style={{ cursor: typeof widgetData === 'number' && !props.isMockupMode ? 'pointer' : '' }}
          className="row no-gutters"
          onClick={props.isMockupMode ? undefined : handleEcoDriveDetail}
        >
          <div className="col-auto">
            <i
              className={`e-widget-icon e-widget-icon--md cmd-icon cmd-icon-eco-drive-${iconType} ${isWidgetDisabled(
                widgetData
              )}`}
              style={{ color: iconColor }}
            />
          </div>
          <div className="col">
            <div className={`e-widget-value e-widget-value--md ${isWidgetDisabled(widgetData)}`}>
              <strong>{getWidgetValue(widgetData)}</strong>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
