import * as React from 'react';
import { IntlShape } from 'react-intl';
import {
  ALARM_ARROW_COLOR,
  IS_ALARM_LAYER,
  IS_DRIVING_POSITION,
  IS_PAUSE_POSITION,
  IS_RIDE_LAYER,
  IS_START_POSITION,
  RIDE_ARROW_COLOR,
  RIDE_POSITION_COLOR,
  IS_ECO_DRIVE_LAYER,
  IS_STOP_POSITION,
} from './constants';
import PositionTooltip from './PositionTooltip';
import GoogleMapsService from '../../Services/GoogleMapsService';
import { IRideMapAlarmPositionMarkerTooltips } from './RidePlayerAlarmMarkerService';
import {
  IRide,
  IRideDetail,
  IRideMapArrowMarkerTooltips,
  IRideMapArrowMarkers,
  IRideMapMarkerTooltips,
  IRideMapMarkers,
  IRideMapNumberIconTooltips,
  IRideMapNumberIcons,
  IRidePlayerSpeedLimitsSettings,
  IRidePosition,
  TPositionType,
  TRideActiveLayer,
  TToggleTooltip,
  TTooltip,
} from './types';
import { getEcoDrive, getSpeedlimit } from './RidePlayerService';
import {
  arrowIcon,
  numberMarkerIcon,
  positionMarkerIcon,
  stopMarkerIcon,
} from './RidePlayerIconService';

/**
 * Create waiting marker tooltips
 * @param {IRide[]} rides
 * @param {IntlShape} intl
 * @returns {IRideMapMarkerInfoWindows}
 */
export const createWaitingMarkerTooltips = (
  rides: IRide[],
  intl: IntlShape
): IRideMapMarkerTooltips => {
  const positionMarkerTooltips: IRideMapMarkerTooltips = {};
  for (let rideIndex = 0; rideIndex < rides.length; rideIndex += 1) {
    const rideNumber = rideIndex + 1;
    positionMarkerTooltips[rideNumber] = [];
    const ride = rides[rideIndex];
    for (let positionIndex = 0; positionIndex < ride.positions.length; positionIndex += 1) {
      if (ride.positions[positionIndex].type === IS_PAUSE_POSITION) {
        let positionTooltip: JSX.Element | null = null;
        positionTooltip = (
          <PositionTooltip
            intl={intl}
            position={ride.positions[positionIndex]}
            positionIndex={positionIndex}
          />
        );

        const positionMarkerTooltip = GoogleMapsService.createRidePositionMarkerOverlay(
          ride.positions[positionIndex],
          positionTooltip
        );

        positionMarkerTooltips[rideNumber][positionIndex] = positionMarkerTooltip;
      }
    }
  }
  return positionMarkerTooltips;
};

/**
 * Create ride number marker tooltips
 * @param {IRide[]} rides
 * @param {IntlShape} intl
 * @returns {IRideMapMarkerInfoWindows}
 */
export const createRideNumberMarkerTooltips = (
  rides: IRide[],
  intl: IntlShape
): IRideMapNumberIconTooltips => {
  const iconMarkerTooltips: IRideMapNumberIconTooltips = {};
  for (let rideIndex = 0; rideIndex < rides.length; rideIndex += 1) {
    const rideNumber = rideIndex + 1;
    const ride = rides[rideIndex];
    const positionIndex = 0;
    let positionTooltip: JSX.Element | null = null;
    positionTooltip = (
      <PositionTooltip
        intl={intl}
        position={ride.positions[positionIndex]}
        positionIndex={positionIndex}
        ride={rides[rideIndex]}
      />
    );

    const positionMarkerTooltip = GoogleMapsService.createRideNumberMarkerOverlay(
      ride.positions[positionIndex],
      positionTooltip
    );

    iconMarkerTooltips[rideNumber] = positionMarkerTooltip;
  }
  return iconMarkerTooltips;
};

/**
 * Create ride position tooltips
 * @param {IRide[]} rides
 * @param {IntlShape} intl
 * @returns {IRideMapMarkerInfoWindows}
 */
export const createPositionTooltips = (rides: IRide[], intl: IntlShape): IRideMapMarkerTooltips => {
  const positionMarkerTooltips: IRideMapMarkerTooltips = {};
  for (let rideIndex = 0; rideIndex < rides.length; rideIndex += 1) {
    const rideNumber = rideIndex + 1;
    positionMarkerTooltips[rideNumber] = [];
    const ride = rides[rideIndex];
    for (let positionIndex = 0; positionIndex < ride.positions.length; positionIndex += 1) {
      let positionTooltip: JSX.Element | null = null;
      positionTooltip = (
        <PositionTooltip
          intl={intl}
          position={ride.positions[positionIndex]}
          positionIndex={positionIndex}
        />
      );

      const positionMarkerTooltip = GoogleMapsService.createRidePositionMarkerOverlay(
        ride.positions[positionIndex],
        positionTooltip
      );

      positionMarkerTooltips[rideNumber][positionIndex] = positionMarkerTooltip;
    }
  }
  return positionMarkerTooltips;
};

/**
 * Create ride number icon marker
 * @param {google.maps.Map} map
 * @param {google.maps.LatLng} position
 * @param {number} rideNumber
 * @param {TTooltip} tooltip
 * @returns {google.maps.Marker}
 */
export const createRideNumberIconMarker = (
  map: google.maps.Map,
  ride: IRide,
  rideNumber: number,
  tooltip: TTooltip,
  handeActiveRide: (number: number) => void
): google.maps.Marker => {
  const position = new google.maps.LatLng(ride.positions[0].gpsLat, ride.positions[0].gpsLon);
  const icon: google.maps.Icon = {
    url: numberMarkerIcon(rideNumber),
    anchor: new google.maps.Point(15, 15),
  };
  const markerOptions: google.maps.MarkerOptions = {
    icon,
    position,
    map,
    zIndex: rideNumber + 100,
  };
  const marker = new google.maps.Marker(markerOptions);
  marker.set('originalZIndex', rideNumber + 100);
  marker.addListener('click', () => handeActiveRide(rideNumber));
  if (tooltip) {
    marker.addListener('mouseover', () =>
      tooltip.toggleTooltipCallback(tooltip.rideNumber, tooltip.positionIndex)
    );
    marker.addListener('mouseout', () =>
      tooltip.toggleTooltipCallback(tooltip.rideNumber, tooltip.positionIndex)
    );
  }
  return marker;
};

/**
 * Create start marker
 * @param {google.maps.Map} map
 * @param {google.maps.LatLng} position
 * @param {TTooltip} tooltip
 * @param {TPositionType} positionType
 * @returns {google.maps.Marker}
 */
export const createStartMarker = (
  map: google.maps.Map,
  position: google.maps.LatLng,
  tooltip?: TTooltip
): google.maps.Marker => {
  const icon: google.maps.Icon = {
    url: '/assets/images/player/StartPositionMarker.svg',
    anchor: new google.maps.Point(10, 10),
  };
  const markerOptions: google.maps.MarkerOptions = {
    icon,
    position,
    map,
    zIndex: 99,
  };
  const marker = new google.maps.Marker(markerOptions);
  marker.setZIndex(99);
  if (tooltip) {
    marker.addListener('mouseover', () =>
      tooltip.toggleTooltipCallback(tooltip.rideNumber, tooltip.positionIndex)
    );
    marker.addListener('mouseout', () =>
      tooltip.toggleTooltipCallback(tooltip.rideNumber, tooltip.positionIndex)
    );
  }
  return marker;
};

export const createStopMarker = (
  map: google.maps.Map,
  position: google.maps.LatLng,
  tooltip?: TTooltip
): google.maps.Marker => {
  const icon: google.maps.Icon = {
    url: stopMarkerIcon(),
    anchor: new google.maps.Point(10, 10),
  };
  let zIndex = 100;
  if (tooltip) {
    zIndex = 100 + tooltip.rideNumber;
  }
  const markerOptions: google.maps.MarkerOptions = {
    icon,
    position,
    map,
    zIndex,
  };
  const marker = new google.maps.Marker(markerOptions);
  marker.set('originalZIndex', zIndex);
  if (tooltip) {
    marker.addListener('mouseover', () =>
      tooltip.toggleTooltipCallback(tooltip.rideNumber, tooltip.positionIndex)
    );
    marker.addListener('mouseout', () =>
      tooltip.toggleTooltipCallback(tooltip.rideNumber, tooltip.positionIndex)
    );
  }
  return marker;
};

/**
 * Create ride position marker
 * @param {google.maps.Map} map
 * @param {google.maps.LatLng} position
 * @returns {google.maps.Marker}
 */
export const createPositionMarker = (
  map: google.maps.Map | undefined,
  position: google.maps.LatLng,
  speed: number,
  speedlimitsEnabled: boolean,
  speedlimitSettings: IRidePlayerSpeedLimitsSettings,
  ecoScore: number | null,
  tooltip?: TTooltip,
  positionType?: TPositionType
): google.maps.Marker => {
  let color = RIDE_POSITION_COLOR;
  if (speedlimitsEnabled) {
    color = getSpeedlimit(speedlimitSettings, speed).color;
  }

  const icon: google.maps.Icon = {
    url: positionMarkerIcon(color),
    anchor: new google.maps.Point(6, 6),
  };

  const markerOptions = {
    icon,
    position,
    map,
  };
  const marker = new google.maps.Marker(markerOptions);
  marker.set('positionType', positionType);
  marker.set('speed', speed);
  marker.set('originalColorSpeedlimits', getSpeedlimit(speedlimitSettings, speed).color);
  marker.set('originalColor', RIDE_POSITION_COLOR);
  marker.set('originalColorEcoScore', ecoScore !== null ? getEcoDrive(ecoScore).color : null);
  marker.set('ecoScore', ecoScore);
  marker.setZIndex(98);
  if (tooltip) {
    marker.addListener('mouseover', () =>
      tooltip.toggleTooltipCallback(tooltip.rideNumber, tooltip.positionIndex)
    );
    marker.addListener('mouseout', () =>
      tooltip.toggleTooltipCallback(tooltip.rideNumber, tooltip.positionIndex)
    );
  }
  return marker;
};

/**
 * Create ride markers
 * @param {google.maps.Map} map
 * @param {IRide[]} rides
 * @param {TToggleTooltip} handleToggleTooltip
 * @returns {IRideMapMarkers}
 */
export const createPositionMarkers = (
  map: google.maps.Map | undefined,
  rides: IRide[],
  speedlimitsEnabled: boolean,
  speedlimitSettings: IRidePlayerSpeedLimitsSettings,
  handleToggleTooltip?: TToggleTooltip
): IRideMapMarkers => {
  const markers: IRideMapMarkers = {};
  for (let rideIndex = 0; rideIndex < rides.length; rideIndex += 1) {
    const rideNumber = rideIndex + 1;
    markers[rideNumber] = [];
    const ride = rides[rideIndex];
    for (let positionIndex = 0; positionIndex < ride.positions.length; positionIndex += 1) {
      if (
        ride.positions[positionIndex].type !== IS_START_POSITION &&
        ride.positions[positionIndex].type !== IS_STOP_POSITION
      ) {
        const marker = createPositionMarker(
          map,
          new google.maps.LatLng(
            ride.positions[positionIndex].gpsLat,
            ride.positions[positionIndex].gpsLon
          ),
          ride.positions[positionIndex].speed,
          speedlimitsEnabled,
          speedlimitSettings,
          ride.positions[positionIndex].ecoScore,
          handleToggleTooltip
            ? {
                rideNumber,
                positionIndex,
                toggleTooltipCallback: handleToggleTooltip,
              }
            : undefined,
          ride.positions[positionIndex].type
        );

        if (marker) {
          markers[rideNumber].push(marker);
        }
      }
    }
  }
  return markers;
};

/**
 * Create waiting marker
 * @param {google.maps.Map} map
 * @param {google.maps.LatLng} position
 * @returns {google.maps.Marker}
 */
const createWaitingMarker = (
  map: google.maps.Map | undefined,
  position: google.maps.LatLng,
  tooltip?: TTooltip
): google.maps.Marker => {
  const markerOptions = {
    icon: {
      url: '/assets/images/player/PausePositionMarker.svg',
      anchor: new google.maps.Point(20, 16),
    },
    position,
    map,
    zIndex: 99,
  };
  const marker = new google.maps.Marker(markerOptions);
  if (tooltip) {
    marker.addListener('mouseover', () =>
      tooltip.toggleTooltipCallback(tooltip.rideNumber, tooltip.positionIndex)
    );
    marker.addListener('mouseout', () =>
      tooltip.toggleTooltipCallback(tooltip.rideNumber, tooltip.positionIndex)
    );
  }
  return marker;
};

/**
 * Create waiting markers
 * @param {google.maps.Map} map
 * @param {IRidePosition[]} positions
 * @param {TToggleTooltip} handleToggleInfoWindow
 * @returns
 */
export const createWaitingMarkers = (
  map: google.maps.Map,
  rides: IRide[],
  activeRide: number,
  handleToggleInfoWindow?: TToggleTooltip
): IRideMapMarkers => {
  const markers: IRideMapMarkers = {};
  for (let rideIndex = 0; rideIndex < rides.length; rideIndex += 1) {
    const rideNumber = rideIndex + 1;
    markers[rideNumber] = [];
    const ride = rides[rideIndex];
    // console.log('rideNumber === activeRide: ', rideNumber, rideNumber === activeRide);
    for (let positionIndex = 0; positionIndex < ride.positions.length; positionIndex += 1) {
      if (ride.positions[positionIndex].type === 'PAUSE') {
        const marker = createWaitingMarker(
          rideNumber === activeRide ? map : undefined,
          new google.maps.LatLng(
            ride.positions[positionIndex].gpsLat,
            ride.positions[positionIndex].gpsLon
          ),
          handleToggleInfoWindow
            ? {
                rideNumber,
                positionIndex,
                toggleTooltipCallback: handleToggleInfoWindow,
              }
            : undefined
        );

        if (marker) {
          markers[rideNumber].push(marker);
        }
      }
    }
  }
  return markers;
};

/**
 * Create timline marker tooltip
 * @param {number} positionIndex
 * @param {IRidePosition} position
 * @param {IRideDetail} rideData
 * @param {IntlShape} intl
 * @returns {HTMLMapMarker}
 */
export const createTimelineMarkerTooltip = (
  positionIndex: number,
  position: IRidePosition,
  rideData: IRideDetail,
  intl: IntlShape
): HTMLMapMarker => {
  const positionTooltip = (
    <PositionTooltip intl={intl} position={position} positionIndex={positionIndex} />
  );

  const positionMarkerTooltip = GoogleMapsService.createRidePositionMarkerOverlay(
    position,
    positionTooltip
  );

  return positionMarkerTooltip;
};

/**
 * Create timeline position marker
 * @param {google.maps.Map} map
 * @param {TToggleTooltip} handleToggleTooltip
 * @returns {google.maps.Marker}
 */
export const createTimelinePositionMarker = (
  map: google.maps.Map,
  handleToggleTooltip: () => void
): google.maps.Marker => {
  const icon: google.maps.Icon = {
    url: '/assets/images/player/TimelineMarker.svg',
    anchor: new google.maps.Point(8, 8),
  };
  const markerOptions: google.maps.MarkerOptions = {
    icon,
    map,
    zIndex: 9998,
  };
  const marker = new google.maps.Marker(markerOptions);
  marker.addListener('mouseover', () => handleToggleTooltip());
  marker.addListener('mouseout', () => handleToggleTooltip());
  return marker;
};

/**
 * Create arrow marker tooltips
 * @param {IRide[]} rides
 * @param {IntlShape} intl
 * @param {IRideMapMarkers} arrowMarkers
 * @returns {IRideMapArrowMarkerTooltips}
 */
export const createArrowMarkerTooltips = (
  rides: IRide[],
  intl: IntlShape,
  arrowMarkers: IRideMapArrowMarkers
): IRideMapArrowMarkerTooltips => {
  const positionMarkerTooltips: IRideMapArrowMarkerTooltips = {};
  Object.keys(arrowMarkers).forEach((rideNumber: string) => {
    positionMarkerTooltips[rideNumber] = [];
    const rideIndex = Number(rideNumber) - 1;
    const ride = rides[rideIndex];
    Object.keys(arrowMarkers[Number(rideNumber)]).forEach((positionIndex: string) => {
      let positionTooltip: JSX.Element | null = null;
      positionTooltip = (
        <PositionTooltip
          intl={intl}
          position={ride.positions[positionIndex]}
          positionIndex={Number(positionIndex)}
        />
      );

      const positionMarkerTooltip = GoogleMapsService.createRidePositionMarkerOverlay(
        ride.positions[positionIndex],
        positionTooltip
      );

      positionMarkerTooltips[rideNumber][positionIndex] = positionMarkerTooltip;
    });
  });

  return positionMarkerTooltips;
};

/**
 * Create ride number markers
 * @param [google.maps.Map} map
 * @param {IRide[]} rides
 * @returns {IRideMapNumberIcons}
 */
export const createRideNumberMarkers = (
  map: google.maps.Map,
  rides: IRide[],
  handleToggleTooltip: (rideNumber: number) => void,
  handleActiveRide: (rideNumber: number) => void
): IRideMapNumberIcons => {
  const rideNumberIcons: IRideMapNumberIcons = {};
  for (let rideIndex = 0; rideIndex < rides.length; rideIndex += 1) {
    const icon = createRideNumberIconMarker(
      map,
      rides[rideIndex],
      rideIndex + 1,
      {
        rideNumber: rideIndex + 1,
        positionIndex: 0,
        toggleTooltipCallback: handleToggleTooltip,
      },
      handleActiveRide
    );
    rideNumberIcons[rides[rideIndex].rideData.key] = icon;
  }
  return rideNumberIcons;
};

/**
 * Create arrow marker
 * @param {google.maps.Map} map
 * @param {google.maps.LatLng} position
 * @returns {google.maps.Marker}
 */
export const createArrowMarker = (
  map: google.maps.Map | undefined,
  position: google.maps.LatLng,
  angle: number,
  speed: number,
  ecoScore: number,
  speedlimitsEnabled: boolean,
  speedlimitSettings: IRidePlayerSpeedLimitsSettings,
  activeLayer: TRideActiveLayer,
  positionIndex: number,
  tooltip?: TTooltip
): google.maps.Marker => {
  let color = RIDE_ARROW_COLOR; // default ride green color
  if (activeLayer === IS_RIDE_LAYER && speedlimitsEnabled) {
    color = getSpeedlimit(speedlimitSettings, speed).color;
  }
  if (activeLayer === IS_ALARM_LAYER) {
    color = ALARM_ARROW_COLOR;
  }
  if (activeLayer === IS_ECO_DRIVE_LAYER) {
    color = getEcoDrive(ecoScore).color;
  }
  const icon: google.maps.Icon = {
    url: arrowIcon(angle, color),
    anchor: new google.maps.Point(6, 7),
  };
  const markerOptions: google.maps.MarkerOptions = {
    icon,
    position,
    map,
    zIndex: 10,
  };
  const marker = new google.maps.Marker(markerOptions);
  marker.set('angle', angle);
  marker.set('speed', speed);
  marker.set('positionIndex', positionIndex);
  marker.set('ecoScore', ecoScore);
  if (tooltip) {
    marker.addListener('mouseover', () =>
      tooltip.toggleTooltipCallback(tooltip.rideNumber, tooltip.positionIndex)
    );
    marker.addListener('mouseout', () =>
      tooltip.toggleTooltipCallback(tooltip.rideNumber, tooltip.positionIndex)
    );
  }
  return marker;
};

/**
 * Create arrow markers
 * @param {google.maps.Map} map
 * @param {IRide[]} rides
 * @param {number} nth
 * @param {boolean} speedlimitsEnabled
 * @param {IRidePlayerSpeedLimitsSettings} speedlimitSettings
 * @param {TRideActiveLayer} activeLayer
 * @param {TToggleTooltip} handleToggleTooltip
 * @returns {IRideMapMarkers}
 */
export const createArrowMarkers = (
  map: google.maps.Map | undefined,
  arrowMarkers: IRideMapArrowMarkers,
  arrowMarkerTooltips: IRideMapArrowMarkerTooltips,
  rides: IRide[],
  nth = 10,
  speedlimitsEnabled: boolean,
  speedlimitSettings: IRidePlayerSpeedLimitsSettings,
  activeLayer: TRideActiveLayer,
  intl: IntlShape,
  handleToggleTooltip?: TToggleTooltip
): void => {
  // ): IRideMapArrowMarkers => {
  // const markers: IRideMapArrowMarkers = {};

  for (let rideIndex = 0; rideIndex < rides.length; rideIndex += 1) {
    if (!arrowMarkers[rideIndex]) {
      arrowMarkers[rideIndex] = {};
    }
    const rideNumber = rideIndex + 1;
    // markers[rideNumber] = [];
    const ride = rides[rideIndex];
    for (let positionIndex = 0; positionIndex < ride.positions.length; positionIndex += 1) {
      if (positionIndex % nth === nth - 1) {
        const marker = createArrowMarker(
          map,
          new google.maps.LatLng(
            ride.positions[positionIndex].gpsLat,
            ride.positions[positionIndex].gpsLon
          ),
          ride.positions[positionIndex].gpsCourse,
          ride.positions[positionIndex].speed,
          ride.positions[positionIndex].ecoScore,
          speedlimitsEnabled,
          speedlimitSettings,
          activeLayer,
          positionIndex,
          handleToggleTooltip
            ? {
                rideNumber,
                positionIndex,
                toggleTooltipCallback: handleToggleTooltip,
              }
            : undefined
        );

        if (marker) {
          // markers[rideNumber].push(marker);
          // arrowMarkers[rideNumber][positionIndex] = marker;
          arrowMarkers[rideNumber] = {
            ...arrowMarkers[rideNumber],
            [positionIndex]: marker,
          };

          let positionTooltip: JSX.Element | null = null;
          positionTooltip = (
            <PositionTooltip
              intl={intl}
              position={ride.positions[positionIndex]}
              positionIndex={positionIndex}
            />
          );

          const positionMarkerTooltip = GoogleMapsService.createRidePositionMarkerOverlay(
            ride.positions[positionIndex],
            positionTooltip
          );

          arrowMarkerTooltips[rideNumber] = {
            ...arrowMarkerTooltips[rideNumber],
            [positionIndex]: positionMarkerTooltip,
          };
        }
      }
    }
  }
  // return markers;
};

/**
 * Update all arrow markers color by speedlimit settings
 * @param {IRideMapMarkers} arrowMarkers
 * @param {boolean} speedlimitsEnabled
 * @param {IRidePlayerSpeedLimitsSettings} speedlimitSettings
 * @returns {void}
 */
export const updateAllArrowMarkersColorBySpeedlimits = (
  arrowMarkers: IRideMapArrowMarkers,
  speedlimitsEnabled: boolean,
  speedlimitSettings: IRidePlayerSpeedLimitsSettings
): void => {
  Object.keys(arrowMarkers).forEach((rideNumber: string) => {
    Object.keys(arrowMarkers[Number(rideNumber)]).forEach((positionIndex: string) => {
      const marker: google.maps.Marker = arrowMarkers[Number(rideNumber)][Number(positionIndex)];
      const color = speedlimitsEnabled
        ? getSpeedlimit(speedlimitSettings, marker.get('speed')).color
        : RIDE_ARROW_COLOR;
      const newIcon: google.maps.Icon = {
        url: arrowIcon(marker.get('angle'), color),
        anchor: new google.maps.Point(6, 7),
      };
      marker.setIcon(newIcon);
    });
  });
};

/**
 * Update all arrow markers color by speedlimit settings
 * @param {IRideMapMarkers} arrowMarkers
 * @returns {void}
 */
export const updateAllArrowMarkersColorByEcoScore = (arrowMarkers: IRideMapArrowMarkers): void => {
  Object.keys(arrowMarkers).forEach((rideNumber: string) => {
    Object.keys(arrowMarkers[Number(rideNumber)]).forEach((positionIndex: string) => {
      const marker: google.maps.Marker = arrowMarkers[Number(rideNumber)][Number(positionIndex)];
      const color = getEcoDrive(marker.get('ecoScore')).color;
      const newIcon: google.maps.Icon = {
        url: arrowIcon(marker.get('angle'), color),
        anchor: new google.maps.Point(6, 7),
      };
      marker.setIcon(newIcon);
    });
  });
};

/**
 * Update all arrow markers color
 * @param {IRideMapMarkers} arrowMarkers
 * @param {string} color
 * @param {void}
 */
export const updateAllArrowMarkersColor = (
  arrowMarkers: IRideMapArrowMarkers,
  color: string
): void => {
  Object.keys(arrowMarkers).forEach((rideNumber: string) => {
    Object.keys(arrowMarkers[Number(rideNumber)]).forEach((positionIndex: string) => {
      const marker: google.maps.Marker = arrowMarkers[Number(rideNumber)][Number(positionIndex)];
      const newIcon: google.maps.Icon = {
        url: arrowIcon(marker.get('angle'), color),
        anchor: new google.maps.Point(6, 7),
      };
      marker.setIcon(newIcon);
    });
  });
};

/**
 * Hide position markers, when arrow marker exist on the same position
 * @param {IRideMapMarkers} arrowMarkers
 * @param {IRideMapMarkers} positionMarkers
 * @param {number} rideNumber
 */
export const hidePositionMarkersByByArrowMarkers = (
  arrowMarkers: IRideMapArrowMarkers,
  positionMarkers: IRideMapMarkers,
  // alarmPositionMarkers: IRideMapAlarmPositionMarkers,
  // ecoDrivePositionMarkers: IRideMapEcoDrivePositionMarkers,
  // eventPositionMarkers: IRideMapEventPositionMarkers,
  rideNumber: number
): void => {
  const arrowMarkersPositions: string[] = [];
  if (arrowMarkers[rideNumber]) {
    Object.keys(arrowMarkers[rideNumber]).forEach((positionIndex: string) => {
      const arrowMarker: google.maps.Marker = arrowMarkers[rideNumber][Number(positionIndex)];
      const arrowMarkerPosition = arrowMarker.getPosition();
      if (arrowMarkerPosition) {
        const stringifyPosition = arrowMarkerPosition.toString();
        arrowMarkersPositions.push(stringifyPosition);
      }
    });

    if (positionMarkers[rideNumber]) {
      positionMarkers[rideNumber].forEach((positionMarker: google.maps.Marker) => {
        const positionMarkerPosition = positionMarker.getPosition();
        if (positionMarkerPosition) {
          if (arrowMarkersPositions.includes(positionMarkerPosition.toString())) {
            positionMarker.setMap(null);
          }
        }
      });
    }

    // if (alarmPositionMarkers) {
    //   alarmPositionMarkers[rideNumber].forEach((alarmPositionMarker: google.maps.Marker) => {
    //     const positionMarkerPosition = alarmPositionMarker.getPosition();
    //     if (positionMarkerPosition) {
    //       if (arrowMarkersPositions.includes(positionMarkerPosition.toString())) {
    //         alarmPositionMarker.setMap(null);
    //       }
    //     }
    //   });
    // }

    // if (ecoDrivePositionMarkers) {
    //   ecoDrivePositionMarkers[rideNumber].forEach((ecoDrivePositionMarker: google.maps.Marker) => {
    //     const positionMarkerPosition = ecoDrivePositionMarker.getPosition();
    //     if (positionMarkerPosition) {
    //       if (arrowMarkersPositions.includes(positionMarkerPosition.toString())) {
    //         ecoDrivePositionMarker.setMap(null);
    //       }
    //     }
    //   });
    // }

    // if (eventPositionMarkers) {
    //   eventPositionMarkers[rideNumber].forEach((eventPositionMarker: google.maps.Marker) => {
    //     const positionMarkerPosition = eventPositionMarker.getPosition();
    //     if (positionMarkerPosition) {
    //       if (arrowMarkersPositions.includes(positionMarkerPosition.toString())) {
    //         eventPositionMarker.setMap(null);
    //       }
    //     }
    //   });
    // }
  }
};

/**
 * Update all position markers color by speedlimit settings
 * @param {IRideMapMarkers} positionMarkers
 * @param {boolean} speedlimitsEnabled
 * @param {IRidePlayerSpeedLimitsSettings} speedlimitSettings
 * @returns {void}
 */
export const updateAllPositionMarkersColorBySpeedlimits = (
  positionMarkers: IRideMapMarkers,
  speedlimitsEnabled: boolean,
  speedlimitSettings: IRidePlayerSpeedLimitsSettings,
  isRideActive: number | false
): void => {
  Object.keys(positionMarkers).forEach((rideNumber: string) => {
    positionMarkers[rideNumber].forEach((marker: google.maps.Marker) => {
      if (isRideActive !== false && Number(rideNumber) !== isRideActive) {
        return;
      }
      if (marker.get('positionType') === IS_DRIVING_POSITION) {
        const color = speedlimitsEnabled
          ? getSpeedlimit(speedlimitSettings, marker.get('speed')).color
          : RIDE_POSITION_COLOR;
        const newIcon: google.maps.Icon = {
          url: positionMarkerIcon(color),
          anchor: new google.maps.Point(6, 6),
        };
        marker.setIcon(newIcon);
      }
    });
  });
};

/**
 * Update position marker with tooltip when new position is received
 * @param {google.maps.Map} map
 * @param {IRideMapMarkers} positionMarkers
 * @param {IRideMapMarkerTooltips} positionMarkerTooltips
 * @param {number} rideNumber
 * @param {IRideDetail} rideData
 * @param {IRidePosition} newPosition
 * @param {boolean} speedlimitsEnabled
 * @param {IRidePlayerSpeedLimitsSettings} speedlimitSettings
 * @param {number} positionIndex
 * @param {IntlShape} intl
 * @param {TToggleTooltip} handleToggleTooltip
 * @returns {void}
 */
export const updatePositionMarkersWithNewPosition = (
  map: google.maps.Map | undefined,
  positionMarkers: IRideMapMarkers,
  positionMarkerTooltips: IRideMapMarkerTooltips,
  rideNumber: number,
  rideData: IRideDetail,
  newPosition: IRidePosition,
  speedlimitsEnabled: boolean,
  speedlimitSettings: IRidePlayerSpeedLimitsSettings,
  positionIndex: number,
  intl: IntlShape,
  handleToggleTooltip?: TToggleTooltip
): void => {
  const position = new google.maps.LatLng(newPosition.gpsLat, newPosition.gpsLon);
  const marker = createPositionMarker(
    map,
    position,
    newPosition.speed,
    speedlimitsEnabled,
    speedlimitSettings,
    newPosition.ecoScore,
    handleToggleTooltip
      ? {
          rideNumber,
          positionIndex,
          toggleTooltipCallback: handleToggleTooltip,
        }
      : undefined,
    newPosition.type
  );
  if (positionMarkers[rideNumber]) {
    positionMarkers[rideNumber].push(marker);
  }
  let positionTooltip: JSX.Element | null = null;
  positionTooltip = (
    <PositionTooltip intl={intl} position={newPosition} positionIndex={positionIndex} />
  );

  const positionMarkerTooltip = GoogleMapsService.createRidePositionMarkerOverlay(
    newPosition,
    positionTooltip
  );

  positionMarkerTooltips[rideNumber][positionIndex] = positionMarkerTooltip;
};

/**
 * Update arrow markers with new marker
 * @param {google.maps.Map} map
 * @param {IRideMapArrowMarkers} arrowMarkers
 * @param {IRideMapArrowMarkerTooltips} arrowMarkerTooltips
 * @param {number} rideNumber
 * @param {IRide} ride
 * @param {boolean} speedlimitsEnabled
 * @param {IRidePlayerSpeedLimitsSettings} speedlimitSettings
 * @param {TRideActiveLayer} activeLayer
 * @param {IntlShape} intl
 * @param {TToggleTooltip} handleToggleTooltip
 * @requires {void}
 */
export const updateArrowMarkersWithNewPosition = (
  map: google.maps.Map | undefined,
  arrowMarkers: IRideMapArrowMarkers,
  arrowMarkerTooltips: IRideMapArrowMarkerTooltips,
  rideNumber: number,
  ride: IRide,
  speedlimitsEnabled: boolean,
  speedlimitSettings: IRidePlayerSpeedLimitsSettings,
  activeLayer: TRideActiveLayer,
  intl: IntlShape,
  handleToggleTooltip: TToggleTooltip,
  activeRide: number
): void => {
  const nth = 10;
  ride.positions.forEach((position: IRidePosition, positionIndex: number) => {
    if (!arrowMarkers[rideNumber]) {
      arrowMarkers[rideNumber] = {};
    }
    if (positionIndex % nth === nth - 1) {
      if (!arrowMarkers[rideNumber][positionIndex]) {
        const marker = createArrowMarker(
          rideNumber === activeRide ? map : undefined,
          new google.maps.LatLng(
            ride.positions[positionIndex].gpsLat,
            ride.positions[positionIndex].gpsLon
          ),
          ride.positions[positionIndex].gpsCourse,
          ride.positions[positionIndex].speed,
          ride.positions[positionIndex].ecoScore,
          speedlimitsEnabled,
          speedlimitSettings,
          activeLayer,
          positionIndex,
          handleToggleTooltip
            ? {
                rideNumber,
                positionIndex,
                toggleTooltipCallback: handleToggleTooltip,
              }
            : undefined
        );

        arrowMarkers[rideNumber][positionIndex] = marker;

        let positionTooltip: JSX.Element | null = null;
        positionTooltip = (
          <PositionTooltip intl={intl} position={position} positionIndex={positionIndex} />
        );

        const positionMarkerTooltip = GoogleMapsService.createRidePositionMarkerOverlay(
          position,
          positionTooltip
        );

        arrowMarkerTooltips[rideNumber] = {
          ...arrowMarkerTooltips[rideNumber],
          [positionIndex]: positionMarkerTooltip,
        };
      }
    }
  });
};

export const hideAllTooltips = (
  tooltips: React.MutableRefObject<IRideMapMarkerTooltips | IRideMapAlarmPositionMarkerTooltips>,
  positionIndex: number
): void => {
  Object.keys(tooltips.current).forEach((rideNum: string) => {
    Object.keys(tooltips.current[Number(rideNum)]).forEach((posIndex: string) => {
      if (positionIndex !== Number(posIndex)) {
        tooltips.current[Number(rideNum)][Number(posIndex)].setMap(null);
      }
    });
  });
};
