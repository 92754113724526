import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSetRecoilState } from 'recoil';
import ChangePasswordService from '../../Services/ChangePasswordService';
import Form from './form';
import { setLoaderAtom } from '../../store/recoil/AppState';

export default function ChangePassword(): JSX.Element {
  const [isOpened, setIsOpened] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any[] | null>(null);
  const setLoader = useSetRecoilState<boolean>(setLoaderAtom);

  const handleClose = () => {
    setIsOpened(false);
  };

  const handleShowModal = async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    setLoader(true);
    const form = await ChangePasswordService.getForm();
    if (form && form.inputs) {
      setLoader(false);
      setIsOpened(true);
      setData(form.inputs);
    }
  };

  return (
    <div>
      <a href="/" className="subMenu menuLink" onClick={handleShowModal}>
        {' '}
        <FormattedMessage id="menu.changePassword" />{' '}
      </a>
      {isOpened && data && (
        <div className="col-12" style={{ textIndent: 0 }}>
          <Form data={data} handleClose={handleClose} />
        </div>
      )}
    </div>
  );
}
