import { useMatomo } from '@datapunt/matomo-tracker-react';
import { TrackEventParams } from '@datapunt/matomo-tracker-react/lib/types';

type UseTrackEventParams = [string, string, string, number?];
type UseAnalytics = {
  trackEvent: (params: UseTrackEventParams) => void;
  setUsername: (username: string) => void;
};

const useAnalytics = (): UseAnalytics => {
  const { trackEvent, pushInstruction } = useMatomo();

  const setUsername = (username: string) => {
    pushInstruction('setUserId', username);
  };

  const trackFn = (params: UseTrackEventParams) => {
    const [category, action, name, value] = params;
    const trackEventParams: TrackEventParams = { category, action, name, value };
    trackEvent(trackEventParams);
  };

  const data: UseAnalytics = {
    trackEvent: trackFn,
    setUsername,
  };

  return data;
};

export default useAnalytics;
