import * as React from 'react';
import { useRecoilState } from 'recoil';
import MultiSelectFilter from '../Filter/MultiSelectFilter';
import useFilter from '../hooks/useFilter';
// import * as TLCLiteState from '../TLCLiteState';
import SortButton from './SortButton';
import * as CmdTableState from '../CmdTableState';

interface IThMultiSelectProps {
  id: string;
  tableName: string;
  title: string;
  isSortable?: boolean;
  // minWidth?: string;
  // maxWidth?: string;
  tooltip?: string;
}

function ThMultiSelect(props: IThMultiSelectProps): JSX.Element {
  const [tableActionsFilters, setTableActionsFilters] = useRecoilState(
    CmdTableState.tableFilter({ [props.tableName]: props.id })
  );
  const { getFilterData } = useFilter(props.tableName);

  const handleOnClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    const { id } = event.currentTarget;

    setTableActionsFilters(
      (tableActionFilter) =>
        tableActionFilter && {
          ...tableActionFilter,
          id: props.id,
          isOpen: true,
          isActionChipsOpen: false,
        }
    );

    if (id) {
      if (!tableActionsFilters) {
        getFilterData(props.tableName, props.id);
      }
    }
  };

  const renderMultiSelect = (): JSX.Element | null => {
    if (tableActionsFilters && tableActionsFilters.isOpen && tableActionsFilters.data) {
      return (
        <MultiSelectFilter
          id={props.id}
          tableName={props.tableName}
          filterValues={tableActionsFilters.data}
          title={props.title}
        />
      );
    }

    return null;
  };

  return (
    <div
      className={`cmd-table-actions ${
        tableActionsFilters && tableActionsFilters.values ? 'active' : ''
      }`}
      style={{
        // minWidth: props.minWidth || '150px',
        // maxWidth: props.maxWidth || '300px',
        flex: 1,
        whiteSpace: 'nowrap',
        cursor: 'pointer',
      }}
      title={props.tooltip}
    >
      <div className="d-flex w-100">
        <div
          id={props.id}
          className="text-truncate"
          onClick={handleOnClick}
          data-cy={`filter-button-text-${props.id}`}
        >
          {props.title}
        </div>

        <a
          id={props.id}
          href="#"
          className="filter cmd-icons cmd-icon-filter"
          onClick={handleOnClick}
          data-cy={`filter-button-${props.id}`}
        >
          {/* test */}
        </a>
        {props.isSortable && <SortButton id={props.id} tableName={props.tableName} />}
        <div />
        {renderMultiSelect()}
      </div>
    </div>
  );
}

export default ThMultiSelect;
