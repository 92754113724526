import * as React from 'react';
import Error500 from '../Error500';
import NotFound from '../NotFound';
import { showMessage } from '../../Services/ToastService';

interface IComponentState {
  hasError: boolean;
  error?: any;
}

class ErrorBoundary extends React.PureComponent {
  state: Readonly<IComponentState> = {
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.info(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI

      if (this.state.error && this.state.error.name === 'ChunkLoadError') {
        console.warn('Application has been updated. Please refresh page.');
        showMessage(
          'Application update',
          'Application has been updated. Please refresh page.',
          '',
          null,
          null
        );
        return null;
      }
      return <Error500 />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
