import { CorsWorkerService as Worker } from '../../Services/CorsWorkerService';

export const SuperclusterWorker = await new Worker(
  new URL('SuperclusterWorker.ts', import.meta.url),
  { type: 'module', name: 'socket-supercluster-worker' },
).createWorker();

export const SuperclusterWaypointWorker = await new Worker(
  new URL('SuperclusterWaypointWorker.ts', import.meta.url),
  { type: 'module', name: 'socket-supercluster-waypoint-worker' },
).createWorker();

export const SuperclusterBluecoinWorker = await new Worker(
  new URL('SuperclusterBluecoinWorker.ts', import.meta.url),
  { type: 'module', name: 'socket-supercluster-bluecoin-worker' },
).createWorker();
