/* eslint-disable react/jsx-wrap-multilines */
import * as React from 'react';
import { useRecoilState } from 'recoil';
import RangeSelectFilter from '../Filter/RangeSelectFilter';
import useFilter from '../hooks/useFilter';
import TooltipComponent from '../../TooltipComponent';
import SortButton from './SortButton';
import * as CmdTableState from '../CmdTableState';

interface IThRangeProps {
  id: string;
  title: string;
  tooltip?: string;
  tableName: string;
  isSortable: boolean;
  unit?: string;
}

function ThRange(props: IThRangeProps): JSX.Element {
  const [tableActionsFilters, setTableActionsFilters] = useRecoilState(
    CmdTableState.tableFilter({ [props.tableName]: props.id })
  );

  const { getFilterData } = useFilter(props.tableName);

  const handleOnClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    const { id } = event.currentTarget;

    setTableActionsFilters(
      (tableActionFilter) =>
        tableActionFilter && {
          ...tableActionFilter,
          id: props.id,
          isOpen: true,
          isActionChipsOpen: false,
        }
    );

    if (id) {
      if (!tableActionsFilters) {
        getFilterData(props.tableName, props.id);
      }
    }
  };

  return (
    <div
      className={`cmd-table-actions ${
        tableActionsFilters && tableActionsFilters.values ? 'active' : ''
      }`}
      style={{
        // minWidth: '110px',
        flex: 1,
        position: 'relative',
        whiteSpace: 'nowrap',
        cursor: 'pointer',
      }}
      title={props.tooltip}
    >
      <div className="d-flex w-100">
        <div
          id={props.id}
          onClick={handleOnClick}
          className="text-truncate"
          data-cy={`filter-button-text-${props.id}`}
        >
          {props.title}
        </div>

        <a
          href="#"
          className="filter cmd-icons cmd-icon-filter"
          id={props.id}
          onClick={handleOnClick}
          data-cy={`filter-button-${props.id}`}
        >
          {/* test */}
        </a>
        {props.isSortable && <SortButton id={props.id} tableName={props.tableName} />}
        <div />
        {tableActionsFilters &&
          tableActionsFilters.isOpen &&
          !tableActionsFilters.isActionChipsOpen &&
          tableActionsFilters.data && (
            <div id="range-select" style={{ position: 'absolute', left: '0px', top: '48px' }}>
              <div className="open">
                <RangeSelectFilter
                  id={props.id}
                  tableName={props.tableName}
                  title={props.title}
                  valueFrom={tableActionsFilters.values?.min}
                  valueTo={tableActionsFilters.values?.max}
                  defaultMinValue={
                    tableActionsFilters.data.minValue ? tableActionsFilters.data.minValue : '0'
                  }
                  defaultMaxValue={
                    tableActionsFilters.data.maxValue
                      ? tableActionsFilters.data.maxValue
                      : '9999999'
                  }
                  suffix={props.unit}
                />
              </div>
            </div>
          )}
      </div>
    </div>
  );
}

export default React.memo(ThRange);
