export const VEHICLE_SETTINGS_CUSTOMER_ID_KEY = 'customer';
export const VEHICLE_SETTINGS_LICENCE_NUMBER = 'licenseNumber';
export const VEHICLE_SETTINGS_MANUFACTURER = 'manufacturer';
export const VEHICLE_SETTINGS_NAME = 'vehicleName';
export const VEHICLE_SETTINGS_TYPE = 'vehicleType';
export const VEHICLE_SETTINGS_DRIVER = 'driver';
export const VEHICLE_SETTINGS_FUEL_CARD = 'fuelCards';
export const VEHICLE_SETTINGS_COST_CENTER = 'costCenter';
export const VEHICLE_SETTINGS_FUEL_TYPE = 'fuelType';
export const VEHICLE_SETTINGS_CAPACITY = 'totalCapacity';
export const VEHICLE_SETTINGS_CONS_THEORETICAL = 'cons100kmTheoretical';
export const VEHICLE_SETTINGS_CONS_NORM = 'cons100kmNorm';
export const VEHICLE_SETTINGS_INIT_MILEAGE = 'initialMileage';
export const VEHICLE_SETTINGS_UNIT_MODEL = 'unitModel';
export const VEHICLE_SETTINGS_PHONE = 'phoneNumber';
export const VEHICLE_SETTINGS_CAN = 'can';
export const VEHICLE_SETTINGS_PRIVATE_RIDE_DEFAULT = 'privateRideDefault';
export const VEHICLE_SETTINGS_PRIVATE_RIDE_INPUT = 'privateRideInput';
export const VEHICLE_SETTINGS_AUTO_CORR = 'autocorrectionEnabled';
export const VEHICLE_SETTINGS_ECO_DRIVE = 'ecoDrive';
export const VEHICLE_SETTINGS_MODEL = 'model';
export const VEHICLE_SETTINGS_MANUFACTURER_YAER = 'manufactureYear';
export const VEHICLE_SETTINGS_COMMISSIONING = 'commissioning';
export const VEHICLE_SETTINGS_VIN = 'vin';
export const VEHICLE_SETTINGS_EMISSION_VALID = 'emissionInspectionValidTo';
export const VEHICLE_SETTINGS_TECH_VALID = 'technicalInspectionValidTo';
export const VEHICLE_SETTINGS_NOTE = 'note';
export const VEHICLE_SETTINGS_BLOCKING = 'vehicleBlockingAvailable';
export const VEHICLE_SETTINGS_BLUECOIN = 'bluecoinEnabled';
export const VEHICLE_SETTINGS_CARD_READER = 'cardReaderType';
export const VEHICLE_SETTINGS_CREATED_AT = 'createdAt';
export const VEHICLE_SETTINGS_CREATED_BY = 'createdBy';
export const VEHICLE_SETTINGS_MODIFIED_AT = 'modifiedAt';
export const VEHICLE_SETTINGS_MODIFIED_BY = 'modifiedBy';
export const VEHICLE_SETTINGS_TABLE_KEY = 'v1/vehicle';
export const VEHICLE_SETTINGS_SETTINGS_SAVE = 'vehicle_v2';
export const VEHICLE_SETTINGS_URL = '/cars_settings';
export const VEHICLE_SETTINGS_DETAIL_KEY = 'detail';

export const PeriodLockDefinitionTranslationKeys = {
  customer: 'table_list.columns.customerId',
  licenseNumber: 'table_list.columns.vehicles.licenceNumber',
  manufacturer: 'table_list.columns.vehicles.manufacturer',
  vehicleName: 'table_list.columns.vehicles.vehicleName',
  vehicleType: 'table_list.columns.vehicleTypeId',
  driver: 'table_list.columns.driverId',
  fuelCards: 'table_list.columns.fuelCards',
  costCenter: 'table_list.columns.costCenterId',
  fuelType: 'table_list.columns.fuelTypeId',
  totalCapacity: 'table_list.columns.vehicles.totalCapacity',
  cons100kmTheoretical: 'table_list.columns.vehicles.cons100kmTheoretical',
  cons100kmNorm: 'table_list.columns.vehicles.cons100kmNorm',
  initialMileage: 'table_list.columns.vehicles.initialMileage',
  unitModel: 'table_list.columns.unitModelId',
  phoneNumber: 'table_list.columns.vehicles.phoneNumber',
  can: 'table_list.columns.vehicles.can',
  privateRideDefault: 'table_list.columns.privateRideDefault',
  privateRideInput: 'table_list.columns.vehicles.privateRideInput',
  autocorrectionEnabled: 'table_list.columns.autocorrectionEnabled',
  ecoDrive: 'table_list.columns.vehicles.ecoDrive',
  model: 'table_list.columns.vehicles.model',
  manufactureYear: 'table_list.columns.vehicles.manufactureYear',
  commissioning: 'table_list.columns.vehicles.commissioning',
  vin: 'table_list.columns.vehicles.vin',
  emissionInspectionValidTo: 'table_list.columns.emissionInspectionValidTo',
  technicalInspectionValidTo: 'table_list.columns.technicalInspectionValidTo',
  note: 'table_list.columns.vehicles.note',
  vehicleBlockingAvailable: 'vehicleSettings.vehicleBlockingAvailable',
  bluecoinEnabled: 'vehicleSettings.bluecoinEnabled',
  cardReaderType: 'vehicleSettings.cardReaderType',
  createdAt: 'table_list.columns.createdAt',
  createdBy: 'table_list.columns.createdBy',
  modifiedAt: 'table_list.columns.modifiedAt',
  modifiedBy: 'table_list.columns.modifiedBy',
};
