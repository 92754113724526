import en from 'dayjs/locale/en';
import sk from 'dayjs/locale/sk';
import cz from 'dayjs/locale/cs';
import hu from 'dayjs/locale/hu';
import localforage from 'localforage';
import { AxiosResponse } from 'axios';
import { getRequest, STATUS_APP_ERROR, STATUS_NOT_FOUND } from './HttpService';
import JSONValidationService from './JSONValidationService';
import LanguageDataSchema from '../components/LanguageDataSchema.json';

export interface ILanguageList {
  [index: string]: string;
}

export const EN_LANGUAGE = 'en';
export const SK_LANGUAGE = 'sk';
export const CZ_LANGUAGE = 'cs';
export const HU_LANGUAGE = 'hu';
export const EN_LANGUAGE_NUMBERS = 'en-EN';
export const SK_LANGUAGE_NUMBERS = 'sk-SK';
export const CZ_LANGUAGE_NUMBERS = 'cs-CS';
export const HU_LANGUAGE_NUMBERS = 'hu-HU';

export const languages: ILanguageList = {
  en: 'English',
  sk: 'Slovenčina',
  cs: 'Čeština',
  hu: 'Magyar',
};

export interface ILocalStorageTranslation {
  [language: string]: ITranslation;
}

interface ITranslationItem {
  key: string;
  value: string | null;
}

export interface ITranslationApiResponse {
  status: 'ok';
  translations: ITranslationItem[];
}

export interface ITranslation {
  [localeString: string]: string;
}

export const setTranslation = async (data: ITranslation): Promise<ITranslation> => {
  return localforage.setItem('translations', data);
};

export const getTranslation = async (language: string): Promise<ITranslation | null> => {
  const response = await getRequest<ITranslationApiResponse | AxiosResponse<unknown, any> | false>(
    `/v1/translations/${language}`,
    {
      showErrorToaster: false,
      returnAxiosError: true,
    }
  );
  if (response && (response as ITranslationApiResponse).translations) {
    JSONValidationService.validateSchema(LanguageDataSchema, response, 'Languages Data Schema');
    const locale: ITranslation = {};
    (response as ITranslationApiResponse).translations.map((item: ITranslationItem) => {
      if (item.key && item.value) {
        locale[item.key] = item.value;
      }
    });
    setTranslation(locale);
    return locale;
  }
  if (
    (response && (response as AxiosResponse<unknown, any>).status === STATUS_NOT_FOUND) ||
    (response as AxiosResponse<unknown, any>).status === STATUS_APP_ERROR
  ) {
    return localforage.getItem<ITranslation>('translations');
  }
  return null;
};

export const getDefaultLanguage = (): string => {
  let language = localStorage.language
    ? localStorage.language
    : navigator.language.split(/[-_]/)[0];
  if (!language || Object.keys(languages).filter((key: string) => key === language).length === 0) {
    language = EN_LANGUAGE;
  }
  return language;
};
export const getDayjsLocale = (locale: string): ILocale => {
  switch (locale) {
    case EN_LANGUAGE:
      return en;
    case CZ_LANGUAGE:
      return cz;
    case HU_LANGUAGE:
      return hu;

    default:
      return sk;
  }
};

export const getLocaleNumber = (locale: string): string => {
  switch (locale) {
    case EN_LANGUAGE:
      return EN_LANGUAGE_NUMBERS;
    case CZ_LANGUAGE:
      return CZ_LANGUAGE_NUMBERS;
    case HU_LANGUAGE:
      return HU_LANGUAGE_NUMBERS;

    default:
      return SK_LANGUAGE_NUMBERS;
  }
};
