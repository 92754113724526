import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { isOnlinePanelOpenAtom, IVehicleOnlinePanels, onlinePanelAtom } from '../OnlinePanelState';
import { ICustomWidgetProps } from './Widget';
import RemoveButton from './RemoveButton';
import { getWidgetTooltip } from './WidgetService';

export default function ElectroChargingWidget(props: ICustomWidgetProps): JSX.Element {
  const { formatMessage: f } = useIntl();
  const [widgetData, setWidgetData] = React.useState<{
    charging: boolean | null;
    plugged: boolean | null;
  } | null>({
    charging: true,
    plugged: true,
  });
  const vehicleId = useRecoilValue<number | false>(isOnlinePanelOpenAtom);
  const onlinePanelData = useRecoilValue<IVehicleOnlinePanels>(onlinePanelAtom);

  React.useMemo(() => {
    if (!props.isMockupMode) {
      const onlinePanel = onlinePanelData[Number(vehicleId)];

      if (
        onlinePanel &&
        onlinePanel.electric &&
        (onlinePanel.electric.charging !== null || onlinePanel.electric.plugged !== null)
      ) {
        setWidgetData({
          charging: onlinePanel.electric.charging,
          plugged: onlinePanel.electric.plugged,
        });
      } else {
        setWidgetData(null);
      }
    }
  }, [onlinePanelData, props.isMockupMode, vehicleId]);

  const getWidgetState = () => {
    const plugged = widgetData?.plugged;
    const charging = widgetData?.charging;
    if (!widgetData) {
      return 'yes text-gray-light';
    }
    if (charging && plugged) {
      return 'yes text-green';
    }
    if (!charging && plugged) {
      return 'connected text-orange';
    }
    return 'disconnected text-gray';
  };

  const getWidgetText = () => {
    const plugged = widgetData?.plugged;
    const charging = widgetData?.charging;
    if (!widgetData) {
      return <strong>-</strong>;
    }
    if (charging && plugged) {
      return f({ id: 'onlineTemplates.subPage.section.widgets.electro.charging' });
    }
    if (!charging && plugged) {
      return f({ id: 'onlineTemplates.subPage.section.widgets.electro.connected' });
    }
    return f({ id: 'onlineTemplates.subPage.section.widgets.electro.disconnected' });
  };

  const getTooltipText = () => {
    if (props.data.size === '1x1' && widgetData) {
      const plugged = widgetData?.plugged;
      const charging = widgetData?.charging;
      if (charging && plugged) {
        return f({ id: 'onlineTemplates.subPage.section.widgets.electro.charging.tooltip' });
      }
      if (!charging && plugged) {
        return f({ id: 'onlineTemplates.subPage.section.widgets.electro.connected.tooltip' });
      }
      return f({ id: 'onlineTemplates.subPage.section.widgets.electro.disconnected.tooltip' });
    }
    return f({ id: `widget.${props.data.icon}.tooltip` });
  };

  return (
    <div
      data-cy="battery-widget"
      className={`b-widget b-widget--${props.data.rowSize}x${props.data.columnSize} ${
        props.data.hasIcon && props.data.columnSize > 1 ? 'b-widget--alignLeft' : ''
      } widget-grid-name-${props.data.gridName} ${props.isInGrid ? '' : 'droppable-element'}`}
      draggable={!props.isInGrid}
      onDragStart={props.isInGrid ? undefined : props.onDragCallback}
      title={getWidgetTooltip(
        getTooltipText(),
        f({ id: 'onlineTemplates.subPage.section.widgets.tooltip' }),
        props.isInGrid,
        props.isMockupMode
      )}
    >
      {props.isInGrid && (
        <RemoveButton
          dataCy="electro-charging-widget-in-grid"
          onRemoveCallback={props.onRemoveCallback}
          gridName={props.data.gridName}
        />
      )}
      {props.data.size === '1x1' && (
        <i
          className={`e-widget-icon e-widget-icon--md cmd-icon cmd-icon-charging-vehicle-${getWidgetState()}`}
        />
      )}
      {props.data.size === '2x1' && (
        <div className="row no-gutters">
          <div className="col-auto">
            <i
              className={`e-widget-icon e-widget-icon--md cmd-icon cmd-icon-charging-vehicle-${getWidgetState()}`}
            />
          </div>
          <div className={`col ${!widgetData ? 'text-gray-light' : ''}`}>
            <div>{getWidgetText()}</div>
          </div>
        </div>
      )}
    </div>
  );
}
