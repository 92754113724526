import * as React from 'react';
import dayjs from 'dayjs';
import useTable from '../hooks/useTable';

interface IDateTimeCellProps {
  datetime: string | null;
  format?: string;
  minWidth?: string;
  // maxWidth?: string;
}

function DateTimeCell(props: IDateTimeCellProps): JSX.Element {
  const { handleAnonymizedData } = useTable();
  const formatString = props.format || 'D.M.YYYY H:mm:ss';

  const dateValue = (value: string | number) => {
    return dayjs(value).format(formatString);
  };

  const value = props.datetime ? handleAnonymizedData(props.datetime as string, dateValue) : null;
  return (
    <div
      className="align-left"
      style={{
        minWidth: props.minWidth || '200px',
        // maxWidth: props.maxWidth || '300px',
        flex: 1,
      }}
    >
      {value || <>&ndash;</>}
    </div>
  );
}

export default DateTimeCell;
