import { atom, selectorFamily } from 'recoil';
import { IVehicleData, IVehiclesData } from '../../components/Vehicles/types';

export const vehiclesDataAtom = atom<IVehiclesData>({
  key: 'vehiclesData',
  default: {},
});

export const vehicleDataByIdSelector = selectorFamily<IVehicleData | null, number | false>({
  key: 'vehicleDataById',
  get:
    (vehicleId: number | false) =>
    ({ get }) => {
      if (!vehicleId) {
        return null;
      }
      const vehicles = get(vehiclesDataAtom);
      return vehicles[vehicleId];
    },
});
