import axios from 'axios';

export class CorsWorkerService {
  private readonly url: string | URL;

  private readonly options?: WorkerOptions;

  private axiosInstace = axios.create();

  constructor(url: string | URL, options?: WorkerOptions) {
    this.url = url;
    this.options = options;
  }

  async createWorker(): Promise<Worker> {
    if (process.env.NODE_ENV === 'production') {
      // const f = await fetch(this.url);
      const response = await this.axiosInstace.get(this.url.toString());
      if (response && response.data) {
        // const t = await f.text();
        const t = response.data;
        const b = new Blob([t], {
          type: 'application/javascript',
        });
        const url = URL.createObjectURL(b);
        const worker = new Worker(url, this.options);
        return worker;
      }
    }
    return new Worker(new URL(this.url, import.meta.url), this.options);
  }
}
