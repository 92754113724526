import * as React from 'react';
import { IntlProvider } from 'react-intl';
import { CmdLoader } from '@commander-services/gui-components';
import { useQuery } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';
import * as LanguageService from './Services/LanguageService';
import { localeStringsAtom } from './store/recoil/AppState';
import { ITranslation } from './Services/LanguageService';

export interface IApplicationState {
  language: string;
  setLanguage?: React.Dispatch<React.SetStateAction<string>>;
}

interface IStoreProviderProps {
  children: React.ReactNode;
}

const appState: IApplicationState = {
  language: LanguageService.getDefaultLanguage(),
};

export const StoreContext = React.createContext(appState);

export default function StoreProvider({ children }: IStoreProviderProps): JSX.Element {
  const [language, setLanguage] = React.useState<string>(LanguageService.getDefaultLanguage());
  const [messages, setMessages] = useRecoilState<ITranslation>(localeStringsAtom);

  const { isLoading, data } = useQuery({
    queryKey: ['translations', language],
    queryFn: () => LanguageService.getTranslation(language),
    staleTime: 9000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  React.useEffect(() => {
    if (data) {
      setMessages(data);
      localStorage.language = language;
    }
  }, [data, language, setMessages]);

  const value = React.useMemo(() => {
    return { language, setLanguage };
  }, [language]);

  if (isLoading || Object.keys(messages).length === 0) {
    return <CmdLoader background="#282830" />;
  }

  return (
    <StoreContext.Provider value={value}>
      <React.Suspense fallback={<CmdLoader background="#282830" />}>
        <IntlProvider locale={language} messages={messages}>
          {children}
        </IntlProvider>
      </React.Suspense>
    </StoreContext.Provider>
  );
}
