export const PLAYED_LINE_EVENT_LAYER_COLOR = '#0C0149';
export const PLAYED_LINE_RIDE_LAYER_COLOR = '#146755';
export const PLAYED_LINE_ECODRIVE_LAYER_COLOR = '#1B6754';
export const PLAYED_LINE_ALARM_LAYER_COLOR = '#530B1F';
export const RIDE_LINE_COLOR = '#05B590';
export const RIDE_POSITION_COLOR = '#027d63';
export const RIDE_ARROW_COLOR = '#146755';
export const RIDE_LINE_ZINDEX = 2;
export const RIDE_LINE_SIZE = 4;
export const RIDE_LINE_OPACITY = 1.0;
export const ALARM_LINE_COLOR = '#DE6387';
export const ALARM_ACTIVE_LINE_COLOR = '#530A1F';
export const EVENT_ACTIVE_LINE_COLOR = '#533DA2';
export const ALARM_ARROW_COLOR = '#541325';
export const EVENT_ARROW_COLOR = '#0d336f';
export const ALARM_LINE_SIZE = 4; // 8
export const ALARM_LINE_ZINDEX = 4;
export const ALARM_LINE_OPACITY = 1.0;
export const WAITING_LINE_COLOR = '#032C6B';
export const WAITING_LINE_OPACITY = 1.0;
export const WAITING_LINE_SIZE = 4;
export const WAITING_LINE_ZINDEX = 6;
export const EVENT_LINE_COLOR = '#C4BCE5';
export const EVENT_LINE_OPACITY = 1.0;
export const EVENT_LINE_SIZE = 4;
export const EVENT_LINE_ZINDEX = 6;
export const ACTIVE_RIDE_COLOR = '#146755';
export const ACTIVE_RIDE_SIZE = 8;
export const ACTIVE_RIDE_ZINDEX = 2;
export const ACTIVE_RIDE_OPACITY = 1.0;
export const DARK_GREY_LINE_COLOR = '#747474';
export const LIGHT_GREY_LINE_COLOR = '#C9C9C9';
export const IS_RIDE_LAYER = 'ride';
export const IS_ALARM_LAYER = 'alarm';
export const IS_ECO_DRIVE_LAYER = 'ecoDrive';
export const IS_EVENT_LAYER = 'event';
export const RIDE_DETAIL_ZOOM_LEVEL = 14;
export const IS_START_POSITION = 'START';
export const IS_DRIVING_POSITION = 'DRIVING';
export const IS_PAUSE_POSITION = 'PAUSE';
export const IS_STOP_POSITION = 'STOP';
export const IS_ALARM = 'alarm';
export const IS_WAYPOINT = 'waypoint';
export const IS_EVENT = 'event';
export const IS_PAUSE = 'pause';
export const IS_PRIVATE_WAYPOINT = 'private';
export const IS_CUSTOMER_WAYPOINT = 'customer';
