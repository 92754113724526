/* eslint-disable no-bitwise */
import * as React from 'react';
import { IntlShape } from 'react-intl';
import dayjs from 'dayjs';
import './PositionTooltip.scss';
import {
  convertDuration,
  getAlarmsFromAnnouncementsInPosition,
  getEventsFromAnnouncementsInPosition,
  getWaitingsFromAnnouncementsInPosition,
  getWaypointsFromAnnouncementsInPosition,
} from './RidePlayerService';
import Config from '../../../config';
import { calculateDuration } from './SelectRidesModal';
import { IAnnouncement, IRide, IRidePosition } from './types';
import {
  IS_DRIVING_POSITION,
  IS_PAUSE_POSITION,
  IS_START_POSITION,
  IS_STOP_POSITION,
} from './constants';

interface IPositionTooltipProps {
  position: IRidePosition;
  positionIndex: number;
  intl: IntlShape;
  ride?: IRide;
}

function PositionTooltip(props: IPositionTooltipProps): JSX.Element {
  const waitings = getWaitingsFromAnnouncementsInPosition(props.position);
  const waypoints = getWaypointsFromAnnouncementsInPosition(props.position);
  const alarms = getAlarmsFromAnnouncementsInPosition(props.position);
  const events = getEventsFromAnnouncementsInPosition(props.position);

  const showAddress = () => {
    return props.position.address || `${props.position.gpsLat}, ${props.position.gpsLon}`;
  };

  return (
    <div className="position-tooltip">
      <table>
        <tbody>
          {Config.Player.debug.showPositionIndex && (
            <tr>
              <th>Position index:</th>
              <td>{props.positionIndex}</td>
            </tr>
          )}
          {props.position.timestamp && (
            <tr>
              <th>{props.intl.formatMessage({ id: 'ridePlayer.positionTooltip.time' })}</th>
              <td>{dayjs(props.position.timestamp).format('DD.MM.YYYY HH:mm:ss')}</td>
            </tr>
          )}
          <tr>
            <th>{props.intl.formatMessage({ id: 'ridePlayer.positionTooltip.address' })}</th>
            <td>{showAddress()}</td>
          </tr>
          {props.position.speed !== undefined &&
            (props.position.type === IS_DRIVING_POSITION ||
              props.position.type === IS_START_POSITION ||
              props.position.type === IS_STOP_POSITION) && (
              <tr>
                <th>{props.intl.formatMessage({ id: 'ridePlayer.positionTooltip.speed' })}</th>
                <td>{props.position.speed} km/h</td>
              </tr>
            )}
          {props.position.type === IS_START_POSITION && props.ride && (
            <>
              <tr>
                <th>
                  {props.intl.formatMessage({ id: 'ridePlayer.positionTooltip.rideDuration' })}
                </th>
                <td>
                  {calculateDuration(
                    props.ride.positions[0].timestamp,
                    props.ride.positions[props.ride.positions.length - 1].timestamp
                  )}
                </td>
              </tr>
              <tr>
                <th>
                  {props.intl.formatMessage({ id: 'ridePlayer.positionTooltip.rideDistance' })}
                </th>
                <td>
                  {props.ride.positions[props.ride.positions.length - 1].distanceCumulative.toFixed(
                    3
                  )}{' '}
                  km
                </td>
              </tr>
            </>
          )}
          {waitings !== null &&
            waitings.duration !== null &&
            props.position.type === IS_PAUSE_POSITION && (
              <tr>
                <th>
                  {props.intl.formatMessage({ id: 'ridePlayer.positionTooltip.waitingDuration' })}
                </th>
                <td>{convertDuration(waitings.duration)}</td>
              </tr>
            )}
          {props.position.ecoScore && (
            <tr>
              <th>{props.intl.formatMessage({ id: 'ridePlayer.positionTooltip.ecoScore' })}</th>
              <td>{props.position.ecoScore}</td>
            </tr>
          )}
          {props.position.announcements && (
            <tr>
              <th>{props.intl.formatMessage({ id: 'ridePlayer.positionTooltip.waypoints' })}</th>
              <td>
                {waypoints.length > 0 ? (
                  waypoints.map((waypoint: IAnnouncement, index: number) => (
                    <div key={`waypoint-tooltip-item-${waypoint.id}-${index}`}>
                      {waypoint.title}
                      {waypoint.duration ? ` (${convertDuration(waypoint.duration)})` : ''}
                    </div>
                  ))
                ) : (
                  <div>
                    {props.intl.formatMessage({ id: 'ridePlayer.positionTooltip.noWaypoints' })}
                  </div>
                )}
              </td>
            </tr>
          )}
          {props.position.announcements && (
            <tr>
              <th>{props.intl.formatMessage({ id: 'ridePlayer.positionTooltip.alarm' })}</th>
              <td>
                {alarms.length > 0 ? (
                  alarms.map((alarm: IAnnouncement, index: number) => (
                    <div key={`alarm-tooltip-item-${alarm.id}-${index}`}>
                      {alarm.title}
                      {alarm.duration ? ` (${convertDuration(alarm.duration)})` : ''}
                    </div>
                  ))
                ) : (
                  <div>
                    {props.intl.formatMessage({ id: 'ridePlayer.positionTooltip.noAlarms' })}
                  </div>
                )}
              </td>
            </tr>
          )}
          {props.position.announcements && (
            <tr>
              <th>{props.intl.formatMessage({ id: 'ridePlayer.positionTooltip.event' })}</th>
              <td>
                {events.length > 0 ? (
                  events.map((event: IAnnouncement, index: number) => (
                    <div key={`event-tooltip-item-${event.id}-${index}`}>
                      {props.intl.formatMessage({ id: event.title })}
                      {event.duration ? ` (${convertDuration(event.duration)})` : ''}
                    </div>
                  ))
                ) : (
                  <div>
                    {props.intl.formatMessage({ id: 'ridePlayer.positionTooltip.noEvents' })}
                  </div>
                )}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default PositionTooltip;
