import { CmdAlert, CmdToast } from '@commander-services/gui-components';
import * as React from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useIntl } from 'react-intl';
import { IMaintenanceStatusResponse, IS_MAINTENANCE_PLANNED } from './MaintenanceService';
import * as MaitenanceState from './MaintenanceState';

dayjs.extend(utc);
dayjs.extend(timezone);

interface IToastProps {
  showAsAlert?: boolean;
}

export default function Toast(props: IToastProps): JSX.Element | null {
  const { formatMessage: f } = useIntl();
  const maintenance = useRecoilValue<IMaintenanceStatusResponse | false>(
    MaitenanceState.maintenance
  );
  const [showMaintenanceToast, setShowMaintenanceToast] = useRecoilState<boolean>(
    MaitenanceState.showMaintenanceToast
  );

  const handleOnClose = () => {
    setShowMaintenanceToast(false);
  };

  if (
    maintenance &&
    maintenance.maintenance &&
    maintenance.maintenance.status === IS_MAINTENANCE_PLANNED &&
    showMaintenanceToast
  ) {
    const newStartDate = dayjs(maintenance.maintenance.start).add(2, 'hours');
    const newEnDate = dayjs(maintenance.maintenance.end).add(2, 'hours');

    const messagePayload = {
      // fromDate: dayjs(newStartDate).tz('Europe/Bratislava').format('DD.MM.YYYY'),
      // fromDate: dayjs(newStartDate).tz('Europe/Bratislava').format('DD.MM.YYYY'),
      fromDate: dayjs.utc(maintenance.maintenance.start).local().format('DD.MM.YYYY'),
      // fromTime: dayjs(newStartDate).tz('Europe/Bratislava', true).format('H:mm'),
      fromTime: dayjs.utc(maintenance.maintenance.start).local().format('H:mm'),
      // toDate: dayjs(newEnDate).tz('Europe/Bratislava').format('DD.MM.YYYY'),
      toDate: dayjs.utc(maintenance.maintenance.end).local().format('DD.MM.YYYY'),
      // toTime: dayjs(newEnDate).tz('Europe/Bratislava').format('H:mm'),
      toTime: dayjs.utc(maintenance.maintenance.end).local().format('H:mm'),
      sStart: '<strong>',
      sEnd: '</strong>',
    };

    const title = f({ id: 'maintenancePage.message.dearCustomers' });
    const message = f({ id: 'maintenancePage.message.info' }, messagePayload);

    if (props.showAsAlert) {
      const alertData = {
        title: f({ id: 'maintenancePage.message.dearCustomers' }),
        message,
        // messagePayload: message,
        type: 'warning',
      };
      return <CmdAlert id="maintenance-alert" data={alertData} onCloseCallback={handleOnClose} />;
    }

    return (
      <CmdToast
        id="maintenance-toast"
        title={title}
        type="warning"
        onClose={handleOnClose}
        message={message}
      />
    );
  }
  return null;
}
