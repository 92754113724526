import { atom, selector } from 'recoil';
import { IMessageReportCreated } from '../SocketConnection';
import localForageEffect from '../../store/recoil/effects/localForageEffect';
import { localForageStore } from '../../store/localForageStore';

export interface IDownloadLater {
  code: string;
  jobId: string;
}

export interface IDownload {
  jobId: string;
  isDownloaded: boolean;
  code: string;
  percentage: number;
  filename: string;
  generating_start: string;
  generating_end: string | null;
}

export const isActive = atom<false | string>({
  key: 'isActive',
  default: false,
});

export const data = atom<IMessageReportCreated | null>({
  key: 'data',
  default: null,
});

export const valuesJsons = atom<string[]>({
  key: 'valuesJsons',
  default: [],
});

export const downloads = atom<IDownload[]>({
  key: 'exportDownloads',
  default: selector({
    key: 'exportDownloads/default',
    get: async ({ get }) =>
      (await localForageStore.getItem<IDownload[] | null>('exportDownloads')) || [],
  }),
  effects: [localForageEffect('exportDownloads')],
});
