import * as React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import dayjs from 'dayjs';
import TableDatepicker from '../../TableDatepicker';
import useFilter from '../hooks/useFilter';
import SortButton from './SortButton';
import { IDatepickerSettings, setTableDatepickerAtom } from '../../../store/recoil/TableState';
import {
  IFilterChipsValues,
  ICmdTableActionFilter,
  ITLCFilter,
  ITLCFilterTable,
} from '../interfaces';
import * as CmdTableState from '../CmdTableState';

interface IThDatimeProps {
  id: string;
  title: string;
  tableName: string;
  // minWidth?: string;
  // maxWidth?: string;
  isSortable?: boolean;
  tooltip?: string;
  alwaysActive?: boolean;
}

function ThDateTime(props: IThDatimeProps): JSX.Element {
  const setTableActionsFilters = useSetRecoilState(
    CmdTableState.tableFilter({ [props.tableName]: props.id })
  );
  const tableActionsFilters = useRecoilValue(
    CmdTableState.tableFilter({ [props.tableName]: props.id })
  );
  const { updateFilterItemsState } = useFilter(props.tableName);
  const setFilterForRequest = useSetRecoilState<ITLCFilter>(
    CmdTableState.filterForRequest(props.tableName)
  );
  const datepickerSettings = useRecoilValue<IDatepickerSettings | null>(setTableDatepickerAtom);
  const [minDatepickerTimestamp, setMinDatepickerTimestamp] = React.useState<Date | string>();
  const [maxDatepickerTimestamp, setMaxDatepickerTimestamp] = React.useState<Date | string>();

  const getDatepickerValue = (id: string, index: number) => {
    const value =
      !datepickerSettings || !datepickerSettings[id] || !datepickerSettings[id].date[index]
        ? ''
        : datepickerSettings[id].date[index];
    if (value) {
      return dayjs(value).format('DD.MM.YYYY HH:mm');
    }
    return '';
  };

  const handleOnClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    const { id } = event.currentTarget;

    if (props.alwaysActive) {
      return;
    }

    setTableActionsFilters((oldState: ICmdTableActionFilter | null) => {
      if (oldState) {
        const newState: ICmdTableActionFilter = {
          ...oldState,
          isOpen: !oldState.isOpen,
          isActionChipsOpen: false,
          isFilterActive: true,
        };
        return newState;
      }
      return oldState;
    });

    if (id) {
      if (!tableActionsFilters) {
        const dataForFilter: ITLCFilterTable = {
          type: 'datetime',
          value: '',
          values: '',
          required: false,
        };
        updateFilterItemsState([
          {
            id: props.id,
            isOpen: true,
            isActionChipsOpen: false,
            data: dataForFilter,
            values: null,
          },
        ]);
      }
    }
  };

  React.useEffect(() => {
    if (tableActionsFilters && !tableActionsFilters.isFilterActive) {
      setMinDatepickerTimestamp('');
      setMaxDatepickerTimestamp('');
    }
  }, [tableActionsFilters]);

  React.useEffect(() => {
    if (
      datepickerSettings &&
      datepickerSettings[props.id] &&
      datepickerSettings[props.id].date &&
      datepickerSettings[props.id].date.length === 2
    ) {
      if (
        dayjs(datepickerSettings[props.id].date[0]).format('DD.MM.YYYY HH:mm') !==
        dayjs(minDatepickerTimestamp).format('DD.MM.YYYY HH:mm')
      ) {
        setMinDatepickerTimestamp(datepickerSettings[props.id].date[0]);
      }
      if (
        dayjs(datepickerSettings[props.id].date[1]).format('DD.MM.YYYY HH:mm') !==
        dayjs(maxDatepickerTimestamp).format('DD.MM.YYYY HH:mm')
      ) {
        setMaxDatepickerTimestamp(datepickerSettings[props.id].date[1]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datepickerSettings]);

  React.useEffect(() => {
    if (
      minDatepickerTimestamp &&
      maxDatepickerTimestamp &&
      tableActionsFilters &&
      tableActionsFilters.id === props.id &&
      tableActionsFilters.isOpen &&
      !props.alwaysActive
    ) {
      const selectedValues: IFilterChipsValues = {
        item: `${getDatepickerValue(props.id, 0)} - ${getDatepickerValue(props.id, 1)}`,
        value: '',
        title: props.title ? props.title : '',
        type: 'datetime',
      };

      const data = {};

      data[props.id] = {
        min: minDatepickerTimestamp,
        max: maxDatepickerTimestamp,
      };

      setFilterForRequest((oldState: ITLCFilter) => {
        const newState: ITLCFilter = {
          ...oldState,
          ...data,
        };
        return newState;
      });

      setTableActionsFilters(
        (tableActionFilter) =>
          tableActionFilter && {
            ...tableActionFilter,
            id: props.id,
            isOpen: false,
            isActionChipsOpen: false,
            isFilterActive: true,
            values: { ...selectedValues },
          }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minDatepickerTimestamp, maxDatepickerTimestamp]);

  return (
    <div
      id="table-header-col-dateTime"
      className={`cmd-table-actions ${
        (tableActionsFilters && tableActionsFilters.values) || props.alwaysActive ? 'active' : ''
      }`}
      style={{
        whiteSpace: 'nowrap',
        cursor: 'pointer',
        // minWidth: props.minWidth || '200px',
        // maxWidth: props.maxWidth || '350px',
        flex: 1,
      }}
      title={props.tooltip}
    >
      <TableDatepicker name={props.id} type="head">
        <div
          id={props.id}
          className="d-flex"
          onClick={handleOnClick}
          style={{ position: 'relative' }}
          data-cy={`filter-button-text-${props.id}`}
        >
          <div className="text-truncate">{props.title}</div>

          <a
            id={props.id}
            href="#"
            className="filter cmd-icons cmd-icon-filter"
            data-cy={`filter-button-${props.id}`}
          >
            {/* test */}
          </a>
          {props.isSortable && (
            <SortButton
              id={props.id}
              tableName={props.tableName}
              alwaysActive={props.alwaysActive}
            />
          )}

          <div />
        </div>
      </TableDatepicker>
    </div>
  );
}

export default ThDateTime;
