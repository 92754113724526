import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Download, { IFile } from '../../Download';

interface IDownloadMenuProps {
  files: IFile[];
  lastItems?: any;
  countRows?: number;
}

const DownloadMenu: React.FC<IDownloadMenuProps> = ({ files, lastItems, countRows }) => {
  return (
    <div className="text-center">
      <div
        className={`attachments-info ${lastItems && countRows && countRows > 2 ? 'dropup' : ''}`}
      >
        <span className="count">+{files.length}</span>
        <div
          className="dropdown-menu dropdown-menu-right dropdown-menu-custom fs-2"
          style={{ marginBottom: '3px' }}
        >
          <div className="px-2 py-1 font-weight-bold border-bottom-on">
            <FormattedMessage id="table.attachments" />
          </div>
          {files.map((file: IFile) => (
            <span key={`download-${file.name}`} className="px-2 py-1 d-block text-decoration-none">
              <Download file={file} />
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DownloadMenu;
