import * as React from 'react';
import { RecoilRoot } from 'recoil';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { InstanceParams } from '@datapunt/matomo-tracker-react/lib/types';
import AllRoutes from './router';
import StoreProvider from './StoreProvider';
import RecoilDebugObserver from './components/RecoilDebugObserver';
import Config from '../config';
import ApplicationUpdate from './components/ApplicationUpdate';
// import CookieConsent from './components/CookieConsent';
// import * as CookieConsentService from './components/CookieConsent/CookieConsentService';
import RecoilOutside from './components/RecoilOutside';
import { getUsername } from './Services/UserService';

const queryClient = new QueryClient();

export default function App(): JSX.Element {
  // React.useEffect(() => {
  //   if (typeof Config.analytics === 'string') {
  //     // eslint-disable-next-line prettier/prettier, no-multi-assign
  //     const _mtm = (window as any)._mtm = (window as any)._mtm || [];
  //     // eslint-disable-next-line prettier/prettier, no-multi-assign
  //     const _paq = (window as any)._paq = (window as any)._paq || [];
  //     _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
  //     const d = document;
  //     const g = d.createElement('script');
  //     const s = d.getElementsByTagName('script')[0];
  //     g.async = true;
  //     g.src = Config.analytics;
  //     s.parentNode?.insertBefore(g, s);
  //     const username = getUsername();
  //     if (username) {
  //       _paq.push(['setUserId', username]);
  //     }
  //   }
  // }, []);

  const matomoOptions: InstanceParams = {
    urlBase: Config.analytics.baseUrl,
    siteId: Config.analytics.siteId,
    srcUrl: Config.analytics.srcUrl,
    disabled: Config.analytics.disabled,
  };

  const username = getUsername();
  if (username) {
    matomoOptions.userId = username;
  }

  const matomoInstance = createInstance(matomoOptions);

  return (
    <RecoilRoot>
      <RecoilOutside />
      <QueryClientProvider client={queryClient}>
        <StoreProvider>
          <MatomoProvider value={matomoInstance}>
            {process.env.NODE_ENV === 'production' && <ApplicationUpdate />}
            {/* <CookieConsent
              cancelCallback={CookieConsentService.cancelCallback}
              acceptCallback={CookieConsentService.acceptCallback}
            /> */}
            {Config.recoil.debug && <RecoilDebugObserver />}
            <AllRoutes />
          </MatomoProvider>
        </StoreProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </RecoilRoot>
  );
}
