import dayjs from 'dayjs';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { isOnlinePanelOpenAtom, IVehicleOnlinePanels, onlinePanelAtom } from '../OnlinePanelState';
import { ICustomWidgetProps } from './Widget';
import { vehicleDataByIdSelector } from '../../../store/recoil/vehiclesData';
import RemoveButton from './RemoveButton';

interface ILastPosition {
  latitude: number | null;
  longitude: number | null;
  date: string | null;
  address: string | null;
}

export default function LastPositionWidget(props: ICustomWidgetProps): JSX.Element {
  const { formatMessage: f } = useIntl();
  const [widgetData, setWidgetData] = React.useState<ILastPosition | null>({
    latitude: 48.152457,
    longitude: 17.136671,
    date: '2021-01-13T15:31:05Z',
    address: 'Testovacia 123',
  });

  const vehicleId = useRecoilValue<number | false>(isOnlinePanelOpenAtom);
  const vehicleData = useRecoilValue(vehicleDataByIdSelector(vehicleId));
  const onlinePanelData = useRecoilValue<IVehicleOnlinePanels>(onlinePanelAtom);

  React.useMemo(() => {
    if (!props.isMockupMode) {
      const onlinePanel = onlinePanelData[Number(vehicleId)];

      let vehiclePosition: ILastPosition | null = null;
      if (onlinePanel) {
        // vehiclePosition = {
        //   latitude: onlinePanel.latitude || null,
        //   longitude: onlinePanel.longitude || null,
        //   date: onlinePanel.timestamp,
        //   address: onlinePanel.address,
        // };
        vehiclePosition = {
          latitude: vehicleData && vehicleData.position ? vehicleData.position.latitude : null,
          longitude: vehicleData && vehicleData.position ? vehicleData.position.longitude : null,
          date:
            vehicleData && vehicleData.position && vehicleData.position.timestamp
              ? vehicleData.position.timestamp
              : '',
          address: vehicleData && vehicleData.position ? vehicleData.position.address : null,
        };
      }

      if (vehiclePosition) {
        setWidgetData(vehiclePosition);
      } else {
        setWidgetData(null);
      }
    }
  }, [onlinePanelData, props.isMockupMode, vehicleData, vehicleId]);

  let widgetTooltip = f({ id: 'widget.lastPosition.tooltip' });
  if (!props.isInGrid && props.isMockupMode) {
    widgetTooltip = f({ id: 'onlineTemplates.subPage.section.widgets.tooltip' });
  }

  if (!props.isInGrid && !props.isMockupMode) {
    widgetTooltip =
      widgetData && widgetData.address
        ? widgetData.address
        : f({ id: 'widget.lastPosition.tooltip' });
  }

  const onDragStart = props.isInGrid ? undefined : props.onDragCallback;

  return (
    <div
      data-cy="last-position-widget"
      data-latlng={`${vehicleData?.position?.latitude}, ${vehicleData?.position?.longitude}`}
      className={`b-widget b-widget--${props.data.rowSize}x${props.data.columnSize}
      widget-grid-name-${props.data.gridName} b-widget--alignLeft ${
        props.isInGrid ? '' : 'droppable-element'
      }`}
      draggable={!props.isInGrid}
      onDragStart={onDragStart}
      title={widgetTooltip}
      style={{ position: 'relative' }}
    >
      {props.isInGrid && (
        <RemoveButton
          dataCy="last-position-widget-in-grid"
          onRemoveCallback={props.onRemoveCallback}
          gridName={props.data.gridName}
        />
      )}
      {props.data.columnSize === 4 && props.data.rowSize === 1 && (
        <>
          <div data-cy="last-position-widget-date" className="row no-gutters my-1">
            <div className="col-auto">
              <i className="e-widget-icon cmd-icon cmd-icon-arrival-time" />
            </div>
            <div className="col">
              <div className="e-widget-title">
                <strong>
                  {widgetData && widgetData.date
                    ? dayjs(widgetData.date).format('DD.MM.YYYY HH:mm:ss')
                    : '-'}
                </strong>
              </div>
            </div>
          </div>
          <div className="row no-gutters my-1" data-cy="last-position-widget-address">
            <div className="col-auto">
              <i className="e-widget-icon cmd-icon cmd-icon-last-position" />
            </div>
            <div className="col">
              <div className="e-widget-title">
                <div className="text-truncate">
                  <strong>
                    {widgetData &&
                      !widgetData.address &&
                      widgetData.latitude &&
                      widgetData.longitude &&
                      `${widgetData.latitude}, ${widgetData.longitude}`}
                    {widgetData && widgetData.address && `${widgetData.address}`}
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
