import * as React from 'react';
import { useIntl } from 'react-intl';

interface RemoveButtonProps {
  dataCy: string;
  gridName: string;
  onRemoveCallback: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

function RemoveButton(props: RemoveButtonProps): JSX.Element {
  const { formatMessage: f } = useIntl();
  return (
    <button
      data-cy={props.dataCy}
      title={f({ id: 'widgets.removeFromGrid.tooltip' })}
      className="remove-widget-button"
      data-grid-name={props.gridName}
      onClick={props.onRemoveCallback}
    >
      <i className="cmd-icon cmd-icon-remove-sm" />
      <span className="sr-only">{f({ id: 'widgets.removeFromGrid.tooltip' })}</span>
    </button>
  );
}

export default RemoveButton;
