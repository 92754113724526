/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from 'react';
import ReactDOM from 'react-dom';
import { useIntl } from 'react-intl';

interface IContactModalProps {
  closeCallback: () => void;
}

export default function Modal(props: IContactModalProps): React.ReactPortal | null {
  const { formatMessage: f } = useIntl();
  const modalRoot = document.getElementById('modal-root');

  const handleClose = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    props.closeCallback();
  };

  const modalContent: JSX.Element = (
    <div className="w-page">
      <div className="modal-wrapper">
        <div className="modal-wrapper__content modal-xs">
          <div className="modal">
            <div className="modal__header">
              <div className="row">
                <div className="col">
                  <h4 className="modal__header__title">{f({ id: 'contact.modal.title' })}</h4>
                </div>
                <div className="col-auto">
                  <a
                    href="#"
                    title="#"
                    onClick={handleClose}
                    className="cmd-icon cmd-icon-remove close-modal"
                  />
                </div>
              </div>
            </div>
            <div className="modal__content">
              <div className="b-contact-modal">
                <div className="title">{f({ id: 'contact.modal.text' })}</div>
                <div>
                  Commander Services s.r.o.
                  <br />
                  Žitná 23, 831 06 Bratislava
                  <br />
                  IČO: 511 834 55
                  <br />
                  DIČ: 212 061 9270
                  <br />
                  IČ DPH: SK21 2061 9270
                </div>
                <div className="row no-gutters align-items-center mt-2">
                  <div className="col-auto mr-2">
                    <i className="cmd-icons cmd-icon-phone text-gray-lighten fs-4 position-relative bottom-2" />
                  </div>
                  <div className="col">
                    <a href="tel:+421553333330" className="text-gray">
                      +421 553 333 330
                    </a>
                  </div>
                </div>
                <div className="row no-gutters align-items-center mt-2">
                  <div className="col-auto mr-2">
                    <i className="cmd-icons cmd-icon-post-office text-gray-lighten fs-4 position-relative bottom-1" />
                  </div>
                  <div className="col">
                    <a href="mailto:hotline@commander.sk" className="text-gray">
                      hotline@commander.sk
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal__footer">
              <div className="row">
                <div className="col-12 col-sm-auto mt-2 mt-sm-0 ml-auto">
                  <div className="row no-gutters">
                    <div className="col-12 col-sm-auto my-1 order-1 order-sm-2">
                      <a
                        href="#"
                        title="#"
                        onClick={handleClose}
                        className="e-button e-button--gray"
                      >
                        {f({ id: 'contact.modal.close' })}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  if (modalRoot) {
    return ReactDOM.createPortal(modalContent, modalRoot);
  }
  return null;
}
