import * as React from 'react';

const useOutsideClick = (
  ref: React.RefObject<HTMLElement>,
  callback: (e: MouseEvent) => void,
  ignoreRefs: React.RefObject<HTMLElement>[] = []
): void => {
  const handleClick = (e: MouseEvent) => {
    if (
      ref.current &&
      !ref.current.contains(e.target as Node) &&
      !ignoreRefs.some(
        (ignoreRef) => ignoreRef.current && ignoreRef.current.contains(e.target as Node)
      )
    ) {
      callback(e);
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClick, { capture: true });

    return () => {
      document.removeEventListener('click', handleClick, { capture: true });
    };
  }, [ref, callback, ignoreRefs]);
};

export default useOutsideClick;
