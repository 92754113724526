import * as React from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import ReactDOM from 'react-dom';
import PermissionsService, {
  PERMISSION_ONLINE_MAP_TEMPLATES_ASSIGN,
  PERMISSION_ONLINE_MAP_TEMPLATES_CUSTOMER,
  PERMISSION_ONLINE_MAP_TEMPLATES_PRIVATE,
  PERMISSION_ONLINE_MAP_TEMPLATES_SYSTEM,
} from '../../../../Services/PermissionsService';
import {
  TABLE_REFUELINGS_IMPORT_RECORDS,
  TABLE_TEMPLATES,
} from '../../../../Services/TableService';

const PERIOD_LOCK_DEACTIVATE = 'action_period_lock_definition_disable';
const PERIOD_LOCK_ACTIVATE = 'action_period_lock_definition_enable';
const PERIOD_UNLOCK = 'action_status_unlock';
const PERIOD_LOCK = 'action_status_lock';
const PERIOD_LOCK_EDIT_LOCKAFTER = 'action_edit_lockAfter';

interface IComponentProps {
  tableName: string;
  handleAction: (event: any) => void;
  clientY: number;
  lastItem?: boolean;
  actions: any;
  id: number;
  lockDate: string;
}

export default function ActionsMenu(props: IComponentProps): JSX.Element | null {
  const { formatMessage: f } = useIntl();

  const getTableMenuClassName = (i: any) => {
    let className = '';
    if (i.icon === 'edit' && props.tableName === TABLE_REFUELINGS_IMPORT_RECORDS) {
      className = 'cmd-icon cmd-icon-edit fs-4';
    } else if (i.icon === 'detail' || i.icon === 'edit') {
      className = 'cmd-icon cmd-icon-detail fs-4';
    } else if (i.icon === 'activate' || i.id === PERIOD_LOCK_ACTIVATE) {
      className = `cmd-icon cmd-icon-active fs-4`;
    } else if (i.icon === 'deactivate' || i.id === PERIOD_LOCK_DEACTIVATE) {
      className = `cmd-icon cmd-icon-inactive fs-4`;
    } else if (i.id === PERIOD_LOCK) {
      className = `cmd-icon cmd-icon-lock fs-4`;
    } else if (i.id === PERIOD_UNLOCK) {
      className = `cmd-icon cmd-icon-unlock fs-4`;
    } else if (i.id === PERIOD_LOCK_EDIT_LOCKAFTER) {
      className = `cmd-icon cmd-icon-calendar fs-4`;
    } else if (i.icon === 'logs') {
      className = 'cmd-icons cmd-icon-notes';
    } else if (i.icon === 'layer-plus') {
      className = `cmd-icons cmd-icon-edit-template-person-alternative-2`;
    } else if (i.icon === 'plus-square') {
      className = `cmd-icons cmd-icon-edit-template-alternative-2`;
    } else if (i.icon === 'cmd-icon-preview') {
      className = `cmd-icon cmd-icon-overview-2`;
    } else if (i.id === 'action_history') {
      className = 'cmd-icons cmd-icon-history';
    } else if (i.icon === 'remove') {
      className = `cmd-icons cmd-icon-bin fs-4`;
    } else if (i.icon === 'ban') {
      className = `cmd-icons cmd-icon-ban fs-4`;
    } else if (i.icon === 'tasks') {
      className = `cmd-icon cmd-icon-listing fs-4`;
    } else {
      className = `cmd-icon cmd-icon-${i.icon.replace('new-css-icon-preview', 'preview')} fs-4`;
    }
    return className;
  };

  const handleActionMenuTitle = (actions) => {
    if (actions.id === 'action_edit' && props.tableName === TABLE_TEMPLATES) {
      if (
        PermissionsService.hasAccess(PERMISSION_ONLINE_MAP_TEMPLATES_CUSTOMER, ['update']) ||
        PermissionsService.hasAccess(PERMISSION_ONLINE_MAP_TEMPLATES_PRIVATE, ['update']) ||
        PermissionsService.hasAccess(PERMISSION_ONLINE_MAP_TEMPLATES_SYSTEM, ['update']) ||
        PermissionsService.hasAccess(PERMISSION_ONLINE_MAP_TEMPLATES_ASSIGN, ['update'])
      ) {
        return actions.title;
      }
      return f({ id: 'table.detailBtn' });
    }
    if (actions.id === 'action_edit' && props.tableName !== TABLE_REFUELINGS_IMPORT_RECORDS) {
      return f({ id: 'table.detailBtn' });
    }
    return actions.title;
  };

  const renderItemInTableMenu = (i: any, key: number) => {
    return (
      <a
        href="#"
        key={`multiple-actions-${key}`}
        title={
          i.id === 'action_edit'
            ? f({ id: 'table.detailBtn.tooltip' })
            : i.type === 'custom'
              ? i.title
              : f({
                  id: `table.filter.${i.icon}`,
                })
        }
        data-cy={`table-filter-${i.id}`}
        className="px-1 py-xs d-flex text-decoration-none text-gray"
        data-type={i.type === 'custom' ? i.id : i.type}
        data-id={props.id}
        onClick={props.handleAction}
        data-lock-date={props.lockDate || ''}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <div
          style={{
            width: '18px',
            marginRight: '10px',
            marginLeft: '5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <i className={getTableMenuClassName(i)} />
        </div>
        &nbsp;
        {handleActionMenuTitle(i)}
      </a>
    );
  };

  const renderMenu = () => {
    return (
      <div
        className="dropdown-menu dropdown-menu-right dropdown-menu-custom fs-2 border-0"
        aria-labelledby="dropdownMenu2"
        style={{
          padding: 0,
          display: 'block',
          // top: props.lastItem ? props.clientY - 100 : props.clientY + 34,
          top: props.clientY + 34,
          right: 30,
        }}
      >
        {props.actions.map((i: any, key: number) => renderItemInTableMenu(i, key))}
      </div>
    );
  };

  const actionsRoot = document.getElementById('tlc-actions');

  if (actionsRoot) {
    return ReactDOM.createPortal(renderMenu(), actionsRoot);
  }
  console.error('Actions menu root element is not defined.');
  return null;
}
