import { atom, selector, selectorFamily } from 'recoil';
import { BluecoinData, BluecoinsData, IFiltersBluecoin } from './interfaces';
import Config from '../../../../config';
import { boundsAtom, isClusterEnabledAtom, zoomAtom } from '../OnlineMapState';
import { MIN_POINTS_FOR_DISABLED_CLUSTER } from '../GoogleMapLayer/constants';
import setIsBluecoinsMapOpenToSocketServerEffect from '../../../store/recoil/effects/setIsBluecoinsMapOpenToSocketServerEffect';
import { SuperclusterBluecoinWorker } from '../CreateWorkers';

export const bluecoinsAtom = atom<BluecoinsData>({
  key: 'bluecoins',
  default: {},
});

export const selectedBluecoinsAtom = atom<number[]>({
  key: 'selectedBluecoins',
  default: [],
});

export const isBluecoinPanelOpenAtom = selector<boolean>({
  key: 'isBluecoinPanelOpen',
  get: ({ get }) => {
    return get(selectedBluecoinsAtom).length > 0;
  },
});

export const bluecoinsArrayAtom = selector<BluecoinData[]>({
  key: 'bluecoinsArray',
  get: ({ get }) => {
    const bluecoinsData = get(bluecoinsAtom);

    const data = Object.keys(bluecoinsData).map((bluecoinId: string) => {
      return bluecoinsData[Number(bluecoinId)];
    });
    return data;
  },
});

export const bluecoinByIdSelector = selectorFamily<BluecoinData | undefined, number>({
  key: 'bluecoinById',
  get:
    (bluecoinId: number) =>
    ({ get }) => {
      const bluecoinsData = get(bluecoinsAtom);
      return bluecoinsData[bluecoinId];
    },
});

export const filteredBluecoinStringAtom = atom<string>({
  key: 'filteredBluecoinString',
  default: '',
});

export const filteredBluecoinsAtom = atom<BluecoinData[]>({
  key: 'filteredBluecoins',
  default: [],
});

export const activeFilterBluecoinsAtom = atom<IFiltersBluecoin | null>({
  key: 'activeFilterBluecoins',
  default: null,
});

export const isBluecoinDataLoadingAtom = atom<boolean>({
  key: 'isBluecoinsLoaded',
  default: false,
});

export const isBluecoinsOpenAtom = atom<boolean>({
  key: 'isBluecoinsOpen',
  default: false,
  effects: [setIsBluecoinsMapOpenToSocketServerEffect()],
});

export const bluecoinIdAtom = atom<number | null>({
  key: 'bluecoinId',
  default: null,
});

export const bluecoinsDataSelector = selector<BluecoinData[]>({
  key: 'bluecoinsData',
  get: ({ get }) => {
    let bluecoinsDataWorker = get(bluecoinsArrayAtom);
    const filteredWaypointsStringWorker = get(filteredBluecoinStringAtom);
    const filteredWaypointsWorker = get(filteredBluecoinsAtom);
    const zoomData = get(zoomAtom);
    const boundsData = get(boundsAtom);
    const isClusterEnabledValue = get<boolean>(isClusterEnabledAtom);
    if (filteredWaypointsStringWorker.length > 0) {
      bluecoinsDataWorker = filteredWaypointsWorker;
    }

    SuperclusterBluecoinWorker.postMessage({
      bluecoinsData: bluecoinsDataWorker,
      bounds: boundsData,
      zoom: zoomData,
      minPoints: isClusterEnabledValue
        ? Config.onlineMap.superCluster.minPoints
        : MIN_POINTS_FOR_DISABLED_CLUSTER,
    });
    return bluecoinsDataWorker;
  },
});

export const isMinimalizedBleAtom = atom<boolean>({
  key: 'isMinimalizedBle',
  default: false,
});
