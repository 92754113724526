/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from 'react';

interface IPreviewModalButton {
  id: number;
  title: string;
  callback: () => void;
  tooltip?: string;
}

interface IPreviewModalProps {
  title: string;
  id: string;

  children: React.ReactNode;
  // buttons?: IPreviewModalButton[];
  closeCallback: () => void;
  closeTooltip?: string;
}

const PreviewOnlinePanelModal: React.FC<IPreviewModalProps> = (props): JSX.Element => {
  // const modalRoot = document.getElementById('modal-root');

  return (
    <div className="modal-wrapper modal-wrapper--xl">
      <div className="modal-wrapper__content">
        <div className="modal" id={props.id}>
          <div className="modal__header">
            <div className="row">
              <div className="col">
                <h4 className="modal__header__title">{props.title}</h4>
              </div>
              <div className="col-auto">
                <button
                  style={{ background: 'none', border: 'none' }}
                  title={props.closeTooltip || ''}
                  className="cmd-icon cmd-icon-remove close-modal"
                  onClick={props.closeCallback}
                />
              </div>
            </div>
          </div>

          <div className="modal__content">
            <div
              className="w-map online-panel-preview-bg"
              style={{
                position: 'relative',
                left: 0,
                minHeight: '500px',
                // background: `url('/assets/map-bg.jpg') top left`,
              }}
            >
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  // if (modalRoot) {
  //   return ReactDOM.createPortal(modalContent, modalRoot);
  // }
  // console.error('Modal root element is not defined.');
  // return <></>;
};

export default PreviewOnlinePanelModal;
