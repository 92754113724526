export const ASCENDING_KEY = 'asc';
export const DESCENDING_KEY = 'desc';
export const DURATION_SUFFIX = 'min';
export const MIN_OFFSET_LENGTH = 20;

export const ACTIONS_KEY = 'actions';
export const CUSTOMERS_KEY = 'customers';

export const VISIBILITY_SYSTEM = 'system';

export const REQUIRED = 'required';

export const TOTAL_KEY = 'total';

export const GDPR_VALUE = '[GDPR]';
export const PAID_VALUE = '[PAID]';
export const OFF_VALUE = '[OFF]';
export const NOT_AVAILABLE_VALUE = '[N/A]';

export const INVALID_DATE = 'Invalid Date';
export const ID_RESET = 'reset';

export const RANGE_SELECT_CLASSNAME = [
  'form-control input active',
  'input-time',
  'dropdown-footer',
  'CmdCheckbox',
  'form-control input active',
  'arrowUp',
  'arrowDown',
  'icons-module_cmd-icon',
  'input-time',
  'CmdCalendar',
  'form-control',
  'timepicker',
  'p-0',
  'js-checkbox-indeterminate',
  'CmdRangeFilterForm',
  'CmdInput',
];
