import * as React from 'react';
import { CmdLoader } from '@commander-services/gui-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { DefaultParams } from 'wouter';
import MainMenu from './MainMenu';
import NavigatorContainer from './NavigatorContainer';
import PageContent from './PageContent';
import { LanguagesContext, LanguageContextProps } from './LanguagesContext';
import Toastr from './Toastr';
import { isLoggedIn } from '../Services/UserService';
import Broadcast from './Broadcast';
import MaintenanceToast from './Maintenance/Toast';
import { isDisabledAtom } from './Navigator/NavigatorState';
import { setLoaderAtom } from '../store/recoil/AppState';

const Login = React.lazy(() => import('./Login'));

interface ILayoutProps {
  access?: boolean;
  isNavigatorDisabled?: boolean;
  component?: React.ComponentType<any>;
  newStyle?: boolean;
  isRideBook?: boolean;
  children?: React.ReactNode;
  routerParams?: DefaultParams;
}

export default function Layout(props: ILayoutProps) {
  const showLoader = useRecoilValue<boolean>(setLoaderAtom);
  const [isNavigatorDisabled, setIsNavigatorDisabled] = useRecoilState<boolean>(isDisabledAtom);

  React.useEffect(() => {
    if (props.isNavigatorDisabled && !isNavigatorDisabled) {
      setIsNavigatorDisabled(true);
    } else if (!props.isNavigatorDisabled && isNavigatorDisabled) {
      setIsNavigatorDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const routeProps = { ...props };
  delete routeProps.component;

  let accessEnabled = true;
  if (props.access !== undefined) {
    accessEnabled = props.access;
  }

  if (isLoggedIn()) {
    const oldStyleContent = (
      <div>
        <div id="application">
          <Broadcast />
          <div
            id="wrapper"
            className="navigator-collapse"
            style={{
              paddingLeft:
                window.location.pathname.search('ride-map') !== -1 ||
                window.location.pathname.search('overview_tours/detail') !== -1
                  ? 0
                  : 216,
            }}
          >
            <MainMenu />
            {/* <TokenChecker /> */}
            <PageContent
              isRideBook={props.isRideBook || false}
              isNavigatorDisabled={isNavigatorDisabled || false}
            >
              <Toastr />
              <MaintenanceToast />
              {accessEnabled && props.component && <props.component router={props.routerParams} />}
              {/* {!accessEnabled && !showLoader && <Error404 />} */}
            </PageContent>
            {!isNavigatorDisabled && <NavigatorContainer />}
          </div>
        </div>
        {showLoader && <CmdLoader inContent={true} />}
      </div>
    );

    const newStyleContent = (
      <div className="w-page">
        <Broadcast />
        <aside className="w-navbar">
          <MainMenu />
        </aside>
        <aside className="w-navbar-navigator">
          {!isNavigatorDisabled && <NavigatorContainer />}
        </aside>
        <div className="w-page-content">
          <Toastr />
          <MaintenanceToast />
          {props.component && <props.component router={props.routerParams} />}
          {props.children}
        </div>
        {showLoader && <CmdLoader />}
      </div>
    );

    return props.newStyle ? newStyleContent : oldStyleContent;
  }

  return (
    <LanguagesContext.Consumer>
      {(context: LanguageContextProps) => <Login context={context} />}
    </LanguagesContext.Consumer>
  );
}
