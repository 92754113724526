import { atom } from 'recoil';

export const subPageCloseButton = atom<boolean>({
  key: 'subPageCloseButton',
  default: false,
});

export const isSubPageOpen = atom<boolean>({
  key: 'isSubPageOpen',
  default: false,
});
