import { SocketConnectionWorker } from '../../../components/SocketConnection';
import { EVENT_IS_OPEN } from '../../../components/SocketConnection/constants';
import { getUserId } from '../../../Services/UserService';
import { browserTabIdAtom } from '../AppState';

const setIsGraphOpenToSocketServerEffect =
  () =>
  ({ onSet, getPromise }): void => {
    onSet(async (newValue: number | false) => {
      const browserTabId = await getPromise(browserTabIdAtom);
      const data = {
        event: EVENT_IS_OPEN,
        payload: ['graph', newValue],
        userId: getUserId(),
        browserTabId,
      };
      SocketConnectionWorker.postMessage(data);
    });
  };

export default setIsGraphOpenToSocketServerEffect;
