import { SocketConnectionWorker } from '../../../components/SocketConnection';
import { EVENT_SELECT_CUSTOMERS } from '../../../components/SocketConnection/constants';
import { getUserId } from '../../../Services/UserService';

const setSelectedCustomersToSocketServerEffect =
  () =>
  ({ onSet }): void => {
    onSet((newValue: number[]) => {
      const data = {
        event: EVENT_SELECT_CUSTOMERS,
        payload: newValue,
        userId: getUserId(),
      };
      SocketConnectionWorker.postMessage(data);
    });
  };

export default setSelectedCustomersToSocketServerEffect;
