import { setRecoil } from '../components/RecoilOutside';
import { setLoaderAtom } from '../store/recoil/AppState';
import { showLoaderAtom } from '../components/Navigator/NavigatorState';

const LoaderService = {
  showNavigatorLoader(value = true): void {
    setRecoil<boolean>(showLoaderAtom, value);
  },

  showLoader(value = true): void {
    setRecoil<boolean>(setLoaderAtom, value);
  },
};

export default LoaderService;
