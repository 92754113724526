interface BooleanCellProps {
  value: boolean;
  trueValue: string;
  falseValue: string;
}

function BooleanCell(props: BooleanCellProps): React.ReactNode {
  return (
    <div className="text-cell align-left" style={{ flex: 1 }}>
      {props.value ? props.trueValue : props.falseValue}
    </div>
  );
}

export default BooleanCell;
