/* eslint-disable no-await-in-loop */
import * as React from 'react';
import { useIntl } from 'react-intl';
import Dropzone from 'react-dropzone';
import { CmdChips, CmdError, CmdUpload } from '@commander-services/gui-components';
import { Control, useController } from 'react-hook-form';
import { showMessage } from '../Toastr/ToastService';
import ApiService from '../../Services/ApiService';
import * as UploadService from '../../Services/UploadService';

interface IUploadProps {
  id: string;
  label: string;
  name: string;
  number: number;
  maxFileSize: number;
  acceptedFileTypes: string[];
  url: string;
  control: Control<any>;
  width?: string;
  dnd?: boolean;
  required?: boolean;
  readonly?: boolean;
  onChangeCallback?: (value: any) => void;
  multiple?: boolean;
}

const CmdUploadHookForm: React.FC<IUploadProps> = (props) => {
  const { formatMessage: f } = useIntl();
  const uploadFileRef: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();
  const {
    field: { ref, onChange, onBlur, value },
    fieldState: { invalid, error },
  } = useController({
    name: props.name,
    control: props.control,
    defaultValue: '', // or the default value
  });

  const renderDndUpload = () => {
    let allowedNumber = 1;

    const num = !props.number ? 1 : props.number;
    allowedNumber = num - value.length;

    if (allowedNumber < 0) {
      allowedNumber = 0;
    }

    const handleRemoveFile = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();

      const file = Number(event.currentTarget.getAttribute('data-file'));

      if (value && file) {
        const files = value;

        let newFiles: any = [];
        if (files.length) {
          files.forEach((item: any) => {
            if (item.id !== file) {
              newFiles.push(item);
            }
          });
        } else {
          newFiles = newFiles[files];
        }
        onChange(props.name, newFiles);
        if (props.onChangeCallback) {
          props.onChangeCallback(newFiles);
        }
      }
    };

    const uploadFile = async (sentFile: any, name: string) => {
      const response = await ApiService.upload(props.url, sentFile);

      let currentFiles: any = [];

      if (response) {
        if (value) {
          currentFiles = [...value];
        }
        const { file, attachment } = response.data;

        if (name !== null && (file || attachment)) {
          currentFiles.push(file || attachment);
        }
      }

      return currentFiles;
    };

    const handleFiles = async (files: FileList, nameForForm: string, preview = false) => {
      const selfFiles: File[] = [files[0]];
      let checkJS = 0;

      const maxNumOfFiles = value.lentgh;

      if (
        (!maxNumOfFiles && selfFiles.length <= 5) ||
        (maxNumOfFiles >= selfFiles.length && selfFiles)
      ) {
        Object.values(selfFiles).forEach((element: File) => {
          if (element) {
            const elementNameArr = element.name.split('.');
            const elementTypeBySuffix = elementNameArr[elementNameArr.length - 1].toLowerCase();

            if (
              props.acceptedFileTypes &&
              (props.acceptedFileTypes.includes(element.type) ||
                props.acceptedFileTypes.includes(elementTypeBySuffix))
            ) {
              const bytesSize = UploadService.bytesToSize(element.size);

              if (
                (!props.maxFileSize &&
                  bytesSize &&
                  bytesSize.val <= 10 &&
                  bytesSize.type === 'MB') ||
                (bytesSize && bytesSize.type === 'KB') ||
                (bytesSize && bytesSize.type === 'Bytes') ||
                (bytesSize &&
                  props.maxFileSize &&
                  props.maxFileSize >= bytesSize.val &&
                  bytesSize.type === 'MB') ||
                (bytesSize && bytesSize.type === 'KB') ||
                (bytesSize && bytesSize.type === 'Bytes')
              ) {
                if (preview) {
                  UploadService.previewFile(element);
                }
                checkJS += 1;
              } else if (bytesSize === null) {
                showMessage('', f({ id: 'upload.minSize' }), 'warning');
              } else {
                if (bytesSize)
                  console.warn('Incorrect file size: ', bytesSize.val, props.maxFileSize);

                showMessage('', `${f({ id: 'upload.maxSize' })} ${props.maxFileSize}`, 'warning');
              }
            } else {
              let fileTypesMessage = '';
              const fileTypesLength = props.acceptedFileTypes.length;

              props.acceptedFileTypes.forEach((item: string, key: number) => {
                if (fileTypesLength === key + 1) {
                  fileTypesMessage += `${item}.`;
                } else {
                  fileTypesMessage += `${item}, `;
                }
              });

              showMessage(
                '',
                `${f({ id: 'upload.useCorrectFileType' })} ${fileTypesMessage}`,
                'warning'
              );
            }
          }
        });

        let currentFiles: any = [];
        if (selfFiles.length === checkJS) {
          if (files.length === 1) {
            const tempFiles = await uploadFile(files[0], nameForForm);

            currentFiles.push(...tempFiles);
          } else {
            for (let i = 0; i < files.length; i += 1) {
              const tempFiles = await uploadFile(files[i], nameForForm);

              currentFiles.push(...tempFiles);
            }
          }

          if (currentFiles.length) {
            currentFiles = currentFiles.filter(
              (item: any, index: number) => currentFiles.indexOf(item) === index
            );
          }
          onChange(props.name, currentFiles);
          if (props.onChangeCallback) {
            props.onChangeCallback(currentFiles);
          }
        }
      }
    };

    const handleUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();

      if (
        uploadFileRef &&
        uploadFileRef.current &&
        uploadFileRef.current.files &&
        value.length < props.number
      ) {
        handleFiles(uploadFileRef.current.files, props.name);
        event.currentTarget.value = '';
      } else {
        console.warn('Max files exceeded.');
        showMessage('', f({ id: 'upload.maxFiles' }), 'warning');
      }
    };

    const handleDragAndDropUpload = (acceptedFiles: File[]) => {
      const files = Object.assign(acceptedFiles);
      if (
        uploadFileRef &&
        uploadFileRef.current &&
        uploadFileRef.current.files &&
        value.length + files.length <= props.number
      ) {
        handleFiles(files, props.name);
      } else {
        console.warn('Max files exceeded.');
        showMessage('', f({ id: 'upload.maxFiles' }), 'warning');
      }
    };

    return (
      <div className={`form-group ${error && 'form-group-error'}`}>
        <div className="d-block mt-lg-3 form-label">
          {props.required && (
            <span className="text-danger text-danger required-field-info">* </span>
          )}
          <b>{props.label}</b>
        </div>
        <div className={`d-block`}>
          <Dropzone onDrop={handleDragAndDropUpload} multiple={props.multiple}>
            {({ getRootProps, getInputProps }: any) => (
              <CmdUpload
                dnd={true}
                getInputProps={getInputProps()}
                getRootProps={getRootProps()}
                title={f({ id: 'upload.uploadFile' }, { size: props.maxFileSize })}
                subtitle={f({ id: 'upload.or' })}
                chooseButtonName={f({ id: 'upload.choose' })}
                multiple={props.multiple}
                name={props.name}
                allowedNumber={allowedNumber}
                uploadFileRef={uploadFileRef}
                handleUploadFile={handleUploadFile}
              />
            )}
          </Dropzone>
          <CmdChips items={value} handleRemoveFile={handleRemoveFile} readonly={props.readonly} />
          {error && <CmdError message={error.message?.[props.name] || String(error.message)} />}
        </div>
      </div>
    );
  };

  const renderUploadInput = () => {
    const widthValue = props.width ? props.width : 'col-sm-9';

    let errBorder = '';
    if (error) {
      errBorder = 'err-border';
    }

    let allowedNumber = 1;

    const num = !props.number ? 1 : props.number;
    allowedNumber = num - value.length;

    if (allowedNumber < 0) {
      allowedNumber = 0;
    }

    const handleRemoveFile = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();

      const file = Number(event.currentTarget.getAttribute('data-file'));

      if (value && file) {
        const files = value;

        let newFiles: any = [];
        if (files.length) {
          files.forEach((item: any) => {
            if (item.id !== file) {
              newFiles.push(item);
            }
          });
        } else {
          newFiles = newFiles[files];
        }
        onChange(props.name, newFiles);
      }
    };

    const uploadFile = async (sentFile: any, name: string) => {
      // const link = props.url ? props.url : '/v1/file/upload'; // /v1/${url}/attachment/add

      const response = await ApiService.upload(props.url, sentFile);

      let currentFiles: any = [];

      if (response) {
        if (value) {
          currentFiles = [...value];
        }
        const { file, attachment } = response.data;
        if (name !== null) {
          currentFiles.push(file || attachment);
          onChange(props.name, currentFiles);
        }
      }
    };

    const handleFiles = (files: FileList, nameForForm: string, preview = false) => {
      const selfFiles: File[] = [files[0]];
      let checkJS = 0;

      const maxNumOfFiles = value.lentgh;

      if (
        (!maxNumOfFiles && selfFiles.length <= 5) ||
        (maxNumOfFiles >= selfFiles.length && selfFiles)
      ) {
        Object.values(selfFiles).forEach((element: File) => {
          if (element) {
            const elementNameArr = element.name.split('.');
            const elementTypeBySuffix = elementNameArr[elementNameArr.length - 1].toLowerCase();

            if (
              props.acceptedFileTypes &&
              (props.acceptedFileTypes.includes(element.type) ||
                props.acceptedFileTypes.includes(elementTypeBySuffix))
            ) {
              const bytesSize = UploadService.bytesToSize(element.size);

              if (
                (!props.maxFileSize &&
                  bytesSize &&
                  bytesSize.val <= 10 &&
                  bytesSize.type === 'MB') ||
                (bytesSize && bytesSize.type === 'KB') ||
                (bytesSize && bytesSize.type === 'Bytes') ||
                (bytesSize &&
                  props.maxFileSize &&
                  props.maxFileSize >= bytesSize.val &&
                  bytesSize.type === 'MB') ||
                (bytesSize && bytesSize.type === 'KB') ||
                (bytesSize && bytesSize.type === 'Bytes')
              ) {
                if (preview) {
                  UploadService.previewFile(element);
                }
                checkJS += 1;
              } else if (bytesSize === null) {
                showMessage('', f({ id: 'upload.minSize' }), 'warning');
              } else {
                if (bytesSize)
                  console.warn('Incorrect file size: ', bytesSize.val, props.maxFileSize);
                showMessage('', f({ id: 'upload.maxSize' }), 'warning');
              }
            } else {
              let fileTypesMessage = '';
              const fileTypesLength = fileTypesMessage.length;

              props.acceptedFileTypes.forEach((item: string, key: number) => {
                if (fileTypesLength === key + 1) {
                  fileTypesMessage += item;
                } else {
                  fileTypesMessage += `${item}, `;
                }
              });

              showMessage(
                '',
                `${f({ id: 'upload.useCorrectFileType' })} ${fileTypesMessage}`,
                'warning'
              );
            }
          }
        });

        if (selfFiles.length === checkJS) {
          if (files.length === 1) {
            uploadFile(files[0], nameForForm);
          } else {
            for (let i = 0; i < files.length; i += 1) {
              uploadFile(files[i], nameForForm);
            }
          }
        }
      }
    };

    const handleFileUploadIcon = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();
      const element = document.getElementsByClassName('fileUploading')[0];
      UploadService.simulateClick(element);
    };

    const handleUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();

      if (
        uploadFileRef &&
        uploadFileRef.current &&
        uploadFileRef.current.files &&
        value.length < props.number
      ) {
        handleFiles(uploadFileRef.current.files, props.name);
        event.currentTarget.value = '';
      } else {
        console.warn('Max files exceeded.');
        showMessage('', f({ id: 'upload.maxFiles' }), 'warning');
      }
    };

    return (
      <div className="mb-1 form-group">
        {props.readonly ? (
          <>
            <div className="text-left font-weight-bold">{props.label}</div>
            <div className="col-sm-9">
              <CmdChips
                items={value}
                handleRemoveFile={handleRemoveFile}
                readonly={props.readonly}
              />
            </div>
          </>
        ) : (
          <div className="text-left font-weight-bold col-sm-3">
            {props.required && (
              <span className="text-danger text-danger required-field-info">* </span>
            )}
            <b>{props.label}</b>
          </div>
        )}
        {!props.readonly && (
          <div className={widthValue}>
            {!props.readonly && (
              <CmdUpload
                multiple={props.multiple}
                name={props.name}
                allowedNumber={allowedNumber}
                uploadFileRef={uploadFileRef}
                handleUploadFile={handleUploadFile}
                handleFileUploadIcon={handleFileUploadIcon}
              />
            )}
            <div>
              <CmdChips
                items={value}
                handleRemoveFile={handleRemoveFile}
                readonly={props.readonly}
              />
              {error && <CmdError message={error.message?.[props.name]} />}
            </div>
          </div>
        )}
      </div>
    );
  };

  return props.dnd ? renderDndUpload() : renderUploadInput();
};

export default CmdUploadHookForm;
