/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
import { orderBy } from 'lodash';
import { CmdModal, CmdModalFooterButton } from '@commander-services/gui-components';
import * as React from 'react';
import { useIntl } from 'react-intl';
import TooltipComponent from '../../../../TooltipComponent';
import { WORD_RULE } from '.';

interface IVehicle {
  name: string;
  licenceNumber: string;
  label: string;
  customer: string;
}

interface ISelectRidesModalProps {
  vehicles: IVehicle[];
  handleCloseModal: () => void;
  title: string;
}

interface ISortByColumn {
  column: string;
  type: 'asc' | 'desc';
}

const COLUMN_LICENCE_NUMBER = 'licenceNumber';
const COLUMN_NAME = 'name';
const COLUMN_CUSTOMER = 'customer';
const ASC = 'asc';
const DESC = 'desc';

export default function VehiclesList(props: ISelectRidesModalProps): JSX.Element {
  const { formatMessage: f } = useIntl();
  const [sortedVehicles, setSortedVehiles] = React.useState<IVehicle[]>(props.vehicles);
  const [sortByColumn, setSortByColumn] = React.useState<ISortByColumn>({
    column: '',
    type: 'asc',
  });

  React.useEffect(() => {
    const newSortedRides = orderBy(sortedVehicles, sortByColumn.column, sortByColumn.type);
    setSortedVehiles(newSortedRides);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortByColumn]);

  const buttons: CmdModalFooterButton[] = [
    {
      id: 'close-select-rides-modal',
      type: 'button',
      title: f({ id: 'waypoint.confirm.dialog.cancel' }), // Vulgarny quickhack. V aktualnom case tejto zmeny, mi nesli zmenit preklady, lebo Anton tam ma limit na upload (440 nieco kb) a uz samotne preklady ho prekracuju
      className: 'e-button e-button--gray',
      closeCallback: props.handleCloseModal,
    },
  ];

  const handleSort = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    const sortColumn = event.currentTarget.getAttribute('data-sort-column');
    const sortType = event.currentTarget.getAttribute('data-sort-type');
    if (sortColumn && sortType) {
      setSortByColumn((oldState: ISortByColumn) => {
        const newState: ISortByColumn = {
          ...oldState,
        };
        if (oldState.column !== sortColumn) {
          newState.column = sortColumn;
          newState.type = ASC;
        }
        if (oldState.column === sortColumn && sortType === ASC) {
          newState.type = DESC;
        }
        if (oldState.column === sortColumn && sortType === DESC) {
          newState.type = ASC;
        }
        return newState;
      });
    }
  };

  const getSortType = (column: string): string => {
    if (column === sortByColumn.column) {
      return sortByColumn.type === ASC ? ASC : DESC;
    }
    return ASC;
  };

  const getSortIconClass = (column: string): string => {
    if (sortByColumn && sortByColumn.column === column) {
      if (sortByColumn.type === ASC) {
        return 'cmd-icon-sorting-a-z text-active';
      }
      return 'cmd-icon-sorting-z-a text-active';
    }
    return 'cmd-icon-sorting-a-z text-gray';
  };

  const renderValue = (title: string) => {
    const words = title.replace(/([ ,;]+)/g, '$1§sep§').split('§sep§');
    return words && words.length > WORD_RULE ? (
      <TooltipComponent
        id={`vehicle-list-${title}`}
        firstItems={true}
        overlay={<div style={{ color: '#fff', textAlign: 'left' }}>{title}</div>}
      >
        <div>{words.slice(0, WORD_RULE).join(' ')} ...</div>
      </TooltipComponent>
    ) : (
      title
    );
  };

  return (
    <CmdModal id="select-rides-modal" title={props.title} footerButtons={buttons} size="xl">
      <div className="b-select-ride-table" style={{ maxHeight: '350px', overflowY: 'auto' }}>
        <div className="table-responsive height-auto">
          <table className="table table-striped table-bordered table-listings m-0">
            <thead>
              <tr>
                <th className="th-sticky-top">
                  {f({ id: 'form.labels.vehicles.licenceNumber' })}
                  <a
                    id="sort-licence-number"
                    href="#"
                    title={
                      sortByColumn.type === ASC
                        ? f({ id: 'table.filter.desc' })
                        : f({ id: 'table.filter.asc' })
                    }
                    onClick={handleSort}
                    data-sort-column={COLUMN_LICENCE_NUMBER}
                    data-sort-type={getSortType(COLUMN_LICENCE_NUMBER)}
                    className={`filter cmd-icon ${getSortIconClass(
                      COLUMN_LICENCE_NUMBER
                    )} text-decoration-none position-relative ml-2`}
                  />
                </th>
                <th className="th-sticky-top">
                  {f({ id: 'form.inputs.vehicles.vehicleName' })}
                  <a
                    id="sort-name"
                    href="#"
                    title={
                      sortByColumn.type === ASC
                        ? f({ id: 'table.filter.desc' })
                        : f({ id: 'table.filter.asc' })
                    }
                    onClick={handleSort}
                    data-sort-column={COLUMN_NAME}
                    data-sort-type={getSortType(COLUMN_NAME)}
                    className={`filter cmd-icon ${getSortIconClass(
                      COLUMN_NAME
                    )} text-decoration-none position-relative ml-2`}
                  />
                </th>
                <th className="th-sticky-top" title={f({ id: 'form.inputs.customer' })}>
                  {f({ id: 'form.inputs.customer' })}
                  <a
                    id="sort-customer"
                    href="#"
                    title={
                      sortByColumn.type === ASC
                        ? f({ id: 'table.filter.desc' })
                        : f({ id: 'table.filter.asc' })
                    }
                    onClick={handleSort}
                    data-sort-column={COLUMN_CUSTOMER}
                    data-sort-type={getSortType(COLUMN_CUSTOMER)}
                    className={`filter cmd-icon ${getSortIconClass(
                      COLUMN_CUSTOMER
                    )} text-decoration-none position-relative ml-2`}
                  />
                </th>
              </tr>
            </thead>

            <tbody>
              {sortedVehicles.map((vehicle: IVehicle) => {
                return (
                  <tr key={`select-ride-${vehicle.licenceNumber}`}>
                    <td className="align-middle">{renderValue(vehicle.licenceNumber)}</td>
                    <td className="align-middle">{renderValue(vehicle.name)}</td>
                    <td className="align-middle">{renderValue(vehicle.customer)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </CmdModal>
  );
}
