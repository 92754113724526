import { SignInResponse } from '../Login/types';
import { postRequest } from '../../Services/HttpService';

export const getRefreshToken = (): string | null => {
  return localStorage.getItem('refreshToken');
};

export const getRefreshTokenExpiresAt = (): string | null => {
  return localStorage.getItem('refreshTokenExpiresAt');
};

export const getAuthTokenTimeout = (): number => {
  const authTokenExpiresAt = localStorage.getItem('authTokenExpiresAt');
  if (!authTokenExpiresAt) {
    return 0;
  }
  const currentDate = new Date().getTime();
  const expiredDate = new Date(authTokenExpiresAt).getTime();
  const timeout = expiredDate - currentDate;
  // console.log('currentDate: ', currentDate);
  // console.log('expiredDate: ', expiredDate);
  // console.log('timeout: ',  timeout, timeout / 1000)
  return timeout / 1000;
};

export const refreshTokenExists = (): boolean => {
  const refreshToken = getRefreshToken();
  if (refreshToken === null) {
    return false;
  }
  return true;
};

export const getRefreshTokenRequest = async (
  refreshToken: string | null | undefined
): Promise<SignInResponse | false> => {
  if (!refreshToken) {
    return false;
  }
  return postRequest<{ refreshToken: string }, SignInResponse>(
    '/v2/auth/refresh-token',
    { refreshToken },
    {
      returnAxiosError: false,
      returnAxiosResponse: false,
      showToaster: false,
      showErrorToaster: false,
      ignoreRefreshTokenMiddleware: true,
      noAuthHeader: true,
    }
  );
};
