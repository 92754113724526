import * as React from 'react';
import { GDPR_VALUE, NOT_AVAILABLE_VALUE, OFF_VALUE, PAID_VALUE } from '../constants';

interface IUseTable {
  handleAnonymizedData: (
    data: string | number,
    processValueFn?: (value: string | number) => string
  ) => JSX.Element | string;
  isAnonymizedValue: (value: string | number) => boolean;
}

const useTable = (): IUseTable => {
  const handleAnonymizedData = (
    value: string | number,
    processValueFn?: (v: string | number) => string
  ): JSX.Element | string => {
    switch (value) {
      case GDPR_VALUE:
        return <i className="cmd-icons cmd-icon-gdpr-document-lock-dark-gray" />;
      case PAID_VALUE:
        return <>&ndash;</>;
      case OFF_VALUE:
        return <>&ndash;</>;
      case NOT_AVAILABLE_VALUE:
        return <>&ndash;</>;

      default:
        if (processValueFn) {
          return processValueFn(value);
        }
        return String(value);
    }
  };

  const isAnonymizedValue = (orginalValue: string | number): boolean => {
    if (orginalValue === GDPR_VALUE) {
      return true;
    }
    if (orginalValue === NOT_AVAILABLE_VALUE) {
      return true;
    }
    if (orginalValue === OFF_VALUE) {
      return true;
    }
    if (orginalValue === PAID_VALUE) {
      return true;
    }
    return false;
  };

  const data = {
    handleAnonymizedData,
    isAnonymizedValue,
  };

  return data;
};

export default useTable;
