import localforage from 'localforage';
import { atom, DefaultValue } from 'recoil';
import { getLanguage } from '../../Services/UserService';
import configJs from '../../../config';
import { IMainMenuItem } from './interfaces';

const getIsMainMenuOpenFromIndexedDb = async (): Promise<boolean> => {
  const isMainMenuOpened = await localforage.getItem<boolean>('isMainMenuOpened');
  if (isMainMenuOpened === null) {
    return true;
  }
  return isMainMenuOpened;
};

const getMenuUrlFromIndexedDb = async (): Promise<string> => {
  const url = await localforage.getItem<string>('menuUrl');
  if (url === null) {
    return configJs.Login.homeUrl;
  }
  return url;
};

const getMenuIdFromIndexedDb = async (): Promise<number> => {
  const id = await localforage.getItem<number>('activeMenuId');
  if (id === null) {
    return 1;
  }
  return id;
};

const getMenuParentIdFromIndexedDb = async (): Promise<number> => {
  const id = await localforage.getItem<number>('activeMenuParentId');
  if (id === null) {
    return 0;
  }
  return id;
};

const setMenuIdToIndexedDb = async (key: string, id: number): Promise<number> => {
  return localforage.setItem(key, id);
};

const setMenuUrlToIndexedDb = async (url: string): Promise<string> => {
  return localforage.setItem('menuUrl', url);
};

const setIsMainMenuOpenedToIndexedDb = async (data: boolean): Promise<boolean> => {
  return localforage.setItem('isMainMenuOpened', data);
};

export const menuItemsAtom = atom<IMainMenuItem[]>({
  key: 'menuItems',
  default: [],
});

export const activeMenuIdAtom = atom<number>({
  key: 'activeMenuId',
  default: getMenuIdFromIndexedDb(),
  effects: [
    ({ onSet }) => {
      onSet((newValue: number | DefaultValue) => {
        setMenuIdToIndexedDb('activeMenuId', Number(newValue));
      });
    },
  ],
});

export const activeMenuParentIdAtom = atom<number>({
  key: 'activeMenuParentId',
  default: getMenuParentIdFromIndexedDb(),
  effects: [
    ({ onSet }) => {
      onSet((newValue: number | DefaultValue) => {
        setMenuIdToIndexedDb('activeMenuParentId', Number(newValue));
      });
    },
  ],
});

export const isMenuShownAtom = atom<boolean>({
  key: 'isMenuShown',
  default: getIsMainMenuOpenFromIndexedDb(),
  effects: [
    ({ onSet }) => {
      onSet((newValue: boolean) => {
        setIsMainMenuOpenedToIndexedDb(newValue);
      });
    },
  ],
});

export const isSubMenuShownAtom = atom<boolean>({
  key: 'isSubMenuShown',
  default: getIsMainMenuOpenFromIndexedDb(),
});

export const activeLanguageAtom = atom<string | null>({
  key: 'activeLanguage',
  default: getLanguage(),
});

export const carRentalAtom = atom<string>({
  key: 'carRental',
  default: '',
});

export const urlAtom = atom<string>({
  key: 'menuUrl',
  default: getMenuUrlFromIndexedDb(),
  effects: [
    ({ onSet }) => {
      onSet((newValue: string | DefaultValue) => {
        setMenuUrlToIndexedDb(String(newValue));
      });
    },
  ],
});
