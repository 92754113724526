import { ACTIONS_KEY, CUSTOMERS_KEY } from '../CmdTable/constants';

export const UUID_KEY = 'uuid';
export const ID_KEY = 'id';
export const EXTERNALID_KEY = 'externalId';
export const MANUFACTURER_KEY = 'manufacturer';
export const MODEL_KEY = 'model';
export const ASSIGNED_KEY = 'assigned';
export const STATE_KEY = 'state';
export const USAGE_START_KEY = 'usageStart';
export const USAGE_UNBLOCK_KEY = 'usageUnblock';
export const LAST_USAGE_KEY = 'lastUsage';
export const USAGE_END_KEY = 'usageEnd';
export const USAGE_START_ADDRESS_KEY = 'usageStartAddress';
export const USAGE_END_ADDRESS_KEY = 'usageEndAddress';
export const LAST_USAGE_ADDRESS_KEY = 'lastUsageAddress';
export const CUSTOMER_KEY = 'customer';
export const VEHICLE_LICENCE_NUMBER_KEY = 'vehicleLicenceNumber';
export const VEHICLE_KEY = 'vehicle';
export const USAGE_START_GPS_KEY = 'usageLatLonStart';
export const USAGE_END_GPS_KEY = 'usageLatLonEnd';
export const NAME_KEY = 'name';
export const MAC_ADDRESS_KEY = 'macAddress';
export const SERIAL_NUMBER_KEY = 'serialNumber';
export const USAGE_DURATION_KEY = 'duration';
export const TIME_KEY = 'time';
export const USAGE_START_COORDINATES_KEY = 'usageStartCoordinates';
export const USAGE_END_COORDINATES_KEY = 'usageEndCoordinates';
export const USAGE_TIMESTAMP_KEY = 'timestamp';

export const IS_OK = 'ok';
export const IS_INACTIVE = 'inactive';
export const IS_ACTIVE = 'active';
export const IS_NEW = 'new';
export const IS_DISCARDED = 'discarded';
export const BLUECOINS_LIST = 'bluecoins';
export const BLUECOINS_USAGE = 'bluecoins_usage';

export const DEFAULT_ORDER_COLUMNS: { key: string; hidden: boolean }[] = [
  { key: UUID_KEY, hidden: true },
  { key: EXTERNALID_KEY, hidden: true },
  { key: MANUFACTURER_KEY, hidden: true },
  { key: MODEL_KEY, hidden: true },
  { key: ASSIGNED_KEY, hidden: false },
  { key: STATE_KEY, hidden: false },
  { key: VEHICLE_KEY, hidden: false },
  { key: USAGE_START_KEY, hidden: false },
  { key: USAGE_UNBLOCK_KEY, hidden: false },
  { key: LAST_USAGE_KEY, hidden: false },
  { key: USAGE_START_ADDRESS_KEY, hidden: true },
  { key: LAST_USAGE_ADDRESS_KEY, hidden: true },
  { key: CUSTOMERS_KEY, hidden: true },
  { key: ACTIONS_KEY, hidden: false },
];

export const BLE_TABLE_NAME = 'v1/bluecoin';
export const BLE_TABLE_SETTINGS_KEY = 'bluecoins';
export const TOTAL_KEY = 'total';
export const CHECKBOX_KEY = 'checkboxId';

export const BlucoinsTableTranslationKeys = {
  uuid: 'table_list.columns.bluecoin.uuid',
  macAddress: 'table_list.columns.bluecoin.macAddress',
  serialNumber: 'table_list.columns.bluecoin.serialNumber',
  manufacturer: 'table_list.columns.bluecoin.manufacturer',
  model: 'table_list.columns.bluecoin.model',
  assigned: 'table_list.columns.bluecoin.assigned',
  state: 'table_list.columns.bluecoin.state',
  vehicle: 'table_list.columns.bluecoin.vehicle',
  vehicleLicenceNumber: 'table_list.columns.bluecoin.vehicleLicenceNumber',
  usageStart: 'table_list.columns.bluecoin.usageStart',
  lastUsage: 'table_list.columns.bluecoin.lastUsage',
  usageStartAddress: 'table_list.columns.bluecoin.usageStartAddress',
  lastUsageAddress: 'table_list.columns.bluecoin.lastUsageAddress',
  customers: 'table_list.columns.bluecoin.customers',
};

// Bluecoins usage
export const BLE_USAGE_TABLE_NAME = 'v1/bluecoin/usage';
export const BLE_USAGE_TABLE_SETTINGS_KEY = 'bluecoins_usage';

export const BlucoinsUsageTableTranslationKeys = {
  uuid: 'table_list.columns.bluecoinUsage.uuid',
  macAddress: 'table_list.columns.bluecoinUsage.macAddress',
  serialNumber: 'table_list.columns.bluecoinUsage.serialNumber',
  manufacturer: 'table_list.columns.bluecoinUsage.manufacturer',
  model: 'table_list.columns.bluecoinUsage.model',
  name: 'table_list.columns.bluecoinUsage.name',
  assigned: 'table_list.columns.bluecoinUsage.assigned',
  duration: 'table_list.columns.bluecoinUsage.duration',
  vehicle: 'table_list.columns.bluecoinUsage.vehicle',
  vehicleLicenceNumber: 'table_list.columns.bluecoinUsage.vehicleLicenceNumber',
  timestamp: 'table_list.columns.bluecoinUsage.usageStart',
  usageEnd: 'table_list.columns.bluecoinUsage.usageEnd',
  usageStartAddress: 'table_list.columns.bluecoinUsage.usageStartAddress',
  usageEndAddress: 'table_list.columns.bluecoinUsage.usageEndAddress',
  usageStartCoordinates: 'table_list.columns.bluecoinUsage.usageStartCoordinates',
  usageEndCoordinates: 'table_list.columns.bluecoinUsage.usageEndCoordinates',
  customer: 'table_list.columns.bluecoinUsage.customer',
};
