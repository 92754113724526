import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRecoilState, useRecoilValue } from 'recoil';
import CmdSkeletonLoader from '@commander-services/cmd-skeleton-loader';
import { useLocation, Link } from 'wouter';
import Languages from '../Languages';
import { defaultLanguage } from '../LanguagesContext';
import { languages } from '../../Services/LanguageService';
import ChangePassword from '../ChangePassword';
import PermissionsService, { PERMISSION_LOGIN } from '../../Services/PermissionsService';
import JWTService from '../../Services/JWTService';
import { IApplicationState, StoreContext } from '../../StoreProvider';
import './styles.scss';
import SvgIconService from '../../Services/SvgIconService';
import { URL_ABOUT, URL_ALARM, URL_ONLINE_HELP } from '../../router/constants';
import Contact from './Contact';
import SelectCustomers from './SelectCustomers';
import { ICustomer } from '../../interfaces/customer';
import ExportDownload from '../Export/ExportDownload';
import { iconSwitcher } from './MainMenuService';
import { isMaintenanceTokenValid } from '../Maintenance/MaintenanceService';
import { IMainMenuItem } from './interfaces';
import Gravatar from './Gravatar';
import { getLanguage, getUsername, logOut } from '../../Services/UserService';
import { customersAtom } from '../../store/recoil/customers';
import {
  activeMenuIdAtom,
  activeMenuParentIdAtom,
  carRentalAtom,
  isMenuShownAtom,
  isSubMenuShownAtom,
  menuItemsAtom,
  urlAtom,
} from './MainMenuState';
import useAnalytics from '../../hooks/useAnalytics';

const logoSvg = SvgIconService.logo();

export default function MainMenu(): JSX.Element {
  const { trackEvent } = useAnalytics();
  const [location, setLocation] = useLocation();
  const { formatMessage: f } = useIntl();
  const menuItems = useRecoilValue<IMainMenuItem[]>(menuItemsAtom);
  const [menuId, setMenuId] = useRecoilState<number>(activeMenuIdAtom);
  const [parentId, setParentId] = useRecoilState<number>(activeMenuParentIdAtom);
  const [subMenuId, setSubMenuId] = React.useState('');
  const activeLanguage = getLanguage();
  const [menuToggle, setMenuToggle] = useRecoilState<boolean>(isMenuShownAtom);
  const [subMenuToggle, setSubmenuToggle] = useRecoilState<boolean>(isSubMenuShownAtom);
  const [isBottomMenuOpened, setIsBottomMenuOpened] = React.useState(false);
  const carRental = useRecoilValue<string>(carRentalAtom);
  const [isLanguageItemOpened, setIsLanguageItemOpened] = React.useState(false);
  const [url, setUrl] = useRecoilState<string>(urlAtom);
  const [confirmed, setConfirmed] = React.useState<boolean>(false);
  const customers = useRecoilValue<ICustomer[]>(customersAtom);

  const handleSubMenuId = (id: number, subId: number) => {
    trackEvent(['menu', 'click', `Submenu handle`]);

    setMenuId(subId);
    setParentId(id);
  };

  const handleMenuId = (id: number) => {
    trackEvent(['menu', 'click', `Menu handle`]);
    setMenuId(id);
    setParentId(0);
  };

  React.useEffect(() => {
    if (url !== '' && location === '/') {
      setLocation(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  React.useEffect(() => {
    if (window.location.pathname !== '/') {
      if (menuItems && menuItems.length > 0 && window.location.pathname !== url) {
        setUrl(window.location.pathname);
        const routeArr = window.location.pathname.split('/');
        const route = `/${routeArr[1]}`;
        if (menuItems.find((items: any) => items.link === route)) {
          const tempMenu = Object.assign(menuItems);
          const menu = tempMenu.find((items: any) => items.link === route);
          if (menu) {
            handleMenuId(menu.id);
          }
        } else if (menuItems.find((items: any) => items.items !== null)) {
          const tempMenu = Object.assign(menuItems);
          const menu = tempMenu.filter((items: any) => items.items);

          if (
            menu &&
            menu.find((items: any) => items.items.find((subitem: any) => subitem.link === route))
          ) {
            const mainMenufilter = menu.filter((items: any) =>
              items.items.find((subitem: any) => subitem.link === route)
            );

            const subMenuFilter = mainMenufilter[0].items.filter(
              (items: any) => items.link === route
            );
            handleSubMenuId(mainMenufilter[0].id, subMenuFilter[0].id);
          }
        }
      }
    }

    if (window.location.pathname === `${URL_ALARM}/add` && !confirmed) {
      window.onbeforeunload = () => {
        const socketServerToken = JWTService.getSocketServerToken();
        return socketServerToken ? 'Refresh?' : null;
      };
    } else {
      window.onbeforeunload = null;
    }
  });

  const handleLogout = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    trackEvent(['menu', 'click', f({ id: 'menu.logout' })]);
    let confirmedLogout = true;
    if (window.location.pathname === `${URL_ALARM}/add`) {
      // eslint-disable-next-line no-alert
      confirmedLogout = window.confirm(
        f({ id: 'table.confirm.dialog.alarm.subpage.close.message' })
      );
    }
    if (confirmedLogout) {
      setConfirmed(true);
      logOut();
    }
  };

  const handleBottomMenu = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    trackEvent(['menu', 'click', 'Bottom menu']);
    if (!menuToggle) {
      setMenuToggle(true);
    }
    if (!subMenuToggle) {
      setSubmenuToggle(true);
    }
    setIsBottomMenuOpened(!isBottomMenuOpened);
  };

  const handleLanguageMenu = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    trackEvent(['menu', 'click', 'Change language menu']);
    setIsLanguageItemOpened(!isLanguageItemOpened);
  };

  const handleMainMenuToggle = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    setMenuToggle(!menuToggle);
    trackEvent(['menu', 'click', 'Menu toogle']);
    if (isBottomMenuOpened) {
      setIsBottomMenuOpened(false);
    }
    setSubmenuToggle(!menuToggle);
  };

  const handleActiveMenuId = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const id = event.currentTarget.getAttribute('data-setactivemenu-id');

    if (id) {
      handleMenuId(Number(id));
    }
  };

  const handleToggleSubmenu = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    const id = event.currentTarget.getAttribute('data-id');
    trackEvent(['menu', 'click', `SubMenu toggle`]);
    if (id) {
      setParentId(Number(id));
      if (id !== subMenuId) {
        setSubmenuToggle(true);
      } else {
        setSubmenuToggle(!subMenuToggle);
      }
      if (!menuToggle) {
        setMenuToggle(true);
      }
    }
    setSubMenuId(id || '');
  };

  const handleActiveSubMenuId = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const id = event.currentTarget.getAttribute('data-id');
    const subId = event.currentTarget.getAttribute('data-subid');

    if (id && subId) {
      handleSubMenuId(Number(id), Number(subId));
    }
  };

  const renderCarRental = () => {
    return (
      <li id="car-rental" data-cy="car-rental">
        <a href={carRental} target="_blank" rel="noreferrer">
          <span style={{ paddingLeft: '6px' }} className="cmd-font-stack cmd-font-lg pull-left">
            <i className="icon cmd-icon cmd-icon-rent-car" />
          </span>
          {f({ id: 'menu.carRental' })}

          <i
            style={{
              position: 'absolute',
              margin: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              right: '16px',
            }}
            className="icon cmd-icon cmd-icon-relink icon-pull-right"
          />
        </a>
      </li>
    );
  };

  const getLocalLanguage = () => {
    return activeLanguage ? languages[activeLanguage] : languages[defaultLanguage];
  };

  return (
    <div
      id="sidebar-wrapper"
      className={menuToggle ? 'width216' : 'width53'}
      style={{ display: 'flex' }}
    >
      <div
        className="logo-wrapper bg-primary clearfix"
        onClick={handleMainMenuToggle}
        role="presentation"
      >
        <a
          className="navbar-brand"
          href="/"
          id="menu-toggle"
          data-cy="main-menu_toggle"
          title={!menuToggle ? f({ id: 'menu.show' }) : f({ id: 'menu.hide' })}
        >
          <i id="toggleMenu" className={`cmd-icon-menu-${!menuToggle ? 'open' : 'close'}`} />
          <span className="sr-only">
            {!menuToggle ? f({ id: 'menu.show' }) : f({ id: 'menu.hide' })}
          </span>
        </a>
        <a
          href="/"
          title={f({ id: 'menu.logo' })}
          className="logo-nav"
          onClick={() => trackEvent(['menu', 'click', 'logo'])}
        >
          <img
            src={logoSvg}
            width="120"
            alt="Commander"
            className={!menuToggle ? 'displayNone' : ''}
          />
        </a>
      </div>

      {menuItems.length === 0 ? (
        <CmdSkeletonLoader type="menu" padding="1rem" />
      ) : (
        <>
          <ExportDownload />
          {isMaintenanceTokenValid() && (
            <p style={{ background: 'red', color: '#fff', textAlign: 'center', padding: '5px' }}>
              Maintenance in progress
            </p>
          )}
          <div className="b-custom-scrollbar">
            <div className="overflow">
              <ul
                className="p-0 mb-0 sidebar-nav"
                id="navigation-scrollbars"
                data-cy="sidebar-menu"
              >
                {menuItems &&
                  Object.values(menuItems).map((item: IMainMenuItem) =>
                    !item.items ? (
                      <li key={item.id}>
                        <Link href={item.link}>
                          <a
                            href="#"
                            className={item.id === menuId ? 'systemActive' : ''}
                            id={`link-${item.id}`}
                            data-cy={`menu-item-${item.text}`}
                            data-setactivemenu-id={item.id}
                            onClick={handleActiveMenuId}
                            title={f({ id: `menu.${item.text}` })}
                          >
                            <span
                              className="cmd-font-stack cmd-font-lg pull-left"
                              style={{ paddingLeft: '6px' }}
                            >
                              <i className={iconSwitcher(item.text)} />
                            </span>
                            {f({ id: `menu.${item.text}` })}
                          </a>
                        </Link>
                      </li>
                    ) : (
                      <li key={item.id}>
                        <a
                          href="/"
                          id={`link-${item.id}`}
                          data-id={item.id}
                          onClick={handleToggleSubmenu}
                          data-cy={`menu-item-${item.text}`}
                          title={f({ id: `menu.${item.text}` })}
                        >
                          {item.text === 'gdpr' ? (
                            <span
                              style={{ paddingLeft: '6px' }}
                              className="cmd-font-stack cmd-font-lg pull-left"
                            >
                              <i className="cmd-icon cmd-icon-menu-gdpr" />
                            </span>
                          ) : (
                            <span className="cmd-font-stack cmd-font-lg pull-left">
                              <i className={iconSwitcher(item.text)} />
                            </span>
                          )}
                          <FormattedMessage id={`menu.${item.text}`} />
                          <span
                            className={
                              item.id === parentId && subMenuToggle ? 'caret open' : 'caret'
                            }
                          />
                        </a>
                        <div
                          className={`sb-nav-sub-menu collapse-block ${
                            item.id === parentId && subMenuToggle ? ' in ' : ''
                          }`}
                          id={`child${item.id}`}
                        >
                          <div className="sb-nav-sub-menu-wrapper">
                            {item.items &&
                              Object.values(item.items).map((subItem: IMainMenuItem) => (
                                <div key={subItem.id} className="sb-nav-sub-menu-item">
                                  <Link href={subItem.link}>
                                    <a
                                      href="#"
                                      data-id={item.id}
                                      data-subid={subItem.id}
                                      onClick={handleActiveSubMenuId}
                                      id={`link-${subItem.id}`}
                                      data-cy={`menu-item-${item.text}-submenu-item-${subItem.text}`}
                                      className={subItem.id === menuId ? 'systemActive' : ''}
                                      title={f({
                                        id: `menu.${subItem.text}`,
                                      })}
                                    >
                                      {f({ id: `menu.${subItem.text}` })}
                                    </a>
                                  </Link>
                                </div>
                              ))}
                          </div>
                        </div>
                      </li>
                    )
                  )}
              </ul>
            </div>
          </div>

          <ul className="sidebar-nav p-0 mb-0 mt-auto bottom-nav">
            {carRental && renderCarRental()}
            <li className="user-menu" style={{ cursor: 'pointer' }}>
              <a
                id="7"
                href="/"
                onClick={handleBottomMenu}
                data-cy="menu-item-7"
                data-toggle="collapse"
                data-target="#collapse-1"
                aria-expanded="true"
                aria-controls="collapse-1"
                title={getUsername() || undefined}
              >
                <Gravatar />
                <div
                  className="font-weight-bold text-truncate-username"
                  title={getUsername() ?? ''}
                >
                  {getUsername()}
                </div>
                <div className="font-weight-normal">{getLocalLanguage()}</div>

                <span className={isBottomMenuOpened ? 'caret open' : 'caret'} />
              </a>

              <div
                className={`sb-nav-sub-menu ${isBottomMenuOpened ? 'in' : ''} collapse-block`}
                id="collapse-1"
              >
                {customers.length > 1 && <SelectCustomers />}
                {/* <div className="sb-nav-sub-menu-wrapper">
                  <div className="sb-nav-sub-menu-item">
                    <Link
                      to={URL_ABOUT}
                      id="aboutp"
                      title={f({ id: 'menu.aboutApp' })}
                      className="subMenu menuLink"
                    >
                      <FormattedMessage id="menu.aboutApp" />
                    </Link>
                  </div>
                </div> */}
                <div className="sb-nav-sub-menu-wrapper">
                  <div className="sb-nav-sub-menu-item">
                    <Link href={URL_ABOUT}>
                      <a
                        href="#"
                        id="aboutp"
                        title={f({ id: 'menu.aboutApp' })}
                        className="subMenu menuLink"
                      >
                        {f({ id: 'menu.aboutApp' })}
                      </a>
                    </Link>
                  </div>
                </div>
                <div className="sb-nav-sub-menu-wrapper">
                  <div className="sb-nav-sub-menu-item">
                    {/* <Link href={URL_ONLINE_HELP}> */}
                    <a
                      href={URL_ONLINE_HELP}
                      target="_blank"
                      id="aboutp"
                      title="Online help"
                      className="subMenu menuLink"
                      rel="noreferrer"
                    >
                      Online help
                    </a>
                    {/* </Link> */}
                  </div>
                </div>
                <div className="sb-nav-sub-menu-wrapper">
                  <div className="sb-nav-sub-menu-item">
                    <Contact />
                  </div>
                </div>
                <div className="sb-nav-sub-menu-wrapper">
                  <div className="sb-nav-sub-menu-item">
                    <a
                      className="position-relative subMenu menuLink"
                      onClick={handleLanguageMenu}
                      href="/"
                      data-toggle="collapse"
                      data-target="#collapse-2"
                      aria-expanded="true"
                      aria-controls="collapse-2"
                      title={f({ id: 'menu.changeLanguage' })}
                    >
                      {f({ id: 'menu.changeLanguage' })}
                      <span className={isLanguageItemOpened ? 'caret open' : 'caret'} />
                    </a>
                    <div
                      className={`lang-picker collapse-block py-0 ${isLanguageItemOpened ? 'in' : ''}`}
                      id="collapse-2"
                    >
                      {/* <LanguagesContext.Consumer>
                        {(context: IContextMainMenu) => <Languages {...props} context={context} />}
                      </LanguagesContext.Consumer> */}
                      <StoreContext.Consumer>
                        {(context: IApplicationState) => <Languages context={context} />}
                      </StoreContext.Consumer>
                    </div>
                  </div>
                </div>
                {PermissionsService.hasAccess(PERMISSION_LOGIN, ['update']) && (
                  <div className="sb-nav-sub-menu-wrapper">
                    <div className="sb-nav-sub-menu-item">
                      <ChangePassword />
                    </div>
                  </div>
                )}
                <div className="sb-nav-sub-menu-wrapper">
                  <div className="sb-nav-sub-menu-item">
                    <a
                      href="/"
                      className="subMenu menuLink"
                      onClick={handleLogout}
                      title={f({ id: 'menu.logout' })}
                    >
                      {f({ id: 'menu.logout' })}
                    </a>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </>
      )}
    </div>
  );
}
