import { atom } from 'recoil';
import { v4 as uuidv4 } from 'uuid';
import localforage from 'localforage';
import localForageEffect from './effects/localForageEffect';
import setGdprSettingsToSocketServerEffect from './effects/setGdprSettingsToSocketServerEffect';
import { ITranslation } from '../../Services/LanguageService';

export const setLoaderAtom = atom<boolean>({
  key: 'AppState.systemLoader',
  default: false,
});

export const onPageWithFormAtom = atom<boolean>({
  key: 'AppState.onPageWithForm',
  default: false,
  effects: [localForageEffect('onPageWithForm')],
});

// Identifies actual tab. Never changed this value. Use only default.
export const browserTabIdAtom = atom<string>({
  key: 'AppState.browserTabId',
  default: uuidv4(),
});

export const gdprSettingsAtom = atom<Record<string, any>>({
  key: 'AppState.gdprSettings',
  default: (await localforage.getItem('gdprSettings')) || [], // this is an empty array and is causing errors on SocketServer
  effects: [localForageEffect('gdprSettings'), setGdprSettingsToSocketServerEffect()],
});

export const localeStringsAtom = atom<ITranslation>({
  key: 'AppState.localeStrings',
  default: {},
});
