import { getRequest } from '../../Services/HttpService';
import JSONValidationService from '../../Services/JSONValidationService';
import MainMenuSchema from './MainMenuSchema.json';
import { IMenuItemsApiResponse } from './interfaces';

export const getMenuItems = async (): Promise<any> => {
  const response = await getRequest<IMenuItemsApiResponse>('/v1/menu-items/list', {});
  if (response) {
    JSONValidationService.validateSchema(MainMenuSchema, response, 'Main menu Data Schema');
    return response;
  }
  return false;
};

export const iconSwitcher = (cssName: string) => {
  const cssProp = ' cmd-font-stack-1x';

  switch (cssName) {
    case 'map':
      return `cmd-icon cmd-icon-map ${cssProp}`;
    case 'rideBook':
      return `cmd-icon cmd-icon-driving-overview ${cssProp}`;
    case 'reports':
      return `cmd-icon cmd-icon-report ${cssProp}`;
    case 'refuelings':
      return `cmd-icon cmd-icon-refueling-overview ${cssProp}`;
    case 'costs':
      return `cmd-icon cmd-icon-cost-overview ${cssProp}`;
    case 'corrections':
      return `cmd-icon cmd-icon-odometer ${cssProp}`;
    case 'settings':
      return `cmd-icon cmd-icon-cog ${cssProp}`;
    case 'alarms':
      return `cmd-icon cmd-icon-alarm-2 ${cssProp}`;
    case 'expenseImports':
      return `cmd-icon cmd-icon-import`;
    case 'bluecoins':
      return `cmd-icon cmd-icon-bluecoin`;

    default:
      return `cmd-icon cmd-icon-error ${cssProp}`;
  }
};
