import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import SearchService from '../../Services/SearchService';
import { IVehicles } from '../Vehicles/types';
import { vehiclesAtom } from '../../store/recoil/vehicles';
import { filter } from '../../Services/VehicleService';
import { filteredVehiclesAtom, filterStringAtom } from './NavigatorState';

export default function FilterNew() {
  const { formatMessage: f } = useIntl();
  const [filterString, setFilterString] = useRecoilState<string>(filterStringAtom);
  const setFilteredVehicles = useSetRecoilState<number[]>(filteredVehiclesAtom);
  const vehicles = useRecoilValue<IVehicles>(vehiclesAtom);

  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const handleFilterString = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const filterStringByValue = event.target.value;
    setFilterString(filterStringByValue);
  };

  const handleClearFilterString = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    setFilterString('');
    setFilteredVehicles(filter(vehicles, SearchService.removeAccent('')));
    inputRef.current?.focus();
  };

  React.useEffect(() => {
    setFilteredVehicles(filter(vehicles, SearchService.removeAccent(filterString)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterString, vehicles]);

  return (
    <div className="b-navigator-search">
      <input
        type="text"
        className="b-navigator-search-input"
        placeholder={f({ id: 'navigator.search' })}
        id="navigatorSearch"
        onChange={handleFilterString}
        value={filterString}
        ref={inputRef as React.LegacyRef<HTMLInputElement>}
      />
      <a
        href="#"
        title={f({ id: 'navigator.search' })}
        className="cmd-icons cmd-icon-search b-navigator-search-action"
      >
        {/* {test} */}
      </a>
      {filterString.length > 0 && (
        <a
          href="#"
          title=""
          className="cmd-icons cmd-icon-remove-sm b-navigator-search-action b-navigator-search-action--right"
          onClick={handleClearFilterString}
        >
          {/* {test} */}
        </a>
      )}
    </div>
    // <div className="navigator-search navigator-section">
    //   <div className="border-container">
    //     <input
    //       type="text"
    //       placeholder={f({ id: 'navigator.search' })}
    //       className="form-control search"
    //       id="navigatorSearch"
    //       onChange={handleFilterString}
    //       value={filterString}
    //     />
    //     <button type="button" onClick={handleClearFilterString}>
    //       <i className="clear cmd-icons cmd-icon-remove" />
    //     </button>
    //   </div>
    // </div>
  );
}
