import * as React from 'react';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import { CmdModal, CmdModalFooterButton } from '@commander-services/gui-components';
import ReactDOM from 'react-dom';
import { useQuery } from '@tanstack/react-query';
import TooltipComponent from '../TooltipComponent';
import { ILogData } from '../../interfaces/logRefuelings';
import * as CurrencyService from '../../Services/CurrencyService';
import { ICurrency } from '../../Services/CurrencyService';

interface IComponentProps {
  title: string;
  cancelCallback: () => void;
  data: ILogData[];
}

const VEHICLE_ID = 'vehicleId';
const CURRENCY_ID = 'currencyId';
const EXPENSE_TYPE_ID = 'expenseTypeId';
const ERROR_NAME = 'errors';

export default function ShowLogRefueling(props: IComponentProps): JSX.Element | null {
  const { formatMessage: f } = useIntl();
  const [filters, setFilters] = React.useState<string[]>([]);

  const { data: currencyData } = useQuery({
    queryKey: ['currencies'],
    queryFn: () => CurrencyService.getCurrencies(),
    staleTime: 9000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  React.useEffect(() => {
    const slider: any = document.querySelector('.js-table-drag');

    if (slider) {
      let isDown = false;
      let startX: number;
      let scrollLeft: number;
      slider.addEventListener('mousedown', (e: any) => {
        isDown = true;
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });
      slider.addEventListener('mouseleave', () => {
        isDown = false;
        slider.classList.remove('user-select-off');
      });
      slider.addEventListener('mouseup', () => {
        isDown = false;
        slider.classList.remove('user-select-off');
      });
      slider.addEventListener('mousemove', (e: any) => {
        if (!isDown) {
          return;
        }
        e.preventDefault();
        slider.classList.add('user-select-off');
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 3; // scroll-fast
        slider.scrollLeft = scrollLeft - walk;
      });
    }
  }, []);

  const handleCancel = () => {
    props.cancelCallback();
  };

  const handleFilters = (
    event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    const type = event.currentTarget.id;

    if (type) {
      const filterIndex = filters.indexOf(type);

      if (filterIndex > -1) {
        const newFilters = filters.filter((filterType: string) => filterType !== type);
        setFilters(newFilters);
      } else {
        const newFilters = [...filters, type];
        setFilters(newFilters);
      }
    }
  };

  const handleTableHead = (name: string): boolean => {
    if (
      name === VEHICLE_ID ||
      // name === CURRENCY_ID ||
      name === ERROR_NAME ||
      name === EXPENSE_TYPE_ID
    ) {
      return false;
    }
    return true;
  };

  const formatTableTitle = (item: ILogData, data: string) => {
    let convertedData;

    if (item[data]) {
      switch (data) {
        case 'accountingTs':
          convertedData = dayjs(item[data]).format('DD.MM.YYYY HH:mm');
          break;
        case 'type':
          if (item[data] === 'missing-data') {
            convertedData = f({ id: 'log.refueling.missing.data' });
          } else if (item[data] === 'duplicity') {
            convertedData = f({ id: 'log.refueling.duplicity' });
          } else if (item[data] === 'blacklisted') {
            convertedData = f({ id: 'log.refueling.blaclisted' });
          }
          break;

        default:
          convertedData = item[data];

          break;
      }
    }
    return convertedData;
  };

  const formatDataFromTable = (item: ILogData, data: string) => {
    let convertedData;
    const wordRule = 5;

    const sentence = typeof props.title === 'string' ? props.title : '';
    const words = sentence.replace(/([ ,;]+)/g, '$1§sep§').split('§sep§');

    if (item[data]) {
      switch (data) {
        case 'accountingTs':
          convertedData = dayjs(item[data]).format('DD.MM.YYYY HH:mm');
          break;
        case 'currencyId': {
          if (currencyData) {
            const currency = currencyData.find((curr: ICurrency) => curr.id === Number(item[data]));
            if (currency) {
              convertedData = currency.code;
              break;
            }
          }
          break;
        }
        case 'type':
          if (item[data] === 'missing-data') {
            convertedData = f({ id: 'log.refueling.missing.data' });
          } else if (item[data] === 'duplicity') {
            convertedData = f({ id: 'log.refueling.duplicity' });
          } else if (item[data] === 'blacklisted') {
            convertedData = f({ id: 'log.refueling.blaclisted' });
          }
          break;
        case 'vehicle':
          convertedData = `${(item[data] as string).substring(0, 2)}${(
            item[data] as string
          ).substring(2, 5)}${(item[data] as string).substring(5, 7)} `;

          break;

        default:
          if (typeof item[data] === 'string') {
            if (item[data].replace(/([ ,;]+)/g, '$1§sep§').split('§sep§').length > wordRule) {
              const dataLog = item[data]
                .replace(/([ ,;]+)/g, '$1§sep§')
                .split('§sep§')
                .slice(0, wordRule)
                .join(' ');

              convertedData = `${dataLog} ...`;
            } else {
              convertedData = item[data];
            }
          } else {
            convertedData = item[data];
          }
          break;
      }
    }

    return convertedData;
  };

  const wordRule = 5;
  const tableData = props.data;
  let data = tableData;
  if (tableData && filters.length !== 0) {
    data = tableData.filter((item: ILogData) => !filters.includes(item.type));
  }

  const buttons: CmdModalFooterButton[] = [
    {
      id: 'close-modal',
      type: 'button',
      className: 'e-button',
      title: f({ id: 'alarms.modal.rule.button.close' }),
      tooltip: f({ id: 'alarms.modal.recipient.close.tooltip' }),
      closeCallback: handleCancel,
    },
  ];

  const modalRoot = document.getElementById('modal-root');

  const modalContent: JSX.Element = (
    <CmdModal id="wizard-form-modal" width="55vw" title={props.title} footerButtons={buttons}>
      <div className="pb-0 mb-5" key="infoTableInModalSummary">
        <div className="row row--size-sm">
          <div className="col-auto e-checkbox mr-2 my-1">
            <label htmlFor="missing-data">
              <input
                id="missing-data"
                type="checkbox"
                className="mr-1"
                onChange={handleFilters}
                defaultChecked={true}
              />{' '}
              <span className="pl-5">{f({ id: 'log.refueling.missing.data' })}</span>
            </label>
          </div>
          <div className="col-auto e-checkbox mr-2 my-1">
            <label htmlFor="duplicity">
              <input
                id="duplicity"
                type="checkbox"
                className="mr-1"
                onChange={handleFilters}
                defaultChecked={true}
              />{' '}
              <span className="pl-5">{f({ id: 'log.refueling.duplicity' })}</span>
            </label>
          </div>
          <div className="col-auto e-checkbox mr-2 my-1">
            <label htmlFor="blacklisted">
              <input
                id="blacklisted"
                type="checkbox"
                className="mr-1"
                onChange={handleFilters}
                defaultChecked={true}
              />{' '}
              <span className="pl-5">{f({ id: 'log.refueling.blaclisted' })}</span>
            </label>
          </div>
        </div>
      </div>

      <div>
        <div className="table-responsive js-table-drag table-drag">
          <table className="table table-striped table-bordered table-listings table-listing-small m-0">
            <tbody>
              <tr className="bg-none hover-off height-md ">
                <th className="table-dropdown th-sticky" style={{ verticalAlign: 'middle' }}>
                  <div>{f({ id: 'log.refueling.type' })}</div>
                </th>
                <th className="table-dropdown th-sticky" style={{ verticalAlign: 'middle' }}>
                  <div>
                    {f({
                      id: 'log.refueling.error',
                    })}
                  </div>
                </th>
                <th className="table-dropdown th-sticky" style={{ verticalAlign: 'middle' }}>
                  <div>{f({ id: 'log.refueling.date' })}</div>
                </th>
                <th className="table-dropdown th-sticky" style={{ verticalAlign: 'middle' }}>
                  <div>{f({ id: 'log.refueling.identifier' })}</div>
                </th>
                <th className="table-dropdown th-sticky" style={{ verticalAlign: 'middle' }}>
                  <div>{f({ id: 'log.refueling.vehicle' })}</div>
                </th>
                <th className="table-dropdown th-sticky" style={{ verticalAlign: 'middle' }}>
                  <div>{f({ id: 'log.refueling.type' })}</div>
                </th>
                <th className="table-dropdown th-sticky " style={{ verticalAlign: 'middle' }}>
                  <div>{f({ id: 'log.refueling.amount' })}</div>
                </th>
                <th className="table-dropdown th-sticky" style={{ verticalAlign: 'middle' }}>
                  <div>{f({ id: 'log.refueling.total.price' })}</div>
                </th>
                <th className="table-dropdown th-sticky" style={{ verticalAlign: 'middle' }}>
                  <div>{f({ id: 'log.refueling.currency' })}</div>
                </th>
                <th className="table-dropdown th-sticky" style={{ verticalAlign: 'middle' }}>
                  <div>{f({ id: 'log.refueling.record' })}</div>
                </th>
              </tr>

              {data &&
                data.map((item: ILogData, i: number) => (
                  <tr
                    key={`table-row-${i}`}
                    className="bg-none hover-off height-md"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {Object.keys(item).map(
                      (name: string, index: number) =>
                        handleTableHead(name) && (
                          <td key={`table-columns-${index}`}>
                            {typeof item[name] === 'string' &&
                            item[name].replace(/([ ,;]+)/g, '$1§sep§').split('§sep§').length >
                              wordRule ? (
                              <TooltipComponent
                                id="refuelings-log"
                                firstItems={false}
                                overlay={
                                  <div
                                    style={{
                                      color: '#fff',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      fontSize: '11px',
                                    }}
                                  >
                                    {formatTableTitle(item, name)}
                                  </div>
                                }
                              >
                                {formatDataFromTable(item, name)}
                              </TooltipComponent>
                            ) : (
                              formatDataFromTable(item, name)
                            )}
                          </td>
                        )
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </CmdModal>
  );

  if (modalRoot) {
    return ReactDOM.createPortal(modalContent, modalRoot);
  }
  console.error('Modal root element is not defined.');
  return null;
}
