import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { isOnlinePanelOpenAtom, IVehicleOnlinePanels, onlinePanelAtom } from '../OnlinePanelState';
import { ICustomWidgetProps } from './Widget';
import RemoveButton from './RemoveButton';

export default function LpgWidget(props: ICustomWidgetProps): JSX.Element {
  const { formatMessage: f } = useIntl();
  const [widgetData, setWidgetData] = React.useState<boolean>(true);

  const vehicleId = useRecoilValue<number | false>(isOnlinePanelOpenAtom);
  const onlinePanelData = useRecoilValue<IVehicleOnlinePanels>(onlinePanelAtom);

  React.useMemo(() => {
    if (!props.isMockupMode) {
      const onlinePanel = onlinePanelData[Number(vehicleId)];

      if (onlinePanel && typeof vehicleId === 'number') {
        setWidgetData(!!onlinePanel.onLpg);
      } else {
        setWidgetData(false);
      }
    }
  }, [onlinePanelData, props.isMockupMode, vehicleId]);

  let widgetTooltip = widgetData
    ? f({ id: 'widget.lpg.tooltip.yes' })
    : f({ id: 'widget.lpg.tooltip.no' });
  if (!props.isInGrid && props.isMockupMode) {
    widgetTooltip = f({ id: 'onlineTemplates.subPage.section.widgets.tooltip' });
  }
  if (!props.isInGrid && props.isMockupMode) {
    widgetTooltip = f({ id: 'onlineTemplates.subPage.section.widgets.tooltip' });
  }

  const onDragStart = props.isInGrid ? undefined : props.onDragCallback;

  return (
    <div
      data-cy="lpg-widget"
      className={`b-widget b-widget--${props.data.rowSize}x${props.data.columnSize}
      widget-grid-name-${props.data.gridName} ${props.isInGrid ? '' : 'droppable-element'}`}
      draggable={!props.isInGrid}
      onDragStart={onDragStart}
      title={widgetTooltip}
      style={{ position: 'relative' }}
    >
      {props.isInGrid && (
        <RemoveButton
          dataCy="lpg-widget-in-grid"
          onRemoveCallback={props.onRemoveCallback}
          gridName={props.data.gridName}
        />
      )}
      {props.data.columnSize === 1 && props.data.rowSize === 1 && widgetData !== null ? (
        <i
          data-cy={`lpg-widget-${widgetData && !props.isMockupMode ? 'active' : ''}-mode`}
          className={`e-widget-icon cmd-icon cmd-icon-lpg ${
            widgetData && !props.isMockupMode ? 'text-green' : ''
          }`}
        />
      ) : (
        <i className="e-widget-icon cmd-icon cmd-icon-lpg" />
      )}
    </div>
  );
}
