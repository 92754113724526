import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';
import '../public/styles/tailwind.css';
import '../public/commander-scss/main.scss';
import Config from '../config';

const NODE_ENV_PRODUCTION = 'production';

if (process.env.NODE_ENV === NODE_ENV_PRODUCTION) {
  Sentry.init({
    dsn: Config.Sentry.dns,
    integrations: [new BrowserTracing()],
    environment: Config.Sentry.environment,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

if (process.env.NODE_ENV === NODE_ENV_PRODUCTION) {
  disableReactDevTools();
}

// export const store = configureStore((window as any).__INITIAL_STATE__);

const container = document.getElementById('app');
if (!container) throw new Error('Failed to find the root element');

const root = createRoot(container);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// if (module.hot) {
//   module.hot.accept();
// }
