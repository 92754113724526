import * as React from 'react';
import { saveAs } from 'file-saver';

import ApiService from '../../Services/ApiService';

function getIcon(type: string): string {
  let icon = '';
  const typeArr = type.split('/');
  if (typeArr.length > 1 && typeArr[1] === 'pdf') {
    icon = '-pdf';
  } else if (typeArr[0] !== 'application') {
    icon = `-${typeArr[0]}`;
  }
  return icon;
}

export interface IFile {
  path: string;
  name: string;
  size: number;
  mimeType: string;
}

interface IDownloadProps {
  file: IFile;
}

export default function Download({ file }: IDownloadProps): JSX.Element {
  const handleDownloadFile = async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();

    const response = await ApiService.download(file.path, file.mimeType);
    const contentDisposition = response.headers['content-disposition'];
    const contentType = response.headers['content-type'];

    if (response) {
      const filenameRegex = /filename="([^"]*)"/;
      const filenameMatch = contentDisposition.match(filenameRegex);
      const filename = filenameMatch ? filenameMatch[1] : file.name;

      const typeRegex = /([^;]*)/;
      const typeMatch = contentType.match(typeRegex);
      const type = typeMatch ? typeMatch[0] : file.mimeType;

      const blob = new Blob([response.data], { type });
      saveAs(blob, filename);
    }
  };

  return (
    <>
      {file && file.mimeType && (
        <i className={`cmd-icons cmd-icon-file${getIcon(file.mimeType)} fs-2 mr-2`} />
      )}
      <a href="/" onClick={handleDownloadFile} key={file.name} style={{ background: 'none' }}>
        {file.name}
      </a>
    </>
  );
}
