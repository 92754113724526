function EmptyCell() {
  return (
    <div
      className={`text-cell align-left`}
      style={{ flex: 1 }}
    >
      &nbsp;
    </div>
  );
}

export default EmptyCell;
