import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import CustomTooltip from '../CustomTooltip';
import useTooltip from '../hooks/useTooltip';
import { CanRefuelingsRecordRide } from '../../Refuelings/CanRefuelings/types';

interface RideCellProps {
  data: CanRefuelingsRecordRide;
  rideId: number;
  customerId: number;
  dateFormat?: string;
  tooltipEnabled?: boolean;
}

function RideCell({ rideId, customerId, dateFormat, data, tooltipEnabled = false }: RideCellProps) {
  const { formatMessage: f } = useIntl();
  const { hover, tooltipPos, textRef, handleMouseEnter, handleMouseLeave } = useTooltip();
  const [showTooltip, setShowTooltip] = useState(false);

  const formatString = dateFormat || 'D.M.YYYY H:mm:ss';

  const dateValue = React.useCallback(
    (value: string | null): string => {
      if (!value) {
        return '';
      }
      return dayjs(value).format(formatString);
    },
    [formatString]
  );

  const value = React.useMemo(() => {
    return data && data.anonymized && !data.timestamp
      ? f({ id: 'canRefuelings.privateRide' })
      : `${dateValue(data.timestamp)}, ${data.address}`;
  }, [data, dateValue, f]);

  useEffect(() => {
    const isTextTruncated = () => {
      if (!textRef.current) return false;

      return textRef.current.offsetWidth < textRef.current.scrollWidth;
    };

    setShowTooltip(isTextTruncated());
  }, [value, textRef]);

  return (
    <div
      className="text-cell align-left"
      style={{ flex: 1 }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <CustomTooltip
        content={value}
        show={hover && (showTooltip || tooltipEnabled)}
        position={tooltipPos}
      >
        <div className="text-cell" ref={textRef}>
          {!data.anonymized! ? (
            <a href={`/trip-map/${customerId}-${rideId}`} target="_blank" rel="noopener noreferrer">
              {value}
            </a>
          ) : (
            value
          )}
        </div>
      </CustomTooltip>
    </div>
  );
}

export default RideCell;
