/* eslint-disable no-return-assign */
import dayjs from 'dayjs';
import { FormikErrors, FormikValues } from 'formik';
import { TInputValue, IValidationResponse } from './FormFieldValidatorService';

export type TValidationRuleParam =
  | 'required'
  | 'min'
  | 'max'
  | 'minLength'
  | 'maxLength'
  | 'regexp'
  | 'assertTrue'
  | 'extensions'
  | 'enum'
  | 'equals'
  | 'requires'
  | 'choiseInList'
  | 'mimeTypes'
  | 'minLength'
  | 'minDate'
  | 'maxDate'
  | 'minTime'
  | 'maxTime'
  | 'requiredAlt'
  | 'maxDate'
  | 'minDate';

export interface IValidationRule {
  param: TValidationRuleParam;
  value: any; // string | string[] | number | number[] | boolean | boolean[];
  message: string;
}

export interface IValidationApiResponse {
  [indexId: string]: IValidationRule[];
}

export interface IInput {
  disabled: boolean;
  indexId: string;
  placeholder: string;
  readonly: boolean;
  rules: IValidationRule[];
  title: string;
  type: string;
  value: any;
  values?: any;
  multiple?: boolean;
  subtype?: string;
  sendOnChange?: () => void;
  section?: string | null;
  inputGroup?: string | null;
  groupIndex?: number;
  firstDisabled?: boolean;
  options?: any;
}

const FormikValidatorService = {
  /** Validate Formik values by validation API response */
  validateByValidationApiResponse(
    values: FormikValues,
    validationApiResponse: IValidationApiResponse
  ): FormikErrors<any> {
    const errors: FormikErrors<FormikValues> = {};
    const fields = Object.keys(values);
    const validationFields = Object.keys(validationApiResponse);

    fields.map((fieldName: string) => {
      if (validationApiResponse[fieldName]) {
        let errorMessages = '';
        let errorKeys: string[] = [];
        validationApiResponse[fieldName].map((rule: IValidationRule) => {
          const fieldValidation = this.validateRule(
            values[fieldName],
            rule,
            values,
            validationFields[fieldName],
            errors
          );
          if (!fieldValidation.isValid && fieldValidation.errorMessage) {
            errorMessages += `${fieldValidation.errorMessage}<br />`;
          }
          if (!fieldValidation.isValid && fieldValidation.errorKeysArray) {
            errorKeys = errorKeys.concat(fieldValidation.errorKeysArray);
          }
        });
        if (errorMessages) {
          if (errorKeys.length > 0) {
            errorMessages += `errorKeys:${errorKeys}<br />`;
          }
          errors[fieldName] = errorMessages;
        }
      }
    });
    return errors;
  },

  /** Validate Formik values by input rules */
  validate(values: FormikValues, inputs: IInput[]): FormikErrors<FormikValues> {
    const errors: FormikErrors<FormikValues> = {};
    const fields = Object.keys(values);

    fields.map((fieldName: string) => {
      const field = inputs.find((input: IInput) => input.indexId === fieldName);
      if (field) {
        let errorMessages = '';
        let errorKeys: string[] = [];
        field.rules.map((rule: IValidationRule) => {
          const validation = this.validateRule(
            values[fieldName],
            rule,
            values,
            field.rules,
            errors
          );
          if (!validation.isValid && validation.errorMessage) {
            errorMessages += `${validation.errorMessage}<br />`;
          }
          if (!validation.isValid && validation.errorKeysArray) {
            errorKeys = errorKeys.concat(validation.errorKeysArray);
          }
        });
        if (errorMessages) {
          if (errorKeys.length > 0) {
            errorMessages += `errorKeys:${errorKeys}<br />`;
          }
          errors[fieldName] = errorMessages;
        }
      }
    });
    return errors;
  },

  validateRegexp(inputValue: TInputValue, regexp: string): boolean {
    const regExpForRegExp = /\/(.*)\/(\w*)/;
    const reg = regexp.match(regExpForRegExp);

    if (!inputValue) {
      return true;
    }

    if (reg && reg[1] && !reg[2]) {
      const result = RegExp(reg[1]).test(inputValue as string);
      // console.log('Result (regexp wihtout param): ', result);
      return result;
    }

    if (reg && reg[1] && reg[2]) {
      const result = RegExp(reg[1], reg[2]).test(inputValue as string);
      // console.log('Result (regexp with param): ', result);
      return result;
    }

    console.warn('Wrong regexp: ', reg);
    return false;

    // return RegExp
    // const firstChar = regexp.charAt(0);
    // const secondChar = regexp.charAt(1);
    // let result: any = null;
    // if (firstChar === '^') {
    //   result = (inputValue as string).match(regexp);
    //   console.log('Result 1: ', result);
    // } else if (
    //   regexp === `${/^((?!\((engine_rpm)|(throttle_position)|(vehicle_speed)|(waypoint)\)).)*$/s}`
    // ) {
    //   result = RegExp(regexp).test(inputValue as string);
    //   console.log('Result 4: ', result);
    // } else {
    //   const expression = regexp.slice(1, -1); // Remove "/" character from begining and end of the regexp string
    //   result = RegExp(expression).test(inputValue as string);
    //   console.log('Result 2: ', result);
    // }
    // console.log('Result 3: ', result);
    // if (!result) {
    //   return false;
    // }
    // return result.length > 0 || result === true;
  },

  validateIsRequired(inputValue: TInputValue, rule: IValidationRule): boolean {
    if (
      ((!inputValue && inputValue !== 0) ||
        (typeof inputValue === 'object' &&
          !(inputValue instanceof Date) &&
          inputValue !== null &&
          Object.keys(inputValue).length === 0) ||
        (Array.isArray(inputValue) && inputValue.length === 0)) &&
      rule.value
    ) {
      return false;
    }
    return true;
  },

  validateMinLength(inputValue: TInputValue, rule: IValidationRule): boolean {
    if (inputValue !== null && (inputValue as string).length < rule.value) {
      return false;
    }
    return true;
  },

  validateMaxLength(inputValue: TInputValue, rule: IValidationRule): boolean {
    if (rule.value !== null) {
      if (inputValue !== null && inputValue !== undefined && (inputValue as string).length > rule.value) {
        return false;
      }
      return true;
    }
    return true;
  },

  validateExtension(inputValue: TInputValue, rule: IValidationRule): boolean {
    if (
      Array.isArray(rule.value) &&
      typeof inputValue === 'string' &&
      !(rule.value as string[]).includes(inputValue)
    ) {
      return false;
    }
    return true;
  },

  validateTimeFrom(timeFrom: Date, timeTo: Date): boolean {
    // const fromTime = dayjs(`2000-01-01 ${timeFrom}`);
    // const toTime = dayjs(`2000-01-01 ${timeTo}`);
    // const mins = toTime.diff(fromTime, 'minute', true);
    // const totalHours = Number(mins / 60);
    // return totalHours >= 0;
    return dayjs(timeTo).isAfter(dayjs(timeFrom));
  },

  validateRule(
    inputValue: TInputValue,
    rule: IValidationRule,
    inputValues: FormikValues,
    rules: IValidationRule[],
    errors: FormikErrors<FormikValues>
  ): IValidationResponse {
    const validationResponse: IValidationResponse = {
      isValid: false,
    };

    switch (rule.param) {
      case 'required': {
        if (!this.validateIsRequired(inputValue, rule)) {
          validationResponse.errorMessage = rule.message;
        } else {
          validationResponse.isValid = true;
        }
        break;
      }
      case 'assertTrue': {
        if (!rule.value) {
          validationResponse.errorMessage = rule.message;
        } else {
          validationResponse.isValid = true;
        }
        break;
      }
      case 'minLength': {
        if (!this.validateMinLength(inputValue, rule) && inputValue !== '') {
          validationResponse.errorMessage = rule.message;
        } else {
          validationResponse.isValid = true;
        }
        break;
      }
      case 'min': {
        // if (rule.value !== null) {
        if (inputValue && typeof inputValue === 'object') {
          const errorArray: string[] = [];
          if (Object.values(inputValue).length > 0) {
            Object.keys(inputValue).forEach((key: any) => {
              if (inputValue[key] < rule.value) {
                errorArray.push(key);
              }
            });
          }
          if (errorArray.length > 0) {
            validationResponse.errorMessage = rule.message;
            validationResponse.errorKeysArray = errorArray;
          } else {
            validationResponse.isValid = true;
          }
          break;
        } else if (this.isFieldRequired(rules) && inputValue !== '') {
          if (inputValue < rule.value) {
            validationResponse.errorMessage = rule.message;
          } else {
            validationResponse.isValid = true;
          }
          break;
        } else {
          if (inputValue === '') {
            validationResponse.isValid = true;
            break;
          }
          if (inputValue < rule.value) {
            validationResponse.errorMessage = rule.message;
          } else {
            validationResponse.isValid = true;
          }
          break;
        }
        // }
        // validationResponse.isValid = true;
        // break;
      }
      case 'maxLength': {
        if (!this.validateMaxLength(inputValue, rule) && inputValue !== '') {
          validationResponse.errorMessage = rule.message;
        } else {
          validationResponse.isValid = true;
        }
        break;
        // if (rule.value !== null) {
        //   if ((inputValue as string).length > rule.value) {
        //     validationResponse.errorMessage = rule.message;
        //   } else {
        //     validationResponse.isValid = true;
        //   }
        //   break;
        // }
        // validationResponse.isValid = true;
        // break;
      }
      case 'max': {
        if (typeof inputValue === 'object') {
          const errorArray: string[] = [];
          if (inputValue && Object.values(inputValue).length > 0) {
            Object.keys(inputValue).forEach((key: any) => {
              if (inputValue[key] > rule.value) {
                errorArray.push(key);
              }
            });
          }
          if (errorArray.length > 0) {
            validationResponse.errorMessage = rule.message;
            validationResponse.errorKeysArray = errorArray;
          } else {
            validationResponse.isValid = true;
          }
          break;
        } else {
          if (rule.value !== null) {
            if (inputValue !== '' && inputValue > rule.value) {
              validationResponse.errorMessage = rule.message;
            } else {
              validationResponse.isValid = true;
            }
            break;
          }
          validationResponse.isValid = true;
          break;
        }
      }
      case 'regexp': {
        if (this.validateRegexp(inputValue, rule.value as string)) {
          validationResponse.isValid = true;
        }
        validationResponse.errorMessage = rule.message || 'Error.';
        break;
      }
      case 'extensions': {
        if (!this.validateExtension(inputValue, rule)) {
          validationResponse.errorMessage = rule.message;
        } else {
          validationResponse.isValid = true;
        }
        // if (
        //   Array.isArray(rule.value) &&
        //   typeof inputValue === 'string' &&
        //   !(rule.value as string[]).includes(inputValue)
        // ) {
        //   validationResponse.errorMessage = rule.message;
        // } else {
        //   validationResponse.isValid = true;
        // }
        break;
      }
      case 'equals': {
        if (inputValue !== inputValues[rule.value as string]) {
          validationResponse.errorMessage = rule.message;
        } else {
          validationResponse.isValid = true;
        }
        break;
      }
      case 'requires': {
        if (Array.isArray(rule.value)) {
          rule.value.forEach((value: string | number | boolean) => {
            if (!inputValues[value as string]) {
              validationResponse.errorMessage = rule.message;
            } else {
              validationResponse.isValid = true;
            }
          });
        }
        break;
      }
      case 'choiseInList': {
        if (
          inputValue &&
          Array.isArray(rule.value) &&
          typeof inputValue === 'number' &&
          !(rule.value as number[]).includes(inputValue as number)
        ) {
          validationResponse.errorMessage = rule.message;
        } else {
          validationResponse.isValid = true;
        }
        break;
      }
      case 'mimeTypes': {
        // TODO: Doplnit validaciu MIME TYPE suboru
        validationResponse.isValid = true;
        break;
      }
      case 'requiredAlt': {
        let isValid = true;

        const validFields: string[] = [];
        const invalidFields: string[] = [];

        rule.value.map((fieldName: string) => {
          if (
            ((!inputValues[fieldName] && inputValues[fieldName] !== 0) ||
              (typeof inputValues[fieldName] === 'object' &&
                !(inputValues[fieldName] instanceof Date) &&
                inputValues[fieldName] !== null &&
                Object.keys(inputValues[fieldName]).length === 0) ||
              (Array.isArray(inputValues[fieldName]) && inputValues[fieldName].length === 0)) &&
            rule.value
          ) {
            isValid = false;
            invalidFields.push(fieldName);
          } else {
            validFields.push(fieldName);
          }
        });
        if (validFields.length > 0) {
          rule.value.map((fieldName: string) => {
            delete errors[fieldName];
          });
          validationResponse.isValid = isValid;
        } else {
          validationResponse.errorMessage = rule.message;
        }
        break;
      }
      default: {
        // validationResponse.errorMessage = `Wrong validation rule: ${rule.param}`;
        break;
      }
    }

    return validationResponse;
  },

  isFieldRequired(rules: IValidationRule[] | undefined): boolean {
    if (!rules) {
      return false;
    }
    const required = rules.filter((rule: IValidationRule) => rule.param === 'required');
    return required.length !== 0;
  },
};

export default FormikValidatorService;
