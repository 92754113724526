/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
import { orderBy } from 'lodash';
import { CmdModal, CmdModalFooterButton } from '@commander-services/gui-components';
import * as React from 'react';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import TooltipComponent from '../../../../TooltipComponent';
import { WORD_RULE } from '.';

interface IFuelCardList {
  provider: string | null;
  cardNumber: string;
  identifier: string;
  validUntil: Date;
}

interface ISelectFuelCardsModalProps {
  fuelCards: IFuelCardList[];
  handleCloseModal: () => void;
  title: string;
}

interface ISortByColumn {
  column: string;
  type: 'asc' | 'desc';
}

const COLUMN_PROVIDER_NAME = 'provider';
const COLUMN_CARD_NUMBER = 'cardNumber';
const COLUMN_IDENTIFIER = 'identifier';
const COLUMN_VALID_UNTIL = 'validUntil';
const ASC = 'asc';
const DESC = 'desc';

export default function FuelCardsList(props: ISelectFuelCardsModalProps): JSX.Element {
  const { formatMessage: f } = useIntl();
  const [sortedFuelCards, setSortedFuelCards] = React.useState<IFuelCardList[]>(props.fuelCards);
  const [sortByColumn, setSortByColumn] = React.useState<ISortByColumn>({
    column: '',
    type: 'asc',
  });

  React.useEffect(() => {
    const newSortedRides = orderBy(sortedFuelCards, sortByColumn.column, sortByColumn.type);
    setSortedFuelCards(newSortedRides);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortByColumn]);

  const buttons: CmdModalFooterButton[] = [
    {
      id: 'close-select-rides-modal',
      type: 'button',
      title: f({ id: 'waypoint.confirm.dialog.cancel' }), // Vulgarny quickhack. V aktualnom case tejto zmeny, mi nesli zmenit preklady, lebo Anton tam ma limit na upload (440 nieco kb) a uz samotne preklady ho prekracuju
      className: 'e-button e-button--gray',
      closeCallback: props.handleCloseModal,
    },
  ];

  const handleSort = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    const sortColumn = event.currentTarget.getAttribute('data-sort-column');
    const sortType = event.currentTarget.getAttribute('data-sort-type');
    if (sortColumn && sortType) {
      setSortByColumn((oldState: ISortByColumn) => {
        const newState: ISortByColumn = {
          ...oldState,
        };
        if (oldState.column !== sortColumn) {
          newState.column = sortColumn;
          newState.type = ASC;
        }
        if (oldState.column === sortColumn && sortType === ASC) {
          newState.type = DESC;
        }
        if (oldState.column === sortColumn && sortType === DESC) {
          newState.type = ASC;
        }
        return newState;
      });
    }
  };

  const getSortType = (column: string): string => {
    if (column === sortByColumn.column) {
      return sortByColumn.type === ASC ? ASC : DESC;
    }
    return ASC;
  };

  const getSortIconClass = (column: string): string => {
    if (sortByColumn && sortByColumn.column === column) {
      if (sortByColumn.type === ASC) {
        return 'cmd-icon-sorting-a-z text-active';
      }
      return 'cmd-icon-sorting-z-a text-active';
    }
    return 'cmd-icon-sorting-a-z text-gray';
  };

  const renderValue = (title: string | null) => {
    if (title) {
      const words = title.replace(/([ ,;]+)/g, '$1§sep§').split('§sep§');
      return words && words.length > WORD_RULE ? (
        <TooltipComponent
          id={`vehicle-list-${title}`}
          firstItems={true}
          overlay={<div style={{ color: '#fff', textAlign: 'left' }}>{title}</div>}
        >
          <div>{words.slice(0, WORD_RULE).join(' ')} ...</div>
        </TooltipComponent>
      ) : (
        title
      );
    }
    return '';
  };

  return (
    <CmdModal id="select-rides-modal" title={props.title} footerButtons={buttons} size="xl">
      <div className="b-select-ride-table" style={{ maxHeight: '350px', overflowY: 'auto' }}>
        <div className="table-responsive height-auto">
          <table className="table table-striped table-bordered table-listings m-0">
            <thead>
              <tr>
                <th className="th-sticky-top">
                  {f({ id: 'table_list.columns.fuelCards.fuelCardProviderId' })}
                  <a
                    id="sort-provider-name"
                    href="#"
                    title={
                      sortByColumn.type === ASC
                        ? f({ id: 'table.filter.desc' })
                        : f({ id: 'table.filter.asc' })
                    }
                    onClick={handleSort}
                    data-sort-column={COLUMN_PROVIDER_NAME}
                    data-sort-type={getSortType(COLUMN_PROVIDER_NAME)}
                    className={`filter cmd-icon ${getSortIconClass(
                      COLUMN_PROVIDER_NAME
                    )} text-decoration-none position-relative ml-2`}
                  />
                </th>
                <th className="th-sticky-top">
                  {f({ id: 'form.inputs.fuelCards.cardNumber' })}
                  <a
                    id="sort-card-number"
                    href="#"
                    title={
                      sortByColumn.type === ASC
                        ? f({ id: 'table.filter.desc' })
                        : f({ id: 'table.filter.asc' })
                    }
                    onClick={handleSort}
                    data-sort-column={COLUMN_CARD_NUMBER}
                    data-sort-type={getSortType(COLUMN_CARD_NUMBER)}
                    className={`filter cmd-icon ${getSortIconClass(
                      COLUMN_CARD_NUMBER
                    )} text-decoration-none position-relative ml-2`}
                  />
                </th>
                <th className="th-sticky-top" title={f({ id: 'form.inputs.fuelCards.identifier' })}>
                  {f({ id: 'form.inputs.fuelCards.identifier' })}
                  <a
                    id="sort-identifier"
                    href="#"
                    title={
                      sortByColumn.type === ASC
                        ? f({ id: 'table.filter.desc' })
                        : f({ id: 'table.filter.asc' })
                    }
                    onClick={handleSort}
                    data-sort-column={COLUMN_IDENTIFIER}
                    data-sort-type={getSortType(COLUMN_IDENTIFIER)}
                    className={`filter cmd-icon ${getSortIconClass(
                      COLUMN_IDENTIFIER
                    )} text-decoration-none position-relative ml-2`}
                  />
                </th>
                <th
                  className="th-sticky-top"
                  title={f({ id: 'table_list.columns.fuelCards.validUntil' })}
                >
                  {f({ id: 'table_list.columns.fuelCards.validUntil' })}
                  <a
                    id="sort-valid-until"
                    href="#"
                    title={
                      sortByColumn.type === ASC
                        ? f({ id: 'table.filter.desc' })
                        : f({ id: 'table.filter.asc' })
                    }
                    onClick={handleSort}
                    data-sort-column={COLUMN_VALID_UNTIL}
                    data-sort-type={getSortType(COLUMN_VALID_UNTIL)}
                    className={`filter cmd-icon ${getSortIconClass(
                      COLUMN_VALID_UNTIL
                    )} text-decoration-none position-relative ml-2`}
                  />
                </th>
              </tr>
            </thead>

            <tbody>
              {sortedFuelCards.map((fuelCard: IFuelCardList) => {
                return (
                  <tr key={`select-ride-${fuelCard.cardNumber}`}>
                    <td className="align-middle">{renderValue(fuelCard.provider)}</td>
                    <td className="align-middle">{renderValue(fuelCard.cardNumber)}</td>
                    <td className="align-middle">{renderValue(fuelCard.identifier)}</td>
                    <td className="align-middle">
                      {renderValue(dayjs(fuelCard.validUntil).format('DD.MM.YYYY'))}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </CmdModal>
  );
}
