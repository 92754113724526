import * as React from 'react';
import { Slovak } from 'flatpickr/dist/l10n/sk';
import { Czech } from 'flatpickr/dist/l10n/cs';
import { english } from 'flatpickr/dist/l10n/default';
import enJson from '../lang/en.json';
import skJson from '../lang/sk.json';
import { ILanguageMessages } from '../interfaces/language';
import * as LanguageService from '../Services/LanguageService';

export const messages: ILanguageMessages = {
  en: enJson,
  sk: skJson,
};

export const defaultLanguage = LanguageService.getDefaultLanguage();

export const flatpickrLanguages = {
  en: english,
  sk: Slovak,
  cz: Czech,
};

export type LanguageContextProps = {
  language: string;
  updateValue?: (key: string, val: string) => void;
  state?: any;
};

export const LanguagesContext = React.createContext<LanguageContextProps>({
  language: defaultLanguage,
});
