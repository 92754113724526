import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useLocation } from 'wouter';
import Config from '../../../config';
import showMessage from '../Toastr/ToastService';
import { IVehicleData, IVehicleDriver, TVehicleStatus } from '../Vehicles/types';
import { selectedVehiclesAtom } from '../../store/recoil/vehicles';
import { vehicleDataByIdSelector } from '../../store/recoil/vehiclesData';
import { toggleSelectVehicle } from '../../Services/VehicleService';
import { isOnlinePanelOpenAtom } from '../OnlinePanel/OnlinePanelState';
import {
  autofocusAtom,
  isRidePlayerOpenAtom,
  vehicleFocusAtom,
} from '../OnlineMapNew/OnlineMapState';
import {
  getNavigatorStatusColor,
  getNavigatorStatusColorStroke,
  getPath,
  getPathStroke,
} from './NavigatorIconService';
import { isMinimalizedCarsIconsAtom } from './NavigatorState';

interface IVehicleStatusClass {
  [key: string]: string;
}

interface IVehicleTypeClass {
  [key: string]: string;
}

interface INavigatorVehicleProps {
  id: number;
  name: string;
  licenceNumber: string;
  driver: IVehicleDriver;
  status: TVehicleStatus;
  selected: boolean;
  vehicleTypeId: number;
  customerId?: number;
  groupId?: number;
}

export default function VehicleNew(props: INavigatorVehicleProps) {
  const setSelectedVehicles = useSetRecoilState(selectedVehiclesAtom);
  const vehicleData = useRecoilValue<IVehicleData | null>(vehicleDataByIdSelector(props.id));
  const selectedVehicles = useRecoilValue(selectedVehiclesAtom);
  const [isOnlinePanelOpen, setIsOnlinePanelOpen] = useRecoilState<number | false>(
    isOnlinePanelOpenAtom
  );
  const [isRidePlayerOpen, setIsRidePlayerOpen] = useRecoilState<number | boolean>(
    isRidePlayerOpenAtom
  );
  const { formatMessage: f } = useIntl();
  const setVehicleFocus = useSetRecoilState<boolean>(vehicleFocusAtom);
  const setAutofocus = useSetRecoilState<boolean>(autofocusAtom);
  const isMinimalizedCarsIcons = useRecoilValue<boolean>(isMinimalizedCarsIconsAtom);
  const [location, navigate] = useLocation();

  const getStatusClassName = (status: TVehicleStatus) =>
    (Config.Vehicle.statusClass as IVehicleStatusClass)[status] || null;

  const getVehicleTypeClassName = (vehicleTypeId: number) =>
    (Config.Vehicle.typeClass as IVehicleTypeClass)[vehicleTypeId] || null;

  const handleSelectVehicle = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.ChangeEvent<HTMLInputElement>
  ): boolean => {
    event.preventDefault();
    const vehicleId = event.currentTarget.getAttribute('data-vehicle-id');

    if (vehicleId && isRidePlayerOpen && !selectedVehicles.includes(Number(vehicleId))) {
      showMessage(
        'navigator.toastr.selectVehicleDisabled.title',
        'navigator.toastr.selectVehicleDisabled.message',
        'warning',
        4000
      );
      return false;
    }
    if (
      (vehicleId && !isRidePlayerOpen) ||
      (Number(vehicleId) === Number(isOnlinePanelOpen) && isRidePlayerOpen)
    ) {
      if (
        Config.selectedVehiclesLimit.enabled &&
        selectedVehicles.length > Config.selectedVehiclesLimit.limit
      ) {
        showMessage(
          'navigator.toastr.selectedVehicleLimit.title',
          'navigator.toastr.selectedVehicleLimit.message',
          'warning',
          null,
          { limit: Config.selectedVehiclesLimit.limit }
        );
        return false;
      }
      // Store selected vehicle to recoil
      setSelectedVehicles((state: number[]) => toggleSelectVehicle(state, Number(vehicleId)));

      if (!props.selected) {
        setIsOnlinePanelOpen(false);
      }
    }
    return true;
  };

  const handleSelectVehicleWithOnlinePanel = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.ChangeEvent<HTMLInputElement>
  ): boolean => {
    event.preventDefault();
    const vehicleId = event.currentTarget.getAttribute('data-vehicle-id');
    if (isRidePlayerOpen && String(isRidePlayerOpen) === String(vehicleId)) {
      setIsRidePlayerOpen(false);
      setIsOnlinePanelOpen(false);
      return false;
    }
    const openOnlinePanel = event.currentTarget.getAttribute('data-open-online-panel');

    if (vehicleId && isRidePlayerOpen && !selectedVehicles.includes(Number(vehicleId))) {
      showMessage(
        'navigator.toastr.selectVehicleDisabled.title',
        'navigator.toastr.selectVehicleDisabled.message',
        'warning',
        4000
      );
      return false;
    }
    if (
      (vehicleId && !isRidePlayerOpen) ||
      (Number(vehicleId) === Number(isOnlinePanelOpen) && isRidePlayerOpen)
    ) {
      if (
        Config.selectedVehiclesLimit.enabled &&
        selectedVehicles.length > Config.selectedVehiclesLimit.limit
      ) {
        showMessage(
          'navigator.toastr.selectedVehicleLimit.title',
          'navigator.toastr.selectedVehicleLimit.message',
          'warning',
          null,
          { limit: Config.selectedVehiclesLimit.limit }
        );
        return false;
      }

      // Open online panel
      if (openOnlinePanel && !selectedVehicles.includes(Number(vehicleId))) {
        // Store selected vehicle to recoil
        setSelectedVehicles((state: number[]) => toggleSelectVehicle(state, Number(vehicleId)));
        if (vehicleData?.position) {
          setIsOnlinePanelOpen(Number(vehicleId));
        }
        setAutofocus(false);
        setVehicleFocus(true);
      } else if (
        selectedVehicles.includes(Number(vehicleId)) &&
        typeof isOnlinePanelOpen === 'number' &&
        isOnlinePanelOpen === Number(vehicleId)
      ) {
        setIsOnlinePanelOpen(false);
      } else if (
        selectedVehicles.includes(Number(vehicleId)) &&
        typeof isOnlinePanelOpen === 'number' &&
        isOnlinePanelOpen !== Number(vehicleId)
      ) {
        if (vehicleData?.position) {
          setIsOnlinePanelOpen(Number(vehicleId));
        }
        setAutofocus(false);
        setVehicleFocus(true);
      } else {
        if (vehicleData?.position) {
          setIsOnlinePanelOpen(Number(vehicleId));
        }
        setAutofocus(false);
        setVehicleFocus(true);
      }
    }
    return true;
  };

  let classList = `cmd-icons customer-vehicle-checkbox-${props.customerId} ${
    props.selected ? 'cmd-icon-check' : ''
  }`;
  if (props.groupId) {
    classList = `cmd-icons group-vehicle-checkbox-${props.groupId} ${
      props.selected ? 'cmd-icon-check' : ''
    }`;
  }

  let statusTitle = '';
  if (getStatusClassName(props.status)) {
    statusTitle = f({
      id: `navigator.status.${getStatusClassName(props.status)}`,
    });
    const vehicleClassName = getVehicleTypeClassName(props.vehicleTypeId);
    if (vehicleClassName) {
      const localeString = `navigator.car.${vehicleClassName.replace('icon-', '')}`;
      statusTitle += ` ${f({
        id: localeString,
      })}`;
    }
  }

  const svg = (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      data-cy={`navigator-icon-${props.vehicleTypeId}-${props.status}`}
      className="b-navigator-listing-item-vehicle-icon"
    >
      <path
        id="path"
        d={props.id === Config.mucha ? getPathStroke(999) : getPathStroke(props.vehicleTypeId)}
        fill={`#${getNavigatorStatusColorStroke(
          props.status,
          Number(props.id) === Number(isOnlinePanelOpen) && location.includes('maps')
        )}`}
        fillOpacity="1"
        stroke="none"
      />
      <path
        id="path"
        d={props.id === Config.mucha ? getPath(999) : getPath(props.vehicleTypeId)}
        fill={`#${getNavigatorStatusColor(
          props.status,
          Number(props.id) === Number(isOnlinePanelOpen) && location.includes('maps')
        )}`}
        fillOpacity="1"
        stroke="none"
      />
    </svg>
  );

  const getMinimalizedStatusIcon = (vehicleStatus: TVehicleStatus): string => {
    switch (vehicleStatus) {
      case 'DRIVING':
        return 'green';
      case 'STOP':
        return 'red';
      case 'PRIVATE':
        return 'dark-gray';
      case 'NO_GSM':
        return 'yellow';
      case 'NO_GPS':
        return 'blue';
      default:
        return 'gray';
    }
  };

  if (isMinimalizedCarsIcons) {
    return (
      <div className="b-navigator-listing" style={{ height: 'auto' }}>
        <div
          className={`b-navigator-listing-item b-navigator-listing-item--collapsed ${
            props.selected && Number(props.id) === Number(isOnlinePanelOpen)
              ? 'b-navigator-listing-item--active '
              : ''
          } row no-gutters align-items-center`}
          title={`ID: ${props.id} - ${props.name} ${
            props.driver && props.driver.name ? `- ${props.driver.name}` : ''
          }`}
        >
          <div
            className="col b-navigator-listing-item-vehicle"
            data-vehicle-id={props.id}
            data-open-online-panel="1"
            onClick={handleSelectVehicle}
            data-cy={`navigator-vehicle-${props.id}`}
          >
            <div className="row no-gutters align-items-center">
              <div className="col-auto pr-4">
                <div className="e-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      checked={props.selected}
                      onChange={handleSelectVehicle}
                    />
                    <span />
                  </label>
                </div>
              </div>
              <div
                className="col"
                id={String(props.id)}
                title={`ID: ${props.id} - ${props.name} ${
                  props.driver && props.driver.name ? `- ${props.driver.name}` : ''
                }`}
              >
                <div
                  className="text-truncate b-navigator-listing-item-vehicle-title"
                  style={{ width: '150px' }}
                  data-cy="navigator-carName"
                >
                  <strong> {props.name}</strong>
                </div>
                <div
                  className="text-truncate b-navigator-listing-item-vehicle-driver"
                  data-cy={`navigator-driverName-${props.driver.id}`}
                >
                  {props.driver.name}
                </div>
              </div>
            </div>
          </div>

          <div
            style={{ cursor: 'pointer' }}
            className="col-auto text-center b-navigator-listing-item-spz"
            title={`ID: ${props.id} - ${props.name} ${
              props.driver && props.driver.name ? `- ${props.driver.name}` : ''
            }`}
            onClick={handleSelectVehicleWithOnlinePanel}
            data-vehicle-id={props.id}
            data-open-online-panel="1"
            data-cy={`navigator-vehicle-online-panel-${props.id}`}
          >
            <div className="d-flex align-items-center justify-content-end">
              <span
                className="text-truncate d-inline-block mr-1"
                style={{ width: '60px' }}
                data-cy="navigator-licenceNumber"
              >
                {props.licenceNumber}
              </span>
              <span
                className={`b-navigator-listing-item-vehicle-state b-navigator-listing-item-vehicle-state--${
                  Number(props.id) === Number(isOnlinePanelOpen) && location.includes('maps')
                    ? 'solid'
                    : 'full'
                }-${getMinimalizedStatusIcon(props.status)}`}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="b-navigator-listing" style={{ height: 'auto' }}>
      <div
        className={`b-navigator-listing-item ${
          props.selected && Number(props.id) === Number(isOnlinePanelOpen)
            ? 'b-navigator-listing-item--active'
            : ''
        } row no-gutters align-items-center`}
        title={`ID: ${props.id} - ${props.name} ${
          props.driver && props.driver.name ? `- ${props.driver.name}` : ''
        }`}
      >
        <div
          className="col b-navigator-listing-item-vehicle"
          onClick={handleSelectVehicle}
          data-vehicle-id={props.id}
          data-open-online-panel="1"
          data-cy={`navigator-vehicle-${props.id}`}
        >
          <div className="row no-gutters align-items-center">
            <div className="col-auto pr-4">
              <div className="e-checkbox">
                <label htmlFor={`navigator-checkbox-${props.id}`}>
                  <input
                    id={`navigator-checkbox-${props.id}`}
                    type="checkbox"
                    data-vehicle-id={props.id}
                    data-open-online-panel="1"
                    checked={props.selected}
                    onChange={handleSelectVehicle}
                  />
                  <span />
                </label>
              </div>
            </div>
            <div
              id={String(props.id)}
              className="col"
              title={`ID: ${props.id} - ${props.name} ${
                props.driver && props.driver.name ? `- ${props.driver.name}` : ''
              }`}
            >
              <div
                className="text-truncate b-navigator-listing-item-vehicle-title"
                data-cy="navigator-carName"
                style={{ width: '150px' }}
                title={`ID: ${props.id} - ${props.name} ${
                  props.driver && props.driver.name ? `- ${props.driver.name}` : ''
                }`}
              >
                <strong>{props.name}</strong>
              </div>
              {props.driver.name && (
                <div
                  className="text-truncate b-navigator-listing-item-vehicle-driver"
                  title={`ID: ${props.id} - ${props.name} ${
                    props.driver && props.driver.name ? `- ${props.driver.name}` : ''
                  }`}
                  data-cy={`navigator-driverName-${props.driver.id}`}
                  style={{ width: '150px' }}
                >
                  {props.driver.name}
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          style={{ cursor: 'pointer' }}
          title={`ID: ${props.id} - ${props.name} ${
            props.driver && props.driver.name ? `- ${props.driver.name}` : ''
          }`}
          className="col-auto text-center b-navigator-listing-item-spz"
          onClick={handleSelectVehicleWithOnlinePanel}
          data-vehicle-id={props.id}
          data-open-online-panel="1"
          data-cy={`navigator-vehicle-online-panel-${props.id}`}
        >
          <div
            title={`ID: ${props.id} - ${props.name} ${
              props.driver && props.driver.name ? `- ${props.driver.name}` : ''
            }`}
          >
            {/* 24 x24 */}
            {svg}
            <div
              className="text-truncate"
              data-cy="navigator-licenceNumber"
              style={{ width: '60px' }}
            >
              {props.licenceNumber}
              <span className="b-navigator-listing-item-vehicle-state b-navigator-listing-item-vehicle-state--full-red" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
