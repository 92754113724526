import { atom } from 'recoil';
import {
  IDynamicTableActiveRowsState,
  IDynamicTableActiveRowState,
  IDynamicTableColumnIdState,
  IDynamicTableDetailsState,
  IDynamicTableFilterState,
  IDynamicTableMoreActiveRowsState,
  IDynamicTableOpenedMenuState,
  IDynamicTablePageChangingState,
  IDynamicTableRowHeightState,
  IDynamicTableSortingState,
  IDynamicTableState,
  IDynamicTableVariousCustomersState,
} from '../../interfaces/tables';

export interface ITableFilter {
  [columnName: string]: string[] | number | string | null;
}

export interface IDatepickerSettings {
  [key: string]: {
    type: string;
    date: Date[];
    interval: string | null;
  };
}

export const setTableFilterAtom = atom<ITableFilter | null>({
  key: 'tableFilter',
  default: null,
});

export const setTableDatepickerAtom = atom<IDatepickerSettings | null>({
  key: 'tableDatepicker',
  default: null,
});

export const tablesAtom = atom<IDynamicTableState>({
  key: 'tables',
  default: {},
});

export const setDynamicTableData = (
  state: IDynamicTableState,
  data: IDynamicTableState
): IDynamicTableState => {
  const newState = data;
  return { ...state, ...newState };
};

export const setDynamicTableFilter = (
  state: IDynamicTableState,
  data: IDynamicTableFilterState
): IDynamicTableState => {
  const { name, columnId, value } = data;
  const newState: IDynamicTableState = {
    [name]: {
      ...state[name],
      columns: {
        ...state[name].columns,
        [columnId]: {
          ...state[name].columns[columnId],
          filter: {
            ...state[name].columns[columnId].filter,
            active: true,
            selectedValue: value,
          },
        },
      },
    },
  };
  return {
    ...state,
    ...newState,
  };
};

export const deleteDynamicTableFilter = (
  state: IDynamicTableState,
  data: IDynamicTableColumnIdState
): IDynamicTableState => {
  const { name, columnId } = data;
  const newState: IDynamicTableState = {
    [name]: {
      ...state[name],
      columns: {
        ...state[name].columns,
        [columnId]: {
          ...state[name].columns[columnId],
          filter: {
            ...state[name].columns[columnId].filter,
            active: !state[name].columns[columnId].filter.active,
          },
        },
      },
    },
  };
  return {
    ...state,
    ...newState,
  };
};

export const setOpenedMenuName = (
  state: IDynamicTableState,
  data: IDynamicTableOpenedMenuState
) => {
  const { filter, name, title, label } = data;
  let newState = {};
  if (filter === undefined) {
    if (!state[name].openedMenuName[title]) {
      if (Object.keys(state[name].openedMenuName).length > 0) {
        newState = {
          [name]: {
            ...state[name],
            openedMenuName: {
              [title]: label,
            },
          },
        };
      } else {
        newState = {
          [name]: {
            ...state[name],
            openedMenuName: {
              ...state[name].openedMenuName,
              [title]: label,
            },
          },
        };
      }
    } else {
      newState = {
        [name]: {
          ...state[name],
          openedMenuName: {
            ...Object.keys(state[name].openedMenuName).filter((item) => item !== title),
            [title]: label,
          },
        },
      };
    }
  } else {
    newState = {
      [name]: { ...state[name], openedMenuName: {} },
    };
  }

  return { ...state, ...newState };
};

export const changeDynamicTablePage = (
  state: IDynamicTableState,
  data: IDynamicTablePageChangingState
): IDynamicTableState => {
  const { name, number, pagingType } = data;
  const newState: IDynamicTableState = {
    [name]: {
      ...state[name],
      paging: {
        ...state[name].paging,
        [pagingType]: number,
      },
    },
  };
  return {
    ...state,
    ...newState,
  };
};

export const changeDynamicTableSort = (
  state: IDynamicTableState,
  data: IDynamicTableSortingState
) => {
  const { columnId, sort, name } = data;
  const newState = {
    [name]: {
      ...state[name],
      sorting: {
        [columnId]: sort,
      },
    },
  };
  return {
    ...state,
    ...newState,
  };
};

export const setTableRowHeight = (
  state: IDynamicTableState,
  data: IDynamicTableRowHeightState
): IDynamicTableState => {
  const { rowHeight, name } = data;
  const newState: IDynamicTableState = {
    [name]: {
      ...state[name],
      rowHeight,
    },
  };
  return {
    ...state,
    ...newState,
  };
};

export const setTableColumnChecked = (
  state: IDynamicTableState,
  data: IDynamicTableColumnIdState
): IDynamicTableState => {
  const { columnId, name } = data;
  const newState: IDynamicTableState = {
    [name]: {
      ...state[name],
      columns: {
        ...state[name].columns,
        [columnId]: {
          ...state[name].columns[columnId],
          visible: !state[name].columns[columnId].visible,
        },
      },
    },
  };
  return {
    ...state,
    ...newState,
  };
};

// is this used?
export const toggleTripsOverviewSubdetails = (
  state: IDynamicTableState,
  data: IDynamicTableDetailsState
): IDynamicTableState => {
  const { id, name } = data;
  const newState: IDynamicTableState = {
    [name]: {
      ...state[name],
      subdetails: id,
    },
  };
  return {
    ...state,
    ...newState,
  };
};

export const setTableActiveRow = (
  state: IDynamicTableState,
  data: IDynamicTableActiveRowState
): IDynamicTableState => {
  const { id, name } = data;

  const rows =
    state[name].activeRows.findIndex((k: any) => String(k) === String(id)) === -1
      ? [id, ...state[name].activeRows]
      : Object.values(state[name].activeRows).filter((i: any) => String(i) !== String(id));
  const newState: IDynamicTableState = {
    [name]: {
      ...state[name],
      activeRows: rows,
    },
  };

  return {
    ...state,
    ...newState,
  };
};

// is this used?
export const toggleTripsOverviewDetails = (
  state: IDynamicTableState,
  data: IDynamicTableDetailsState
): IDynamicTableState => {
  const { id, name } = data;
  const newState: IDynamicTableState = {
    [name]: {
      ...state[name],
      details: id,
    },
  };
  return {
    ...state,
    ...newState,
  };
};

export const setTableAllActiveRows = (
  state: IDynamicTableState,
  data: IDynamicTableActiveRowsState
): IDynamicTableState => {
  const { name } = data;
  const rows =
    state[name].activeRows.length === state[name].records.length
      ? []
      : state[name].records.map((x: any) => x.id);
  const newState = {
    [name]: {
      ...state[name],
      activeRows: rows,
    },
  };
  return {
    ...state,
    ...newState,
  };
};

export const setTripsTableAllActiveRows = (
  state: IDynamicTableState,
  data: IDynamicTableActiveRowsState
): IDynamicTableState => {
  const { name } = data;
  const rideIds: any = [];
  Object.values(state[name].records).forEach((i: any) => {
    Object.values(i.vehicles).forEach((j: any) => {
      Object.values(j.records).forEach((k: any) => {
        rideIds.push(k.rideId);
      });
    });
  });
  const rows = state[name].activeRows.length === rideIds.length ? [] : rideIds;
  const newState: IDynamicTableState = {
    [name]: {
      ...state[name],
      activeRows: rows,
    },
  };
  return {
    ...state,
    ...newState,
  };
};

export const setTableMoreActiveRows = (
  state: IDynamicTableState,
  data: IDynamicTableMoreActiveRowsState
): IDynamicTableState => {
  const { name, ids, status } = data;
  const rows =
    status === 'next'
      ? state[name].activeRows.filter((i: any) => ids.indexOf(i) === -1).concat(ids)
      : state[name].activeRows.filter((i: any) => ids.indexOf(i) === -1);
  const newState: IDynamicTableState = {
    [name]: {
      ...state[name],
      activeRows: rows,
    },
  };
  return {
    ...state,
    ...newState,
  };
};

export const setTableVariousCustomers = (
  state: IDynamicTableState,
  data: IDynamicTableVariousCustomersState
): IDynamicTableState => {
  const { customerIds, rideTypes, locked, name, noParking, drivers } = data;
  const newState: IDynamicTableState = {
    [name]: {
      ...state[name],
      rideTypes,
      noParking,
      locked,
      chosenCustomers: customerIds,
      drivers,
    },
  };
  return {
    ...state,
    ...newState,
  };
};

export const setTableActiveMultipleAction = (
  state: IDynamicTableState,
  data: IDynamicTableDetailsState
): IDynamicTableState => {
  const { id, name } = data;
  const newState = {
    [name]: {
      ...state[name],
      activeMultipleAction: id,
    },
  };
  return {
    ...state,
    ...newState,
  };
};
