export const URL_TRIPS_OVERVIEW = '/overview_tours';
export const URL_CORRECTIONS = '/corrections';
export const URL_SUPPLIERS = '/suppliers';
export const URL_LOGIN = '/login';
export const URL_VEHICLES_SETTINGS = '/cars_settings';
export const URL_ABOUT = '/about';
export const URL_ONLINE_HELP = 'https://www.commander.sk/manual/ccc/Index.html';
export const URL_MAPS = '/maps';
export const URL_MAP = '/map';
export const URL_ALARM = '/alarm';
export const URL_REFUELING = '/refuelings';
export const URL_CAN_REFUELINGS = '/can-refuelings';
export const URL_EXPENSE_IMPORT = '/expenses_import';
export const URL_EXPENSE_TYPES = '/expense_types';
export const URL_GDPR_PROFILE = '/gdpr-profile';
export const URL_GDPR_USER = '/gdpr-user';
export const URL_FUEL_CARDS = '/fuel_cards';
export const URL_EMPLOYEE = '/employee';
export const URL_AUTO_REPORTS = '/auto-reports';
export const URL_ACTIVATE_ACCOUNT = '/activate-account';
export const URL_EXPIRED_PASSWORD = '/expired-password';
export const URL_FORGOT_PASSWORD = '/forgot-password';
export const URL_RESET_PASSWORD = '/reset-password';
export const URL_REPORTS = '/reports';
export const URL_REPORT_DOWNLOAD = '/report/download';
export const URL_ONLINE_PANEL = '/online_panel';
export const URL_VEHICLES_GROUPS = '/cars_groups_settings';
export const URL_CODEBOOKS = '/system_settings';
export const URL_TRIP_MAP = '/trip-map';
export const URL_GRAPHS = '/graphs';
export const URL_FUEL_LEVEL_LITERS = '/fuel-level-liters';
export const URL_BATTERY_VOLTAGE = '/battery-voltage';
export const URL_RPM = '/rpm';
export const URL_FUEL_LEVEL_PERCENTAGE = '/fuel-level-percentage';
export const URL_THROTTLE = '/throttle';
export const URL_ECO_DRIVE = '/eco_drive';
export const URL_VELOCITY = '/velocity';
export const URL_TRIPS_OVERVIEW_WITH_ECO_DRIVE = '/overview_tours/eco_drive';
export const URL_BLUECOINS = '/bluecoins';
export const URL_BLUECOINS_USAGE = '/bluecoins/usage';
export const URL_EV_BATTERY_KW = '/ev-battery-abs';
export const URL_EV_BATTERY_PERCENT = '/ev-battery-percent';
export const URL_AD_BLUE_PERCENT = '/ad-blue-percent';
export const URL_LOGOUT = '/logout';
