import { CmdRangeSelect } from '@commander-services/gui-components';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilState, useSetRecoilState } from 'recoil';
import dayjs from 'dayjs';
import useKeyPress, { ESCAPE_KEY } from '../../../hooks/useKeyPress';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { ITLCFilter, ICmdTableData } from '../interfaces';
import { ID_RESET, INVALID_DATE, RANGE_SELECT_CLASSNAME } from '../constants';
import * as CmdTableState from '../CmdTableState';

interface IRangeDurationFilterProps {
  id: string;
  tableName: string;
}

function RangeDurationFilter(props: IRangeDurationFilterProps): JSX.Element {
  const [tableActionsFilters, setTableActionsFilters] = useRecoilState(
    CmdTableState.tableFilter({ [props.tableName]: props.id })
  );
  const setFilterForRequest = useSetRecoilState<ITLCFilter>(
    CmdTableState.filterForRequest(props.tableName)
  );
  const { formatMessage: f } = useIntl();
  const clickOutsideRef: React.RefObject<HTMLDivElement> = React.createRef();

  useOutsideClick(clickOutsideRef, (event) => {
    const { id } = event.target;
    const elementClass = event.target.className;

    if (
      id !== props.id &&
      !RANGE_SELECT_CLASSNAME.find(
        (item) => typeof elementClass === 'string' && elementClass.includes(item)
      ) &&
      id !== ID_RESET &&
      ((tableActionsFilters && tableActionsFilters.isOpen) ||
        (tableActionsFilters &&
          tableActionsFilters.isActionChipsOpen &&
          tableActionsFilters.isFilterActive))
    ) {
      setTableActionsFilters(
        (tableActionFilter) =>
          tableActionFilter && {
            ...tableActionFilter,
            id: props.id,
            isOpen: false,
            isActionChipsOpen: false,
          }
      );
    }
  });

  useKeyPress(ESCAPE_KEY, () => {
    setTableActionsFilters(
      (tableActionFilter) =>
        tableActionFilter && {
          ...tableActionFilter,
          id: props.id,
          isOpen: false,
          isActionChipsOpen: false,
        }
    );
  });

  const handleRangeFilter = (from: string, to: string) => {
    let defaultFrom = from;
    let defaultTo = to;

    if (!from && !to) {
      defaultFrom = '00:00';
      defaultTo = '23:59';
    }

    const timeFrom =
      dayjs(defaultFrom).format('HH:mm') !== INVALID_DATE
        ? dayjs(defaultFrom).format('HH:mm')
        : defaultFrom;

    const timeTo =
      dayjs(defaultTo).format('HH:mm') !== INVALID_DATE
        ? dayjs(defaultTo).format('HH:mm')
        : defaultTo;
    const [hourFrom, minutesFrom] = timeFrom.split(':').map((val) => Number(val));
    const convertFrom = dayjs.duration({ hours: hourFrom, minutes: minutesFrom }).asSeconds();
    const [hourTo, minutesTo] = timeTo.split(':').map((val) => Number(val));
    const convertTo = dayjs.duration({ hours: hourTo, minutes: minutesTo }).asSeconds();

    const data = {};

    data[props.id] = { min: Number(convertFrom), max: Number(convertTo) };

    setFilterForRequest((prevFilter) => ({
      ...prevFilter,
      ...data,
    }));

    setTableActionsFilters(
      (tableActionFilter) =>
        tableActionFilter && {
          ...tableActionFilter,
          id: props.id,
          isOpen: false,
          isActionChipsOpen: false,
          isFilterActive: true,
          values: {
            value: { min: convertFrom, max: convertTo },
            item: `${timeFrom} h - ${timeTo} h`,
            title: f({ id: 'table_list.columns.rideBook.duration' }),
            type: 'rangeSelect',
            min: from,
            max: to,
            unit: 'h',
            noteFrom: `hh:mm`,
            noteTo: `hh:mm`,
          },
        }
    );
  };

  return (
    <div id={props.id} className="open" ref={clickOutsideRef}>
      {tableActionsFilters && tableActionsFilters.data && (
        <CmdRangeSelect
          handleRangeFilter={handleRangeFilter}
          fromText={f({ id: 'table_list.columnFilters.rangeSelect.min' })}
          toText={f({ id: 'table_list.columnFilters.rangeSelect.max' })}
          noteFrom="hh:mm"
          noteTo="hh:mm"
          valueFrom={
            tableActionsFilters.values && tableActionsFilters.values.min
              ? tableActionsFilters.values.min
              : '00:00'
          }
          valueTo={
            tableActionsFilters.values && tableActionsFilters.values.max
              ? tableActionsFilters.values.max
              : '23:59'
          }
          applyText={f({ id: 'table.apply' })}
          resetText={f({ id: 'table.filter.reset' })}
          modeTime={true}
        />
      )}
    </div>
  );
}

export default RangeDurationFilter;
