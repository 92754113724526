import { ISimpleSelectOption } from '../components/Forms/ArrayFields/interfaces';
import ApiService from './ApiService';
import { IInput } from './FormikValidatorService';

export const enableMaxActualMonth = (
  values: ISimpleSelectOption[],
  month: number
): ISimpleSelectOption[] => {
  const months = values.filter((option: ISimpleSelectOption) => Number(option.value) <= month);
  return months;
};

export const findValuesForSelect = (inputs: IInput[], inputName: string): ISimpleSelectOption[] => {
  const input = inputs.find((inputItem: IInput) => inputItem.indexId === inputName);
  if (input && input.values) {
    return input.values;
  }
  return [];
};

const ReportService = {
  async activateReport(autoReportIds: string[]): Promise<any> {
    const response = await ApiService.put(`/v1/auto-report/activate`, { autoReportIds }, true);
    return response ? response.data : null;
  },

  async deactivateReport(autoReportIds: string[]): Promise<any> {
    const response = await ApiService.put(`/v1/auto-report/deactivate`, { autoReportIds }, true);
    return response ? response.data : null;
  },
};

export default ReportService;
