import { CmdRangeFilterForm, CmdRangeInput } from '@commander-services/gui-components';
import * as React from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import useKeyPress, { ESCAPE_KEY } from '../../../hooks/useKeyPress';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { ITLCFilter } from '../interfaces';
import { DURATION_SUFFIX } from '../constants';
import { AVARAGE_SPEED_KEY, MAXIMAL_SPEED_KEY } from '../../TripsOverviewNew/constants';
import * as CmdTableState from '../CmdTableState';
import { transformErrors } from '../../../Services/HookFormService';

interface IRangeSelectFilterValues extends FieldValues {
  from: string | number;
  to: string | number;
}
interface IRangeSelectFilterProps {
  id: string;
  title: string;
  tableName: string;
  valueFrom?: string | number;
  valueTo?: string | number;
  defaultMinValue: string | number;
  defaultMaxValue: string | number;
  suffix?: string;
  note?: string;
}

const validateValues = (
  values: IRangeSelectFilterValues,
  props: IRangeSelectFilterProps,
  f: IntlShape['formatMessage']
): Record<string, string> => {
  const errors: Record<string, string> = {};
  const convertedValues = { ...values };
  convertedValues.from = Number(String(values.from).replace(',', '.'));
  convertedValues.to = Number(String(values.to).replace(',', '.'));

  if (!values.from) {
    errors.from = `${f({ id: 'waypoints.edit.title.required' })}, ${f({
      id: 'ride.menu.speedlimit.validation',
    })}`;
  }
  if (!values.to) {
    errors.to = `${f({ id: 'waypoints.edit.title.required' })}, ${f({
      id: 'ride.menu.speedlimit.validation',
    })}`;
  }

  if (Number(convertedValues.from) < Number(props.defaultMinValue)) {
    errors.from = `${f({ id: 'tables.tripsOverview.rangeSelect.greater.than' })} ${props.defaultMinValue}`;
  }
  if (Number(convertedValues.from) >= Number(convertedValues.to)) {
    errors.from = `${f({ id: 'datepicker.spanFromToValidation' })}`;
  }
  if (
    Number(convertedValues.to) > Number(props.defaultMaxValue) &&
    Number(convertedValues.to) >= Number(props.defaultMinValue) &&
    Number(convertedValues.to) > Number(convertedValues.from)
  ) {
    errors.to = `${f({ id: 'tables.tripsOverview.rangeSelect.less.greater' })} ${props.defaultMaxValue}`;
  }

  return errors;
};

const resolver = async (data: IRangeSelectFilterValues, context) => {
  const { props, f } = context;
  const validateErrors = validateValues(data, props, f);
  const formErrors = transformErrors(validateErrors);
  return {
    values: formErrors && Object.keys(formErrors).length ? {} : data,
    errors: formErrors,
  };
};

function RangeSelectFilter(props: IRangeSelectFilterProps): JSX.Element {
  const [tableActionsFilters, setTableActionsFilters] = useRecoilState(
    CmdTableState.tableFilter({ [props.tableName]: props.id })
  );
  const { formatMessage: f } = useIntl();
  const setFilterForRequest = useSetRecoilState<ITLCFilter>(
    CmdTableState.filterForRequest(props.tableName)
  );

  const clickOutsideRef: React.RefObject<HTMLDivElement> = React.createRef();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    trigger,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      from: props.valueFrom ? props.valueFrom : props.defaultMinValue,
      to: props.valueTo ? props.valueTo : props.defaultMaxValue,
    },
    resolver: async (values) => resolver(values, { props, f }),
  });

  useOutsideClick(clickOutsideRef, () => {
    setTableActionsFilters(
      (tableActionFilter) =>
        tableActionFilter && {
          ...tableActionFilter,
          id: props.id,
          isOpen: false,
          isActionChipsOpen: false,
        }
    );
  });

  useKeyPress(ESCAPE_KEY, () => {
    setTableActionsFilters(
      (tableActionFilter) =>
        tableActionFilter && {
          ...tableActionFilter,
          id: props.id,
          isOpen: false,
          isActionChipsOpen: false,
        }
    );
  });

  const getValuesForSelect = (from: string, to: string) => {
    if (props.suffix === DURATION_SUFFIX) {
      return { min: Number(from) * 60, max: Number(to) * 60 };
    }
    return { min: Number(from), max: Number(to) };
  };

  const handleOnSubmit = (values) => {
    const data = {};

    const convertedValues = { ...values };
    convertedValues.from = Number(String(values.from).replace(',', '.'));
    convertedValues.to = Number(String(values.to).replace(',', '.'));
    data[props.id] = getValuesForSelect(convertedValues.from, convertedValues.to);

    setFilterForRequest((prevFilter) => ({
      ...prevFilter,
      ...data,
    }));
    setTableActionsFilters(
      (tableActionFilter) =>
        tableActionFilter && {
          ...tableActionFilter,
          id: props.id,
          isOpen: false,
          isActionChipsOpen: false,
          isFilterActive: true,
          values: {
            value: { min: Number(values.from), max: Number(values.to) },
            item: `${values.from} ${props.suffix ? props.suffix : ''} - ${values.to} ${
              props.suffix ? props.suffix : ''
            }`,
            title: props.title,
            type: 'rangeSelect',
            min: values.from,
            max: values.to,
            unit: props.note,
            suffixInput: props.suffix,
            defaultMin: tableActionsFilters?.data?.minValue,
            defaultMax: tableActionsFilters?.data?.maxValue,
          },
        }
    );
  };

  const handleReset = () => {
    setValue('from', props.defaultMinValue);
    setValue('to', props.defaultMaxValue);
    trigger();
  };

  return (
    <div
      className="table-filter"
      ref={clickOutsideRef}
      style={{ position: 'absolute', left: '-8px', top: '-19px', zIndex: 999 }}
    >
      {tableActionsFilters && tableActionsFilters.data && (
        <CmdRangeFilterForm
          id={props.id}
          submitCallback={handleSubmit(handleOnSubmit)}
          closeCallback={handleReset}
          submitBtnTitle={f({ id: 'table.apply' })}
        >
          <Controller
            name="from"
            control={control}
            render={({ field }) => (
              <CmdRangeInput
                {...field}
                id="from"
                label={f({ id: 'table_list.columnFilters.rangeSelect.min' })}
                type="float"
                currency={props.suffix}
                note={props.note}
                noDecimal={!!(props.id === MAXIMAL_SPEED_KEY || props.id === AVARAGE_SPEED_KEY)}
                setValue={(value) => {
                  setValue('from', value);
                  trigger();
                }}
                className="no-drag"
                error={errors?.from?.message}
                touched={true}
              />
            )}
          />
          <Controller
            name="to"
            control={control}
            render={({ field }) => (
              <CmdRangeInput
                {...field}
                id="to"
                label={f({ id: 'table_list.columnFilters.rangeSelect.max' })}
                type="float"
                currency={props.suffix}
                setValue={(value) => {
                  setValue('to', value);
                  trigger();
                }}
                note={props.note}
                margin={true}
                noDecimal={!!(props.id === MAXIMAL_SPEED_KEY || props.id === AVARAGE_SPEED_KEY)}
                className="no-drag"
                error={errors?.to?.message}
                touched={true}
              />
            )}
          />
        </CmdRangeFilterForm>
      )}
    </div>
  );
}

export default RangeSelectFilter;
