import * as React from 'react';

export const ESCAPE_KEY = 'Escape';

const useKeyPress = (targetKey: string, callback: () => void): void => {
  const handleKey = ({ key }) => {
    if (key === targetKey) {
      callback();
    }
  };

  React.useEffect(() => {
    document.addEventListener('keyup', handleKey);

    return () => {
      document.removeEventListener('keyup', handleKey);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useKeyPress;
